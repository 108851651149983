import { TenderSchema } from '../model';

export function getAgreementSubmit(values: TenderSchema, is_blocked_work_params: boolean) {
  const workParametersFull =
    !is_blocked_work_params &&
    typeof values.base_cost_price === 'number' &&
    typeof values.margin === 'number' &&
    !!values.calculation_files?.length &&
    !!values.request_file &&
    typeof values.specialist_min_cost_price === 'number' &&
    !!values.min_cost_price_reason;
  return workParametersFull;
}
