import { RadioButton, RadioButtonOption } from '@gravity-ui/uikit';
import { useAtomValue } from 'jotai';
import { useState } from 'react';

import {
  CalendarCardProps,
  CommercialOfferStatuses,
  ContractStage,
  TenderStatuses,
} from '@entities';
import { InfiniteCalendar } from '@features';
import { cn } from '@shared/lib';
import { compactAtom } from '@shared/model';
import { RightSidebar } from '@shared/ui';

interface CalendarDrawerProps {
  open: boolean;
  onClose: () => void;
  entityType: CalendarCardProps['entityType'];
}
export function CalendarDrawer({ open, onClose, entityType }: CalendarDrawerProps) {
  const commercialOffersOptions: RadioButtonOption<CommercialOfferStatuses | ''>[] = [
    {
      value: '',
      content: 'Все',
    },
    {
      value: 'Подача',
      content: 'Подача',
    },
    {
      value: 'Ожидание',
      content: 'Ожидание',
    },
    {
      value: 'Торги опубликованы',
      content: 'Торги опубликованы',
    },
    {
      value: 'Выигран',
      content: 'Выигран',
    },
    {
      value: 'Проигран',
      content: 'Проигран',
    },
    {
      value: 'Отказ',
      content: 'Отказ',
    },
    {
      value: 'Отмена',
      content: 'Отмена',
    },
    {
      value: 'Не отработано',
      content: 'Не отработано',
    },
    {
      value: 'Не подано',
      content: 'Не подано',
    },
  ];
  const tendersOptions: RadioButtonOption<'' | TenderStatuses>[] = [
    {
      value: '',
      content: 'Все',
    },
    {
      value: 'Выигран',
      content: 'Выигран',
    },
    {
      value: 'Подача',
      content: 'Подача',
    },
    {
      value: 'Ожидание',
      content: 'Ожидание',
    },
    {
      value: 'Проигран',
      content: 'Проигран',
    },
    {
      value: 'Отклонение',
      content: 'Отклонение',
    },
    {
      value: 'Отказ',
      content: 'Отказ',
    },
    {
      value: 'Не подано',
      content: 'Не подано',
    },
  ];
  const contractsOptions: RadioButtonOption<'' | ContractStage>[] = [
    {
      value: '',
      content: 'Все',
    },
    {
      value: 'Проект',
      content: 'Проект',
    },
    {
      value: 'Заключен',
      content: 'Заключен',
    },
    {
      value: 'Рассчитан',
      content: 'Рассчитан',
    },
    {
      value: 'Исполнен',
      content: 'Исполнен',
    },
    {
      value: 'Расторгнут',
      content: 'Расторгнут',
    },
  ];

  const isCompactNavSidebar = useAtomValue(compactAtom);
  const [selectedStatus, setSelectedStatus] = useState<CalendarCardProps['selectedStatus']>('');

  const title = entityType === 'КП' ? 'КП' : entityType === 'Контракты' ? 'Контрактов' : 'Торгов';

  function getOptionsByEntityName() {
    switch (entityType) {
      case 'КП':
        return commercialOffersOptions;
      case 'Торги':
        return tendersOptions;
      case 'Контракты':
        return contractsOptions;
    }
  }

  return (
    <RightSidebar
      open={open}
      onClose={onClose}
      className={cn({
        'w-[calc(100%_-_56px)]': isCompactNavSidebar,
        'w-[calc(100%_-_236px)]': !isCompactNavSidebar,
      })}
    >
      <RightSidebar.Header
        title={'Календарь ' + title}
        onClose={onClose}
        className="pt-[26px] pr-5 pb-[10px] pl-8"
      />
      <RadioButton
        options={getOptionsByEntityName()}
        size="l"
        className="mb-6 ml-8 mr-auto"
        onUpdate={option => {
          setSelectedStatus(option);
        }}
      />
      <InfiniteCalendar
        entityType={entityType}
        selectedStatus={selectedStatus}
      />
    </RightSidebar>
  );
}
