import { Xmark } from '@gravity-ui/icons';
import { Button, Checkbox, Icon, RadioGroup, Text, User } from '@gravity-ui/uikit';

import './index.sass';

import { DatePicker } from '@gravity-ui/date-components';
import { dateTime } from '@gravity-ui/date-utils';
import { useAtom } from 'jotai';

import { cn } from '@shared/lib';

import { TYPE_OPTIONS, TYPE_OPTIONS_WITHOUT_ACTION } from '../../constants';
import { getStatusOptions } from '../../lib';
import { chatFiltersAtom, ChatFilterStatus, ChatFilterType } from '../../model';

export interface FilterChatProps {
  open: boolean;
  users: Array<{ id: string; name: string; avatarImageURL: string }>;
  allowedStatuses?: ChatFilterStatus[];
  hideActionType?: boolean;
  onClose: () => void;
}

export function FilterChat({
  open,
  users,
  allowedStatuses,
  hideActionType,
  onClose,
}: FilterChatProps) {
  const [chatFilters, setChatFilters] = useAtom(chatFiltersAtom);

  return (
    <div
      className={cn(
        'filter-chat-form border-l border-base-generic shrink-0 flex flex-col gap-2 py-4',
        {
          open: open,
          close: !open,
        }
      )}
    >
      <div className="flex items-center justify-between px-4">
        <Text
          variant="subheader-2"
          color="primary"
        >
          Фильтры
        </Text>
        <Button
          view="flat"
          size="l"
          onClick={onClose}
        >
          <Icon data={Xmark} />
        </Button>
      </div>
      <div className="flex flex-col gap-6 px-4">
        <div className="flex flex-col gap-2">
          <Text
            variant="body-1"
            color="secondary"
          >
            Дата и время
          </Text>
          <DatePicker
            size="l"
            hasClear
            format="DD/MM/YYYY HH:mm:ss"
            value={chatFilters.date}
            maxValue={dateTime({ input: Date.now() })}
            onUpdate={date => setChatFilters(prev => ({ ...prev, date }))}
          />
        </div>
        <div className="flex flex-col gap-3">
          <Text
            variant="body-1"
            color="secondary"
          >
            Люди
          </Text>
          {users.map(({ id, name, avatarImageURL }) => (
            <label
              key={id}
              htmlFor={`filter-chat-form-user-${id}`}
              className="flex items-center gap-[9px] cursor-pointer"
              aria-label={name}
            >
              <User
                className="grow"
                size="s"
                name={name}
                avatar={{
                  text: name,
                  imgUrl: avatarImageURL,
                  alt: name,
                }}
              />
              <Checkbox
                id={`filter-chat-form-user-${id}`}
                checked={!!chatFilters.userIds?.includes(id)}
                onUpdate={checked => {
                  setChatFilters(prev => {
                    const userIds = checked
                      ? prev.userIds
                        ? [...prev.userIds, id]
                        : [id]
                      : prev.userIds && prev.userIds.length > 1
                        ? prev.userIds.filter(userId => userId !== id)
                        : null;

                    return {
                      ...prev,
                      userIds,
                    };
                  });
                }}
              />
            </label>
          ))}
        </div>
        <div className="flex flex-col gap-3">
          <Text
            variant="body-1"
            color="secondary"
          >
            Тип
          </Text>
          <RadioGroup
            className="flex flex-col gap-3"
            options={hideActionType ? TYPE_OPTIONS_WITHOUT_ACTION : TYPE_OPTIONS}
            value={chatFilters.type ?? 'all'}
            onUpdate={type =>
              setChatFilters(prev => ({
                ...prev,
                type: type === 'all' ? null : (type as ChatFilterType), // в onUpdate прокидывается string
              }))
            }
          />
        </div>
        {allowedStatuses && (
          <div className="flex flex-col gap-3">
            <Text
              variant="body-1"
              color="secondary"
            >
              Статус
            </Text>
            <RadioGroup
              className="flex flex-col gap-3"
              options={getStatusOptions(allowedStatuses)}
              value={chatFilters.status ?? 'all'}
              onUpdate={status =>
                setChatFilters(prev => ({
                  ...prev,
                  status: status === 'all' ? null : (status as ChatFilterStatus), // в onUpdate прокидывается string
                }))
              }
            />
          </div>
        )}
      </div>
    </div>
  );
}
