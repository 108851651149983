import { RestrictionAdvantageRequirementStatus } from '@entities';
import { yupRu } from '@shared/config';
import { schemaFields } from '@shared/lib';

export const restrictionAdvantageRequirementsSchema = yupRu.object({
  /** @description Наименование */
  name: schemaFields.text().required(),
  /**
   * @description Статус
   * @enum {'Действующий' | 'Недействующий'}
   */
  status: schemaFields.radio<RestrictionAdvantageRequirementStatus>([
    'Действующий',
    'Недействующий',
  ]),
  /** @description Основание */
  basis_id: schemaFields.id(),
  /** @description Механизм применения */
  usage_mechanism: schemaFields.textArea(),
});

export type RestrictionAdvantageRequirementsSchema = yupRu.InferType<
  typeof restrictionAdvantageRequirementsSchema
>;
