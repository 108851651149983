import { SupplierHasContract, SupplierStatus, SupplierType } from '@entities';
import { yupRu } from '@shared/config';
import { schemaFields } from '@shared/lib';

export const suppliersSchema = yupRu.object({
  /** @description Наименование */
  name: schemaFields.text().required(),
  /** @description Полное наименование */
  full_name: schemaFields.text(),
  /** @description Товарный знак */
  trademark: schemaFields.text().required(),
  /** @description ИНН */
  inn: yupRu
    .string()
    .min(10)
    .max(12)
    .test({
      test: value => {
        if (value === undefined) return false;
        try {
          return !!BigInt(value);
        } catch {
          return false;
        }
      },
      message: 'Должен состоять из цифр',
    })
    .test({
      test: value => typeof value === 'string' && (value.length === 10 || value.length === 12),
      message: 'ИНН состоит из 10 или 12 арабских цифр',
    })
    .required(),
  /** @description КПП */
  kpp: yupRu
    .string()
    .test({
      test: value => {
        if (value === undefined) return false;
        try {
          return !!BigInt(value);
        } catch {
          return false;
        }
      },
      message: 'Должен состоять из цифр',
    })
    .min(9)
    .max(9)
    .transform(v => v || undefined)
    .required(),
  /** @description Регион фактического нахождения офиса */
  region_id: schemaFields.select().required(),
  /**
   * @description Тип поставщика
   * @enum {string}
   */
  type: schemaFields.radio<SupplierType>(['ОФР', 'Постоянный', 'Разовый']).required(),
  /**
   * @description Статус поставщика
   * @enum {string}
   */
  status: schemaFields
    .radio<SupplierStatus>(['Производитель', 'Вендор', 'Дистрибьютер', 'Дилер', 'Работы', 'Услуги'])
    .required(),
  /**
   * @description Договор
   * @enum {string}
   */
  has_contract: schemaFields.radio<SupplierHasContract>(['Есть', 'Нет']).required(),
  /** @description Договор */
  contract_id: schemaFields.select(),
  /** @description Объект поставок */
  purchase_object_id: schemaFields.select().required(),
  /** @description Сайт */
  website: schemaFields.text().url().required(),
  /** @description Теги (краткое описание) */
  tags: schemaFields.tags(),
  /** @description Файлы: прайсы, каталоги, презентации, прочее */
  files: schemaFields.multiFile(),
  /** @description Контакты: */
  contact_ids: schemaFields.multiSelect().requiredArray(),
  /** @description Адреса */
  addresses: schemaFields.tags(),
  /** @description Платежные реквизиты */
  payment_detail_id: schemaFields.select(),
});

export type SuppliersSchema = yupRu.InferType<typeof suppliersSchema>;
