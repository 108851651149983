import { useToaster } from '@gravity-ui/uikit';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import { $regionsHooks } from '@entities';
import { CreateHandbookDialog, regionsFormAtom } from '@features';
import { bodyResponseType } from '@shared/api';
import { RightSidebar } from '@shared/ui';

import { RegionsLayout } from './RegionsLayout';

interface CreateRegionsProps {
  open: boolean;
  onClose: () => void;
  afterCreate?: (createdRegion: bodyResponseType<'post', '/handbook/regions'>) => void;
  withDialog?: boolean;
}

export function CreateRegions({ open, onClose, afterCreate, withDialog }: CreateRegionsProps) {
  const { add } = useToaster();

  const queryClient = useQueryClient();

  const [regionsCreation, setRegionsCreation] = useAtom(regionsFormAtom);

  // Queries
  const createRegionsMutation = $regionsHooks.createMutation();

  //

  return (
    <RegionsLayout
      formProps={{
        defaultValues: regionsCreation,
        onValuesChange: values => {
          setRegionsCreation(values);
        },
        onClickCancelButton: () => {
          setRegionsCreation(undefined);
          onClose();
        },
        compactBottomPanel: true,
        // disabled: underApproval,
        initialEditable: true,
        onValid: async values => {
          await createRegionsMutation
            .mutateAsync({
              body: values,
            })
            .then(createdRegion => {
              add({
                name: 'create-regions-success',
                theme: 'success',
                content: 'Карточка Региона успешна создана!',
              });
              queryClient.invalidateQueries({ queryKey: ['/regions/all'] });
              afterCreate?.(createdRegion);

              setRegionsCreation(undefined);
              onClose();
            })
            .catch(() => {
              add({
                name: 'create-regions-error',
                theme: 'danger',
                content: 'Ошибка при создании карточки Региона',
              });
            });
        },
        onInvalid: errors => {
          console.log('@errors', errors);
        },
      }}
      renderForm={formComponent =>
        withDialog ? (
          <CreateHandbookDialog
            onClose={onClose}
            open={open}
            title="Создание карточки Региона"
          >
            {formComponent}
          </CreateHandbookDialog>
        ) : (
          <RightSidebar
            open={open}
            onClose={onClose}
          >
            <RightSidebar.Header
              title="Создание карточки Региона"
              onClose={onClose}
            />
            {formComponent}
          </RightSidebar>
        )
      }
    />
  );
}
