export const LOCAL_STORAGE = {
  token: 'token',
  agreementsTabs: 'agreements_tabs',
  agreementsSelectedTab: 'agreements_selected_tab',
  activeTabChat: 'activeTabChat',
  commercialOffersTabs: 'commercial_offers_tabs',
  commercialOffersSelectedTab: 'commercial_offers_selected_tab',
  tendersTabs: 'tenders_tabs',
  tendersSelectedTab: 'tenders_selected_tab',
  contractsTabs: 'contracts_tabs',
  contractsSelectedTab: 'contracts_selected_tab',
  user: 'user',
};
