import { dateTime } from '@gravity-ui/date-utils';

import { CRMTableData } from '@features/table';
import { components } from '@shared/api';
import { DATE_FORMATS } from '@shared/consts';
import { formatToPrice } from '@shared/lib';

import { ColumnsHandbookTable } from '../model';

const parseDate = (date: string, withTime?: boolean) =>
  dateTime({ input: date }).format(
    !withTime ? DATE_FORMATS.fullDate : DATE_FORMATS.fullDateWithTimeRounded
  );

const parseFloat = (float: number): number => {
  return float / 100;
};

const parseMultiple = (names: string[]): string => {
  try {
    return names.join(' \n');
  } catch {
    return '';
  }
};

export const parseTableData = (
  tableData: NonNullable<components['schemas']['handbook.Customers']['list']>
): CRMTableData<ColumnsHandbookTable> => {
  return tableData.map(table => {
    return {
      'Дата, номер': table.created_at ? `${parseDate(table.created_at)} №${table.id}` : '',
      'Краткое наименование': table.name,
      'Полное наименование': table.full_name || '',
      ИНН: table.inn,
      КПП: table.kpp,
      'Тип заказчика': table.customer_type || '',
      'Уникальный номер': table.unique_number || '',
      Приоритет: table.priority || '',
      'Статус клиента': table.client_status || '',
      Комментарий: table.comment || '',
      Уровень: table.customer_level || '',
      'Вышестоящее ведомство': table.higher_agency_value || '',
      Ответственный: table.responsible_value || '',
      'Руководитель ответственного': table.head_responsible_value || '',
      Контакт: table.contact_values ? parseMultiple(table.contact_values) : '',
      Адрес: table.address || '',
      'Платежные реквизиты': table.payment_detail_value || '',
    };
  });
};
