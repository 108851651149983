import { ContractSchema } from '@features';

import { CreateContract } from './CreateContract';
import { EditContract } from './EditContract';

type ContractDrawerProps = {
  onClose: () => void;
} & (
  | {
      type: 'create';
      open: boolean;
      id?: undefined;
      afterCreate?: (contract: ContractSchema) => void;
    }
  | {
      type: 'edit';
      open?: undefined;
      id?: string;
      afterCreate?: undefined;
    }
);

export function ContractDrawer({ type, open, onClose, id, afterCreate }: ContractDrawerProps) {
  return (
    <>
      {type === 'create' ? (
        <CreateContract
          open={open}
          onClose={onClose}
          afterCreate={afterCreate}
        />
      ) : (
        <EditContract
          onClose={onClose}
          id={id}
        />
      )}
    </>
  );
}
