/* eslint-disable @typescript-eslint/no-unused-vars */
import { dateTime } from '@gravity-ui/date-utils';

import {
  CRMTableFilters,
  DateFilterValueType,
  FilterBooleanType,
  FilterValueType,
  NumericFilterValueType,
  SearchFilterValueType,
  SelectFilterValueType,
} from '@features';
import { bodyRequestType, bodyResponseType } from '@shared/api';
import { DATE_FORMATS } from '@shared/consts';
import { WinnerType, WinnerValue } from '@shared/ui';

import { ColumnsContractsTable, frontKeyBackendValueFilters } from '../model';

type BooleanValue = FilterBooleanType['value'];

const checkNumeric = (min?: number, max?: number): NumericFilterValueType => {
  if (typeof min !== 'number' && typeof max !== 'number') {
    return;
  }
  //@ts-expect-error Неверная компиляция для условия
  return { from: min, to: max };
};

const checkDate = (min?: string, max?: string, isFull?: boolean): DateFilterValueType => {
  if (typeof min !== 'string' && typeof max !== 'string') {
    return;
  }
  const format = isFull ? DATE_FORMATS.fullDateWithTimeRounded : DATE_FORMATS.fullDate;
  if (min !== undefined) min = dateTime({ input: min }).format(format);
  if (max !== undefined) max = dateTime({ input: max }).format(format);

  //@ts-expect-error Неверная компиляция для условия
  return { from: min, to: max };
};

const checkBoolean = (bool?: string): BooleanValue => {
  if (bool) return bool as BooleanValue;
  return;
};

const displayBooleanFilterToBackendFilter = (filter: FilterValueType) => {
  if (filter.type !== 'boolean' || !filter.value) {
    return '';
  }
  return filter.value;
};

const displayDateToISOSString = (date?: string, withTime?: boolean) => {
  if (!date) return;
  if (!withTime) {
    const [day, month, year] = date.split('.');
    if (!day || !month || !year) {
      return;
    }
    return new Date(Number(year), Number(month) - 1, Number(day)).toISOString();
  }

  // const [datePart, timePart] = date.split(', ');
  // if (!datePart) {
  //   return;
  // }
  // const [day, month, year] = datePart.split('.');
  // const formattedDateStr = `${year}-${month}-${day}T${timePart}:00.000Z`;

  return new Date(date).toISOString();
};

export const parseFiltersFromBackend = (
  backendFilters: bodyResponseType<'get', '/contract/tabs/{name}'>['filters']
): CRMTableFilters<ColumnsContractsTable> | undefined => {
  if (!backendFilters) return;
  return;
  // return {
  // TODO: нет на бэке
  // 'Дата, номер': {
  //   type: 'date',
  //   value: checkDate(
  //     backendFilters[frontKeyBackendValueFilters['Дата, номер']['from']],
  //     backendFilters[frontKeyBackendValueFilters['Дата, номер']['to']]
  //   ),
  // },
  //   Наименование: {
  //     type: 'search',
  //     value: backendFilters[frontKeyBackendValueFilters['Наименование']],
  //   },
  //   Основание: {
  //     type: 'select',
  //     value: backendFilters[frontKeyBackendValueFilters['Основание']],
  //   },
  //   ФЗ: {
  //     type: 'select',
  //     value: backendFilters[frontKeyBackendValueFilters['ФЗ']],
  //   },
  //   'В рамках ГОЗ по 275-ФЗ': {
  //     type: 'select',
  //     value: backendFilters[frontKeyBackendValueFilters['В рамках ГОЗ по 275-ФЗ']],
  //   },
  //   Сопровождение: {
  //     type: 'select',
  //     value: backendFilters[frontKeyBackendValueFilters['Сопровождение']],
  //   },
  //   'Уникальный номер': {
  //     type: 'search',
  //     value: backendFilters[frontKeyBackendValueFilters['Уникальный номер']],
  //   },
  //   Этап: {
  //     type: 'select',
  //     value: backendFilters[frontKeyBackendValueFilters['Этап']],
  //   },
  //   'Причина расторжения': {
  //     type: 'search',
  //     value: backendFilters[frontKeyBackendValueFilters['Причина расторжения']],
  //   },
  //   Ответственный: {
  //     type: 'select',
  //     value: backendFilters[frontKeyBackendValueFilters['Ответственный']],
  //   },
  //   Заказчик: {
  //     type: 'select',
  //     value: backendFilters[frontKeyBackendValueFilters['Заказчик']],
  //   },
  //   'Приоритет заказчика': {
  //     type: 'priority',
  //     value: checkNumeric(
  //       backendFilters[frontKeyBackendValueFilters['Приоритет заказчика']['from']],
  //       backendFilters[frontKeyBackendValueFilters['Приоритет заказчика']['to']]
  //     ),
  //   },
  //   Поставщик: {
  //     type: 'select',
  //     value: backendFilters[frontKeyBackendValueFilters['Поставщик']],
  //   },
  //   'Объект торгов': {
  //     type: 'select',
  //     value: backendFilters[frontKeyBackendValueFilters['Объект торгов']],
  //   },
  //   Комментарий: {
  //     type: 'search',
  //     value: backendFilters[frontKeyBackendValueFilters['Комментарий']],
  //   },
  //   Дни: {
  //     type: 'select',
  //     value: backendFilters[frontKeyBackendValueFilters['Дни']],
  //   },
  //   'Фактический срок оплаты с момента оплаты': {
  //     type: 'number',
  //     value: checkNumeric(
  //       backendFilters[
  //         frontKeyBackendValueFilters['Фактический срок оплаты с момента оплаты']['from']
  //       ],
  //       backendFilters[
  //         frontKeyBackendValueFilters['Фактический срок оплаты с момента оплаты']['to']
  //       ]
  //     ),
  //   },
  //   'Сумма контракта': {
  //     type: 'currency',
  //     value: checkNumeric(
  //       backendFilters[frontKeyBackendValueFilters['Сумма контракта']['from']],
  //       backendFilters[frontKeyBackendValueFilters['Сумма контракта']['to']]
  //     ),
  //   },
  //   'Номер контракта': {
  //     type: 'search',
  //     value: backendFilters[frontKeyBackendValueFilters['Номер контракта']],
  //   },
  //   'Дата подписания': {
  //     type: 'date',
  //     value: checkDate(
  //       backendFilters[frontKeyBackendValueFilters['Дата подписания']['from']],
  //       backendFilters[frontKeyBackendValueFilters['Дата подписания']['to']]
  //     ),
  //   },
  //   'Срок действия контракта': {
  //     type: 'date',
  //     value: checkDate(
  //       backendFilters[frontKeyBackendValueFilters['Срок действия контракта']['from']],
  //       backendFilters[frontKeyBackendValueFilters['Срок действия контракта']['to']]
  //     ),
  //   },
  //   'Реестровый номер контракта': {
  //     type: 'search',
  //     value: backendFilters[frontKeyBackendValueFilters['Реестровый номер контракта']],
  //   },
  //   Площадка: {
  //     type: 'select',
  //     value: backendFilters[frontKeyBackendValueFilters['Площадка']],
  //   },
  //   'КП/Тендер': {
  //     type: 'select',
  //     value: backendFilters[frontKeyBackendValueFilters['КП/Тендер']],
  //   },
  //   'Регион поставки': {
  //     type: 'select',
  //     value: backendFilters[frontKeyBackendValueFilters['Регион поставки']],
  //   },
  //   'Адрес поставки': {
  //     type: 'search',
  //     value: backendFilters[frontKeyBackendValueFilters['Адрес поставки']],
  //   },
  //   'Срок поставки': {
  //     type: 'date',
  //     value: checkDate(
  //       backendFilters[frontKeyBackendValueFilters['Срок поставки']['from']],
  //       backendFilters[frontKeyBackendValueFilters['Срок поставки']['to']]
  //     ),
  //   },
  //   'Актуальный файл расчета': {
  //     type: 'boolean',
  //     value: checkBoolean(backendFilters[frontKeyBackendValueFilters['Актуальный файл расчета']]),
  //   },
  //   'Файл основания (контракт, обеспечение, доп.соглашение/договор/счет)': {
  //     type: 'boolean',
  //     value: checkBoolean(
  //       backendFilters[
  //         frontKeyBackendValueFilters[
  //           'Файл основания (контракт, обеспечение, доп.соглашение/договор/счет)'
  //         ]
  //       ]
  //     ),
  //   },
  //   'Есть преимущество/ограничение для СМП?': {
  //     type: 'boolean',
  //     value: checkBoolean(
  //       backendFilters[frontKeyBackendValueFilters['Есть преимущество/ограничение для СМП?']]
  //     ),
  //   },
  //   'Обеспечение исполнения контракта есть': {
  //     type: 'boolean',
  //     value: checkBoolean(
  //       backendFilters[frontKeyBackendValueFilters['Обеспечение исполнения контракта есть']]
  //     ),
  //   },
  //   'Сумма обеспечения исполнения': {
  //     type: 'currency',
  //     value: checkNumeric(
  //       backendFilters[frontKeyBackendValueFilters['Сумма обеспечения исполнения']['from']],
  //       backendFilters[frontKeyBackendValueFilters['Сумма обеспечения исполнения']['to']]
  //     ),
  //   },
  //   'Вид обеспечения исполнения': {
  //     type: 'select',
  //     value: backendFilters[frontKeyBackendValueFilters['Вид обеспечения исполнения']],
  //   },
  //   'Срок обеспечения исполнения': {
  //     type: 'date',
  //     value: checkDate(
  //       backendFilters[frontKeyBackendValueFilters['Срок обеспечения исполнения']['from']],
  //       backendFilters[frontKeyBackendValueFilters['Срок обеспечения исполнения']['to']]
  //     ),
  //   },
  //   'Обеспечение гарантийных обязательств есть': {
  //     type: 'boolean',
  //     value: checkBoolean(
  //       backendFilters[frontKeyBackendValueFilters['Обеспечение гарантийных обязательств есть']]
  //     ),
  //   },
  //   'Сумма обеспечения гарантийных обязательств': {
  //     type: 'currency',
  //     value: checkNumeric(
  //       backendFilters[
  //         frontKeyBackendValueFilters['Сумма обеспечения гарантийных обязательств']['from']
  //       ],
  //       backendFilters[
  //         frontKeyBackendValueFilters['Сумма обеспечения гарантийных обязательств']['to']
  //       ]
  //     ),
  //   },
  //   'Вид обеспечения гарантийных обязательств': {
  //     type: 'select',
  //     value:
  //       backendFilters[frontKeyBackendValueFilters['Вид обеспечения гарантийных обязательств']],
  //   },
  //   'Срок обеспечения гарантийных обязательств': {
  //     type: 'date',
  //     value: checkDate(
  //       backendFilters[
  //         frontKeyBackendValueFilters['Срок обеспечения гарантийных обязательств']['from']
  //       ],
  //       backendFilters[
  //         frontKeyBackendValueFilters['Срок обеспечения гарантийных обязательств']['to']
  //       ]
  //     ),
  //   },
  //   'Прогресс исполнения': {
  //     type: 'margin',
  //     value: checkNumeric(
  //       backendFilters[frontKeyBackendValueFilters['Прогресс исполнения']['from']],
  //       backendFilters[frontKeyBackendValueFilters['Прогресс исполнения']['to']]
  //     ),
  //   },
  //   'Лимит контракта': {
  //     type: 'currency',
  //     value: checkNumeric(
  //       backendFilters[frontKeyBackendValueFilters['Лимит контракта']['from']],
  //       backendFilters[frontKeyBackendValueFilters['Лимит контракта']['to']]
  //     ),
  //   },
  //   'Сумма отгрузок, с НДС': {
  //     type: 'currency',
  //     value: checkNumeric(
  //       backendFilters[frontKeyBackendValueFilters['Сумма отгрузок, с НДС']['from']],
  //       backendFilters[frontKeyBackendValueFilters['Сумма отгрузок, с НДС']['to']]
  //     ),
  //   },
  //   'Сумма отгрузок, без НДС': {
  //     type: 'currency',
  //     value: checkNumeric(
  //       backendFilters[frontKeyBackendValueFilters['Сумма отгрузок, без НДС']['from']],
  //       backendFilters[frontKeyBackendValueFilters['Сумма отгрузок, без НДС']['to']]
  //     ),
  //   },
  //   'Сумма НДС': {
  //     type: 'currency',
  //     value: checkNumeric(
  //       backendFilters[frontKeyBackendValueFilters['Сумма НДС']['from']],
  //       backendFilters[frontKeyBackendValueFilters['Сумма НДС']['to']]
  //     ),
  //   },
  //   'Сумма закупок': {
  //     type: 'currency',
  //     value: checkNumeric(
  //       backendFilters[frontKeyBackendValueFilters['Сумма закупок']['from']],
  //       backendFilters[frontKeyBackendValueFilters['Сумма закупок']['to']]
  //     ),
  //   },
  //   'Остаток лимита': {
  //     type: 'currency',
  //     value: checkNumeric(
  //       backendFilters[frontKeyBackendValueFilters['Остаток лимита']['from']],
  //       backendFilters[frontKeyBackendValueFilters['Остаток лимита']['to']]
  //     ),
  //   },
  //   'Сумма оплаты': {
  //     type: 'currency',
  //     value: checkNumeric(
  //       backendFilters[frontKeyBackendValueFilters['Сумма оплаты']['from']],
  //       backendFilters[frontKeyBackendValueFilters['Сумма оплаты']['to']]
  //     ),
  //   },
  //   'Сумма ДЗ': {
  //     type: 'currency',
  //     value: checkNumeric(
  //       backendFilters[frontKeyBackendValueFilters['Сумма ДЗ']['from']],
  //       backendFilters[frontKeyBackendValueFilters['Сумма ДЗ']['to']]
  //     ),
  //   },
  //   'В т.ч. Сумма ПДЗ': {
  //     type: 'currency',
  //     value: checkNumeric(
  //       backendFilters[frontKeyBackendValueFilters['В т.ч. Сумма ПДЗ']['from']],
  //       backendFilters[frontKeyBackendValueFilters['В т.ч. Сумма ПДЗ']['to']]
  //     ),
  //   },
  // };
};

export const parseFiltersToBackend = (
  filters: CRMTableFilters<ColumnsContractsTable>
): bodyRequestType<'put', '/contract/tabs/{name}'>['filters'] => {
  return;
  // return {
  //   actual_file: displayBooleanFilterToBackendFilter(filters['Актуальный файл расчета']),
  //   basis: filters['Основание'].value as SelectFilterValueType,
  //   /** @enum {string} */
  //   basis_file: displayBooleanFilterToBackendFilter(filters['Файл основания (контракт, обеспечение, доп.соглашение/договор/счет)']),
  //   comment: filters['Комментарий'].value as SearchFilterValueType,
  //   /** Format: date-time */
  //   contract_duration_end: displayDateToISOSString(
  //     (filters['Срок действия контракта'].value as DateFilterValueType | null)?.to
  //   ),
  //   /** Format: date-time */
  //   contract_duration_start: displayDateToISOSString(
  //     (filters['Срок действия контракта'].value as DateFilterValueType | null)?.from
  //   ),
  //   contract_limit_max: (filters['Лимит контракта'].value as NumericFilterValueType)?.to,
  //   contract_limit_min: (filters['Лимит контракта'].value as NumericFilterValueType)?.from,
  //   contract_number: filters['Номер контракта'].value as SearchFilterValueType,
  //   contract_security_sum_max: (filters['Сумма обеспечения исполнения'].value as NumericFilterValueType)?.to,
  //   contract_security_sum_min: (filters['Сумма обеспечения исполнения'].value as NumericFilterValueType)?.from,
  //   /** Format: date-time */
  //   contract_security_term_end: displayDateToISOSString(
  //     (filters['Срок обеспечения исполнения'].value as DateFilterValueType | null)?.from
  //   ),
  //   /** Format: date-time */
  //   contract_security_term_start: displayDateToISOSString(
  //     (filters['Срок обеспечения исполнения'].value as DateFilterValueType | null)?.from
  //   ),
  //   contract_security_type: filters['Вид обеспечения исполнения'].value as SelectFilterValueType,
  //   contract_sum_max: (filters['Сумма контракта'].value as NumericFilterValueType)?.to,
  //   contract_sum_min: (filters['Сумма контракта'].value as NumericFilterValueType)?.from,
  //   customer_id: filters['Заказчик'].value as SelectFilterValueType,
  //   customer_priority_max: (filters['Сумма обеспечения исполнения'].value as NumericFilterValueType)?.to,
  //   customer_priority_min: (filters['Сумма обеспечения исполнения'].value as NumericFilterValueType)?.from,
  //   days_type: filters['Дни'].value as SelectFilterValueType,
  //   delivery_address: filters['Адрес поставки'].value as SearchFilterValueType,
  //   delivery_term: string[],
  //   fz: filters['ФЗ'].value as SelectFilterValueType,
  //   gozfz: filters['В рамках ГОЗ по 275-ФЗ'].value as SelectFilterValueType,
  //   /** @enum {string} */
  //   has_contract_security: displayBooleanFilterToBackendFilter(filters['Обеспечение исполнения контракта есть']),
  //   /** @enum {string} */
  //   has_smp_restriction: displayBooleanFilterToBackendFilter(filters['Есть преимущество/ограничение для СМП?']),
  //   /** @enum {string} */
  //   has_warranty_security: displayBooleanFilterToBackendFilter(filters['Обеспечение гарантийных обязательств есть']),
  //   limit_balance_max: number,
  //   limit_balance_min: number,
  //   name: filters['Наименование'].value as SearchFilterValueType,
  //   object_id: filters['Объект торгов'].value as SelectFilterValueType,
  //   overdue_receivables_sum_max: number,
  //   overdue_receivables_sum_min: number,
  //   payment_sum_max: number,
  //   payment_sum_min: number,
  //   payment_term_max: number,
  //   payment_term_min: number,
  //   progress_percent_max: number,
  //   progress_percent_min: number,
  //   purchase_sum_max: number,
  //   purchase_sum_min: number,
  //   receivables_sum_max: number,
  //   receivables_sum_min: number,
  //   region_id: filters['Регион поставки'].value as SelectFilterValueType,
  //   responsible_id: filters['Ответственный'].value as SelectFilterValueType,
  //   shipment_sum_with_vat_max: number,
  //   shipment_sum_with_vat_min: number,
  //   shipment_sum_without_vat_max: number,
  //   shipment_sum_without_vat_min: number,
  //   /** Format: date-time */
  //   signing_date_end: string,
  //   /** Format: date-time */
  //   signing_date_start: string,
  //   stage: filters['Этап'].value as SelectFilterValueType,
  //   supplier_id: filters['Поставщик'].value as SelectFilterValueType,
  //   unique_number: filters['Уникальный номер'].value as SearchFilterValueType,
  //   vat_sum_max: number,
  //   vat_sum_min: number,
  //   warranty_security_sum_max: number,
  //   warranty_security_sum_min: number,
  //   /** Format: date-time */
  //   warranty_security_term_end: string,
  //   /** Format: date-time */
  //   warranty_security_term_start: string,
  //   warranty_security_type: filters['Вид обеспечения гарантийных обязательств'].value as SelectFilterValueType,
  // };
};
