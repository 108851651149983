import { bodyResponseType } from '@shared/api';

export function convertAgreementTypeFromBackend(
  status: NonNullable<bodyResponseType<'get', '/coordination/{id}'>['coordination_type']>
): string {
  switch (status) {
    case 'MIN_PRICE':
      return 'Согласование мин. цены';
    case 'OFFER_PRICE':
      return 'Согласование цены КП';
    case 'PARTICIPATION':
      return 'Согласование участия';
    case 'REJECTION':
      return 'Согласование отказа';
  }
}
