import { ReactNode } from 'react';

import { SuppliersForm, SuppliersFormProps } from '@features';

interface SuppliersLayoutProps {
  renderForm: (formComponent: ReactNode) => ReactNode;
  formProps: SuppliersFormProps;
}

export function SuppliersLayout({ renderForm, formProps }: SuppliersLayoutProps) {
  return <>{renderForm(<SuppliersForm {...formProps} />)}</>;
}
