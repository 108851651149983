import { ThemeProvider, ToasterComponent, ToasterProvider } from '@gravity-ui/uikit';
import { QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  PersistedClient,
  Persister,
  PersistQueryClientProvider,
} from '@tanstack/react-query-persist-client';
import { del, get, set } from 'idb-keyval';
import { useAtomValue } from 'jotai';
import { FC } from 'react';

import { themeAtomLocalStorage } from '@shared/model';

/**
 * Creates an Indexed DB persister
 * @see https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API
 */
function createIDBPersister(idbValidKey: IDBValidKey = 'reactQuery') {
  return {
    persistClient: async (client: PersistedClient) => {
      await set(idbValidKey, client);
    },
    restoreClient: async () => {
      return await get<PersistedClient>(idbValidKey);
    },
    removeClient: async () => {
      await del(idbValidKey);
    },
  } as Persister;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { gcTime: 1000 * 60 * 60 * 12, retry: 1 }, // 12 часов
  },
});

const persister = createIDBPersister();

interface IProviders {
  /** Content that will be wrapped by providers. */
  readonly children: JSX.Element;
}

export const Providers: FC<IProviders> = ({ children }) => {
  const theme = useAtomValue(themeAtomLocalStorage);

  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister }}
    >
      <ThemeProvider theme={theme}>
        <ToasterProvider>
          {children}
          <ToasterComponent />
        </ToasterProvider>
      </ThemeProvider>
      <ReactQueryDevtools />
    </PersistQueryClientProvider>
  );
};
