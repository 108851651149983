import { KnowledgeBaseRelatedProcess, KnowledgeBaseStatus, KnowledgeBaseType } from '@entities';
import { yupRu } from '@shared/config';
import { schemaFields } from '@shared/lib';

export const knowledgeBasesSchema = yupRu.object({
  /**
   * @description Вид @gotags: enums:"НПА,Разъяснение ФОИВ,Решение суда,Решение ФАС,Разъяснение заказчиков,Шаблоны,База знаний"
   * @enum {string}
   */
  type: schemaFields.radio<KnowledgeBaseType>([
    'НПА',
    'Разъяснение ФОИВ',
    'Решение суда',
    'Решение ФАС',
    'Разъяснение заказчиков',
    'Шаблоны',
    'База знаний',
  ]),
  /**
   * @description Статус @gotags: validate:"min_len:2" enums:"Действующий,Недействующий"
   * @enum {string}
   */
  status: schemaFields.radio<KnowledgeBaseStatus>(['Действующий', 'Недействующий']),
  /** @description Название документа @gotags: validate:"required" */
  document_name: schemaFields.text().required(),
  /** @description Ссылка на источник @gotags: validate:"min_len:3" */
  source: schemaFields.text().url(),
  /** @description Краткое описание @gotags: validate:"min_len:3" */
  description: schemaFields.textArea(),
  /** @description Теги @gotags: validate:"min_len:2" */
  tags: schemaFields.tags(),
  /**
   * @description Связанный процесс @gotags: validate:"min_len:2" enums:"Торги,Контракт"
   * @enum {string}
   */
  related_process: schemaFields.radio<KnowledgeBaseRelatedProcess>(['Контракт', 'Торги']),
  /** @description Документы @gotags: validate:"required" */
  documents: schemaFields.multiFile().requiredArray(),
});

export type KnowledgeBasesSchema = yupRu.InferType<typeof knowledgeBasesSchema>;
