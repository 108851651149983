import { bodyResponseType } from '@shared/api';

import { CreateContacts } from './CreateContacts';
import { EditContacts } from './EditContacts';

type ContactsDrawerProps = {
  onClose: () => void;
  withDialog?: boolean;
} & (
  | {
      type: 'create';
      open: boolean;
      id?: undefined;
      afterCreate?: (createdContact: bodyResponseType<'post', '/handbook/contacts'>) => void;
    }
  | {
      type: 'edit';
      open?: undefined;
      id?: string;
      afterCreate?: undefined;
    }
);

export function ContactsDrawer({
  type,
  open,
  onClose,
  id,
  afterCreate,
  withDialog,
}: ContactsDrawerProps) {
  return (
    <>
      {type === 'create' ? (
        <CreateContacts
          open={open}
          onClose={onClose}
          afterCreate={afterCreate}
          withDialog={withDialog}
        />
      ) : (
        <EditContacts
          onClose={onClose}
          id={id}
        />
      )}
    </>
  );
}
