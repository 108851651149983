import { useToaster } from '@gravity-ui/uikit';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import { $knowledgeBasesHooks } from '@entities';
import { CreateHandbookDialog, knowledgeBasesFormAtom } from '@features';
import { bodyResponseType } from '@shared/api';
import { RightSidebar } from '@shared/ui';

import { KnowledgeBasesLayout } from './KnowledgeBasesLayout';

interface CreateKnowledgeBasesProps {
  open: boolean;
  onClose: () => void;
  afterCreate?: (
    createdKnowledgeBase: bodyResponseType<'post', '/handbook/knowledge-bases'>
  ) => void;
  withDialog?: boolean;
}

export function CreateKnowledgeBases({
  open,
  onClose,
  afterCreate,
  withDialog,
}: CreateKnowledgeBasesProps) {
  const { add } = useToaster();

  const queryClient = useQueryClient();

  const [knowledgeBasesCreation, setKnowledgeBasesCreation] = useAtom(knowledgeBasesFormAtom);

  // Queries
  const createKnowledgeBasesMutation = $knowledgeBasesHooks.createMutation();

  //

  return (
    <KnowledgeBasesLayout
      formProps={{
        defaultValues: knowledgeBasesCreation,
        onValuesChange: values => {
          setKnowledgeBasesCreation(values);
        },
        onClickCancelButton: () => {
          setKnowledgeBasesCreation(undefined);
          onClose();
        },
        compactBottomPanel: true,
        // disabled: underApproval,
        initialEditable: true,
        onValid: async values => {
          await createKnowledgeBasesMutation
            .mutateAsync({
              body: values,
            })
            .then(createdKnowledgeBase => {
              add({
                name: 'create-knowledgeBases-success',
                theme: 'success',
                content: 'Карточка Базы Знаний успешна создана!',
              });
              queryClient.invalidateQueries({ queryKey: ['/knowledge-bases/all'] });
              afterCreate?.(createdKnowledgeBase);

              setKnowledgeBasesCreation(undefined);
              onClose();
            })
            .catch(() => {
              add({
                name: 'create-knowledgeBases-error',
                theme: 'danger',
                content: 'Ошибка при создании карточки Базы Знаний',
              });
            });
        },
        onInvalid: errors => {
          console.log('@errors', errors);
        },
      }}
      renderForm={formComponent =>
        withDialog ? (
          <CreateHandbookDialog
            onClose={onClose}
            open={open}
            title="Создание карточки Базы Знаний"
          >
            {formComponent}
          </CreateHandbookDialog>
        ) : (
          <RightSidebar
            open={open}
            onClose={onClose}
          >
            <RightSidebar.Header
              title="Создание карточки Базы Знаний"
              onClose={onClose}
            />
            {formComponent}
          </RightSidebar>
        )
      }
    />
  );
}
