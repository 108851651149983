import { ReactNode, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { CUSTOM_HANDBOOK_TO_RUSSIAN, CustomHandbookName } from '@entities';
import {
  AgenciesDrawer,
  AgenciesTable,
  CompetitorsDrawer,
  CompetitorsTable,
  ContactsDrawer,
  ContactsTable,
  CustomersDrawer,
  CustomersTable,
  DepartmentsDrawer,
  DepartmentsTable,
  EmployeesDrawer,
  EmployeesTable,
  ETradingPlatformsDrawer,
  ETradingPlatformsTable,
  KnowledgeBasesDrawer,
  KnowledgeBasesTable,
  LegalEntitiesDrawer,
  LegalEntitiesTable,
  Okpd2sDrawer,
  Okpd2sTable,
  PaymentDetailsDrawer,
  PaymentDetailsTable,
  PurchaseObjectsDrawer,
  PurchaseObjectsTable,
  RegionsDrawer,
  RegionsTable,
  RestrictionAdvantageRequirementsDrawer,
  RestrictionAdvantageRequirementsTable,
  SupplierContractsDrawer,
  SupplierContractsTable,
  SuppliersDrawer,
  SuppliersTable,
} from '@widgets';
import { URLS } from '@shared/consts';
import { useNavigateTo } from '@shared/lib';
import { CRMBreadCrumbs, HeroWithButton } from '@shared/ui';

export function CustomHandbookGuard() {
  const handbookName = useParams()[URLS.handbook.dynamicParams.handbookName];

  if (!handbookName || !(handbookName in CUSTOM_HANDBOOK_TO_RUSSIAN))
    return (
      <Navigate
        to={URLS.handbook.default}
        replace
      />
    );

  return <CustomHandbook handbookName={handbookName as CustomHandbookName} />;
}

export const CustomHandbook = ({ handbookName }: { handbookName: CustomHandbookName }) => {
  const title = CUSTOM_HANDBOOK_TO_RUSSIAN[handbookName];

  const [createCustomHandbookOpen, setCreateCustomHandbookOpen] = useState(false);

  const { navigateTo } = useNavigateTo();

  const { id } = useParams();

  const [selectedHandbookIndex, setSelectedHandbookIndex] = useState<number | null>(null);

  const handbookByName: Record<CustomHandbookName, ReactNode> = {
    agencies: (
      <AgenciesTable
        selectedOfferIndex={selectedHandbookIndex}
        onRowClick={(id, index) => {
          navigateTo(URLS.handbook.custom + '/' + handbookName + '/' + id);
          setSelectedHandbookIndex(index);
        }}
      />
    ),
    competitors: (
      <CompetitorsTable
        selectedOfferIndex={selectedHandbookIndex}
        onRowClick={(id, index) => {
          navigateTo(URLS.handbook.custom + '/' + handbookName + '/' + id);
          setSelectedHandbookIndex(index);
        }}
      />
    ),
    contacts: (
      <ContactsTable
        selectedOfferIndex={selectedHandbookIndex}
        onRowClick={(id, index) => {
          navigateTo(URLS.handbook.custom + '/' + handbookName + '/' + id);
          setSelectedHandbookIndex(index);
        }}
      />
    ),
    customers: (
      <CustomersTable
        selectedOfferIndex={selectedHandbookIndex}
        onRowClick={(id, index) => {
          navigateTo(URLS.handbook.custom + '/' + handbookName + '/' + id);
          setSelectedHandbookIndex(index);
        }}
      />
    ),
    departments: (
      <DepartmentsTable
        selectedOfferIndex={selectedHandbookIndex}
        onRowClick={(id, index) => {
          navigateTo(URLS.handbook.custom + '/' + handbookName + '/' + id);
          setSelectedHandbookIndex(index);
        }}
      />
    ),
    e_trading_platforms: (
      <ETradingPlatformsTable
        selectedOfferIndex={selectedHandbookIndex}
        onRowClick={(id, index) => {
          navigateTo(URLS.handbook.custom + '/' + handbookName + '/' + id);
          setSelectedHandbookIndex(index);
        }}
      />
    ),
    employees: (
      <EmployeesTable
        selectedOfferIndex={selectedHandbookIndex}
        onRowClick={(id, index) => {
          navigateTo(URLS.handbook.custom + '/' + handbookName + '/' + id);
          setSelectedHandbookIndex(index);
        }}
      />
    ),
    knowledge_base: (
      <KnowledgeBasesTable
        selectedOfferIndex={selectedHandbookIndex}
        onRowClick={(id, index) => {
          navigateTo(URLS.handbook.custom + '/' + handbookName + '/' + id);
          setSelectedHandbookIndex(index);
        }}
      />
    ),
    legal_entities: (
      <LegalEntitiesTable
        selectedOfferIndex={selectedHandbookIndex}
        onRowClick={(id, index) => {
          navigateTo(URLS.handbook.custom + '/' + handbookName + '/' + id);
          setSelectedHandbookIndex(index);
        }}
      />
    ),
    okpd_2: (
      <Okpd2sTable
        selectedOfferIndex={selectedHandbookIndex}
        onRowClick={(id, index) => {
          navigateTo(URLS.handbook.custom + '/' + handbookName + '/' + id);
          setSelectedHandbookIndex(index);
        }}
      />
    ),
    payment_details: (
      <PaymentDetailsTable
        selectedOfferIndex={selectedHandbookIndex}
        onRowClick={(id, index) => {
          navigateTo(URLS.handbook.custom + '/' + handbookName + '/' + id);
          setSelectedHandbookIndex(index);
        }}
      />
    ),
    purchase_objects: (
      <PurchaseObjectsTable
        selectedOfferIndex={selectedHandbookIndex}
        onRowClick={(id, index) => {
          navigateTo(URLS.handbook.custom + '/' + handbookName + '/' + id);
          setSelectedHandbookIndex(index);
        }}
      />
    ),
    regions: (
      <RegionsTable
        selectedOfferIndex={selectedHandbookIndex}
        onRowClick={(id, index) => {
          navigateTo(URLS.handbook.custom + '/' + handbookName + '/' + id);
          setSelectedHandbookIndex(index);
        }}
      />
    ),
    restrictions_advantages_requirements: (
      <RestrictionAdvantageRequirementsTable
        selectedOfferIndex={selectedHandbookIndex}
        onRowClick={(id, index) => {
          navigateTo(URLS.handbook.custom + '/' + handbookName + '/' + id);
          setSelectedHandbookIndex(index);
        }}
      />
    ),
    supplier_contracts: (
      <SupplierContractsTable
        selectedOfferIndex={selectedHandbookIndex}
        onRowClick={(id, index) => {
          navigateTo(URLS.handbook.custom + '/' + handbookName + '/' + id);
          setSelectedHandbookIndex(index);
        }}
      />
    ),
    suppliers: (
      <SuppliersTable
        selectedOfferIndex={selectedHandbookIndex}
        onRowClick={(id, index) => {
          navigateTo(URLS.handbook.custom + '/' + handbookName + '/' + id);
          setSelectedHandbookIndex(index);
        }}
      />
    ),
  };

  const createDrawerByName: Record<CustomHandbookName, ReactNode> = {
    agencies: (
      <AgenciesDrawer
        type="create"
        open={createCustomHandbookOpen}
        onClose={() => setCreateCustomHandbookOpen(false)}
      />
    ),
    competitors: (
      <CompetitorsDrawer
        type="create"
        open={createCustomHandbookOpen}
        onClose={() => setCreateCustomHandbookOpen(false)}
      />
    ),
    contacts: (
      <ContactsDrawer
        type="create"
        open={createCustomHandbookOpen}
        onClose={() => setCreateCustomHandbookOpen(false)}
      />
    ),
    customers: (
      <CustomersDrawer
        type="create"
        open={createCustomHandbookOpen}
        onClose={() => setCreateCustomHandbookOpen(false)}
      />
    ),
    departments: (
      <DepartmentsDrawer
        type="create"
        open={createCustomHandbookOpen}
        onClose={() => setCreateCustomHandbookOpen(false)}
      />
    ),
    employees: (
      <EmployeesDrawer
        type="create"
        open={createCustomHandbookOpen}
        onClose={() => setCreateCustomHandbookOpen(false)}
      />
    ),
    e_trading_platforms: (
      <ETradingPlatformsDrawer
        type="create"
        open={createCustomHandbookOpen}
        onClose={() => setCreateCustomHandbookOpen(false)}
      />
    ),
    knowledge_base: (
      <KnowledgeBasesDrawer
        type="create"
        open={createCustomHandbookOpen}
        onClose={() => setCreateCustomHandbookOpen(false)}
      />
    ),
    legal_entities: (
      <LegalEntitiesDrawer
        type="create"
        open={createCustomHandbookOpen}
        onClose={() => setCreateCustomHandbookOpen(false)}
      />
    ),
    okpd_2: (
      <Okpd2sDrawer
        type="create"
        open={createCustomHandbookOpen}
        onClose={() => setCreateCustomHandbookOpen(false)}
      />
    ),
    payment_details: (
      <PaymentDetailsDrawer
        type="create"
        open={createCustomHandbookOpen}
        onClose={() => setCreateCustomHandbookOpen(false)}
      />
    ),
    purchase_objects: (
      <PurchaseObjectsDrawer
        type="create"
        open={createCustomHandbookOpen}
        onClose={() => setCreateCustomHandbookOpen(false)}
      />
    ),
    regions: (
      <RegionsDrawer
        type="create"
        open={createCustomHandbookOpen}
        onClose={() => setCreateCustomHandbookOpen(false)}
      />
    ),
    restrictions_advantages_requirements: (
      <RestrictionAdvantageRequirementsDrawer
        type="create"
        open={createCustomHandbookOpen}
        onClose={() => setCreateCustomHandbookOpen(false)}
      />
    ),
    supplier_contracts: (
      <SupplierContractsDrawer
        type="create"
        open={createCustomHandbookOpen}
        onClose={() => setCreateCustomHandbookOpen(false)}
      />
    ),
    suppliers: (
      <SuppliersDrawer
        type="create"
        open={createCustomHandbookOpen}
        onClose={() => setCreateCustomHandbookOpen(false)}
      />
    ),
  };

  const editDrawerByName: Record<CustomHandbookName, ReactNode> = {
    agencies: (
      <AgenciesDrawer
        type="edit"
        onClose={() => navigateTo(URLS.handbook.custom + '/' + handbookName + '/')}
        id={id}
      />
    ),
    competitors: (
      <CompetitorsDrawer
        type="edit"
        onClose={() => navigateTo(URLS.handbook.custom + '/' + handbookName + '/')}
        id={id}
      />
    ),
    contacts: (
      <ContactsDrawer
        type="edit"
        onClose={() => navigateTo(URLS.handbook.custom + '/' + handbookName + '/')}
        id={id}
      />
    ),
    customers: (
      <CustomersDrawer
        type="edit"
        onClose={() => navigateTo(URLS.handbook.custom + '/' + handbookName + '/')}
        id={id}
      />
    ),
    departments: (
      <DepartmentsDrawer
        type="edit"
        onClose={() => navigateTo(URLS.handbook.custom + '/' + handbookName + '/')}
        id={id}
      />
    ),
    employees: (
      <EmployeesDrawer
        type="edit"
        onClose={() => navigateTo(URLS.handbook.custom + '/' + handbookName + '/')}
        id={id}
      />
    ),
    e_trading_platforms: (
      <ETradingPlatformsDrawer
        type="edit"
        onClose={() => navigateTo(URLS.handbook.custom + '/' + handbookName + '/')}
        id={id}
      />
    ),
    knowledge_base: (
      <KnowledgeBasesDrawer
        type="edit"
        onClose={() => {
          navigateTo(URLS.handbook.custom + '/' + handbookName);
        }}
        id={id}
      />
    ),
    legal_entities: (
      <LegalEntitiesDrawer
        type="edit"
        onClose={() => navigateTo(URLS.handbook.custom + '/' + handbookName + '/')}
        id={id}
      />
    ),
    okpd_2: (
      <Okpd2sDrawer
        type="edit"
        onClose={() => navigateTo(URLS.handbook.custom + '/' + handbookName + '/')}
        id={id}
      />
    ),
    payment_details: (
      <PaymentDetailsDrawer
        type="edit"
        onClose={() => navigateTo(URLS.handbook.custom + '/' + handbookName + '/')}
        id={id}
      />
    ),
    purchase_objects: (
      <PurchaseObjectsDrawer
        type="edit"
        onClose={() => navigateTo(URLS.handbook.custom + '/' + handbookName + '/')}
        id={id}
      />
    ),
    regions: (
      <RegionsDrawer
        type="edit"
        onClose={() => navigateTo(URLS.handbook.custom + '/' + handbookName + '/')}
        id={id}
      />
    ),
    restrictions_advantages_requirements: (
      <RestrictionAdvantageRequirementsDrawer
        type="edit"
        onClose={() => navigateTo(URLS.handbook.custom + '/' + handbookName + '/')}
        id={id}
      />
    ),
    supplier_contracts: (
      <SupplierContractsDrawer
        type="edit"
        onClose={() => navigateTo(URLS.handbook.custom + '/' + handbookName + '/')}
        id={id}
      />
    ),
    suppliers: (
      <SuppliersDrawer
        type="edit"
        onClose={() => navigateTo(URLS.handbook.custom + '/' + handbookName + '/')}
        id={id}
      />
    ),
  };

  return (
    <>
      <CRMBreadCrumbs
        items={[
          {
            text: 'Справочники',
            href: URLS.handbook.default,
          },
          {
            text: title,
            href: `${URLS.handbook.custom}/${handbookName}`,
          },
        ]}
      />
      <HeroWithButton
        title={title}
        buttonOnClick={() => setCreateCustomHandbookOpen(true)}
      />
      {handbookName in handbookByName && handbookByName[handbookName]}
      {handbookName in createDrawerByName && createDrawerByName[handbookName]}
      {handbookName in editDrawerByName && editDrawerByName[handbookName]}
    </>
  );
};
