import { Button, useToaster } from '@gravity-ui/uikit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { $okpd2sHooks, $suppliersHooks } from '@entities';
import { bodyResponseType } from '@shared/api';
import { cn, useFieldsWithPreview } from '@shared/lib';
import { FieldWithPreview, RightSidebar } from '@shared/ui';

import { purchaseObjectsSchema, PurchaseObjectsSchema } from '../model';

export interface PurchaseObjectsFormProps {
  onValid: SubmitHandler<PurchaseObjectsSchema>;
  onValuesChange?: (values: PurchaseObjectsSchema) => void;
  response?: bodyResponseType<'get', '/handbook/purchase-objects/{id}'>;
  onInvalid?: SubmitErrorHandler<PurchaseObjectsSchema>;
  onClickCancelButton?: () => void;
  initialEditable?: boolean;
  disabled?: boolean;
  hideBottomPanel?: boolean;
  rightSidebarButtons?: boolean;
  defaultValues?: PurchaseObjectsSchema;
  cancelButtonText?: string;
  submitButtonText?: string;
  compactBottomPanel?: boolean;
}

export function PurchaseObjectsForm({
  onValid,
  onValuesChange,
  response,
  onInvalid,
  initialEditable = false,
  onClickCancelButton,
  disabled = false,
  hideBottomPanel = false,
  defaultValues,
  cancelButtonText = 'Отмена',
  submitButtonText = 'Применить',
  compactBottomPanel = false,
}: PurchaseObjectsFormProps) {
  const { add } = useToaster();

  const form = useForm<PurchaseObjectsSchema>({
    resolver: yupResolver(purchaseObjectsSchema),
    mode: 'all',
    defaultValues,
    resetOptions: {
      keepDirtyValues: true,
      keepDefaultValues: false,
    },
  });

  const { createFieldRef, isFieldEditable, selectField, selectedFieldPath, resetSelectedField } =
    useFieldsWithPreview({
      form: form,
      initialEditable,
      disabled,
    });

  const values = form.watch();

  // Queries
  const [filterSuppliers, setFilterSuppliers] = useState('');
  const getHandbookSuppliersInfiniteQuery = $suppliersHooks.useGetAll(
    {
      query: filterSuppliers,
      limit: 30,
    },
    selectedFieldPath === 'supplier_id'
  );
  const flattedSuppliers = useMemo(
    () => getHandbookSuppliersInfiniteQuery.data?.pages.flat(),
    [getHandbookSuppliersInfiniteQuery.data]
  );

  const [filterOkpd2s, setFilterOkpd2s] = useState('');
  const getHandbookOkpd2sInfiniteQuery = $okpd2sHooks.useGetAll(
    {
      query: filterOkpd2s,
      limit: 30,
    },
    selectedFieldPath === 'okpd_2_value' // TODO: скорее всего, переделать на айди
  );
  const flattedOkpd2s = useMemo(
    () => getHandbookOkpd2sInfiniteQuery.data?.pages.flat(),
    [getHandbookOkpd2sInfiniteQuery.data]
  );
  //

  useDeepCompareEffect(() => {
    onValuesChange?.(values);
  }, [values]);

  useDeepCompareEffect(() => {
    if (response) form.reset(response);
  }, [response || {}]);

  useEffect(() => {
    if (Object.values(form.formState.errors).length > 0 && form.formState.isSubmitted)
      add({
        title: 'Не все обязательные поля заполнены',
        name: 'validation-fields-error',
        theme: 'warning',
      });
  }, [form.formState.submitCount]);

  return (
    <form
      className="flex flex-col overflow-hidden grow"
      onSubmit={form.handleSubmit(onValid, onInvalid)}
    >
      <FieldWithPreview>
        <FieldWithPreview.Text
          ref={createFieldRef('object')}
          control={form.control}
          path="object"
          name="Товар"
          edit={isFieldEditable('object')}
          onFieldSelect={() => selectField('object')}
          textInputProps={{ disabled }}
          required
        />
        <FieldWithPreview.Select
          control={form.control}
          path="supplier_id"
          name="Поставщик"
          edit={isFieldEditable('supplier_id')}
          ref={createFieldRef('supplier_id')}
          onFieldSelect={() => selectField('supplier_id')}
          options={
            flattedSuppliers?.map(agency => ({
              content: agency.name,
              value: agency.id,
            })) || []
          }
          defaultOptions={
            response?.supplier_id
              ? [
                  {
                    content: response.supplier_value,
                    value: response.supplier_id,
                  },
                ]
              : undefined
          }
          filter={filterSuppliers}
          onFilterChange={filter => setFilterSuppliers(filter)}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            disabled,
            placeholder: 'Выбрать',
            loading:
              getHandbookSuppliersInfiniteQuery.isFetching ||
              getHandbookSuppliersInfiniteQuery.isFetchingNextPage ||
              getHandbookSuppliersInfiniteQuery.isLoading ||
              getHandbookSuppliersInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookSuppliersInfiniteQuery.fetchNextPage();
            },
          }}
        />
        <FieldWithPreview.Select
          ref={createFieldRef('okpd_2_value')}
          control={form.control}
          path="okpd_2_value"
          edit={isFieldEditable('okpd_2_value')}
          onFieldSelect={() => selectField('okpd_2_value')}
          name="ОКПД-2"
          options={
            flattedOkpd2s?.map(okpd2 => ({
              content: okpd2?.value,
              value: okpd2.value,
            })) || []
          }
          defaultOptions={
            response?.okpd_2_value
              ? [
                  {
                    content: response.okpd_2_value,
                    value: response.okpd_2_value,
                  },
                ]
              : undefined
          }
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          required
          filter={filterOkpd2s}
          onFilterChange={filter => setFilterOkpd2s(filter)}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
            loading:
              getHandbookOkpd2sInfiniteQuery.isFetching ||
              getHandbookOkpd2sInfiniteQuery.isFetchingNextPage ||
              getHandbookOkpd2sInfiniteQuery.isLoading ||
              getHandbookOkpd2sInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookOkpd2sInfiniteQuery.fetchNextPage();
            },
          }}
        />
        <FieldWithPreview.TextArea
          ref={createFieldRef('comment')}
          control={form.control}
          path="comment"
          name="Комментарий"
          edit={isFieldEditable('comment')}
          onFieldSelect={() => selectField('comment')}
          wrapperProps={{ textAlign: 'start' }}
          textAreaProps={{ disabled }}
        />
      </FieldWithPreview>
      {!hideBottomPanel && (
        <RightSidebar.BottomPanel>
          <Button
            className={cn({ 'ml-auto': compactBottomPanel })}
            size="xl"
            view={compactBottomPanel ? 'flat' : 'normal'}
            width={'max'}
            onClick={onClickCancelButton}
            disabled={disabled}
          >
            {cancelButtonText}
          </Button>
          <Button
            size="xl"
            view="action"
            type="submit"
            width={'max'}
            onClick={form.handleSubmit(onValid, onInvalid)}
            disabled={disabled}
          >
            {submitButtonText}
          </Button>
        </RightSidebar.BottomPanel>
      )}
    </form>
  );
}
