/* eslint-disable @typescript-eslint/no-unused-vars */
import { useInfiniteQuery } from '@tanstack/react-query';

import { $api, $mock, bodyRequestType, fetchClient, mockClient } from '@shared/api';

const useGetRelatedTenderQuery = (id: string, enabled: boolean) =>
  $api.useQuery(
    'get',
    '/tender/related/{id}',
    { params: { path: { id: id } } },
    { enabled, refetchOnWindowFocus: false }
  );

const useGetTenderQuery = (id: string, enabled: boolean) =>
  $api.useQuery(
    'get',
    '/tender/{id}',
    { params: { path: { id: id } } },
    { enabled, refetchOnWindowFocus: false }
  );

const updateTenderMutation = () => $api.useMutation('put', '/tender/{id}');
const deleteTenderMutation = () => $api.useMutation('delete', '/tender/{id}');

const createTenderMutation = () => $api.useMutation('post', '/tender/');

function useGetAllTendersInfiniteQuery(
  body: bodyRequestType<'post', '/tender/all'>,
  enabled: boolean
) {
  return useInfiniteQuery({
    queryKey: ['/tender/all', body],
    queryFn: async ({ pageParam }) => {
      const { data } = await fetchClient.POST('/tender/all', {
        // const { data } = await mockClient.POST('/tender/all', {
        body: { ...body, offset: pageParam },
      });

      return data?.tenders || [];
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage?.length === 0) {
        return undefined;
      }
      return lastPageParam + body.limit;
    },
    enabled,
  });
}

export const $tenderHooks = {
  getAll: useGetAllTendersInfiniteQuery,
  getById: useGetTenderQuery,
  update: updateTenderMutation,
  delete: deleteTenderMutation,
  create: createTenderMutation,
  related: useGetRelatedTenderQuery,
};
