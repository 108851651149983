import {
  Centrifuge,
  State,
  Subscription,
  SubscriptionTokenContext,
  UnauthorizedError,
} from 'centrifuge';

import { LOCAL_STORAGE, WS_URL } from '@shared/consts';
import { customStorage } from '@shared/lib';
import { CHANNEL } from '@shared/model';

const centrifugeClient = new Centrifuge(`${WS_URL}/connection/websocket`, {
  debug: import.meta.env.DEV,
  getToken: () => {
    // Токен такой же, как и при авторизации
    return new Promise(resolve => {
      const token = customStorage<string | null>().getItem(LOCAL_STORAGE.token, null);
      if (!token) throw new UnauthorizedError('Токен пустой');
      resolve(token);
    });
  },
});

export function getIsDisconnected() {
  return centrifugeClient.state === State.Disconnected;
}

export function disconnect() {
  return centrifugeClient.disconnect();
}

export function register(token: string) {
  centrifugeClient.setToken(token);
  centrifugeClient.on('error', err => {
    throw new Error(err.error.message);
  });

  centrifugeClient.on('connected', () => console.log('Connected WSS'));

  centrifugeClient.connect();
}

export function subscribe(
  channel: CHANNEL,
  getToken: (token: SubscriptionTokenContext) => Promise<string>
) {
  const currentSub = centrifugeClient.getSubscription(channel);
  if (currentSub) return;

  const sub = centrifugeClient.newSubscription(channel, {
    getToken,
    joinLeave: true,
  });

  sub.on('error', reason => {
    if (import.meta.env.PROD) return;
    throw new Error(`нельзя подписаться на ${channel} канал: ${reason.error}`);
  });

  return sub;
}

export function unsubscribe(sub: Subscription) {
  return centrifugeClient.removeSubscription(sub);
}
