import { ReactNode } from 'react';

import {
  RestrictionAdvantageRequirementsForm,
  RestrictionAdvantageRequirementsFormProps,
} from '@features';

interface RestrictionAdvantageRequirementsLayoutProps {
  renderForm: (formComponent: ReactNode) => ReactNode;
  formProps: RestrictionAdvantageRequirementsFormProps;
}

export function RestrictionAdvantageRequirementsLayout({
  renderForm,
  formProps,
}: RestrictionAdvantageRequirementsLayoutProps) {
  return <>{renderForm(<RestrictionAdvantageRequirementsForm {...formProps} />)}</>;
}
