import { ContactStatus, ContactType } from '@entities';
import { HandbookOption } from '@shared/model';

interface ContactOptions {
  status: HandbookOption<ContactStatus>[];
  type: HandbookOption<ContactType>[];
}

export const CONTACT_OPTIONS: ContactOptions = {
  type: [
    {
      value: 'ГОССНАБ',
      content: 'ГОССНАБ',
    },
    {
      value: 'Заказчик',
      content: 'Заказчик',
    },
    {
      value: 'Поставщик',
      content: 'Поставщик',
    },
  ],
  status: [
    {
      value: 'Ассистент менеджера',
      content: 'Ассистент менеджера',
    },
    {
      value: 'Менеджер',
      content: 'Менеджер',
    },
    {
      value: 'Бухгалтерия',
      content: 'Бухгалтерия',
    },
    {
      value: 'Доставка',
      content: 'Доставка',
    },
    {
      value: 'Иное',
      content: 'Иное',
    },
    {
      value: 'Руководитель менеджера',
      content: 'Руководитель менеджера',
    },
    {
      value: 'Склад',
      content: 'Склад',
    },
  ],
};
