import { CustomBooleanSchema } from '@shared/model';

export function fromBooleanToYesNo(
  bool: boolean | undefined
): Extract<CustomBooleanSchema, 'Да' | 'Нет'> | undefined {
  switch (bool) {
    case true:
      return 'Да';
    case false:
      return 'Нет';
    default:
      return undefined;
  }
}
