import { Funnel, Magnifier } from '@gravity-ui/icons';
import { Button, Checkbox, Icon, Text, TextInput } from '@gravity-ui/uikit';
import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { FileMock, GlobalSearchList, PeopleMock, ProjectMock, SEARCH_MOCK } from '@features';
import { RightSidebar } from '@shared/ui';

import { SearchRightSidebar } from './SearchRightSidebar';

const initialFilterFields = {
  files: true,
  projects: true,
  peoples: true,
};

export function Search() {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [filterFields, setFilterFields] = useState(initialFilterFields);
  const [searchValue, setSearchValue] = useState('');
  const [searchData, setSearchData] = useState<{
    count: number;
    items: {
      files: FileMock[];
      projects: ProjectMock[];
      peoples: PeopleMock[];
    } | null;
  }>({ count: 0, items: null });

  const [debouncedSearchValue] = useDebounce(searchValue, 500);

  console.log(searchData);

  useEffect(() => {
    if (!debouncedSearchValue) {
      setSearchData({ count: 0, items: SEARCH_MOCK });
      return;
    }

    const files = filterFields.files
      ? SEARCH_MOCK.files.filter(({ name }) =>
          name.toLocaleLowerCase().includes(debouncedSearchValue.toLocaleLowerCase())
        )
      : [];
    const projects = filterFields.projects
      ? SEARCH_MOCK.projects.filter(({ name }) =>
          name.toLocaleLowerCase().includes(debouncedSearchValue.toLocaleLowerCase())
        )
      : [];
    const peoples = filterFields.peoples
      ? SEARCH_MOCK.peoples.filter(({ name }) =>
          name.toLocaleLowerCase().includes(debouncedSearchValue.toLocaleLowerCase())
        )
      : [];

    const count = files.length + projects.length + peoples.length;

    setSearchData({ count, items: count === 0 ? null : { files, projects, peoples } });
  }, [debouncedSearchValue, filterFields.files, filterFields.peoples, filterFields.projects]);

  console.log(filterFields);

  return (
    <>
      <div className="flex flex-col h-[calc(100vh-53px)]">
        <div className="border-b border-base-float-announcement">
          <div className="pt-8 pl-5 pb-4">
            <Text
              variant="display-1"
              color="primary"
            >
              Поиск по CRM
            </Text>
          </div>
        </div>
        <div className="py-4 px-5 flex gap-4 w-[849px]">
          <TextInput
            startContent={
              <Icon
                data={Magnifier}
                className="text-text-secondary ml-2"
              />
            }
            placeholder="Название или атрибуты"
            size="l"
            hasClear
            value={searchValue}
            onUpdate={setSearchValue}
          />
          <Button
            size="l"
            view="outlined"
            onClick={() => setIsFiltersOpen(true)}
          >
            <Icon data={Funnel} />
            Фильтры
          </Button>
        </div>
        <div className="flex flex-col gap-4 px-5 w-[849px] grow overflow-auto hide-scrollbar pb-4">
          <GlobalSearchList
            files={filterFields.files ? searchData.items?.files : undefined}
            peoples={filterFields.peoples ? searchData.items?.peoples : undefined}
            projects={filterFields.projects ? searchData.items?.projects : undefined}
          />
        </div>
      </div>
      <RightSidebar
        id="search-filters"
        className="!w-[338px]"
        open={isFiltersOpen}
        onClose={() => setIsFiltersOpen(false)}
      >
        <RightSidebar.Header
          className="pt-[26px] pb-[10px] pl-[32px] pr-[20px] items-center"
          title="Настройки поиска"
          titleProps={{
            variant: 'subheader-3',
          }}
          onClose={() => setIsFiltersOpen(false)}
        />
        <Checkbox
          checked={filterFields.projects}
          className="px-2 mb-2 w-full flex flex-row items-center"
          size="m"
          onUpdate={checked =>
            setFilterFields(prevFilterFields => ({ ...prevFilterFields, projects: checked }))
          }
        >
          <Text variant="subheader-1">Проекты</Text>
        </Checkbox>
        <Checkbox
          checked={filterFields.files}
          className="px-2 mb-2 w-full flex flex-row items-center"
          size="m"
          onUpdate={checked =>
            setFilterFields(prevFilterFields => ({ ...prevFilterFields, files: checked }))
          }
        >
          <Text variant="subheader-1">Файлы</Text>
        </Checkbox>
        <Checkbox
          checked={filterFields.peoples}
          className="px-2 mb-2 w-full flex flex-row items-center"
          size="m"
          onUpdate={checked =>
            setFilterFields(prevFilterFields => ({ ...prevFilterFields, peoples: checked }))
          }
        >
          <Text variant="subheader-1">Люди</Text>
        </Checkbox>
        <RightSidebar.BottomPanel
          className="pt-[15px] px-[32px] pb-[28px] justify-end border-none"
          withoutShadow
        >
          <Button
            view="flat-secondary"
            size="l"
            onClick={() => {
              setFilterFields(structuredClone(initialFilterFields));
            }}
          >
            <Text>Сбросить</Text>
          </Button>
          <Button
            view="action"
            size="l"
            onClick={() => {
              setIsFiltersOpen(false);
            }}
          >
            <Text>Применить</Text>
          </Button>
        </RightSidebar.BottomPanel>
      </RightSidebar>
      <SearchRightSidebar />
    </>
  );
}
