import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { CalendarDrawer, TenderDrawer, TendersTable } from '@widgets';
import { URLS } from '@shared/consts';
import { useNavigateTo } from '@shared/lib';
import { HeroWithButton } from '@shared/ui';

export function Tenders() {
  const { navigateTo } = useNavigateTo();

  const tenderId = useParams()[URLS.processes.tendersDynamicParams.id];

  const [createTenderOpen, setCreateTenderOpen] = useState(false);

  const [calendarDrawerOpen, setCalendarDrawerOpen] = useState(false);

  const [selectedOfferIndex, setSelectedOfferIndex] = useState<number | null>(null);

  return (
    <>
      <HeroWithButton
        title="Торги"
        buttonOnClick={() => setCreateTenderOpen(true)}
      />
      <TendersTable
        selectedOfferIndex={selectedOfferIndex}
        onRowClick={(id, index) => {
          navigateTo(URLS.processes.tenders + '/' + id);
          setSelectedOfferIndex(index);
        }}
        calendarButtonAction={() => {
          setCalendarDrawerOpen(true);
        }}
      />
      <TenderDrawer
        id={tenderId}
        type="edit"
        onClose={() => navigateTo(URLS.processes.tenders)}
      />
      <TenderDrawer
        type="create"
        open={createTenderOpen}
        onClose={() => setCreateTenderOpen(false)}
      />
      <CalendarDrawer
        open={calendarDrawerOpen}
        onClose={() => setCalendarDrawerOpen(false)}
        entityType="Торги"
      />
    </>
  );
}
