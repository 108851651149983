import { yupRu } from '@shared/config';
import { schemaFields } from '@shared/lib';

export const eTradingPlatformsSchema = yupRu.object({
  name: schemaFields.text().required(),
  comment: schemaFields.textArea(),
  link: schemaFields.text().url(),
});

export type ETradingPlatformsSchema = yupRu.InferType<typeof eTradingPlatformsSchema>;
