import BigNumber from 'bignumber.js';

export function roundPercentAndCurrencyToBackend<Value extends number | undefined>(
  value?: number
): Value {
  if (value === undefined) return value as Value;
  return +BigNumber(value).times(100).toFixed(0) as Value;
}

export function roundPercentAndCurrencyFromBackend<Value extends number | undefined>(
  value?: number
): Value {
  if (value === undefined) return value as Value;
  return +BigNumber(value).dividedBy(100) as Value;
}
