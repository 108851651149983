import {
  CustomHandbookNameFromEnglishToRussian,
  SystemHandbookNameFromEnglishToRussian,
} from '../model/types';

export const CUSTOM_HANDBOOK_TO_RUSSIAN: CustomHandbookNameFromEnglishToRussian = {
  agencies: 'Ведомства',
  competitors: 'Конкуренты',
  contacts: 'Контактные лица',
  customers: 'Заказчики',
  departments: 'Подразделения',
  e_trading_platforms: 'Электронные торговые площадки',
  employees: 'Сотрудники',
  knowledge_base: 'База знаний',
  legal_entities: 'Юр. лица (Наши организации)',
  okpd_2: 'ОКПД-2',
  payment_details: 'Платежные реквизиты',
  purchase_objects: 'Объекты закупок/торгов',
  regions: 'Регионы',
  restrictions_advantages_requirements: 'Ограничения, преимущества и доп. требования',
  supplier_contracts: 'Договоры с поставщиками',
  suppliers: 'Поставщики',
};

export const SYSTEM_HANDBOOK_TO_RUSSIAN: SystemHandbookNameFromEnglishToRussian = {
  supplier_type: 'Тип поставщика',
  customer_level: 'Уровень заказчика',
  client_status: 'Статус клиента',
  customer_type: 'Тип заказчика',
  supplier_status: 'Статус поставщика',
  edo: 'ЭДО',
  employee_type: 'Тип сотрудника',
  deferral: 'Отсрочка',
  cp_goal: 'Цель КП',
  cp_status: 'Статус КП',
  approve: 'Согласовать',
  rejection_reason: 'Причина отказа',
  request_source: 'Как получен запрос',
  application_status: 'Статус заявки',
  procurement_status: 'Статус закупки',
  deviation: 'Отклонение',
  federal_law: 'ФЗ',
  procurement_type: 'Закупка централизованная или совместная?',
  execution_method: 'Способ проведения',
  basis: 'Основание',
  goz_under_275_fz: 'В рамках ГОЗ по 275-ФЗ',
  stage: 'Этап',
  days: 'Дни',
  general_status: 'Статус общий',
  contract: 'Договор',
  type: 'Вид',
  related_process: 'Связанный процесс',
  contract_status: 'Статус договора',
  maintance: 'Сопровождение',
  smp_type: 'Преимущество/ограничение для СМП',
  guarantee_contract: 'Обеспечение исполнения контракта',
  warranty_obligations: 'Обеспечение гарантийных обязательств',
  guarantee_kind: 'Вид обеспечения',
  access: 'Доступ',
  status_restriction: 'Статус ограничения/требования',
  approval: 'Согласие',
  agreement: 'Результат согласования',
  type_agreement: 'Тип согласования',
  contact_type: 'Тип контактного лица',
  contact_status: 'Статус контактного лица (Системный)',
  no_take_place_reason: 'Причина почему не состоялась?',
};
