import { CommercialOfferSchema } from '@features';
import { bodyResponseType } from '@shared/api';
import { roundPercentAndCurrencyFromBackend, roundPercentAndCurrencyToBackend } from '@shared/lib';

export function percentAndCurrencyValuesToBackend(
  formValues: CommercialOfferSchema
): CommercialOfferSchema {
  return {
    ...formValues,
    cost_price: roundPercentAndCurrencyToBackend(formValues.cost_price),
    offer_price: roundPercentAndCurrencyToBackend(formValues.offer_price),
    margin: roundPercentAndCurrencyToBackend(formValues.margin),
    proposed_price: roundPercentAndCurrencyToBackend(formValues.proposed_price),
    final_price: roundPercentAndCurrencyToBackend(formValues.final_price),
  };
}

export function percentAndCurrencyValuesFromBackend(
  formValues: CommercialOfferSchema
): CommercialOfferSchema {
  return {
    ...formValues,
    cost_price: roundPercentAndCurrencyFromBackend(formValues.cost_price),
    offer_price: roundPercentAndCurrencyFromBackend(formValues.offer_price),
    margin: roundPercentAndCurrencyFromBackend(formValues.margin),
    proposed_price: roundPercentAndCurrencyFromBackend(formValues.proposed_price),
    final_price: roundPercentAndCurrencyFromBackend(formValues.final_price),
  };
}

export function checkIsNotBlankRelations(
  relations: bodyResponseType<'get', '/offer/related/{id}'>
) {
  if (
    (relations?.coordinations && relations.coordinations.some(obj => Object.keys(obj).length)) ||
    (relations?.contracts && relations.contracts.some(obj => Object.keys(obj).length)) ||
    (relations.tender && Object.keys(relations.tender).length)
  ) {
    return true;
  }
  return false;
}

export function countRelations(relations?: bodyResponseType<'get', '/offer/related/{id}'>) {
  if (!relations || !Object.keys(relations).length) return 0;
  let coordinations = 0;
  let contracts = 0;
  let tender = 0;
  if (relations?.coordinations) {
    for (const obj of relations.coordinations) {
      if (Object.keys(obj).length) coordinations++;
    }
  }
  if (relations?.contracts) {
    for (const obj of relations.contracts) {
      if (Object.keys(obj).length) contracts++;
    }
  }
  if (relations?.tender && Object.keys(relations.tender).length) tender++;
  return coordinations + contracts + tender;
}
