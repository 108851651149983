import { Divider } from '@gravity-ui/uikit';
import { ComponentProps, ReactNode } from 'react';

import { URLS } from '@shared/consts';
import { cn, formatBytes, useNavigateTo } from '@shared/lib';

import { FileMock, PeopleMock, ProjectMock } from '../model/mock';
import { GlobalSearchListGroup } from './GlobalSearchListGroup';
import { GlobalSearchListItem } from './GlobalSearchListItem';
import { GlobalSearchUserListItem } from './GlobalSearchUserListItem';

type GlobalSearchListProps = Omit<ComponentProps<'div'>, 'children'> & {
  files?: FileMock[];
  projects?: ProjectMock[];
  peoples?: PeopleMock[];
  insertBefore?: ReactNode;
} & (
    | {
        closeOnNavigate: true;
        close: () => void;
      }
    | {
        closeOnNavigate?: false;
        close?: undefined;
      }
  );

export function GlobalSearchList({
  files,
  peoples,
  projects,
  insertBefore,
  className,
  closeOnNavigate,
  close,
  ...props
}: GlobalSearchListProps) {
  const { navigateTo } = useNavigateTo();

  return (
    <div
      className={cn('flex flex-col gap-4', className)}
      {...props}
    >
      {insertBefore}
      {!!projects?.length && (
        <>
          <GlobalSearchListGroup name="Проекты">
            <div className="flex flex-col">
              {projects.map(({ date, entity, location, name }, index) => (
                <GlobalSearchListItem
                  key={index}
                  title={name}
                  date={date}
                  location={location}
                  description={entity}
                  onClick={() => {
                    navigateTo(`${URLS.search}/commercial-offer/${index}`);
                    if (closeOnNavigate) {
                      close();
                    }
                  }}
                />
              ))}
            </div>
          </GlobalSearchListGroup>
          <Divider />
        </>
      )}
      {!!files?.length && (
        <>
          <GlobalSearchListGroup name="Файлы">
            <div className="flex flex-col">
              {files.map(({ date, extension, location, name, size }, index) => (
                <GlobalSearchListItem
                  key={index}
                  title={name}
                  date={date}
                  location={location}
                  description={`${extension}, ${formatBytes(size, 1)}`}
                  onClick={() => {
                    navigateTo(`${URLS.search}/commercial-offer/${index}`);
                    if (closeOnNavigate) {
                      close();
                    }
                  }}
                />
              ))}
            </div>
          </GlobalSearchListGroup>
          <Divider />
        </>
      )}
      {!!peoples?.length && (
        <>
          <GlobalSearchListGroup name="Люди">
            {peoples.map(({ avatarImageURL, avatarName, name, role }, index) => (
              <GlobalSearchUserListItem
                key={index}
                avatarImageURL={avatarImageURL}
                avatarName={avatarName}
                name={name}
                role={role}
              />
            ))}
          </GlobalSearchListGroup>
        </>
      )}
    </div>
  );
}
