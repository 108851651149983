import { DelayedTextInput } from '@gravity-ui/components';
import { Magnifier } from '@gravity-ui/icons';
import { Icon } from '@gravity-ui/uikit';
import { ComponentProps } from 'react';

import { cn } from '@shared/lib';

type SearchDialogsProps = Omit<
  ComponentProps<typeof DelayedTextInput>,
  'startContent' | 'placeholder' | 'size' | 'hasClear'
>;

export function SearchDialogs({ className, ...props }: SearchDialogsProps) {
  return (
    <DelayedTextInput
      {...props}
      startContent={
        <Icon
          data={Magnifier}
          className="text-text-secondary"
        />
      }
      placeholder="Поиск сообщения"
      size="l"
      hasClear
      className={cn('w-full input-padding', className)}
    />
  );
}
