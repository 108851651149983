import { useSetAtom } from 'jotai';
import { ReactNode, useState } from 'react';

import {
  createdCompetitorForViewAtom,
  createdCustomerForViewAtom,
  createdEmployeeForViewAtom,
  createdLegalEntityForViewAtom,
  createdPurchaseObjectForViewAtom,
  createdRegionForViewAtom,
  createdSupplierForViewAtom,
} from '@entities';
import {
  CommercialOfferForm,
  CommercialOfferFormProps,
  CommercialOfferSchema,
  createdTenderForViewAtom,
} from '@features';
import { CompetitorsDrawer } from '@widgets/custom-handbook-drawers/competitors-drawer/@x';
import { CustomersDrawer } from '@widgets/custom-handbook-drawers/customers-drawer/@x';
import { EmployeesDrawer } from '@widgets/custom-handbook-drawers/employees-drawer/@x';
import { LegalEntitiesDrawer } from '@widgets/custom-handbook-drawers/legal-entities-drawer/@x';
import { PurchaseObjectsDrawer } from '@widgets/custom-handbook-drawers/purchase-objects-drawer/@x';
import { RegionsDrawer } from '@widgets/custom-handbook-drawers/regions-drawer/@x';
import { SuppliersDrawer } from '@widgets/custom-handbook-drawers/suppliers-drawer/@x';
import { CreateTender } from '@widgets/tender-drawer/@x';

interface CommercialOfferLayoutProps {
  renderForm: (formComponent: ReactNode) => ReactNode;
  formProps: Omit<
    CommercialOfferFormProps,
    | 'onClickCreateCustomerHandbook'
    | 'onClickCreateRegionsHandbook'
    | 'onClickCreatePurchaseObjectsHandbook'
    | 'onClickCreateTenderHandbook'
    | 'onClickCreateCompetitorsHandbook'
    | 'onClickCreateLegalEntitiesHandbook'
    | 'onClickCreateTender'
  >;
}

export const CommercialOfferLayout = ({ renderForm, formProps }: CommercialOfferLayoutProps) => {
  const setCreatedTenderForView = useSetAtom(createdTenderForViewAtom);
  const setCreatedEmployeeForView = useSetAtom(createdEmployeeForViewAtom);
  const setCreatedCustomerForView = useSetAtom(createdCustomerForViewAtom);
  const setCreatedRegionForView = useSetAtom(createdRegionForViewAtom);
  const setCreatedPurchaseObjectForView = useSetAtom(createdPurchaseObjectForViewAtom);
  const setCreatedSupplierForView = useSetAtom(createdSupplierForViewAtom);
  const setCreatedLegalEntityForView = useSetAtom(createdLegalEntityForViewAtom);
  const setCreatedCompetitorForView = useSetAtom(createdCompetitorForViewAtom);

  const [createTenderOpen, setCreateTenderOpen] = useState(false);
  const [createEmployeesOpen, setCreateEmployeesOpen] = useState(false);
  const [createCustomersOpen, setCreateCustomersOpen] = useState(false);
  const [createRegionsOpen, setCreateRegionsOpen] = useState(false);
  const [createPurchaseObjectsOpen, setCreatePurchaseObjectsOpen] = useState(false);
  const [createSuppliersOpen, setCreateSuppliersOpen] = useState(false);
  const [createLegalEntitiesOpen, setCreateLegalEntitiesOpen] = useState(false);
  const [createCompetitorsOpen, setCreateCompetitorsOpen] = useState(false);

  const [offerValues, setOfferValues] = useState<CommercialOfferSchema | undefined>();

  return (
    <>
      {renderForm(
        <CommercialOfferForm
          {...formProps}
          onClickCreateRegionsHandbook={() => setCreateRegionsOpen(true)}
          onClickCreatePurchaseObjectsHandbook={() => setCreatePurchaseObjectsOpen(true)}
          onClickCreateCompetitorsHandbook={() => setCreateCompetitorsOpen(true)}
          onClickCreateCustomersHandbook={() => setCreateCustomersOpen(true)}
          onClickCreateLegalEntitiesHandbook={() => setCreateLegalEntitiesOpen(true)}
          onClickCreateEmployeesHandbook={() => setCreateEmployeesOpen(true)}
          onClickCreateSuppliersHandbook={() => setCreateSuppliersOpen(true)}
          onClickCreateTender={() => setCreateTenderOpen(true)}
          onValuesChange={values => {
            formProps.onValuesChange?.(values);
            setOfferValues(values);
          }}
        />
      )}
      <EmployeesDrawer
        withDialog
        type="create"
        open={createEmployeesOpen}
        onClose={() => setCreateEmployeesOpen(false)}
        afterCreate={values => {
          setCreatedEmployeeForView({
            employee_value: values.name_initials,
            employee_id: values.id,
          });
        }}
      />
      <CustomersDrawer
        withDialog
        type="create"
        open={createCustomersOpen}
        onClose={() => setCreateCustomersOpen(false)}
        afterCreate={values => {
          setCreatedCustomerForView({
            customer_value: values.name,
            customer_id: values.id,
          });
        }}
      />
      <RegionsDrawer
        withDialog
        type="create"
        open={createRegionsOpen}
        onClose={() => setCreateRegionsOpen(false)}
        afterCreate={values => {
          setCreatedRegionForView({
            region_value: values.name,
            region_id: values.id,
          });
        }}
      />
      <PurchaseObjectsDrawer
        withDialog
        type="create"
        open={createPurchaseObjectsOpen}
        onClose={() => setCreatePurchaseObjectsOpen(false)}
        afterCreate={values => {
          setCreatedPurchaseObjectForView({
            purchaseObject_value: values.object,
            purchaseObject_id: values.id,
          });
        }}
      />
      <SuppliersDrawer
        withDialog
        type="create"
        open={createSuppliersOpen}
        onClose={() => setCreateSuppliersOpen(false)}
        afterCreate={values => {
          setCreatedSupplierForView({
            supplier_value: values.name,
            supplier_id: values.id,
          });
        }}
      />
      <LegalEntitiesDrawer
        withDialog
        type="create"
        open={createLegalEntitiesOpen}
        onClose={() => setCreateLegalEntitiesOpen(false)}
        afterCreate={values => {
          setCreatedLegalEntityForView({
            legalEntity_value: values.name,
            legalEntity_id: values.id,
          });
        }}
      />
      <CompetitorsDrawer
        withDialog
        type="create"
        open={createCompetitorsOpen}
        onClose={() => setCreateCompetitorsOpen(false)}
        afterCreate={values => {
          setCreatedCompetitorForView({
            competitor_value: values.name,
            competitor_id: values.id,
          });
        }}
      />

      <CreateTender
        open={createTenderOpen}
        onClose={() => setCreateTenderOpen(false)}
        afterCreate={createdTender => {
          setCreatedTenderForView({
            tender_value: createdTender.title,
            tender_id: createdTender.id,
          });
        }}
        defaultValues={
          offerValues
            ? {
                responsible_id: offerValues['responsible_id'],
                customer_ids: [offerValues['customer_id']],
                delivery_region_id: offerValues.delivery_region_id,
                object_ids: offerValues.purchase_object_id ? [offerValues.purchase_object_id] : [],
              }
            : undefined
        }
      />
    </>
  );
};
