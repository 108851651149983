import { FieldWithPreviewProps } from '@shared/ui';

export const ENUM_OPTIONS = {
  purpose: [
    { value: 'Закупка ЕП', content: 'Закупка ЕП' },
    { value: 'Обоснование НМЦК', content: 'Обоснование НМЦК' },
  ],
  basis_purchase: [
    { value: 'Прямая закупка с договором', content: 'Прямая закупка с договором' },
    { value: 'Прямая закупка без договора', content: 'Прямая закупка без договора' },
  ],
  status: [
    { preview: 'info', value: 'Торги опубликованы', content: 'Торги опубликованы' },
    { preview: 'clear', value: 'Торги не отработаны', content: 'Торги не отработаны' },
    { preview: 'success', value: 'Выигран', content: 'Выигран' },
    { preview: 'danger', value: 'Проигран', content: 'Проигран' },
    { preview: 'danger', value: 'Отказ', content: 'Отказ' },
    { preview: 'utility', value: 'Отмена', content: 'Отмена' },
    { preview: 'normal', value: 'Подача', content: 'Подача' },
    { preview: 'normal', value: 'Ожидание', content: 'Ожидание' },
    { preview: 'clear', value: 'Не подано', content: 'Не подано' },
  ] as FieldWithPreviewProps['Select']['options'],
  reject_reason: [
    {
      value: 'Не успели рассчитать',
      content: 'Не успели рассчитать',
    },
    {
      value: 'Не успели подготовить заявку',
      content: 'Не успели подготовить заявку',
    },
    {
      value: 'Низкая маржа начальная',
      content: 'Низкая маржа начальная',
    },
    {
      value: 'Иная причина',
      content: 'Иная причина',
    },
  ],
};
