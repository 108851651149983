import { useToaster } from '@gravity-ui/uikit';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom, useAtomValue } from 'jotai';
import { useEffect } from 'react';

import { $agreementHooks, userAtom } from '@entities';
import { $commercialOfferHooks } from '@entities/commercial-offers-entity';
import { getAgreementSubmitCommercialOffer } from '@features';
import { RightSidebar } from '@shared/ui';

import { percentAndCurrencyValuesToBackend } from '../lib';
import { commercialOfferCreationAtom } from '../model';
import { CommercialOfferLayout } from './CommercialOfferLayout';

type CreateCommercialOfferProps = {
  open: boolean;
  onClose: () => void;
};

export function CreateCommercialOffer({ open, onClose }: CreateCommercialOfferProps) {
  const { add } = useToaster();

  const queryClient = useQueryClient();

  // Queries
  const createAgreementMutation = $agreementHooks.create();

  //

  const user = useAtomValue(userAtom);

  const [commercialOfferCreation, setCommercialOfferCreation] = useAtom(
    commercialOfferCreationAtom
  );

  const createCommercialOfferMutation = $commercialOfferHooks.create();

  useEffect(() => {
    // Уведомления о создании карточки согласования
    if (createAgreementMutation.isSuccess) {
      add({
        theme: 'success',
        name: 'create-agreement-success',
        title: 'Согласование создано',
      });
    }
    if (createAgreementMutation.isError) {
      add({
        theme: 'danger',
        name: 'create-agreement-error',
        title: 'Не удалось создать Согласование',
      });
    }
  }, [createAgreementMutation.isPending]);

  return (
    <CommercialOfferLayout
      formProps={{
        submitButtonText: 'Создать',
        defaultValues: commercialOfferCreation,
        onValuesChange: setCommercialOfferCreation,
        allowedStatuses: values => {
          if (values.purpose === 'Обоснование НМЦК' || values.purpose === 'Закупка ЕП') {
            return ['Подача'];
          }

          return [];
        },
        onClickCancelButton: () => {
          setCommercialOfferCreation(undefined);
          onClose();
        },
        initialEditable: true,
        hideTitleField: false,
        onValid: async values => {
          // TODO: После создания редиректим в чат
          const newOffer = await createCommercialOfferMutation.mutateAsync({
            body: {
              data: {
                ...values,
                ...percentAndCurrencyValuesToBackend(values),
                is_blocked_work_params: getAgreementSubmitCommercialOffer(values, false),
              },
              meta: {
                user_name: user?.id,
              },
            },
          });
          if (createCommercialOfferMutation.isError) {
            return add({
              name: 'create-offer-validation-error',
              theme: 'danger',
              content: 'Ошибка при создании карточки КП',
            });
          }
          add({
            name: 'create-offer-success',
            theme: 'success',
            content: 'Карточка КП успешна создана!',
          });

          queryClient.invalidateQueries({ queryKey: ['/offer/all'] });

          if (getAgreementSubmitCommercialOffer(values, false))
            await createAgreementMutation.mutateAsync({
              body: {
                coordination_type: 'OFFER_PRICE',
                entity_id: newOffer.id,
                entity_type: 'OFFER',
              },
            });

          setCommercialOfferCreation(undefined);
          onClose();
        },
      }}
      renderForm={formComponent => (
        <RightSidebar
          id="sidebar-commercial-offer"
          open={open}
          onClose={onClose}
        >
          <RightSidebar.Header
            title="Создание коммерческого предложения"
            onClose={onClose}
          />
          {formComponent}
        </RightSidebar>
      )}
    />
  );
}
