import { TabsItemProps } from '@gravity-ui/uikit';
import { atomWithStorage } from 'jotai/utils';

import { LOCAL_STORAGE } from '@shared/consts';
import { customStorage } from '@shared/lib';

export const tableTabsAtom = atomWithStorage<TabsItemProps[]>(
  LOCAL_STORAGE.contractsTabs,
  customStorage<TabsItemProps[]>().getItem(LOCAL_STORAGE.contractsTabs, [
    { id: 'Все', title: 'Все' },
  ]),
  customStorage()
);

export const selectedTabAtom = atomWithStorage<string | null>(
  LOCAL_STORAGE.contractsSelectedTab,
  customStorage<string | null>().getItem(LOCAL_STORAGE.contractsSelectedTab, null),
  customStorage()
);

// export const frontKeyBackendValueColumns = {
//   name: 'Наименование контракта',
//   basis: 'Основание',
//   fz: 'Тип ФЗ',
//   gozfz: 'В рамках ГОЗ по 275-ФЗ',
//   unique_number: 'Уникальный номер контракта',
//   stage: 'Этап',
//   responsible_id: 'Ответственный, значение из справочника сотрудников',
//   customer_id: 'Заказчик, значение из справочника заказчиков',
//   customer_priority: 'Приоритет заказчика, символы',
//   supplier_id: 'Поставщик, значение из справочника поставщиков',
//   object_id: 'Объект торгов, значение из справочника',
//   comment: 'Комментарий, текстовое поле',
//   days_type: 'Тип дней',
//   payment_term: 'Фактический срок оплаты с момента оплаты',
//   contract_sum: 'Сумма контракта в рублях',
//   contract_number: 'Номер контракта, текстовое поле',
//   signing_date: 'Дата подписания контракта',
//   contract_duration: 'Срок действия контракта',
//   kp: 'Карточка контракта с наименованием контракта',
//   region_id: 'Регион поставки, значение из справочника',
//   delivery_address: 'Адрес поставки, текстовое поле',
//   delivery_term: 'Срок поставки, текстовое поле',
//   actual_file: 'Актуальный файл расчета, поле для прикрепления файла',
//   basis_file: 'Файл основания, поле для прикрепления файла',
//   has_smp_restriction: 'Преимущество/ограничение для СМП',
//   has_contract_security: 'Обеспечение исполнения контракта',
//   contract_security_sum: 'Сумма обеспечения исполнения контракта в рублях',
//   contract_security_type: 'Вид обеспечения',
//   contract_security_term: 'Срок обеспечения',
//   has_warranty_security: 'Обеспечение гарантийных обязательств',
//   warranty_security_sum: 'Сумма обеспечения гарантийных обязательств в рублях',
//   warranty_security_type: 'Вид обеспечения гарантийных обязательств',
//   warranty_security_term: 'Срок обеспечения гарантийных обязательств',
//   progress_percent: 'Прогресс исполнения контракта, от 0 до 100%',
//   contract_limit: 'Лимит контракта в рублях',
//   shipment_sum_with_vat: 'Сумма отгрузок с НДС в рублях',
//   shipment_sum_without_vat: 'Сумма отгрузок без НДС в рублях',
//   vat_sum: 'Сумма НДС в рублях',
//   purchase_sum: 'Сумма закупок в рублях',
//   limit_balance: 'Остаток лимита в рублях',
//   payment_sum: 'Сумма оплаты в рублях',
//   receivables_sum: 'Сумма дебиторской задолженности в рублях',
//   overdue_receivables_sum: 'Сумма просроченной дебиторской задолженности в рублях',
// };

export const frontKeyBackendValueColumns: {
  'Дата, номер': { Дата: 'created_at'; Номер: 'id' };
  Наименование: 'name';
  Основание: 'basis';
  ФЗ: 'fz';
  'В рамках ГОЗ по 275-ФЗ': 'gozfz';
  Сопровождение: 'maintenance'; // TODO: нет на бэке
  'Уникальный номер': 'unique_number';
  Этап: 'stage';
  'Причина расторжения': 'termination_reason';
  Ответственный: 'responsible_value'; // TODO: нет на бэке
  Заказчик: 'customer_value';
  'Приоритет заказчика': 'customer_priority';
  Поставщик: 'supplier_value';
  'Объект торгов': 'object_value';
  Комментарий: 'comment';
  Дни: 'days_type';
  'Фактический срок оплаты с момента оплаты': 'payment_term';
  'Сумма контракта': 'contract_sum';
  'Номер контракта': 'contract_number';
  'Дата подписания': 'signing_date';
  'Срок действия контракта': 'contract_duration';
  'Реестровый номер контракта': 'contract_registration_number'; // TODO: нет на бэке
  Площадка: 'trading_platform_value'; // TODO: нет на бэке
  'КП/Тендер': 'related_card_value';
  'Регион поставки': 'region_value'; // TODO: нет на бэке
  'Адрес поставки': 'delivery_address';
  'Срок поставки': 'delivery_term';
  'Актуальный файл расчета': 'actual_files'; // TODO: нет на бэке
  'Файл основания (контракт, обеспечение, доп.соглашение/договор/счет)': 'basis_files'; // TODO: нет на бэке
  'Есть преимущество/ограничение для СМП?': 'has_smp_restriction';
  'Обеспечение исполнения контракта есть': 'has_contract_security';
  'Сумма обеспечения исполнения': 'contract_security_sum';
  'Вид обеспечения исполнения': 'contract_security_type';
  'Срок обеспечения исполнения': 'contract_security_term';
  'Обеспечение гарантийных обязательств есть': 'has_warranty_security';
  'Сумма обеспечения гарантийных обязательств': 'warranty_security_sum';
  'Вид обеспечения гарантийных обязательств': 'warranty_security_type';
  'Срок обеспечения гарантийных обязательств': 'warranty_security_term';
  'Прогресс исполнения': 'progress_percent';
  'Лимит контракта': 'contract_limit';
  'Сумма отгрузок, с НДС': 'shipment_sum_with_vat';
  'Сумма отгрузок, без НДС': 'shipment_sum_without_vat';
  'Сумма НДС': 'vat_sum';
  'Сумма закупок': 'purchase_sum';
  'Остаток лимита': 'limit_balance';
  'Сумма оплаты': 'payment_sum';
  'Сумма ДЗ': 'receivables_sum';
  'В т.ч. Сумма ПДЗ': 'overdue_receivables_sum';
} = {
  'Дата, номер': { Дата: 'created_at', Номер: 'id' },
  Наименование: 'name',
  Основание: 'basis',
  ФЗ: 'fz',
  'В рамках ГОЗ по 275-ФЗ': 'gozfz',
  Сопровождение: 'maintenance', // TODO: нет на бэке
  'Уникальный номер': 'unique_number',
  Этап: 'stage',
  'Причина расторжения': 'termination_reason',
  Ответственный: 'responsible_value', // TODO: нет на бэке
  Заказчик: 'customer_value',
  'Приоритет заказчика': 'customer_priority',
  Поставщик: 'supplier_value',
  'Объект торгов': 'object_value',
  Комментарий: 'comment',
  Дни: 'days_type',
  'Фактический срок оплаты с момента оплаты': 'payment_term',
  'Сумма контракта': 'contract_sum',
  'Номер контракта': 'contract_number',
  'Дата подписания': 'signing_date',
  'Срок действия контракта': 'contract_duration',
  'Реестровый номер контракта': 'contract_registration_number', // TODO: нет на бэке
  Площадка: 'trading_platform_value', // TODO: нет на бэке
  'КП/Тендер': 'related_card_value',
  'Регион поставки': 'region_value', // TODO: нет на бэке
  'Адрес поставки': 'delivery_address',
  'Срок поставки': 'delivery_term',
  'Актуальный файл расчета': 'actual_files', // TODO: нет на бэке
  'Файл основания (контракт, обеспечение, доп.соглашение/договор/счет)': 'basis_files', // TODO: нет на бэке
  'Есть преимущество/ограничение для СМП?': 'has_smp_restriction',
  'Обеспечение исполнения контракта есть': 'has_contract_security',
  'Сумма обеспечения исполнения': 'contract_security_sum',
  'Вид обеспечения исполнения': 'contract_security_type',
  'Срок обеспечения исполнения': 'contract_security_term',
  'Обеспечение гарантийных обязательств есть': 'has_warranty_security',
  'Сумма обеспечения гарантийных обязательств': 'warranty_security_sum',
  'Вид обеспечения гарантийных обязательств': 'warranty_security_type',
  'Срок обеспечения гарантийных обязательств': 'warranty_security_term',
  'Прогресс исполнения': 'progress_percent',
  'Лимит контракта': 'contract_limit',
  'Сумма отгрузок, с НДС': 'shipment_sum_with_vat',
  'Сумма отгрузок, без НДС': 'shipment_sum_without_vat',
  'Сумма НДС': 'vat_sum',
  'Сумма закупок': 'purchase_sum',
  'Остаток лимита': 'limit_balance',
  'Сумма оплаты': 'payment_sum',
  'Сумма ДЗ': 'receivables_sum',
  'В т.ч. Сумма ПДЗ': 'overdue_receivables_sum',
};

export const frontKeyBackendValueSorting: {
  'Дата, номер': 'created_at';
  Наименование: 'name';
  Основание: 'basis';
  ФЗ: 'fz';
  'В рамках ГОЗ по 275-ФЗ': 'gozfz';
  Сопровождение: 'maintenance'; // TODO: нет на бэке
  'Уникальный номер': 'unique_number';
  Этап: 'stage';
  'Причина расторжения': 'termination_reason';
  Ответственный: 'responsible_value'; // TODO: нет на бэке
  Заказчик: 'customer_value';
  'Приоритет заказчика': 'customer_priority';
  Поставщик: 'supplier_value';
  'Объект торгов': 'object_value';
  Комментарий: 'comment';
  Дни: 'days_type';
  'Фактический срок оплаты с момента оплаты': 'payment_term';
  'Сумма контракта': 'contract_sum';
  'Номер контракта': 'contract_number';
  'Дата подписания': 'signing_date';
  'Срок действия контракта': 'contract_duration';
  'Реестровый номер контракта': 'contract_registration_number'; // TODO: нет на бэке
  Площадка: 'trading_platform_value'; // TODO: нет на бэке
  'КП/Тендер': 'related_card_value';
  'Регион поставки': 'region_value'; // TODO: нет на бэке
  'Адрес поставки': 'delivery_address';
  'Срок поставки': 'delivery_term';
  'Актуальный файл расчета': 'actual_files'; // TODO: нет на бэке
  'Файл основания (контракт, обеспечение, доп.соглашение/договор/счет)': 'basis_files'; // TODO: нет на бэке
  'Есть преимущество/ограничение для СМП?': 'has_smp_restriction';
  'Обеспечение исполнения контракта есть': 'has_contract_security';
  'Сумма обеспечения исполнения': 'contract_security_sum';
  'Вид обеспечения исполнения': 'contract_security_type';
  'Срок обеспечения исполнения': 'contract_security_term';
  'Обеспечение гарантийных обязательств есть': 'has_warranty_security';
  'Сумма обеспечения гарантийных обязательств': 'warranty_security_sum';
  'Вид обеспечения гарантийных обязательств': 'warranty_security_type';
  'Срок обеспечения гарантийных обязательств': 'warranty_security_term';
  'Прогресс исполнения': 'progress_percent';
  'Лимит контракта': 'contract_limit';
  'Сумма отгрузок, с НДС': 'shipment_sum_with_vat';
  'Сумма отгрузок, без НДС': 'shipment_sum_without_vat';
  'Сумма НДС': 'vat_sum';
  'Сумма закупок': 'purchase_sum';
  'Остаток лимита': 'limit_balance';
  'Сумма оплаты': 'payment_sum';
  'Сумма ДЗ': 'receivables_sum';
  'В т.ч. Сумма ПДЗ': 'overdue_receivables_sum';
} = { ...frontKeyBackendValueColumns, 'Дата, номер': 'created_at' };

export const frontKeyBackendValueFilters: {
  'Дата, номер': { from: 'min_created_at'; to: 'max_created_at' };
  Наименование: 'name';
  Основание: 'basis';
  ФЗ: 'fz';
  'В рамках ГОЗ по 275-ФЗ': 'gozfz';
  Сопровождение: 'maintenance'; // TODO: нет на бэке
  'Уникальный номер': 'unique_number';
  Этап: 'stage';
  'Причина расторжения': 'termination_reason';
  Ответственный: 'responsible_id'; // TODO: нет на бэке
  Заказчик: 'customer_id';
  'Приоритет заказчика': { from: 'customer_priority_min'; to: 'customer_priority_max' }; // TODO: нет на бэке
  Поставщик: 'supplier_id';
  'Объект торгов': 'object_id';
  Комментарий: 'comment';
  Дни: 'days_type';
  'Фактический срок оплаты с момента оплаты': { from: 'payment_term_min'; to: 'payment_term_max' };
  'Сумма контракта': { from: 'contract_sum_min'; to: 'contract_sum_max' };
  'Номер контракта': 'contract_number';
  'Дата подписания': { from: 'signing_date_start'; to: 'signing_date_end' };
  'Срок действия контракта': { from: 'contract_duration_start'; to: 'contract_duration_end' };
  'Реестровый номер контракта': 'contract_registration_number'; // TODO: нет на бэке
  'КП/Тендер': 'related_card_value'; // TODO: нет на бэке
  Площадка: 'trading_platform_id'; // TODO: нет на бэке
  'Регион поставки': 'region_id'; // TODO: нет на бэке
  'Адрес поставки': 'delivery_address';
  'Срок поставки': 'delivery_term';
  'Актуальный файл расчета': 'actual_files'; // TODO: нет на бэке
  'Файл основания (контракт, обеспечение, доп.соглашение/договор/счет)': 'basis_files'; // TODO: нет на бэке
  'Есть преимущество/ограничение для СМП?': 'has_smp_restriction';
  'Обеспечение исполнения контракта есть': 'has_contract_security';
  'Сумма обеспечения исполнения': {
    from: 'contract_security_sum_min';
    to: 'contract_security_sum_max';
  };
  'Вид обеспечения исполнения': 'contract_security_type';
  'Срок обеспечения исполнения': {
    from: 'contract_security_term_start';
    to: 'contract_security_term_end';
  };
  'Обеспечение гарантийных обязательств есть': 'has_warranty_security';
  'Сумма обеспечения гарантийных обязательств': {
    from: 'warranty_security_sum_min';
    to: 'warranty_security_sum_max';
  };
  'Вид обеспечения гарантийных обязательств': 'warranty_security_type';
  'Срок обеспечения гарантийных обязательств': {
    from: 'warranty_security_term_start';
    to: 'warranty_security_term_end';
  };
  'Прогресс исполнения': {
    from: 'progress_percent_min';
    to: 'progress_percent_max';
  };
  'Лимит контракта': { from: 'contract_limit_min'; to: 'contract_limit_max' };
  'Сумма отгрузок, с НДС': {
    from: 'shipment_sum_with_vat_min';
    to: 'shipment_sum_with_vat_max';
  };
  'Сумма отгрузок, без НДС': {
    from: 'shipment_sum_without_vat_min';
    to: 'shipment_sum_without_vat_max';
  };
  'Сумма НДС': { from: 'vat_sum_min'; to: 'vat_sum_max' };
  'Сумма закупок': { from: 'purchase_sum_min'; to: 'purchase_sum_max' };
  'Остаток лимита': { from: 'limit_balance_min'; to: 'limit_balance_max' };
  'Сумма оплаты': { from: 'payment_sum_min'; to: 'payment_sum_max' };
  'Сумма ДЗ': {
    from: 'receivables_sum_min';
    to: 'receivables_sum_max';
  };
  'В т.ч. Сумма ПДЗ': {
    from: 'overdue_receivables_sum_min';
    to: 'overdue_receivables_sum_max';
  };
} = {
  'Дата, номер': { from: 'min_created_at', to: 'max_created_at' },
  Наименование: 'name',
  Основание: 'basis',
  ФЗ: 'fz',
  'В рамках ГОЗ по 275-ФЗ': 'gozfz',
  Сопровождение: 'maintenance', // TODO: нет на бэке
  'Уникальный номер': 'unique_number',
  Этап: 'stage',
  'Причина расторжения': 'termination_reason',
  Ответственный: 'responsible_id', // TODO: нет на бэке
  Заказчик: 'customer_id',
  'Приоритет заказчика': { from: 'customer_priority_min', to: 'customer_priority_max' }, // TODO: нет на бэке
  Поставщик: 'supplier_id',
  'Объект торгов': 'object_id',
  Комментарий: 'comment',
  Дни: 'days_type',
  'Фактический срок оплаты с момента оплаты': { from: 'payment_term_min', to: 'payment_term_max' },
  'Сумма контракта': { from: 'contract_sum_min', to: 'contract_sum_max' },
  'Номер контракта': 'contract_number',
  'Дата подписания': { from: 'signing_date_start', to: 'signing_date_end' },
  'Срок действия контракта': { from: 'contract_duration_start', to: 'contract_duration_end' },
  'Реестровый номер контракта': 'contract_registration_number', // TODO: нет на бэке
  'КП/Тендер': 'related_card_value', // TODO: нет на бэке
  Площадка: 'trading_platform_id', // TODO: нет на бэке
  'Регион поставки': 'region_id', // TODO: нет на бэке
  'Адрес поставки': 'delivery_address',
  'Срок поставки': 'delivery_term',
  'Актуальный файл расчета': 'actual_files', // TODO: нет на бэке
  'Файл основания (контракт, обеспечение, доп.соглашение/договор/счет)': 'basis_files', // TODO: нет на бэке
  'Есть преимущество/ограничение для СМП?': 'has_smp_restriction',
  'Обеспечение исполнения контракта есть': 'has_contract_security',
  'Сумма обеспечения исполнения': {
    from: 'contract_security_sum_min',
    to: 'contract_security_sum_max',
  },
  'Вид обеспечения исполнения': 'contract_security_type',
  'Срок обеспечения исполнения': {
    from: 'contract_security_term_start',
    to: 'contract_security_term_end',
  },
  'Обеспечение гарантийных обязательств есть': 'has_warranty_security',
  'Сумма обеспечения гарантийных обязательств': {
    from: 'warranty_security_sum_min',
    to: 'warranty_security_sum_max',
  },
  'Вид обеспечения гарантийных обязательств': 'warranty_security_type',
  'Срок обеспечения гарантийных обязательств': {
    from: 'warranty_security_term_start',
    to: 'warranty_security_term_end',
  },
  'Прогресс исполнения': {
    from: 'progress_percent_min',
    to: 'progress_percent_max',
  },
  'Лимит контракта': { from: 'contract_limit_min', to: 'contract_limit_max' },
  'Сумма отгрузок, с НДС': {
    from: 'shipment_sum_with_vat_min',
    to: 'shipment_sum_with_vat_max',
  },
  'Сумма отгрузок, без НДС': {
    from: 'shipment_sum_without_vat_min',
    to: 'shipment_sum_without_vat_max',
  },
  'Сумма НДС': { from: 'vat_sum_min', to: 'vat_sum_max' },
  'Сумма закупок': { from: 'purchase_sum_min', to: 'purchase_sum_max' },
  'Остаток лимита': { from: 'limit_balance_min', to: 'limit_balance_max' },
  'Сумма оплаты': { from: 'payment_sum_min', to: 'payment_sum_max' },
  'Сумма ДЗ': {
    from: 'receivables_sum_min',
    to: 'receivables_sum_max',
  },
  'В т.ч. Сумма ПДЗ': {
    from: 'overdue_receivables_sum_min',
    to: 'overdue_receivables_sum_max',
  },
};
