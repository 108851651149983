import { useToaster } from '@gravity-ui/uikit';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import { $contactsHooks } from '@entities';
import { contactsFormAtom, CreateHandbookDialog } from '@features';
import { bodyResponseType } from '@shared/api';
import { RightSidebar } from '@shared/ui';

import { ContactsLayout } from './ContactsLayout';

interface CreateContactsProps {
  open: boolean;
  onClose: () => void;
  afterCreate?: (createdContact: bodyResponseType<'post', '/handbook/contacts'>) => void;
  withDialog?: boolean;
}

export function CreateContacts({ open, onClose, afterCreate, withDialog }: CreateContactsProps) {
  const { add } = useToaster();

  const queryClient = useQueryClient();

  const [contactsCreation, setContactsCreation] = useAtom(contactsFormAtom);

  // Queries
  const createContactsMutation = $contactsHooks.createMutation();

  //

  return (
    <ContactsLayout
      formProps={{
        defaultValues: contactsCreation,
        onValuesChange: values => {
          setContactsCreation(values);
        },
        onClickCancelButton: () => {
          setContactsCreation(undefined);
          onClose();
        },
        compactBottomPanel: true,
        // disabled: underApproval,
        initialEditable: true,
        onValid: async values => {
          const valuesWithoutWithType: typeof values = {
            ...values,
            organization_with_type: undefined,
          };
          await createContactsMutation
            .mutateAsync({
              // TODO: присутствует в схеме
              body: { ...valuesWithoutWithType, organization_value: undefined },
            })
            .then(createdContact => {
              add({
                name: 'create-contacts-success',
                theme: 'success',
                content: 'Карточка Контакта успешна создана!',
              });
              queryClient.invalidateQueries({ queryKey: ['/contacts/all'] });
              afterCreate?.(createdContact);

              setContactsCreation(undefined);
              onClose();
            })
            .catch(() => {
              add({
                name: 'create-contacts-error',
                theme: 'danger',
                content: 'Ошибка при создании карточки Контакта',
              });
            });
        },
        onInvalid: errors => {
          console.log('@errors', errors);
        },
      }}
      renderForm={formComponent =>
        withDialog ? (
          <CreateHandbookDialog
            onClose={onClose}
            open={open}
            title="Создание карточки Контакта"
          >
            {formComponent}
          </CreateHandbookDialog>
        ) : (
          <RightSidebar
            open={open}
            onClose={onClose}
          >
            <RightSidebar.Header
              title="Создание карточки Контакта"
              onClose={onClose}
            />
            {formComponent}
          </RightSidebar>
        )
      }
    />
  );
}
