import { dateTime } from '@gravity-ui/date-utils';

import { CRMTableData } from '@features/table';
import { bodyResponseType } from '@shared/api';
import { DATE_FORMATS } from '@shared/consts';
import { formatToPrice } from '@shared/lib';

import { ColumnsTendersTable } from '../model';

const parseDate = (date: string, withTime?: boolean) =>
  dateTime({ input: date }).format(
    !withTime ? DATE_FORMATS.fullDate : DATE_FORMATS.fullDateWithTimeRounded
  );

const parseMultiple = (names: string[]): string => {
  try {
    return names.join(' \n');
  } catch {
    return '';
  }
};

const parseFiles = (files: string[]): string => {
  try {
    const keys = files.map(url => {
      return url.replace('https://stage.api.b2gcrm.ru/', '');
    });
    const fileNames = keys.map(key => {
      const split = key.split('/');
      return split.pop()!;
    });
    return parseMultiple(fileNames);
  } catch {
    return '';
  }
};

const parseFloat = (float: number): number => {
  return float / 100;
};

// 'Дата перевода': table.transfer_at ? parseDate(table.transfer_at) : '',
// Себестоимость: table.cost_price ? formatToPrice(table.cost_price, true) : '',
// 'Маржа, %': table.margin ? `${table.margin} %` : '',
// 'Согласовать цену с руководителем': table.manager_approved ? 'СОГЛАСОВАТЬ' : 'НЕ СОГЛАСОВАТЬ',

export const parseTenderTableData = (
  tableData: bodyResponseType<'post', '/tender/all'>['tenders']
): CRMTableData<ColumnsTendersTable> => {
  return tableData!.map(table => {
    return {
      'Дата, номер': table.created_at ? `${parseDate(table.created_at)} №${table.id}` : '',
      Наименование: table.title,
      Ответственный: table.responsible_value,
      'Статус заявки': table.status,
      Отклонение: table.decline || '',
      'Основание и причина отклонения': table.decline_reason || '',
      'Причина отказа': table['reject_reason'] || '',
      'Пояснение к причине отказа': table.reject_description || '',
      'Статус закупки': table['purchase_status'],
      'Причина почему не состоялась?': table.no_take_place_reason || '',
      'Номер извещения': table['notice_number'],
      'Ссылка на процедуру': table['procedure_link'] || '',
      'Электронная торговая площадка': table.trading_platform_value,
      'В рамках ГОЗ по 275-ФЗ': table.goz || '',
      ФЗ: table.fz,
      'Закупка централизованная или совместная?':
        table.is_centralized !== undefined ? (table.is_centralized ? 'ДА' : 'НЕТ') : '',
      Заказчики: table.customer_values ? parseMultiple(table.customer_values) : '',
      // TODO: приоритет во массиве
      'Приоритет заказчика':
        table.customer_priorities !== undefined ? `${table.customer_priorities[0]}` : '',
      Организатор: table.organizer_value || '',
      'Объект торгов': parseMultiple(table['object_values']),
      'Начальная цена': formatToPrice(table.cost_price, true),
      'Участвуем от': table['participate_as_value'],
      Комментарий: table.comment || '',
      'Способ проведения': table.implementation_method,
      'Ограничения, преимущества и доп. требования': parseMultiple(
        table.additional_information_values
      ),
      'Обеспечение заявки': formatToPrice(table.tender_support, true),
      'Окончание подачи': parseDate(table.submission_deadline, true),
      'Проведение процедуры': table.procedure_at ? parseDate(table.procedure_at, true) : '',
      'Подведение итогов': parseDate(table.results_at),
      Документация: table.documentation_files ? parseFiles(table.documentation_files) : '',
      Разъяснения: table.comments_files ? parseFiles(table.comments_files) : '',
      Протоколы: table.protocol_files ? parseFiles(table.protocol_files) : '',
      'Регион поставки': table.delivery_region_value,
      'Адрес поставки': table.delivery_address,
      'Срок поставки': parseDate(table.delivery_at),
      Себестоимость:
        typeof table.base_cost_price === 'number' ? formatToPrice(table.base_cost_price, true) : '',
      'Маржа начальная': table.margin !== undefined ? `${parseFloat(table.margin)} %` : '',
      'Файл расчета процедуры': table.calculation_files ? parseFiles(table.calculation_files) : '',
      'Файл заявки': table.request_file ? parseFiles([table.request_file]) : '',
      'Согласовать минимальную цену с руководителем': !table.is_under_approval
        ? 'СОГЛАСОВАТЬ'
        : 'НЕ СОГЛАСОВАТЬ',
      'Предлагаемая специалистом минимальная цена':
        typeof table.specialist_min_cost_price === 'number'
          ? formatToPrice(table.specialist_min_cost_price, true)
          : '',
      'Обоснование предложения минимальной цены': table.min_cost_price_reason || '',
      'Комментарий согласования': table.approve_comment || '',
      'Минимальная цена':
        typeof table.min_cost_price === 'number' ? formatToPrice(table.min_cost_price, true) : '',
      'Маржа минимальная':
        table.min_margin !== undefined ? `${parseFloat(table.min_margin)} %` : '',
      'Итоговая цена':
        typeof table.final_cost_price === 'number'
          ? formatToPrice(table.final_cost_price, true)
          : '',
      'Процент снижения':
        table.decrease_percent !== undefined ? `${parseFloat(table.decrease_percent)} %` : '',
      'Маржа итоговая':
        table.final_margin !== undefined ? `${parseFloat(table.final_margin)} %` : '',
      Победитель: table.winner_value || '',
      Участники: table.participant_values ? parseMultiple(table.participant_values) : '',
      'Руководитель ответственного': table.responsible_supervisor_value || '',
    };
  });
};
