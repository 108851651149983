import { ReactNode } from 'react';

import { ETradingPlatformsForm, ETradingPlatformsFormProps } from '@features';

interface ETradingPlatformsLayoutProps {
  renderForm: (formComponent: ReactNode) => ReactNode;
  formProps: ETradingPlatformsFormProps;
}

export function ETradingPlatformsLayout({ renderForm, formProps }: ETradingPlatformsLayoutProps) {
  return <>{renderForm(<ETradingPlatformsForm {...formProps} />)}</>;
}
