import { useToaster } from '@gravity-ui/uikit';
import { useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';

import { $customersHooks, userAtom } from '@entities';
import { URLS } from '@shared/consts';
import { useNavigateTo } from '@shared/lib';
import { RightSidebar } from '@shared/ui';

import { CustomersLayout } from './CustomersLayout';

interface EditCustomersProps {
  id: string | undefined;
  onClose: () => void;
}

export function EditCustomers({ id, onClose }: EditCustomersProps) {
  const user = useAtomValue(userAtom);
  const [isOpen, setIsOpen] = useState(false);

  const queryClient = useQueryClient();

  const { navigateTo } = useNavigateTo();
  const { add } = useToaster();

  // Queries

  const getCustomerQuery = $customersHooks.useGetById(id || '', !!id);
  const updateCustomersMutation = $customersHooks.updateByIdMutation();
  const deleteCustomersMutation = $customersHooks.deleteByIdMutation();
  //

  useEffect(() => {
    if (getCustomerQuery.error) {
      navigateTo(URLS.handbook.custom);
      add({
        theme: 'danger',
        name: 'get-customer-error',
        title: 'Произошла ошибка при получении карточки Заказчика',
      });
    }
  }, [getCustomerQuery.isFetching]);

  useEffect(() => {
    if (id) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [id]);

  useEffect(() => {
    // Уведомления об обновлении карточки
    if (updateCustomersMutation.isSuccess) {
      add({
        theme: 'success',
        name: 'update-customers-success',
        title: 'Карточка Заказчика обновлена успешно',
      });
    }
    if (updateCustomersMutation.isError) {
      add({
        theme: 'danger',
        name: 'update-customers-error',
        title: 'Ошибка при обновлении карточки Заказчика',
      });
    }
  }, [updateCustomersMutation.isPending]);

  return (
    <CustomersLayout
      formProps={{
        response: getCustomerQuery.data,
        onClickCancelButton: () => {
          onClose();
        },
        initialEditable: false,
        onValid: async values => {
          if (!id) return;

          await updateCustomersMutation
            .mutateAsync({
              body: values,
              params: {
                path: {
                  id,
                },
              },
            })
            .then(async () => {
              queryClient.invalidateQueries({ queryKey: ['/customers/all'] });

              onClose();
            });
        },
        onInvalid: errors => console.log('@errors', errors),
      }}
      renderForm={formComponent => (
        <RightSidebar
          id="sidebar-customer"
          onClose={() => {
            setIsOpen(false);
            onClose();
          }}
          open={isOpen}
        >
          <RightSidebar.Header
            title={'Редактирование карточки Заказчика'}
            onClose={() => {
              setIsOpen(false);
              onClose();
            }}
            dropdownMenuItems={
              user?.is_admin && !!id
                ? [
                    {
                      theme: 'danger',
                      text: 'Удалить',
                      action: () => {
                        deleteCustomersMutation
                          .mutateAsync({ params: { path: { id: id } } })
                          .then(() => {
                            add({
                              theme: 'success',
                              name: 'delete-customers-success',
                              title: 'Карточка удалена успешно',
                            });
                            queryClient.invalidateQueries({ queryKey: ['/handbook/customers'] });
                            onClose();
                          })
                          .catch(() => {
                            add({
                              theme: 'danger',
                              name: 'delete-customers-error',
                              title: 'Ошибка при удалении карточки',
                            });
                          });
                      },
                    },
                  ]
                : undefined
            }
          />
          {formComponent}
        </RightSidebar>
      )}
    />
  );
}
