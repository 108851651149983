import { ReactNode } from 'react';

import { EmployeesForm, EmployeesFormProps } from '@features';

interface EmployeesLayoutProps {
  renderForm: (formComponent: ReactNode) => ReactNode;
  formProps: EmployeesFormProps;
}

export function EmployeesLayout({ renderForm, formProps }: EmployeesLayoutProps) {
  return <>{renderForm(<EmployeesForm {...formProps} />)}</>;
}
