export function generateTitle({
  uniqueNumber,
  customerInitials,
  objectName,
}: {
  objectName?: string;
  customerInitials?: string;
  uniqueNumber?: string;
}) {
  if (uniqueNumber && customerInitials && objectName) {
    return `${uniqueNumber} / ${customerInitials} / ${objectName}`;
  }
  return '';
}
