import { useToaster } from '@gravity-ui/uikit';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import { $customersHooks } from '@entities';
import { CreateHandbookDialog, customersFormAtom } from '@features';
import { bodyResponseType } from '@shared/api';
import { RightSidebar } from '@shared/ui';

import { CustomersLayout } from './CustomersLayout';

interface CreateCustomersProps {
  open: boolean;
  onClose: () => void;
  afterCreate?: (createdCustomer: bodyResponseType<'post', '/handbook/customers'>) => void;
  withDialog?: boolean;
}

export function CreateCustomers({ open, onClose, afterCreate, withDialog }: CreateCustomersProps) {
  const { add } = useToaster();

  const queryClient = useQueryClient();

  const [customersCreation, setCustomersCreation] = useAtom(customersFormAtom);

  // Queries
  const createCustomersMutation = $customersHooks.createMutation();

  //

  return (
    <CustomersLayout
      formProps={{
        defaultValues: customersCreation,
        onValuesChange: values => {
          setCustomersCreation(values);
        },
        onClickCancelButton: () => {
          setCustomersCreation(undefined);
          onClose();
        },
        compactBottomPanel: true,
        // disabled: underApproval,
        initialEditable: true,
        onValid: async values => {
          await createCustomersMutation
            .mutateAsync({
              body: values,
            })
            .then(createdCustomer => {
              add({
                name: 'create-customers-success',
                theme: 'success',
                content: 'Карточка Заказчика успешна создана!',
              });
              queryClient.invalidateQueries({ queryKey: ['/customers/all'] });
              afterCreate?.(createdCustomer);

              setCustomersCreation(undefined);
              onClose();
            })
            .catch(() => {
              add({
                name: 'create-customers-error',
                theme: 'danger',
                content: 'Ошибка при создании карточки Заказчика',
              });
            });
        },
        onInvalid: errors => {
          console.log('@errors', errors);
        },
      }}
      renderForm={formComponent =>
        withDialog ? (
          <CreateHandbookDialog
            onClose={onClose}
            open={open}
            title="Создание карточки Заказчика"
          >
            {formComponent}
          </CreateHandbookDialog>
        ) : (
          <RightSidebar
            open={open}
            onClose={onClose}
          >
            <RightSidebar.Header
              title="Создание карточки Заказчика"
              onClose={onClose}
            />
            {formComponent}
          </RightSidebar>
        )
      }
    />
  );
}
