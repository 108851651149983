import { Avatar, Text } from '@gravity-ui/uikit';

import { FilterChatButton, SearchInChatButton, SettingsButton } from '@features';
import { pluralize } from '@shared/lib';

// TODO: Пропс с онлайном тоже не хватает
type ChatHeaderProps = {
  onClickFilter?: () => void;
  onClickSettings?: () => void;
  onClickSearch?: () => void;
} & (
  | {
      type: 'project';
      countOfMembers: number;
      status: string;
      projectName: string;
      name?: undefined;
      avatarName?: undefined;
      avatarImageURL?: undefined;
    }
  | {
      type: 'person';
      name: string;
      avatarName: string;
      avatarImageURL: string;
      countOfMembers?: undefined;
      status?: undefined;
      projectName?: undefined;
    }
);

export function ChatHeader({
  type,
  countOfMembers,
  avatarName,
  avatarImageURL,
  name,
  projectName,
  status,
  onClickFilter,
  onClickSettings,
  onClickSearch,
}: ChatHeaderProps) {
  return (
    <div className="px-5 py-2 flex gap-2.5 bg-base-background shadow-float-area-default">
      <div className="flex gap-3 grow overflow-hidden">
        <Avatar
          size="l"
          className="shrink-0"
          view={type === 'project' ? 'outlined' : 'filled'}
          text={type === 'project' ? status : avatarName}
          alt={type === 'project' ? status : avatarName}
          imgUrl={type === 'project' ? undefined : avatarImageURL}
          loading="lazy"
        />
        <div className="flex flex-col grow justify-center overflow-hidden">
          <Text
            variant="subheader-1"
            color="primary"
            className="truncate"
          >
            {type === 'project' ? `${status} ${projectName}` : name}
          </Text>
          {type === 'project' && (
            <Text
              variant="body-short"
              color="secondary"
            >
              {countOfMembers} {pluralize(countOfMembers, 'участник', 'участника', 'участников')}
            </Text>
          )}
        </div>
      </div>
      <SearchInChatButton onClick={onClickSearch} />
      <FilterChatButton onClick={onClickFilter} />
      <SettingsButton onClick={onClickSettings} />
    </div>
  );
}
