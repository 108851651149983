import { yupRu } from '@shared/config';
import { schemaFields } from '@shared/lib';

export const purchaseObjectsSchema = yupRu.object({
  /** @description Товар */
  object: schemaFields.text().required(),
  /** @description Комментарий */
  comment: schemaFields.textArea(),
  /** @description ОКПД-2_id */
  okpd_2_value: yupRu.string().transform(v => v || undefined),
  // okpd_2_id?: string; // на бэке нет
  /** @description Поставщик_id */
  supplier_id: yupRu.string().transform(v => v || undefined),
});

export type PurchaseObjectsSchema = yupRu.InferType<typeof purchaseObjectsSchema>;
