// import { CommercialOfferSchema } from '@features/commercial-offer-form/@x';
// import { TenderSchema } from '@features/tender-form/@x';
import { yupRu } from '@shared/config';
// import { fromCustomBooleanSchemaToBoolean } from '@shared/lib';
import { CustomBooleanSchema } from '@shared/model';

import { CoordinationType, EntityType, ResultStatus } from '../types';

export const agreementSchema = yupRu.object({
  title: yupRu.string().required(),
  // offer_card: yupRu.object<CommercialOfferSchema>(),
  // tender_card: yupRu.object<TenderSchema>(),
  entity_card: yupRu.string(), // TODO: заменить потом на карточку кп и тендера для просмотра
  entity_type: yupRu.string().oneOf<EntityType>(['OFFER', 'TENDER']).required(),
  entity_id: yupRu.string().required(),
  customer_id: yupRu.string().required(),
  assigned_by_id: yupRu.string().required(),
  assigned_to_id: yupRu.string().required(),
  cost_price: yupRu.number().when('coordination_type', {
    is: (coordinationType: CoordinationType) =>
      coordinationType === 'MIN_PRICE' || coordinationType === 'OFFER_PRICE',
    then: schema => schema.required(),
    otherwise: schema => schema.transform(() => undefined),
  }),
  specialist_cost_price: yupRu
    .number()
    .min(0)
    .when('coordination_type', {
      is: (coordinationType: CoordinationType) =>
        coordinationType === 'MIN_PRICE' || coordinationType === 'OFFER_PRICE',
      then: schema => schema.required(),
      otherwise: schema => schema.transform(() => undefined),
    }),
  proposed_margin: yupRu.number().when('coordination_type', {
    is: (coordinationType: CoordinationType) =>
      coordinationType === 'MIN_PRICE' || coordinationType === 'OFFER_PRICE',
    then: schema => schema.required(),
    otherwise: schema => schema.transform(() => undefined),
  }),
  price_justification: yupRu
    .string()
    .min(3)
    .when('coordination_type', {
      is: (coordinationType: CoordinationType) =>
        coordinationType === 'MIN_PRICE' || coordinationType === 'OFFER_PRICE',
      otherwise: schema => schema.transform(() => undefined),
    }),
  is_proposal_agree: yupRu.mixed<CustomBooleanSchema>(),
  planned_margin: yupRu
    .number()
    .max(100)
    .when('coordination_type', {
      is: (value: CoordinationType) => value === 'MIN_PRICE' || value === 'OFFER_PRICE',
      then: schema => schema.required(),
      otherwise: schema => schema.transform(() => undefined),
    }),
  min_cost_price: yupRu
    .number()
    .min(0)
    .when('coordination_type', {
      is: (value: CoordinationType) => value === 'MIN_PRICE' || value === 'OFFER_PRICE',
      then: schema => schema.required(),
      otherwise: schema => schema.transform(() => undefined),
    }),
  reject_reason: yupRu.string(),
  reject_description: yupRu.string(),
  calculation_file: yupRu.array(yupRu.string().required()).when('coordination_type', {
    is: (coordinationType: CoordinationType) =>
      coordinationType === 'MIN_PRICE' || coordinationType === 'OFFER_PRICE',
    then: schema => schema.requiredArray(),
    otherwise: schema => schema.transform(() => undefined),
  }),
  offer_file: yupRu.string().when('coordination_type', {
    is: (coordinationType: CoordinationType) => coordinationType === 'OFFER_PRICE',
    otherwise: schema => schema.transform(() => undefined),
  }),
  request_file: yupRu.string().when('coordination_type', {
    is: (coordinationType: CoordinationType) => coordinationType === 'MIN_PRICE',
    otherwise: schema => schema.transform(() => undefined),
  }),
  status: yupRu.string().oneOf<ResultStatus>(['ACCEPTED', 'PENDING', 'REJECTED']).required(),
  comment: yupRu.string(),
  coordination_type: yupRu
    .string()
    .oneOf<CoordinationType>(['MIN_PRICE', 'OFFER_PRICE', 'PARTICIPATION', 'REJECTION'])
    .required(),
});

export type AgreementSchema = yupRu.InferType<typeof agreementSchema>;
