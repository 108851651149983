import { BellSlash, FileText, PersonPlus, Xmark } from '@gravity-ui/icons';
import {
  Button,
  Divider,
  Icon,
  LayerCloseReason,
  Modal,
  ModalProps,
  RadioButton,
  Text,
  User,
} from '@gravity-ui/uikit';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { HistoryItem, HistoryItemProps, MediaItem, MediaItemProps } from '@entities';
import { $api } from '@shared/api';
import { URLS } from '@shared/consts';
import { cn } from '@shared/lib';

import { DEFAULT_SETTING_TAB, SETTINGS_TABS } from '../constants';
import { formatHistoryDate } from '../lib';

export interface ChatSettingsModalProps extends Omit<ModalProps, 'children' | 'onClose'> {
  history: Array<{
    variant: HistoryItemProps['variant'];
    title: string;
    description: string;
    timestamp: number;
    author?: string;
  }>;
  media: Array<MediaItemProps>;
  users: Array<{ name: string }>;
  onClose?: (
    event: MouseEvent | KeyboardEvent | React.MouseEvent,
    reason: LayerCloseReason | 'closeButtonClick'
  ) => void;
}

export function ChatSettingsModal({
  contentClassName,
  media,
  history,
  users,
  onClose,
  ...props
}: ChatSettingsModalProps) {
  const [activeTab, setActiveTab] = useState(DEFAULT_SETTING_TAB);
  const currentChatId = useParams()[URLS.messengerDynamicParams.id];

  const chatQuery = $api.useQuery('get', '/chat/{id}', {
    params: {
      path: {
        id: currentChatId!
      }
    }
  });

  return (
    <Modal
      onClose={onClose}
      contentClassName={cn('rounded-[16px]', contentClassName)}
      {...props}
    >
      <div className="px-6 pt-6 flex flex-col gap-4 w-[496px] h-[520px]">
        <div className="flex items-center gap-5 justify-between">
          <Text
            variant="subheader-3"
            color="primary"
          >
            {chatQuery.data?.title}
          </Text>
          <Button
            view="flat"
            size="l"
            onClick={event => onClose?.(event, 'closeButtonClick')}
          >
            <Icon data={Xmark} />
          </Button>
        </div>
        {activeTab === 'members' && (
          <div className="flex gap-1 justify-stretch">
            <Button
              view="flat-info"
              size="xl"
              selected
              disabled
              className="flex flex-col justify-center px-3 py-4 basis-[0] grow"
              style={{ height: 'auto' }}
            >
              <div className="flex flex-col gap-1 items-center">
                <Icon data={PersonPlus} />
                <Text
                  variant="body-1"
                  className="text-wrap [word-spacing:999px;]"
                >
                  Добавить участника
                </Text>
              </div>
            </Button>
            <Button
              view="flat-info"
              size="xl"
              disabled
              selected
              className="flex flex-col justify-center px-3 py-4 basis-[0] grow"
              style={{ height: 'auto' }}
            >
              <div className="flex flex-col gap-1 items-center">
                <Icon data={BellSlash} />
                <Text
                  variant="body-1"
                  className="text-wrap [word-spacing:999px;]"
                >
                  Выключить уведомления
                </Text>
              </div>
            </Button>
            <Button
              view="flat-info"
              size="xl"
              disabled
              selected
              className="flex flex-col justify-center px-3 py-4 basis-[0] grow"
              style={{ height: 'auto' }}
            >
              <div className="flex flex-col gap-1 items-center">
                <Icon data={FileText} />
                <Text
                  variant="body-1"
                  className="text-wrap [word-spacing:999px;]"
                >
                  Страница проекта
                </Text>
              </div>
            </Button>
          </div>
        )}
        <RadioButton
          size="l"
          options={SETTINGS_TABS}
          value={activeTab}
          onUpdate={setActiveTab}
        />
        <div className="overflow-y-auto hide-scrollbar">
          {activeTab === 'members' && (
            <div className="flex flex-col gap-3 pb-6">
              {chatQuery.data?.participants?.map((user, index) => (
                <User
                  key={index}
                  size="l"
                  name={user.full_name}
                  avatar={{
                    text: user.full_name,
                  }}
                />
              ))}
            </div>
          )}
          {activeTab === 'media' && (
            <div className="flex flex-col gap-3 pb-6">
              {media.map(
                ({ createdAt, createdBy, extension, imageName, imageUrl, name, size }, index) => (
                  <MediaItem
                    key={index}
                    createdAt={createdAt}
                    createdBy={createdBy}
                    extension={extension}
                    imageName={imageName}
                    imageUrl={imageUrl}
                    name={name}
                    size={size}
                  />
                )
              )}
            </div>
          )}
          {activeTab === 'history' && (
            <div className="flex flex-col gap-4">
              {history.map(({ timestamp, description, title, variant, author }, index) => (
                <div
                  key={index}
                  className="flex flex-col gap-2"
                >
                  {!index && <Divider className="my-1" />}
                  <HistoryItem
                    title={title}
                    description={description}
                    additional={`${formatHistoryDate(timestamp)}${author ? `, ${author}` : ''}`}
                    variant={variant}
                  />
                  <Divider className="my-1" />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
