import { yupRu } from '@shared/config';

export const agenciesSchema = yupRu.object({
  comment: yupRu
    .string()
    .max(255)
    .test({
      test: value => !value || value.length >= 3,
      message: 'Должен содержать как минимум 3 символа',
    })
    .transform(value => value || undefined),
  inn: yupRu
    .string()
    .min(10)
    .max(12)
    .test({
      test: value => {
        if (value === undefined) return false;
        try {
          return !!BigInt(value);
        } catch {
          return false;
        }
      },
      message: 'Должен состоять из цифр',
    })
    .test({
      test: value => typeof value === 'string' && (value.length === 10 || value.length === 12),
      message: 'ИНН состоит из 10 или 12 арабских цифр',
    })
    .transform(value => value || undefined),
  name: yupRu.string().min(3).max(255).required(),
});

export type AgenciesSchema = yupRu.InferType<typeof agenciesSchema>;
