import { ModalProps, Text } from '@gravity-ui/uikit';
import { useAtomValue } from 'jotai';
import { forwardRef, Fragment, useEffect, useRef, useState } from 'react';

import { MessageItem, useQueryGetUser } from '@entities';
import { chatFiltersAtom, MediaModal, MediaModalProps } from '@features';
import { components } from '@shared/api';
import { cn } from '@shared/lib';

import { DIVIDE_MESSAGE_AFTER } from '../constants';

interface ChatMessagesProps {
  messageGroups: components['schemas']['chat.Message'][];
}

export const ChatMessages = forwardRef<HTMLDivElement, ChatMessagesProps>(
  ({ messageGroups }, ref) => {
    console.log(messageGroups)
    const [selectedImage, setSelectedImage] = useState<Required<
      Omit<MediaModalProps, keyof ModalProps>
    > | null>(null);

    // TODO: временное решение, в будущем при выборе даты, будут приходить новые сообщения с бэка
    const { date: chatFiltersDate } = useAtomValue(chatFiltersAtom);
    const currentUser = useQueryGetUser();
    const [closestMessageGroupToSelectedDate, setClosestMessageGroupToSelectedDate] = useState<
      null | number
    >(null);
    const closestMessageGroupRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (chatFiltersDate && !!messageGroups.length) {
        const closestIndex = 0;

        if (typeof closestIndex === 'number') {
          Promise.resolve()
            .then(() => setClosestMessageGroupToSelectedDate(closestIndex))
            .then(() => closestMessageGroupRef.current?.scrollIntoView({ block: 'start' }));
        }
      }
    }, [chatFiltersDate, messageGroups]);

    return (
      <>
        <div
          ref={ref}
          className="grow flex flex-col overflow-y-auto relative hide-scrollbar chat-messages"
        >
          {/* {messageGroups.map((group, index) => ( */}
          <Fragment key={`personal`}>
            <div
              className="px-5 pb-5"
              {...(closestMessageGroupToSelectedDate === 0 && {
                ref: closestMessageGroupRef,
              })}
            >
              {/* <ChatDateLabel
                  status={stage}
                  timestamp={dateTimestamp}
                /> */}
              {messageGroups.map((message, index) => {
                const outcoming = currentUser.data?.id === message.author_id;
                const previousMessage = messageGroups[index - 1];
                const related =
                  previousMessage?.author_id &&
                  previousMessage.author_id === currentUser?.data?.id &&
                  new Date(previousMessage.timestamp as string).getTime() + DIVIDE_MESSAGE_AFTER >
                    new Date(message.timestamp as string).getTime();

                // if (type === 'server')
                //   return (
                //     <ChatAction
                //       key={id}
                //       className="py-5"
                //     >
                //       {action}
                //     </ChatAction>
                //   );

                return (
                  <div
                    key={message.id}
                    className={cn({
                      'mt-4': !related,
                    })}
                  >
                    <MessageItem
                      variant={undefined}
                      avatarImageURL={''}
                      avatarName={message.author?.full_name?.[0] ?? 'UN'}
                      name={message.author?.full_name ?? 'неизвестный'}
                      sendedAt={new Date(message.timestamp ?? '').getTime()}
                      related={Boolean(related)}
                      outcoming={outcoming}
                      status={'delivered'}
                    >
                      <Text
                        variant="body-short"
                        color="primary"
                      >
                        {message.message}
                      </Text>
                      {/* {contentType === 'text' && (
                            <Text
                              variant="body-short"
                              color="primary"
                            >
                              {text}
                            </Text>
                          )} */}
                      {/* {contentType === 'file' && (
                            <DownloadFileButton
                              fileExtension={file.extension}
                              fileName={file.name}
                              fileSize={file.size}
                            />
                          )}
                          {contentType === 'image' && (
                            <button
                              onClick={() =>
                                setSelectedImage({
                                  createdBy: sender.name,
                                  imageSrc,
                                  serialNumber: 45,
                                  totalCount: 942,
                                })
                              }
                            >
                              <img
                                className="max-w-[256px] max-h-[512px] object-cover rounded-[8px]"
                                src={imageSrc}
                                alt={`Изображение от ${name}`}
                                loading="lazy"
                              />
                            </button>
                          )} */}
                    </MessageItem>
                  </div>
                );
              })}
            </div>
          </Fragment>
          {/* ))} */}
        </div>
        <MediaModal
          open={!!selectedImage}
          onClose={() => setSelectedImage(null)}
          createdBy={selectedImage?.createdBy}
          imageSrc={selectedImage?.imageSrc}
          serialNumber={selectedImage?.serialNumber}
          totalCount={selectedImage?.totalCount}
        />
      </>
    );
  }
);
