import { DropResult } from '@hello-pangea/dnd';

// a little function to help us with reordering the result
function reorder<TItem>(list: TItem[], startIndex: number, endIndex: number): TItem[] {
  // Check if indices are within bounds and not the same
  if (
    startIndex === endIndex ||
    startIndex < 0 ||
    startIndex >= list.length ||
    endIndex < 0 ||
    endIndex >= list.length
  ) {
    return structuredClone(list); // Return a copy if no reordering is needed
  }

  // Create a new array to avoid mutating the original array
  const result = structuredClone(list);

  // Remove the item from the startIndex
  const [item] = result.splice(startIndex, 1);

  // Insert the item at the endIndex
  result.splice(endIndex, 0, item!);

  return result;
}

type GetDnDDragEndHandler = <T>(
  items: T[],
  onSuccess: (value: T[]) => void
) => (result: DropResult) => void;

export const getDnDDragEndHandler: GetDnDDragEndHandler =
  (items, onSuccess) => (result: DropResult) => {
    const { source, destination, combine } = result;

    // Handle the case where the item is combined with another
    if (combine) {
      const updatedTabs = items.slice();
      updatedTabs.splice(source.index, 1); // Remove the item at the source index
      onSuccess(updatedTabs);
      return;
    }

    // Handle the case where the item is dropped outside the list
    if (!destination) {
      return;
    }

    // Handle the case where the item is dropped in the same position
    if (destination.index === source.index) {
      return;
    }

    // Reorder items if needed
    const reorderedList = reorder(items, source.index, destination.index);
    onSuccess(reorderedList);
  };
