import { $api } from '@shared/api';

// tabs

export const useAgreementGetTabsQuery = () => {
  return $api.useQuery('get', '/coordination/tabs');
};
export const useAgreementDeleteTabMutation = () => {
  return $api.useMutation('delete', '/coordination/tabs/{name}');
};

export const useAgreementCreateTabMutation = () => {
  return $api.useMutation('post', '/coordination/tabs');
};

export const useAgreementUpdateTabMutation = () => {
  return $api.useMutation('put', '/coordination/tabs/{name}');
};
