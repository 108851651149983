import { $api } from '@shared/api';

export const useGetGosplanQuery = (purchaseNumber: string, enabled: boolean) =>
  $api.useQuery(
    'get',
    '/utils/gosplan/purchase/{number}',
    {
      params: { path: { number: purchaseNumber } },
    },
    {
      enabled,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retryOnMount: false,
      retry: 0,
    }
  );
