import { useToaster } from '@gravity-ui/uikit';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import { $restrictionAdvantageRequirementsHooks } from '@entities';
import { CreateHandbookDialog, restrictionAdvantageRequirementsFormAtom } from '@features';
import { bodyResponseType } from '@shared/api';
import { RightSidebar } from '@shared/ui';

import { RestrictionAdvantageRequirementsLayout } from './RestrictionAdvantageRequirementsLayout';

interface CreateRestrictionAdvantageRequirementsProps {
  open: boolean;
  onClose: () => void;
  afterCreate?: (
    createdRestrictionAdvantageRequirement: bodyResponseType<
      'post',
      '/handbook/restriction-advantage-requirements'
    >
  ) => void;
  withDialog?: boolean;
}

export function CreateRestrictionAdvantageRequirements({
  open,
  onClose,
  afterCreate,
  withDialog,
}: CreateRestrictionAdvantageRequirementsProps) {
  const { add } = useToaster();

  const queryClient = useQueryClient();

  const [restrictionAdvantageRequirementsCreation, setRestrictionAdvantageRequirementsCreation] =
    useAtom(restrictionAdvantageRequirementsFormAtom);

  // Queries
  const createRestrictionAdvantageRequirementsMutation =
    $restrictionAdvantageRequirementsHooks.createMutation();

  //

  return (
    <RestrictionAdvantageRequirementsLayout
      formProps={{
        defaultValues: restrictionAdvantageRequirementsCreation,
        onValuesChange: values => {
          setRestrictionAdvantageRequirementsCreation(values);
        },
        onClickCancelButton: () => {
          setRestrictionAdvantageRequirementsCreation(undefined);
          onClose();
        },
        compactBottomPanel: true,
        // disabled: underApproval,
        initialEditable: true,
        onValid: async values => {
          await createRestrictionAdvantageRequirementsMutation
            .mutateAsync({
              body: values,
            })
            .then(createdRestrictionAdvantageRequirement => {
              add({
                name: 'create-restrictionAdvantageRequirements-success',
                theme: 'success',
                content:
                  'Карточка справочника «Ограничения, преимущества и доп. требования» успешна создана!',
              });
              queryClient.invalidateQueries({
                queryKey: ['/restriction-advantage-requirements/all'],
              });
              afterCreate?.(createdRestrictionAdvantageRequirement);

              setRestrictionAdvantageRequirementsCreation(undefined);
              onClose();
            })
            .catch(() => {
              add({
                name: 'create-restrictionAdvantageRequirements-error',
                theme: 'danger',
                content:
                  'Ошибка при создании карточки справочника «Ограничения, преимущества и доп. требования»',
              });
            });
        },
        onInvalid: errors => {
          console.log('@errors', errors);
        },
      }}
      renderForm={formComponent =>
        withDialog ? (
          <CreateHandbookDialog
            onClose={onClose}
            open={open}
            title="Создание карточки справочника «Ограничения, преимущества и доп. требования»"
          >
            {formComponent}
          </CreateHandbookDialog>
        ) : (
          <RightSidebar
            open={open}
            onClose={onClose}
          >
            <RightSidebar.Header
              title="Создание карточки справочника «Ограничения, преимущества и доп. требования»"
              onClose={onClose}
            />
            {formComponent}
          </RightSidebar>
        )
      }
    />
  );
}
