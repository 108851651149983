import { dateTime } from '@gravity-ui/date-utils';

import { DATE_FORMATS } from '@shared/consts';

export function generateTitle({
  customer,
  submissionDeadline,
}: {
  submissionDeadline?: string;
  customer?: string;
}) {
  if (typeof submissionDeadline === 'string' && typeof customer === 'string') {
    return `${dateTime({ input: submissionDeadline }).format(DATE_FORMATS.fullDate)} / ${customer}`;
  }
}
