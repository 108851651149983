import { Button, Icon, Modal, ModalProps, Text } from '@gravity-ui/uikit';

import './index.sass';

import { Xmark } from '@gravity-ui/icons';
import { useId, useState } from 'react';

import { cn } from '@shared/lib';

export interface MediaModalProps extends ModalProps {
  imageSrc?: string;
  serialNumber?: number;
  totalCount?: number;
  createdBy?: string;
  onClose?: () => void;
}

export function MediaModal({
  imageSrc,
  serialNumber,
  totalCount,
  createdBy,
  className,
  onClose,
  ...props
}: MediaModalProps) {
  const closeButtonId = useId();

  const [isTransitionEntered, setIsTransitionEntered] = useState(false);

  return (
    <Modal
      {...props}
      onClose={onClose}
      className={cn('media-modal', className)}
      onTransitionEntered={() => setIsTransitionEntered(true)}
      onTransitionExit={() => setIsTransitionEntered(false)}
    >
      <div className="relative">
        <img
          className="max-w-[1024px] max-h-[1024px] object-cover"
          src={imageSrc}
          alt={`Изображение от ${createdBy}`}
          loading="lazy"
        />
        <div className="absolute flex items-center justify-between left-0 right-0 top-[calc(100%+16px)]">
          <Text
            variant="body-2"
            color="light-complementary"
          >
            {createdBy}
          </Text>
          <Text
            variant="body-2"
            color="light-complementary"
          >
            {serialNumber}/{totalCount}
          </Text>
        </div>
      </div>
      {isTransitionEntered && (
        <label
          htmlFor={closeButtonId}
          className="media-modal__close-button-wrapper cursor-pointer absolute right-0 top-0 p-4 bg-text-complementary rounded-bl-lg"
          aria-label="Закрыть модальное окно"
        >
          <Button
            size="xl"
            view="flat-contrast"
            id={closeButtonId}
            onClick={onClose}
          >
            <Icon
              data={Xmark}
              size={20}
            />
          </Button>
        </label>
      )}
    </Modal>
  );
}
