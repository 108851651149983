import { atom } from 'jotai';

export const createdAgencyForViewAtom = atom<{
  agency_value: string;
  agency_id: string;
}>();
export const createdCompetitorForViewAtom = atom<{
  competitor_value: string;
  competitor_id: string;
}>();
export const createdContactForViewAtom = atom<{ contact_value: string; contact_id: string }>();
export const createdCustomerForViewAtom = atom<{ customer_value: string; customer_id: string }>();
export const createdDepartmentForViewAtom = atom<{
  department_value: string;
  department_id: string;
}>();
export const createdETradingPlatformForViewAtom = atom<{
  eTradingPlatform_value: string;
  eTradingPlatform_id: string;
}>();
export const createdEmployeeForViewAtom = atom<{ employee_value: string; employee_id: string }>();
export const createdKnowledgeBaseForViewAtom = atom<{
  knowledgeBase_value: string;
  knowledgeBase_id: string;
}>();
export const createdLegalEntityForViewAtom = atom<{
  legalEntity_value: string;
  legalEntity_id: string;
}>();
export const createdOkpd2ForViewAtom = atom<{
  okpd2_value: string;
}>();
export const createdPaymentDetailForViewAtom = atom<{
  paymentDetail_value: string;
  paymentDetail_id: string;
}>();
export const createdPurchaseObjectForViewAtom = atom<{
  purchaseObject_value: string;
  purchaseObject_id: string;
}>();
export const createdRegionForViewAtom = atom<{ region_value: string; region_id: string }>();
export const createdRestrictionAdvantageRequirementForViewAtom = atom<{
  restrictionAdvantageRequirement_value: string;
  restrictionAdvantageRequirement_id: string;
}>();
export const createdSupplierContractForViewAtom = atom<{
  supplierContract_value: string;
  supplierContract_id: string;
}>();
export const createdSupplierForViewAtom = atom<{ supplier_value: string; supplier_id: string }>();
