import { dateTime } from '@gravity-ui/date-utils';

import {
  convertAgreementResultStatusFromBackend,
  convertAgreementTypeFromBackend,
} from '@entities';
import { CRMTableData } from '@features/table';
import { bodyResponseType } from '@shared/api';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { DATE_FORMATS, URLS } from '@shared/consts';
import { formatToPrice } from '@shared/lib';

import { ColumnsAgreementsTable } from '../model';

const parseDate = (date: string, withTime?: boolean) =>
  dateTime({ input: date }).format(
    !withTime ? DATE_FORMATS.fullDate : DATE_FORMATS.fullDateWithTimeRounded
  );

const parseMultiple = (names: string[]): string => {
  try {
    return names.join(' \n');
  } catch {
    return '';
  }
};

const parseFiles = (files: string[]): string => {
  try {
    const keys = files.map(url => {
      return url.replace('https://stage.api.b2gcrm.ru/', '');
    });
    const fileNames = keys.map(key => {
      const split = key.split('/');
      return split.pop()!;
    });
    return parseMultiple(fileNames);
  } catch {
    return '';
  }
};

const parseFloat = (float: number): number => {
  return float / 100;
};
// type ResponseType = NonNullable<bodyResponseType<'post', '/coordination/all'>['Coordinations']>[number]
// function tenderOrOffer<Entity extends NonNullable<ResponseType['tender']> | NonNullable<ResponseType['offer'] | undefined>>(entity:Entity, key: keyof Entity ):string {
//   return ''
//   // if (!entity) return ''
//   // return entity[key]
// }

// 'Дата перевода': table.transfer_at ? parseDate(table.transfer_at) : '',
// Себестоимость: table.cost_price ? formatToPrice(table.cost_price, true) : '',
// 'Маржа, %': table.margin ? `${table.margin} %` : '',
// 'Согласовать цену с руководителем': table.manager_approved ? 'СОГЛАСОВАТЬ' : 'НЕ СОГЛАСОВАТЬ',

export const parseAgreementTableData = (
  tableData: NonNullable<bodyResponseType<'post', '/coordination/all'>['Coordinations']>
): CRMTableData<ColumnsAgreementsTable> => {
  return tableData.map(table => {
    return {
      'Дата, номер': table.created_at ? `${parseDate(table.created_at, true)} №${table.id}` : '',
      Наименование: table.tender ? table.tender.title : table.offer ? table.offer.title : '',
      'Тендер/КП': table.tender
        ? `${URLS.processes.tenders}/${table.tender.id}`
        : table.offer
          ? `${URLS.processes.commercialOffers}/${table.offer.id}`
          : '',
      Заказчик:
        table.tender && table.tender.customer_ids?.[0] && table.tender.customer_values?.[0]
          ? table.tender.customer_values[0]
          : table.offer && table.offer.customer_value
            ? table.offer.customer_value
            : '',
      'Запрашивающий согласование': table.assigned_by_value || '',
      Согласовант: table.assigned_to_value || '',
      Себестоимость:
        typeof table.tender?.base_cost_price === 'number'
          ? formatToPrice(table.tender.base_cost_price, true)
          : typeof table.offer?.cost_price === 'number'
            ? formatToPrice(table.offer.cost_price, true)
            : '',
      'Предлагаемая специалистом цена':
        typeof table.tender?.specialist_min_cost_price === 'number'
          ? formatToPrice(table.tender.specialist_min_cost_price, true)
          : typeof table.offer?.proposed_price === 'number'
            ? formatToPrice(table.offer.proposed_price, true)
            : '',
      'Маржа предлагаемая':
        typeof table.tender?.min_margin === 'number'
          ? `${parseFloat(table.tender.min_margin)} %`
          : typeof table.offer?.margin === 'number'
            ? `${parseFloat(table.offer.margin)} %`
            : '',
      'Обоснование предложения цены':
        table.tender && table.tender.min_cost_price_reason
          ? table.tender.min_cost_price_reason
          : table.offer && table.offer.price_justification
            ? table.offer.price_justification
            : '',
      'Согласны с предложением специалиста?':
        table.status === 'ACCEPTED' ? 'Да' : table.status === 'REJECTED' ? 'Нет' : '',
      'Маржа плановая':
        typeof table.tender?.min_margin === 'number'
          ? `${parseFloat(table.tender.min_margin)} %`
          : typeof table.offer?.margin === 'number'
            ? `${parseFloat(table.offer.margin)} %`
            : '',
      'Минимальная цена/цена КП согласования':
        typeof table.tender?.min_cost_price === 'number'
          ? formatToPrice(table.tender.min_cost_price, true)
          : typeof table.offer?.proposed_price === 'number'
            ? formatToPrice(table.offer.proposed_price, true)
            : '',
      // 'Причина отказа':
      //   table.tender && table.tender.reject_reason
      //     ? table.tender.reject_reason
      //     : table.offer && table.offer?.reject_reason
      //       ? table.offer.reject_reason
      //       : '',
      'Причина отказа': '',
      // 'Комментарий причины отказа':
      //   table.tender && table.tender.reject_description
      //     ? table.tender.reject_description
      //     : table.offer
      //       ? table.offer.reject_description || ''
      //       : '',
      'Комментарий причины отказа': '',
      'Файл расчета':
        table.tender && table.tender.calculation_files
          ? parseFiles(table.tender.calculation_files)
          : table.offer && table.offer.calculation_file
            ? parseFiles([table.offer.calculation_file])
            : '',
      'Файл КП': table.offer?.offer_file ? parseFiles([table.offer.offer_file]) : '',
      'Файл Заявки': table.tender?.request_file ? parseFiles([table.tender.request_file]) : '',
      'Результат согласования': convertAgreementResultStatusFromBackend(table.status),
      Комментарий: table.comment || '',
      'Тип согласования': table.coordination_type
        ? convertAgreementTypeFromBackend(table.coordination_type)
        : '',
    };
  });
};
