import { ContractSchema } from '@features';
import { bodyResponseType } from '@shared/api';
import { roundPercentAndCurrencyFromBackend, roundPercentAndCurrencyToBackend } from '@shared/lib';

export function percentAndCurrencyValuesToBackend(formValues: ContractSchema): ContractSchema {
  return {
    ...formValues,
    contract_sum: roundPercentAndCurrencyToBackend(formValues.contract_sum),
    contract_security_sum: roundPercentAndCurrencyToBackend(formValues.contract_security_sum),
    warranty_security_sum: roundPercentAndCurrencyToBackend(formValues.warranty_security_sum),
    contract_limit: roundPercentAndCurrencyToBackend(formValues.contract_limit),
    shipment_sum_with_vat: roundPercentAndCurrencyToBackend(formValues.shipment_sum_with_vat),
    shipment_sum_without_vat: roundPercentAndCurrencyToBackend(formValues.shipment_sum_without_vat),
    vat_sum: roundPercentAndCurrencyToBackend(formValues.vat_sum),
    purchase_sum: roundPercentAndCurrencyToBackend(formValues.purchase_sum),
    limit_balance: roundPercentAndCurrencyToBackend(formValues.limit_balance),
    payment_sum: roundPercentAndCurrencyToBackend(formValues.payment_sum),
    receivables_sum: roundPercentAndCurrencyToBackend(formValues.receivables_sum),
    overdue_receivables_sum: roundPercentAndCurrencyToBackend(formValues.overdue_receivables_sum),
  };
}

export function percentAndCurrencyValuesFromBackend(formValues: ContractSchema): ContractSchema {
  return {
    ...formValues,
    contract_sum: roundPercentAndCurrencyFromBackend(formValues.contract_sum),
    contract_security_sum: roundPercentAndCurrencyFromBackend(formValues.contract_security_sum),
    warranty_security_sum: roundPercentAndCurrencyFromBackend(formValues.warranty_security_sum),
    contract_limit: roundPercentAndCurrencyFromBackend(formValues.contract_limit),
    shipment_sum_with_vat: roundPercentAndCurrencyFromBackend(formValues.shipment_sum_with_vat),
    shipment_sum_without_vat: roundPercentAndCurrencyFromBackend(
      formValues.shipment_sum_without_vat
    ),
    vat_sum: roundPercentAndCurrencyFromBackend(formValues.vat_sum),
    purchase_sum: roundPercentAndCurrencyFromBackend(formValues.purchase_sum),
    limit_balance: roundPercentAndCurrencyFromBackend(formValues.limit_balance),
    payment_sum: roundPercentAndCurrencyFromBackend(formValues.payment_sum),
    receivables_sum: roundPercentAndCurrencyFromBackend(formValues.receivables_sum),
    overdue_receivables_sum: roundPercentAndCurrencyFromBackend(formValues.overdue_receivables_sum),
  };
}

export function checkIsNotBlankRelations(
  relations: bodyResponseType<'get', '/contract/related/{id}'>
) {
  if (
    (relations.tender && Object.keys(relations.tender).length) ||
    (relations.offer && Object.keys(relations.offer).length)
  )
    return true;
  return false;
}

export function countRelations(relations?: bodyResponseType<'get', '/contract/related/{id}'>) {
  if (!relations || !Object.keys(relations).length) return 0;
  let tender = 0;
  let offer = 0;
  if (relations?.offer && Object.keys(relations.offer).length) offer++;
  if (relations?.tender && Object.keys(relations.tender).length) tender++;
  return tender + offer;
}

export function getAllowedStages(
  stage: NonNullable<bodyResponseType<'get', '/contract/{id}'>['stage']>
): NonNullable<ContractSchema['stage']>[] {
  switch (stage) {
    case 'Исполнен':
      return ['Исполнен', 'Рассчитан'];
    case 'Рассчитан':
      return ['Рассчитан', 'Исполнен', 'Расторгнут'];
    case 'Расторгнут':
      return ['Расторгнут'];
    default:
      return ['Заключен', 'Исполнен', 'Проект', 'Рассчитан', 'Расторгнут'];
  }
}

export function convertCustomerPriorityToString(
  priority: number | string | undefined
): '1' | '2' | '3' | '4' | '5' | undefined {
  switch (priority) {
    case 1:
      return '1';
    case 2:
      return '2';
    case 3:
      return '3';
    case 4:
      return '4';
    case 5:
      return '5';
    default:
      return;
  }
}

export function convertCustomerPriorityToNumber(
  priority: string | number | undefined
): 1 | 2 | 3 | 4 | 5 | undefined {
  switch (priority) {
    case '1':
      return 1;
    case '2':
      return 2;
    case '3':
      return 3;
    case '4':
      return 4;
    case '5':
      return 5;
    default:
      return;
  }
}
