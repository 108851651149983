import { CustomBooleanSchema } from '@shared/model';

export function fromCustomBooleanSchemaToBoolean<
  CustomBooleanWithMaybeUndefined extends CustomBooleanSchema | undefined,
>(customBoolean: CustomBooleanWithMaybeUndefined): boolean | undefined {
  if (typeof customBoolean !== 'string') {
    return customBoolean;
  }
  switch (customBoolean) {
    case 'Да':
      return true;
    case 'Нет':
      return false;
    default:
      return undefined;
  }
}
