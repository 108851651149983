import { bodyResponseType } from '@shared/api';

import { CreateAgencies } from './CreateAgencies';
import { EditAgencies } from './EditAgencies';

type AgenciesDrawerProps = {
  onClose: () => void;
  withDialog?: boolean;
} & (
  | {
      type: 'create';
      open: boolean;
      id?: undefined;
      afterCreate?: (createdAgency: bodyResponseType<'post', '/handbook/agencies'>) => void;
    }
  | {
      type: 'edit';
      open?: undefined;
      id?: string;
      afterCreate?: undefined;
    }
);

export function AgenciesDrawer({
  type,
  open,
  onClose,
  id,
  afterCreate,
  withDialog,
}: AgenciesDrawerProps) {
  return (
    <>
      {type === 'create' ? (
        <CreateAgencies
          open={open}
          onClose={onClose}
          afterCreate={afterCreate}
          withDialog={withDialog}
        />
      ) : (
        <EditAgencies
          onClose={onClose}
          id={id}
        />
      )}
    </>
  );
}
