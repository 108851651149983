import BigNumber from 'bignumber.js';

export function calculatePrice(делимое: number, маржа: number) {
  if (маржа === 100) return 0;

  // console.log(`(${делимое} * 100) / (100 - ${маржа}) = ${(делимое * 100) / (100 - маржа)}`);

  const result =
    +BigNumber(делимое).times(100).dividedBy(BigNumber(100).minus(маржа)).times(100).toFixed(0) /
    100;
  return result;
}
