import { Eye, EyeSlash } from '@gravity-ui/icons';
import { Button, Icon, PinInput, Text, TextInput, TextInputProps } from '@gravity-ui/uikit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { yupRu } from '@shared/config';
import { cn, objectKeysSafeToArray } from '@shared/lib';

import { AuthFormProps } from '../model';

import './index.sass';

export function AuthForm<
  Schema extends yupRu.ObjectSchema<yupRu.InferType<yupRu.AnyObjectSchema>>,
>({ schema, defaultValues, title, onValid, onInvalid, inputs, buttons }: AuthFormProps<Schema>) {
  const { control, handleSubmit } = useForm<Schema>({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    defaultValues,
  });

  const [passwordMode, setPasswordMode] =
    useState<Extract<TextInputProps['type'], 'password' | 'text'>>('password');

  return (
    <form
      onSubmit={handleSubmit(onValid, onInvalid)}
      className="my-auto bg-base-background rounded-2xl w-[384px] flex flex-col gap-8 items-center p-8"
      autoComplete="on"
    >
      <Text
        variant="subheader-3"
        className="text-center"
      >
        {title}
      </Text>
      <div className="w-full flex flex-col gap-6">
        {objectKeysSafeToArray(defaultValues).map((key, i) => (
          <Controller
            name={key}
            key={i}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <div>
                {inputs[key].type === 'text' && (
                  <TextInput
                    {...inputs[key].props}
                    {...field}
                    id={field.name}
                    size="xl"
                    className="pb-1"
                    // Ограничим длину ввода
                    onChange={e => {
                      if (e.target.value.length > 40) {
                        e.target.value = e.target.value.substring(0, 40);
                      }
                      field.onChange(e);
                    }}
                  />
                )}
                {inputs[key].type === 'password' && (
                  <TextInput
                    {...inputs[key].props}
                    {...field}
                    type={passwordMode}
                    size="xl"
                    className="pb-1"
                    id={field.name}
                    // Ограничим длину ввода
                    onChange={e => {
                      if (e.target.value.length > 40) {
                        e.target.value = e.target.value.substring(0, 40);
                      }
                      field.onChange(e);
                    }}
                    endContent={
                      <Button
                        onClick={() =>
                          setPasswordMode(passwordMode === 'password' ? 'text' : 'password')
                        }
                        view="flat"
                        className="text-text-secondary"
                        size="m"
                      >
                        <Icon data={passwordMode === 'password' ? EyeSlash : Eye} />
                      </Button>
                    }
                  />
                )}
                {inputs[key].type === 'pinInput' && (
                  <PinInput
                    {...inputs[key].props}
                    {...field}
                    onUpdate={field.onChange}
                    size="xl"
                    validationState={error?.message ? 'invalid' : undefined}
                    errorMessage={error?.message}
                    // Не считается плохой практикой в данном случае с PinInput
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                  />
                )}
                {error && inputs[key].type !== 'pinInput' && (
                  <Text
                    variant="body-short"
                    color="danger"
                  >
                    {error.message}
                  </Text>
                )}
              </div>
            )}
          />
        ))}
      </div>

      <div className={cn(`flex justify-between gap-4 w-full ${buttons.wrapperClassName}`)}>
        {buttons.secondButtonProps && (
          <Button
            {...buttons.secondButtonProps}
            size="xl"
          >
            {buttons.secondButtonProps?.children}
          </Button>
        )}
        <Button
          {...buttons.primaryButtonProps}
          type="submit"
          size="xl"
          view="action"
        >
          {buttons.primaryButtonProps.children}
        </Button>
      </div>
    </form>
  );
}
