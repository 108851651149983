import { useToaster } from '@gravity-ui/uikit';
import { useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';

import { $restrictionAdvantageRequirementsHooks, userAtom } from '@entities';
import { URLS } from '@shared/consts';
import { useNavigateTo } from '@shared/lib';
import { RightSidebar } from '@shared/ui';

import { RestrictionAdvantageRequirementsLayout } from './RestrictionAdvantageRequirementsLayout';

interface EditRestrictionAdvantageRequirementsProps {
  id: string | undefined;
  onClose: () => void;
}

export function EditRestrictionAdvantageRequirements({
  id,
  onClose,
}: EditRestrictionAdvantageRequirementsProps) {
  const user = useAtomValue(userAtom);
  const [isOpen, setIsOpen] = useState(false);

  const queryClient = useQueryClient();

  const { navigateTo } = useNavigateTo();
  const { add } = useToaster();

  // Queries

  const getRestrictionAdvantageRequirementQuery = $restrictionAdvantageRequirementsHooks.useGetById(
    id || '',
    !!id
  );
  const updateRestrictionAdvantageRequirementsMutation =
    $restrictionAdvantageRequirementsHooks.updateByIdMutation();
  const deleteRestrictionAdvantageRequirementsMutation =
    $restrictionAdvantageRequirementsHooks.deleteByIdMutation();
  //

  useEffect(() => {
    if (getRestrictionAdvantageRequirementQuery.error) {
      navigateTo(URLS.handbook.custom);
      add({
        theme: 'danger',
        name: 'get-restrictionAdvantageRequirement-error',
        title:
          'Произошла ошибка при получении карточки справочника «Ограничения, преимущества и доп. требования»',
      });
    }
  }, [getRestrictionAdvantageRequirementQuery.isFetching]);

  useEffect(() => {
    if (id) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [id]);

  useEffect(() => {
    // Уведомления об обновлении карточки
    if (updateRestrictionAdvantageRequirementsMutation.isSuccess) {
      add({
        theme: 'success',
        name: 'update-restrictionAdvantageRequirements-success',
        title:
          'Карточка справочника «Ограничения, преимущества и доп. требования» обновлена успешно',
      });
    }
    if (updateRestrictionAdvantageRequirementsMutation.isError) {
      add({
        theme: 'danger',
        name: 'update-restrictionAdvantageRequirements-error',
        title:
          'Ошибка при обновлении карточки справочника «Ограничения, преимущества и доп. требования»',
      });
    }
  }, [updateRestrictionAdvantageRequirementsMutation.isPending]);

  return (
    <RestrictionAdvantageRequirementsLayout
      formProps={{
        response: getRestrictionAdvantageRequirementQuery.data,
        onClickCancelButton: () => {
          onClose();
        },
        initialEditable: false,
        onValid: async values => {
          if (!id) return;

          await updateRestrictionAdvantageRequirementsMutation
            .mutateAsync({
              body: values,
              params: {
                path: {
                  id,
                },
              },
            })
            .then(async () => {
              queryClient.invalidateQueries({
                queryKey: ['/restriction-advantage-requirements/all'],
              });

              onClose();
            });
        },
        onInvalid: errors => console.log('@errors', errors),
      }}
      renderForm={formComponent => (
        <RightSidebar
          id="sidebar-restrictionAdvantageRequirement"
          onClose={() => {
            setIsOpen(false);
            onClose();
          }}
          open={isOpen}
        >
          <RightSidebar.Header
            title={
              'Редактирование карточки справочника «Ограничения, преимущества и доп. требования»'
            }
            onClose={() => {
              setIsOpen(false);
              onClose();
            }}
            dropdownMenuItems={
              user?.is_admin && !!id
                ? [
                    {
                      theme: 'danger',
                      text: 'Удалить',
                      action: () => {
                        deleteRestrictionAdvantageRequirementsMutation
                          .mutateAsync({ params: { path: { id: id } } })
                          .then(() => {
                            add({
                              theme: 'success',
                              name: 'delete-restriction-advantage-requirements-success',
                              title: 'Карточка удалена успешно',
                            });
                            queryClient.invalidateQueries({
                              queryKey: ['/handbook/restriction-advantage-requirements'],
                            });
                            onClose();
                          })
                          .catch(() => {
                            add({
                              theme: 'danger',
                              name: 'delete-restriction-advantage-requirements-error',
                              title: 'Ошибка при удалении карточки',
                            });
                          });
                      },
                    },
                  ]
                : undefined
            }
          />
          {formComponent}
        </RightSidebar>
      )}
    />
  );
}
