export const URLS = {
  // навигация слева, <NavigationSidebar/>
  processes: {
    default: '/processes',
    // КП
    commercialOffers: '/processes/commercial-offers',
    commercialOffersDynamicParams: {
      id: 'id',
    },
    // Торги
    tenders: '/processes/tenders',
    tendersDynamicParams: {
      id: 'id',
    },
    // Контракты
    contracts: '/processes/contracts',
    contractsDynamicParams: {
      id: 'id',
    },
    // Согласования
    agreements: '/processes/agreements',
    agreementsDynamicParams: {
      id: 'id',
    },
  },
  // Управление (которого нет)
  handling: {
    default: '/handling',
  },
  // Справочники
  handbook: {
    default: '/handbook',
    // Системные справочники
    system: '/handbook/system',
    // Пользовательские справочники
    custom: '/handbook/custom',
    dynamicParams: {
      // Название пользовательского справочника
      handbookName: 'handbookName',
      // Айди пользовательского справочника
      id: 'id',
    },
  },
  // Отправка бага (которого нет)
  bugReport: '/bug-report',
  // Глобальный поиск
  search: {
    default: '/search',
    dynamicParams: {
      // Название процесса (КП, Торги, Контракты, Согласование)
      processName: 'processName',
      // Айди процесса (КП, Торги, Контракты, Согласование)
      processId: 'processId',
    },
  },
  // Авторизация
  auth: {
    default: '/auth',
    // Сброс пароля
    resetPassword: '/auth/reset-password',
    // Ввод нового пароля
    newPassword: '/auth/new-password',
    // Подтверждение пароля
    confirmPincode: '/auth/confirm-pincode',
  },
  // Мессенджер (Чаты)
  messenger: '/messenger',
  messengerDynamicParams: {
    id: 'id',
  },
  // Личный Кабинет
  profile: '/profile',
  // Календарь
  calendar: '/calendar',
};
