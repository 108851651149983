import { bodyResponseType } from '@shared/api';

export function convertAgreementResultStatusFromBackend(
  status: bodyResponseType<'get', '/coordination/{id}'>['status']
): string {
  switch (status) {
    case 'ACCEPTED':
      return 'Согласовано';
    case 'PENDING':
      return 'Ожидает';
    case 'REJECTED':
      return 'Отклонено';
  }
}
