import { useToaster } from '@gravity-ui/uikit';
import { useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';

import { $contactsHooks, userAtom } from '@entities';
import { URLS } from '@shared/consts';
import { useNavigateTo } from '@shared/lib';
import { RightSidebar } from '@shared/ui';

import { ContactsLayout } from './ContactsLayout';

interface EditContactsProps {
  id: string | undefined;
  onClose: () => void;
}

export function EditContacts({ id, onClose }: EditContactsProps) {
  const user = useAtomValue(userAtom);
  const [isOpen, setIsOpen] = useState(false);

  const queryClient = useQueryClient();

  const { navigateTo } = useNavigateTo();
  const { add } = useToaster();

  // Queries

  const getContactQuery = $contactsHooks.useGetById(id || '', !!id);
  const updateContactsMutation = $contactsHooks.updateByIdMutation();
  const deleteContactsMutation = $contactsHooks.deleteByIdMutation();
  //

  useEffect(() => {
    if (getContactQuery.error) {
      navigateTo(URLS.handbook.custom);
      add({
        theme: 'danger',
        name: 'get-contact-error',
        title: 'Произошла ошибка при получении карточки Контакта',
      });
    }
  }, [getContactQuery.isFetching]);

  useEffect(() => {
    if (id) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [id]);

  useEffect(() => {
    // Уведомления об обновлении карточки
    console.log(updateContactsMutation.status);
    if (updateContactsMutation.isSuccess) {
      add({
        theme: 'success',
        name: 'update-contacts-success',
        title: 'Карточка Контакта обновлена успешно',
      });
    }
    if (updateContactsMutation.isError) {
      add({
        theme: 'danger',
        name: 'update-contacts-error',
        title: 'Ошибка при обновлении карточки Контакта',
      });
    }
  }, [updateContactsMutation.isPending]);

  return (
    <ContactsLayout
      formProps={{
        response: getContactQuery.data,
        onClickCancelButton: () => {
          onClose();
        },
        initialEditable: false,
        onValid: async values => {
          if (!id) return;
          const valuesWithoutWithType: typeof values = {
            ...values,
            organization_with_type: undefined,
          };
          await updateContactsMutation
            .mutateAsync({
              // TODO: присутствует в схеме
              body: { ...valuesWithoutWithType, organization_value: undefined },
              params: {
                path: {
                  id,
                },
              },
            })
            .then(async () => {
              queryClient.invalidateQueries({ queryKey: ['/contacts/all'] });

              onClose();
            });
        },
        onInvalid: errors => console.log('@errors', errors),
      }}
      renderForm={formComponent => (
        <RightSidebar
          id="sidebar-contact"
          onClose={() => {
            setIsOpen(false);
            onClose();
          }}
          open={isOpen}
        >
          <RightSidebar.Header
            title={'Редактирование карточки Контакта'}
            onClose={() => {
              setIsOpen(false);
              onClose();
            }}
            dropdownMenuItems={
              user?.is_admin && !!id
                ? [
                    {
                      theme: 'danger',
                      text: 'Удалить',
                      action: () => {
                        deleteContactsMutation
                          .mutateAsync({ params: { path: { id: id } } })
                          .then(() => {
                            add({
                              theme: 'success',
                              name: 'delete-contacts-success',
                              title: 'Карточка удалена успешно',
                            });
                            queryClient.invalidateQueries({ queryKey: ['/handbook/contacts'] });
                            onClose();
                          })
                          .catch(() => {
                            add({
                              theme: 'danger',
                              name: 'delete-contacts-error',
                              title: 'Ошибка при удалении карточки',
                            });
                          });
                      },
                    },
                  ]
                : undefined
            }
          />
          {formComponent}
        </RightSidebar>
      )}
    />
  );
}
