import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { AgreementDrawer, AgreementsTable } from '@widgets';
import { URLS } from '@shared/consts';
import { useNavigateTo } from '@shared/lib';
import { HeroWithButton } from '@shared/ui';

export function Agreements() {
  const { navigateTo } = useNavigateTo();

  const agreementId = useParams()[URLS.processes.agreementsDynamicParams.id];

  const [selectedOfferIndex, setSelectedOfferIndex] = useState<number | null>(null);

  return (
    <>
      <HeroWithButton title="Согласования" />
      <AgreementsTable
        selectedOfferIndex={selectedOfferIndex}
        onRowClick={(id, index) => {
          navigateTo(URLS.processes.agreements + '/' + id);
          setSelectedOfferIndex(index);
        }}
      />
      <AgreementDrawer
        id={agreementId}
        onClose={() => navigateTo(URLS.processes.agreements)}
      />
    </>
  );
}
