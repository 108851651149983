import { yupRu } from '@shared/config';
// import { fromCustomBooleanSchemaToBoolean } from '@shared/lib';
import { CustomBooleanSchema } from '@shared/model';

import {
  ContractBasis,
  ContractDays,
  ContractFZ,
  ContractGOZ,
  ContractRelatedCard,
  ContractStage,
  ContractTransaction,
  CustomerPriority,
} from '../types';

export const contractSchema = yupRu.object({
  // editContract: yupRu.bool().transform(() => {}),
  name: yupRu.string().required(),
  basis: yupRu
    .string()
    .oneOf<ContractBasis>(['Тендер', 'Прямая закупка с договором', 'Прямая закупка без договора'])
    .required(),
  fz: yupRu.string().oneOf<ContractFZ>(['44-ФЗ', '223-ФЗ', 'КОМ.']),
  gozfz: yupRu
    .string()
    .oneOf<ContractGOZ>(['НЕГОЗ', 'ГОЗ'])
    .transform((value: ContractGOZ) => value || undefined),
  maintenance: yupRu
    .string()
    .oneOf<ContractTransaction>(['Нет', 'Казначейское', 'Банковское'])
    .when('editContract', {
      is: (val: boolean | undefined) => val,
      then: schema => schema.required(),
    }),
  unique_number: yupRu
    .string()
    .matches(/([0-9][0-9][0-9][0-9]-[0-9][0-9][0-9][0-9])\b/, {
      message: 'Некорректный формат: xxxx-xxxx, где x - цифра',
      excludeEmptyString: true,
    })
    .required(),
  stage: yupRu
    .string()
    .oneOf<ContractStage>(['Проект', 'Заключен', 'Рассчитан', 'Исполнен', 'Расторгнут']),
  termination_reason: yupRu.string().min(3).max(255),
  responsible_id: yupRu.string().required(),
  customer_id: yupRu.string().required(),
  customer_priority: yupRu.mixed<CustomerPriority>(),
  // .oneOf<CustomerPriority>([1, 2, 3, 4, 5, '1', '2', '3', '4', '5'])
  // .transform((v: number | string | undefined) => {
  //   if (!v) return undefined;
  //   if (typeof v !== 'string') return v;
  //   return +v;
  // }),
  supplier_id: yupRu.string().required(),
  object_ids: yupRu.array(yupRu.string().required()).requiredArray(),
  comment: yupRu.string().min(3).max(255),
  days_type: yupRu.string().oneOf<ContractDays>(['Рабочие', 'Календарные']),
  payment_term: yupRu
    .number()
    .min(0)
    .test({
      test: value => {
        if (value === undefined) return true;
        try {
          return !!BigInt(value);
        } catch {
          return false;
        }
      },
      message: 'Должен состоять из цифр',
    }),
  contract_sum: yupRu.number().required(),
  contract_number: yupRu.string(),
  signing_date: yupRu.string(),
  contract_duration: yupRu.string(),
  contract_registration_number: yupRu.string(),
  trading_platform_id: yupRu.string(),

  // КП/Тендер
  related_card_id: yupRu.string(),
  related_card_type: yupRu.string().oneOf<ContractRelatedCard>(['offer', 'tender']),
  //

  region_id: yupRu.string(),
  delivery_address: yupRu
    .string()
    .min(3)
    .max(255)
    .transform(v => v || undefined),
  delivery_term: yupRu
    .string()
    .min(3)
    .max(255)
    .transform(v => v || undefined),
  actual_files: yupRu.array(yupRu.string().required()),
  basis_files: yupRu.array(yupRu.string().required()),
  has_smp_restriction: yupRu.mixed<CustomBooleanSchema>(),
  // .transform((v: CustomBooleanSchema | undefined) => {
  //   return fromCustomBooleanSchemaToBoolean(v);
  // }),
  has_contract_security: yupRu.mixed<CustomBooleanSchema>(),
  // .transform((v: CustomBooleanSchema | undefined) => {
  //   return fromCustomBooleanSchemaToBoolean(v);
  // }),
  contract_security_sum: yupRu.number(),
  contract_security_type: yupRu.string(),
  contract_security_term: yupRu.string(),
  has_warranty_security: yupRu.mixed<CustomBooleanSchema>(),
  // .transform((v: CustomBooleanSchema | undefined) => {
  //   return fromCustomBooleanSchemaToBoolean(v);
  // }),
  warranty_security_sum: yupRu.number(),
  warranty_security_type: yupRu.string(),
  // .oneOf<
  //   'Денежные средства' | 'Банковская гарантия' | 'Справка о добросовестности'
  // >(['Банковская гарантия', 'Денежные средства', 'Справка о добросовестности'])
  warranty_security_term: yupRu.string(),
  progress_percent: yupRu.number(),
  contract_limit: yupRu.number(),
  shipment_sum_with_vat: yupRu.number(),
  shipment_sum_without_vat: yupRu.number(),
  vat_sum: yupRu.number(),
  purchase_sum: yupRu.number(),
  limit_balance: yupRu.number(),
  payment_sum: yupRu.number(),
  receivables_sum: yupRu.number(),
  overdue_receivables_sum: yupRu.number(),
});

export type ContractSchema = yupRu.InferType<typeof contractSchema>;
