import { RadioButtonOption } from '@gravity-ui/uikit';

import { STATUS_OPTIONS } from '../constants';
import { ChatFilterStatus } from '../model';

export function getStatusOptions(allowedStatuses: ChatFilterStatus[]): RadioButtonOption[] {
  return STATUS_OPTIONS.map(status => ({
    ...status,
    disabled: status.value !== 'all' && !allowedStatuses?.includes(status.value),
  }));
}
