import { dateTime } from '@gravity-ui/date-utils';

import { CRMTableData } from '@features/table';
import { components } from '@shared/api';
import { DATE_FORMATS } from '@shared/consts';

// import { formatToPrice } from '@shared/lib';

import { ColumnsHandbookTable } from '../model';

const parseDate = (date: string, withTime?: boolean) =>
  dateTime({ input: date }).format(
    !withTime ? DATE_FORMATS.fullDate : DATE_FORMATS.fullDateWithTimeRounded
  );

// const parseFloat = (float: number): number => {
//   return float / 100;
// };

const parseMultiple = (names: string[]): string => {
  try {
    return names.join(' \n');
  } catch {
    return '';
  }
};

export const parseTableData = (
  tableData: NonNullable<components['schemas']['handbook.Suppliers']['list']>
): CRMTableData<ColumnsHandbookTable> => {
  return tableData.map(table => {
    return {
      'Дата, номер': table.created_at ? `${parseDate(table.created_at)} №${table.id}` : '',
      'Краткое наименование': table.name || '',
      'Полное наименование': table.full_name || '',
      'Товарный знак': table.trademark || '',
      ИНН: table.inn || '',
      КПП: table.kpp || '',
      'Регион фактического нахождения офиса': table.region_value || '',
      'Тип поставщика': table.type || '',
      'Статус поставщика': table.status || '',
      Договор: table.has_contract || '',
      'Карточка договора': table.contract_value || '',
      'Объекты поставок': table.purchase_object_value || '',
      Сайт: table.website || '',
      'Теги (краткое описание)': table.tags ? parseMultiple(table.tags) : '',
      'Файлы: прайсы, каталоги, презентации, прочее': table.files ? parseMultiple(table.files) : '',
      Контакты: table.contact_values ? parseMultiple(table.contact_values) : '',
      Адреса: table.addresses ? parseMultiple(table.addresses) : '',
      'Платежные реквизиты': table.payment_detail_value || '',
    };
  });
};
