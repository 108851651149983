import { ReactNode } from 'react';

import { SupplierContractsForm, SupplierContractsFormProps } from '@features';

interface SupplierContractsLayoutProps {
  renderForm: (formComponent: ReactNode) => ReactNode;
  formProps: SupplierContractsFormProps;
}

export function SupplierContractsLayout({ renderForm, formProps }: SupplierContractsLayoutProps) {
  return <>{renderForm(<SupplierContractsForm {...formProps} />)}</>;
}
