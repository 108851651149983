import { dateTime } from '@gravity-ui/date-utils';

import { CRMTableData } from '@features/table';
import { components } from '@shared/api';
import { DATE_FORMATS } from '@shared/consts';
import { formatToPrice } from '@shared/lib';

import { ColumnsHandbookTable } from '../model';

const parseDate = (date: string, withTime?: boolean) =>
  dateTime({ input: date }).format(
    !withTime ? DATE_FORMATS.fullDate : DATE_FORMATS.fullDateWithTimeRounded
  );

const parseFloat = (float: number): number => {
  return float / 100;
};

const parseMultiple = (names: string[]): string => {
  try {
    return names.join(' \n');
  } catch {
    return '';
  }
};

export const parseTableData = (
  tableData: NonNullable<components['schemas']['server.EmployeesList']['list']>
): CRMTableData<ColumnsHandbookTable> => {
  return tableData.map(table => {
    return {
      'Дата, номер': table.created_at ? `${parseDate(table.created_at)} №${table.id}` : '',
      'Фамилия И.О.': table.name_initials || '',
      'ФИО полностью': table.full_name || '',
      Тип: table.type || '',
      Фото: table.photo || '',
      Должность: table.position || '',
      Подразделение: table.department_value || '',
      Разряд: table.rank || '',
      Доступ: table.blocked_at ? parseDate(table.blocked_at, true) : '',
      'Мобильный телефон': table.mobile_phone || '',
      'Рабочий телефон': table.work_phone || '',
      'Электронная почта': table.email || '',
      Заметка: table.note || '',
      'Прямые подчиненные': table.direct_subordinate_values
        ? parseMultiple(table.direct_subordinate_values)
        : '',
      'Косвенные подчиненные': table.indirect_subordinate_values
        ? parseMultiple(table.indirect_subordinate_values)
        : '',
    };
  });
};
