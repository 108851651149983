import { bodyResponseType } from '@shared/api';

import { CreateSupplierContracts } from './CreateSupplierContracts';
import { EditSupplierContracts } from './EditSupplierContracts';

type SupplierContractsDrawerProps = {
  onClose: () => void;
  withDialog?: boolean;
} & (
  | {
      type: 'create';
      open: boolean;
      id?: undefined;
      afterCreate?: (
        createdSupplierContract: bodyResponseType<'post', '/handbook/supplier-contracts'>
      ) => void;
    }
  | {
      type: 'edit';
      open?: undefined;
      id?: string;
      afterCreate?: undefined;
    }
);

export function SupplierContractsDrawer({
  type,
  open,
  onClose,
  id,
  afterCreate,
  withDialog,
}: SupplierContractsDrawerProps) {
  return (
    <>
      {type === 'create' ? (
        <CreateSupplierContracts
          open={open}
          onClose={onClose}
          afterCreate={afterCreate}
          withDialog={withDialog}
        />
      ) : (
        <EditSupplierContracts
          onClose={onClose}
          id={id}
        />
      )}
    </>
  );
}
