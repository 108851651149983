import * as yup from 'yup';

// import { formatBytes } from '@shared/lib';

import { ru } from './ru-locale';

yup.setLocale(ru);

// yup.addMethod<yup.MixedSchema<File | undefined>>(
//   yup.mixed,
//   'fileSizeLessThan',
//   function (fileSizeLessThan: number) {
//     // eslint-disable-next-line @typescript-eslint/no-explicit-any
//     return this.test({
//       name: 'file-size',
//       test: function (file) {
//         if (file && file.size > fileSizeLessThan) {
//           return this.createError({
//             message: `Слишком большой вес файла «${file.name}», уменьшите его до ${formatBytes(fileSizeLessThan)}`,
//           });
//         }

//         return true;
//       },
//     });
//   }
// );

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ArrayType = any[] | null | undefined;

declare module 'yup' {
  interface ArraySchema<
    TIn extends ArrayType,
    TContext,
    TDefault = undefined,
    TFlags extends yup.Flags = '',
  > {
    requiredArray(
      property?: string,
      message?: string
    ): ArraySchema<NonNullable<TIn>, TContext, TDefault, TFlags>;
  }
}

function requiredArray<
  TIn extends ArrayType,
  TContext,
  TDefault = undefined,
  TFlags extends yup.Flags = '',
>(
  this: yup.ArraySchema<TIn, TContext, TDefault, TFlags>,
  property?: string,
  message?: string
): yup.ArraySchema<NonNullable<TIn>, TContext, TDefault, TFlags> {
  return this.required().min(1, message || 'Поле обязательно для заполнения');
}

yup.addMethod(yup.array, 'requiredArray', requiredArray);

export { yup as yupRu };
