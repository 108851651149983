import { ArrowUpFromSquare, Pencil, Xmark } from '@gravity-ui/icons';
import { Button, Icon, Text } from '@gravity-ui/uikit';
import { ReactNode } from 'react';

interface TableBottomPanelProps {
  open: boolean;
  onClose: () => void;
  selectedCount: number;
  onClickUpdate: () => void;
  onClickExport: () => void;
  buttonsSlot?: ReactNode;
}

export const TableBottomPanel = ({
  open,
  onClose,
  selectedCount,
  onClickUpdate,
  onClickExport,
  buttonsSlot,
}: TableBottomPanelProps) => {
  if (!open) return undefined;

  return (
    <div className="sticky left-0 bottom-0 right-0 px-5 py-3 bg-base-background shadow-modal flex justify-between">
      <div className="flex gap-4 items-center">
        <Text
          variant="body-1"
          color="primary"
        >
          Выбрано: {selectedCount}
        </Text>
        <div className="flex items-center gap-2">
          <Button
            view="action"
            size="m"
            onClick={onClickUpdate}
          >
            <Icon data={Pencil} />
            Изменить
          </Button>
          <Button
            view="normal"
            size="m"
            onClick={onClickExport}
          >
            <Icon data={ArrowUpFromSquare} />
            Экспорт
          </Button>
          {buttonsSlot}
        </div>
      </div>
      <Button
        size="m"
        view="flat"
        onClick={onClose}
      >
        <Icon data={Xmark} />
      </Button>
    </div>
  );
};
