import { dateTime } from '@gravity-ui/date-utils';
import { Text } from '@gravity-ui/uikit';

import { DATE_FORMATS } from '@shared/consts';
import { formatBytes } from '@shared/lib';

export interface MediaItemProps {
  imageUrl: string;
  imageName: string;
  name: string;
  size: number;
  extension: string;
  createdBy: string;
  createdAt: number;
}

export function MediaItem({
  createdAt,
  createdBy,
  extension,
  imageName,
  imageUrl,
  name,
  size,
}: MediaItemProps) {
  return (
    <div className="flex gap-3 items-center">
      <img
        src={imageUrl}
        alt={imageName}
        className="w-10 h-10 object-cover rounded-[2px]"
        loading="lazy"
      />
      <div className="flex flex-col gap-1 grow overflow-hidden">
        <Text
          variant="body-1"
          color="primary"
          className="truncate"
          title={name}
        >
          {name}
        </Text>
        <div className="flex items-center justify-between gap-3">
          <Text
            variant="caption-2"
            color="complementary"
            className="shrink-0"
          >
            {formatBytes(size)}, {extension}
          </Text>
          <Text
            variant="caption-2"
            color="complementary"
            className="truncate"
          >
            {createdBy}, {dateTime({ input: createdAt }).format(DATE_FORMATS.fullDate)}
          </Text>
        </div>
      </div>
    </div>
  );
}
