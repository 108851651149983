import { ReactNode } from 'react';

import { AgreementForm, AgreementFormProps } from '@features';

interface AgreementLayoutProps {
  renderForm: (formComponent: ReactNode) => ReactNode;
  formProps: AgreementFormProps;
}

export function AgreementLayout({ renderForm, formProps }: AgreementLayoutProps) {
  return <>{renderForm(<AgreementForm {...formProps} />)}</>;
}
