import { dateTime } from '@gravity-ui/date-utils';

import { CRMTableData } from '@features/table';
import { bodyResponseType } from '@shared/api';
import { DATE_FORMATS } from '@shared/consts';
import { formatToPrice } from '@shared/lib';

import { ColumnsContractsTable } from '../model';

const parseDate = (date: string, withTime?: boolean) =>
  dateTime({ input: date }).format(
    !withTime ? DATE_FORMATS.fullDate : DATE_FORMATS.fullDateWithTimeRounded
  );

const parseMultiple = (names: string[]): string => {
  try {
    return names.join(' \n');
  } catch {
    return '';
  }
};

const parseFiles = (files: string[]): string => {
  try {
    const keys = files.map(url => {
      return url.replace('https://stage.api.b2gcrm.ru/', '');
    });
    const fileNames = keys.map(key => {
      const split = key.split('/');
      return split.pop()!;
    });
    return parseMultiple(fileNames);
  } catch {
    return '';
  }
};

const parseFloat = (float: number): number => {
  return float / 100;
};

// 'Дата перевода': table.transfer_at ? parseDate(table.transfer_at) : '',
// Себестоимость: table.cost_price ? formatToPrice(table.cost_price, true) : '',
// 'Маржа, %': table.margin ? `${table.margin} %` : '',
// 'Согласовать цену с руководителем': table.manager_approved ? 'СОГЛАСОВАТЬ' : 'НЕ СОГЛАСОВАТЬ',

export const parseContractTableData = (
  tableData: NonNullable<bodyResponseType<'post', '/contract/all'>['contracts']>
): CRMTableData<ColumnsContractsTable> => {
  return tableData.map(table => {
    return {
      'Дата, номер': table.created_at ? `${parseDate(table.created_at)} №${table.id}` : '',
      Наименование: table.name,
      Основание: table.basis || '',
      ФЗ: table.fz || '',
      'В рамках ГОЗ по 275-ФЗ': table.gozfz || '',
      Сопровождение: table.maintenance || '',
      'Уникальный номер': table.unique_number,
      Этап: table.stage,
      'Причина расторжения': table.termination_reason || '',
      // TODO: должно быть обязательно
      Ответственный: table.responsible_value || '',
      // TODO: должно быть обязательно
      Заказчик: table.customer_value || '',
      'Приоритет заказчика':
        typeof table.customer_priority === 'number' ? table.customer_priority.toString() : '',
      Поставщик: table.supplier_value,
      'Объект торгов': table.object_value,
      Комментарий: table.comment || '',
      Дни: table.days_type || '',
      'Фактический срок оплаты с момента оплаты':
        typeof table.payment_term === 'number' ? table.payment_term.toString() : '',
      'Сумма контракта': formatToPrice(table.contract_sum),
      'Номер контракта': table.contract_number,
      'Дата подписания': parseDate(table.signing_date),
      'Срок действия контракта': table.contract_duration ? parseDate(table.contract_duration) : '',
      'Реестровый номер контракта': table.contract_registration_number || '',
      Площадка: table.trading_platform_value || '',
      'КП/Тендер': table.related_card_value?.title || '',
      'Регион поставки': table.region_value || '',
      'Адрес поставки': table.delivery_address || '',
      'Срок поставки': table.delivery_term || '',
      'Актуальный файл расчета': table.actual_files ? parseFiles(table.actual_files) : '',
      'Файл основания (контракт, обеспечение, доп.соглашение/договор/счет)': table.basis_files
        ? parseFiles(table.basis_files)
        : '',
      'Есть преимущество/ограничение для СМП?':
        table.has_smp_restriction !== undefined ? (table.has_smp_restriction ? 'ДА' : 'НЕТ') : '',
      'Обеспечение исполнения контракта есть':
        table.has_contract_security !== undefined
          ? table.has_contract_security
            ? 'ДА'
            : 'НЕТ'
          : '',
      'Сумма обеспечения исполнения':
        typeof table.contract_security_sum === 'number'
          ? formatToPrice(table.contract_security_sum, true)
          : '',
      'Вид обеспечения исполнения': table.contract_security_type || '',
      'Срок обеспечения исполнения': table.contract_security_term
        ? parseDate(table.contract_security_term)
        : '',
      'Обеспечение гарантийных обязательств есть':
        table.has_contract_security !== undefined
          ? table.has_contract_security
            ? 'ДА'
            : 'НЕТ'
          : '',
      'Сумма обеспечения гарантийных обязательств':
        typeof table.warranty_security_sum === 'number'
          ? formatToPrice(table.warranty_security_sum, true)
          : '',
      'Вид обеспечения гарантийных обязательств': table.warranty_security_type || '',
      'Срок обеспечения гарантийных обязательств': table.warranty_security_term
        ? parseDate(table.warranty_security_term)
        : '',
      'Прогресс исполнения':
        typeof table.progress_percent === 'number' ? `${parseFloat(table.progress_percent)}` : '',
      'Лимит контракта':
        typeof table.contract_limit === 'number' ? formatToPrice(table.contract_limit, true) : '',
      'Сумма отгрузок, с НДС':
        typeof table.shipment_sum_with_vat === 'number'
          ? formatToPrice(table.shipment_sum_with_vat, true)
          : '',
      'Сумма отгрузок, без НДС':
        typeof table.shipment_sum_without_vat === 'number'
          ? formatToPrice(table.shipment_sum_without_vat, true)
          : '',
      'Сумма НДС': typeof table.vat_sum === 'number' ? formatToPrice(table.vat_sum, true) : '',
      'Сумма закупок':
        typeof table.purchase_sum === 'number' ? formatToPrice(table.purchase_sum, true) : '',
      'Остаток лимита':
        typeof table.limit_balance === 'number' ? formatToPrice(table.limit_balance, true) : '',
      'Сумма оплаты':
        typeof table.payment_sum === 'number' ? formatToPrice(table.payment_sum, true) : '',
      'Сумма ДЗ':
        typeof table.receivables_sum === 'number' ? formatToPrice(table.receivables_sum, true) : '',
      'В т.ч. Сумма ПДЗ':
        typeof table.overdue_receivables_sum === 'number'
          ? formatToPrice(table.overdue_receivables_sum, true)
          : '',
    };
  });
};
