import { CreateCommercialOffer } from './CreateCommercialOffer';
import { EditCommercialOffer } from './EditCommercialOffer';

type CommercialOfferDrawerProps = {
  onClose: () => void;
} & (
  | {
      open: boolean;
      type: 'create';
      id?: undefined;
    }
  | {
      open?: undefined;
      type: 'edit';
      id?: string;
    }
);

export function CommercialOfferDrawer({ type, id, open, onClose }: CommercialOfferDrawerProps) {
  return (
    <>
      {type === 'create' ? (
        <CreateCommercialOffer
          open={open}
          onClose={onClose}
        />
      ) : (
        <EditCommercialOffer
          onClose={onClose}
          id={id}
        />
      )}
    </>
  );
}
