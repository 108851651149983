import { useSetAtom } from 'jotai';
import { ReactNode, useState } from 'react';

import {
  createdCompetitorForViewAtom,
  createdCustomerForViewAtom,
  createdEmployeeForViewAtom,
  createdETradingPlatformForViewAtom,
  createdPurchaseObjectForViewAtom,
  createdRegionForViewAtom,
  createdRestrictionAdvantageRequirementForViewAtom,
} from '@entities';
import {
  createdCompetitorParticipantsForViewAtom,
  createdCustomerOrganizerForViewAtom,
  createdLegalEntityParticipateAsForViewAtom,
  createdLegalEntityWinnerForViewAtom,
  TenderForm,
  TenderFormProps,
} from '@features';
import { CompetitorsDrawer } from '@widgets/custom-handbook-drawers/competitors-drawer/@x';
import { CustomersDrawer } from '@widgets/custom-handbook-drawers/customers-drawer/@x';
import { ETradingPlatformsDrawer } from '@widgets/custom-handbook-drawers/e-trading-platforms-drawer/@x';
import { EmployeesDrawer } from '@widgets/custom-handbook-drawers/employees-drawer/@x';
import { LegalEntitiesDrawer } from '@widgets/custom-handbook-drawers/legal-entities-drawer/@x';
import { PurchaseObjectsDrawer } from '@widgets/custom-handbook-drawers/purchase-objects-drawer/@x';
import { RegionsDrawer } from '@widgets/custom-handbook-drawers/regions-drawer/@x';
import { RestrictionAdvantageRequirementsDrawer } from '@widgets/custom-handbook-drawers/restriction-advantage-requirements-drawer/@x';

interface TenderLayoutProps {
  renderForm: (formComponent: ReactNode) => ReactNode;
  formProps: TenderFormProps;
}

export function TenderLayout({ renderForm, formProps }: TenderLayoutProps) {
  const setCreatedEmployeeForView = useSetAtom(createdEmployeeForViewAtom);
  const setCreatedETradingPlatformForView = useSetAtom(createdETradingPlatformForViewAtom);
  const setCreatedCustomerForView = useSetAtom(createdCustomerForViewAtom);
  const setCreatedCustomerOrganizerForView = useSetAtom(createdCustomerOrganizerForViewAtom);
  const setCreatedPurchaseObjectForView = useSetAtom(createdPurchaseObjectForViewAtom);
  const setCreatedLegalEntityParticipateAsForView = useSetAtom(
    createdLegalEntityParticipateAsForViewAtom
  );
  const setCreatedRestrictionAdvantageRequirementForView = useSetAtom(
    createdRestrictionAdvantageRequirementForViewAtom
  );
  const setCreatedRegionForView = useSetAtom(createdRegionForViewAtom);
  const setCreatedLegalEntityWinnerForView = useSetAtom(createdLegalEntityWinnerForViewAtom);
  const setCreatedCompetitorForView = useSetAtom(createdCompetitorForViewAtom);
  const setCreatedCompetitorParticipantsForView = useSetAtom(
    createdCompetitorParticipantsForViewAtom
  );

  const [createdEmployeeOpen, setCreatedEmployeeOpen] = useState(false);
  const [createdETradingPlatformOpen, setCreatedETradingPlatformOpen] = useState(false);
  const [createdCustomerOpen, setCreatedCustomerOpen] = useState(false);
  const [createdCustomerOrganizerOpen, setCreatedCustomerOrganizerOpen] = useState(false);
  const [createdPurchaseObjectOpen, setCreatedPurchaseObjectOpen] = useState(false);
  const [createdLegalEntityParticipateAsOpen, setCreatedLegalEntityParticipateAsOpen] =
    useState(false);
  const [
    createdRestrictionAdvantageRequirementOpen,
    setCreatedRestrictionAdvantageRequirementOpen,
  ] = useState(false);
  const [createdRegionOpen, setCreatedRegionOpen] = useState(false);
  const [createdLegalEntityWinnerOpen, setCreatedLegalEntityWinnerOpen] = useState(false);
  const [createdCompetitorOpen, setCreatedCompetitorOpen] = useState(false);
  const [createdCompetitorParticipantsOpen, setCreatedCompetitorParticipantsOpen] = useState(false);

  return (
    <>
      {renderForm(
        <TenderForm
          {...formProps}
          onClickCreateEmployeesHandbook={() => setCreatedEmployeeOpen(true)}
          onClickCreateETradingPlatformsHandbook={() => setCreatedETradingPlatformOpen(true)}
          onClickCreateCustomersHandbook={() => setCreatedCustomerOpen(true)}
          onClickCreateCustomersOrganizerHandbook={() => setCreatedCustomerOrganizerOpen(true)}
          onClickCreatePurchaseObjectsHandbook={() => setCreatedPurchaseObjectOpen(true)}
          onClickCreateLegalEntitiesParticipateAsHandbook={() =>
            setCreatedLegalEntityParticipateAsOpen(true)
          }
          onClickCreateRestrictionAdvantageRequirementsHandbook={() =>
            setCreatedRestrictionAdvantageRequirementOpen(true)
          }
          onClickCreateRegionsHandbook={() => setCreatedRegionOpen(true)}
          onClickCreateLegalEntitiesWinnerHandbook={() => setCreatedLegalEntityWinnerOpen(true)}
          onClickCreateCompetitorsHandbook={() => setCreatedCompetitorOpen(true)}
          onClickCreateCompetitorsParticipantsHandbook={() =>
            setCreatedCompetitorParticipantsOpen(true)
          }
        />
      )}
      <EmployeesDrawer
        withDialog
        type="create"
        open={createdEmployeeOpen}
        onClose={() => setCreatedEmployeeOpen(false)}
        afterCreate={values => {
          setCreatedEmployeeForView({
            employee_value: values.name_initials,
            employee_id: values.id,
          });
        }}
      />
      <ETradingPlatformsDrawer
        withDialog
        type="create"
        open={createdETradingPlatformOpen}
        onClose={() => setCreatedETradingPlatformOpen(false)}
        afterCreate={values => {
          setCreatedETradingPlatformForView({
            eTradingPlatform_value: values.name,
            eTradingPlatform_id: values.id,
          });
        }}
      />
      <CustomersDrawer
        withDialog
        type="create"
        open={createdCustomerOpen}
        onClose={() => setCreatedCustomerOpen(false)}
        afterCreate={values => {
          setCreatedCustomerForView({
            customer_value: values.name,
            customer_id: values.id,
          });
        }}
      />
      <CustomersDrawer
        withDialog
        type="create"
        open={createdCustomerOrganizerOpen}
        onClose={() => setCreatedCustomerOrganizerOpen(false)}
        afterCreate={values => {
          setCreatedCustomerOrganizerForView({
            customer_value: values.name,
            customer_id: values.id,
          });
        }}
      />
      <PurchaseObjectsDrawer
        withDialog
        type="create"
        open={createdPurchaseObjectOpen}
        onClose={() => setCreatedPurchaseObjectOpen(false)}
        afterCreate={values => {
          setCreatedPurchaseObjectForView({
            purchaseObject_value: values.object,
            purchaseObject_id: values.id,
          });
        }}
      />
      <LegalEntitiesDrawer
        withDialog
        type="create"
        open={createdLegalEntityParticipateAsOpen}
        onClose={() => setCreatedLegalEntityParticipateAsOpen(false)}
        afterCreate={values => {
          setCreatedLegalEntityParticipateAsForView({
            legalEntity_value: values.name,
            legalEntity_id: values.id,
          });
        }}
      />
      <RestrictionAdvantageRequirementsDrawer
        withDialog
        type="create"
        open={createdRestrictionAdvantageRequirementOpen}
        onClose={() => setCreatedRestrictionAdvantageRequirementOpen(false)}
        afterCreate={values => {
          setCreatedRestrictionAdvantageRequirementForView({
            restrictionAdvantageRequirement_value: values.name,
            restrictionAdvantageRequirement_id: values.id,
          });
        }}
      />
      <RegionsDrawer
        withDialog
        type="create"
        open={createdRegionOpen}
        onClose={() => setCreatedRegionOpen(false)}
        afterCreate={values => {
          setCreatedRegionForView({
            region_value: values.name,
            region_id: values.id,
          });
        }}
      />
      <LegalEntitiesDrawer
        withDialog
        type="create"
        open={createdLegalEntityWinnerOpen}
        onClose={() => setCreatedLegalEntityWinnerOpen(false)}
        afterCreate={values => {
          setCreatedLegalEntityWinnerForView({
            legalEntity_value: values.name,
            legalEntity_id: values.id,
          });
        }}
      />
      <CompetitorsDrawer
        withDialog
        type="create"
        open={createdCompetitorOpen}
        onClose={() => setCreatedCompetitorOpen(false)}
        afterCreate={values => {
          setCreatedCompetitorForView({
            competitor_value: values.name,
            competitor_id: values.id,
          });
        }}
      />
      <CompetitorsDrawer
        withDialog
        type="create"
        open={createdCompetitorParticipantsOpen}
        onClose={() => setCreatedCompetitorParticipantsOpen(false)}
        afterCreate={values => {
          setCreatedCompetitorParticipantsForView({
            competitor_value: values.name,
            competitor_id: values.id,
          });
        }}
      />
    </>
  );
}
