import { TabsItemProps } from '@gravity-ui/uikit';
import { atomWithStorage } from 'jotai/utils';

import { LOCAL_STORAGE } from '@shared/consts';
import { customStorage } from '@shared/lib';

export const tableTabsAtom = atomWithStorage<TabsItemProps[]>(
  LOCAL_STORAGE.agreementsTabs,
  customStorage<TabsItemProps[]>().getItem(LOCAL_STORAGE.agreementsTabs, [
    { id: 'Все', title: 'Все' },
  ]),
  customStorage()
);

export const selectedTabAtom = atomWithStorage<string | null>(
  LOCAL_STORAGE.agreementsSelectedTab,
  customStorage<string | null>().getItem(LOCAL_STORAGE.agreementsSelectedTab, null),
  customStorage()
);

// export const frontKeyBackendValueColumns = {
//   'Наименование',
//   'Тендер/КП',
//   'Заказчик',
//   'Запрашивающий согласование',
//   'Согласовант',
//   'Себестоимость',
//   'Предлагаемая специалистом цена',
//   'Маржа предлагаемая',
//   'Обоснование предложения цены',
//   'Согласны с предложением специалиста?',
//   'Маржа плановая',
//   'Минимальная цена/цена КП согласования',
//   'Причина отказа',
//   'Комментарий причины отказа',
//   'Файл расчета',
//   'Файл КП',
//   'Файл Заявки',
//   'Результат согласования',
//   'Комментарий',
//   'Создано',
//   'Тип согласования',
// }
