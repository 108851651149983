import { yupRu } from '@shared/config';
import { schemaFields } from '@shared/lib';

export const departmentsSchema = yupRu.object({
  /** @description Комментарий @gotags: validate:"min_len:3|max_len:255" */
  comment: schemaFields.textArea(),
  /** @description Документы @gotags: validate:"min_len:3|max_len:255" */
  documents: schemaFields.multiFile(),
  /** @description Руководитель @gotags: validate:"required" */
  header_id: schemaFields.select().required(),
  /** @description Наименование @gotags: validate:"required|min_len:3|max_len:255" */
  name: schemaFields.text().required(),
  /** @description Сотрудники @gotags: validate:"required" */
  user_ids: schemaFields.multiSelect().requiredArray(),
});

export type DepartmentsSchema = yupRu.InferType<typeof departmentsSchema>;
