import { yupRu } from '@shared/config';

export const okpd2sSchema = yupRu.object({
  value: yupRu
    .string()
    .matches(/^(\d{2}(?:\.\d{1,2}){0,2})$|^(\d{2}(\.\d{2}){2}\.\d{3})$/m, {
      message: 'Неверный формат',
      excludeEmptyString: true,
    })
    .required(),
});

export type Okpd2sSchema = yupRu.InferType<typeof okpd2sSchema>;
