/* eslint-disable @typescript-eslint/no-unused-vars */
import { useInfiniteQuery } from '@tanstack/react-query';

import {
  $api,
  $mock,
  bodyRequestType,
  fetchClient,
  mockClient,
  ParametersPathType,
  ParametersQueryType,
} from '@shared/api';

function useGetHandbookEmployeesInfiniteQuery(
  query: ParametersQueryType<'get', '/handbook/employees'>,
  enabled: boolean
) {
  return useInfiniteQuery({
    queryKey: ['/handbook/employees', query],
    queryFn: async ({ pageParam }) => {
      const { data } = await fetchClient.GET('/handbook/employees', {
        // const { data } = await mockClient.GET('/handbook/employees', {
        params: { query: { ...query, offset: pageParam } },
      });

      return data?.list || [];
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage?.length === 0) {
        return undefined;
      }
      return lastPageParam + query.limit;
    },
    enabled,
  });
}

const createHandbookEmployeesMutation = () => $api.useMutation('post', '/handbook/employees');

const useGetByIdHandbookEmployees = (
  id: ParametersPathType<'get', '/handbook/employees/{id}'>['id'],
  enabled: boolean
) =>
  $api.useQuery('get', '/handbook/employees/{id}', { params: { path: { id: id } } }, { enabled });

const updateHandbookEmployeesMutation = () => $api.useMutation('put', '/handbook/employees/{id}');
const deleteHandbookEmployeesMutation = () =>
  $api.useMutation('delete', '/handbook/employees/{id}');

const blockHandbookEmployeesMutation = () => $api.useMutation('put', '/user/block/{id}');
const unblockHandbookEmployeesMutation = () => $api.useMutation('delete', '/user/block/{id}');
const adminHandbookEmployeesMutation = () => $api.useMutation('put', '/user/admin/{id}');
const deadminHandbookEmployeesMutation = () => $api.useMutation('delete', '/user/admin/{id}');

export const $employeesHooks = {
  useGetAll: useGetHandbookEmployeesInfiniteQuery,
  useGetById: useGetByIdHandbookEmployees,
  deleteByIdMutation: deleteHandbookEmployeesMutation,
  updateByIdMutation: updateHandbookEmployeesMutation,
  create: createHandbookEmployeesMutation,
  block: blockHandbookEmployeesMutation,
  unblock: unblockHandbookEmployeesMutation,
  admin: adminHandbookEmployeesMutation,
  deadmin: deadminHandbookEmployeesMutation,
};
