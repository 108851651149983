import { LabelProps } from '@gravity-ui/uikit';

import { TenderStatuses } from '../model/types/statuses';

export const convertTenderStatusToLabelTheme = (status: TenderStatuses): LabelProps['theme'] => {
  switch (status) {
    case 'Подача':
    case 'Ожидание':
      return 'normal';
    case 'Выигран':
      return 'success';
    case 'Проигран':
    case 'Отказ':
    case 'Отклонение':
      return 'danger';
    case 'Не подано':
      return 'clear';
  }
};
