import { yupRu } from '@shared/config';
import { schemaFields } from '@shared/lib';

export const regionsSchema = yupRu.object({
  /** @description Наименование */
  name: schemaFields.text().required(),
  /** @description Код региона */
  code: yupRu
    .string()
    .max(3)
    .transform(value => value || undefined),
});

export type RegionsSchema = yupRu.InferType<typeof regionsSchema>;
