import createFetchClient from 'openapi-fetch';
import createClient from 'openapi-react-query';

import { API_URL } from '@shared/consts';

import { authorizationMiddleware } from './middlewares';
import type { paths } from './model';

export const mockClient = createFetchClient<paths>({
  baseUrl: 'http://' + window.location.hostname + ':' + window.location.port,
});

export const fetchClient = createFetchClient<paths>({
  baseUrl: API_URL,
});

mockClient.use(authorizationMiddleware);
fetchClient.use(authorizationMiddleware);

export const $mock = createClient(mockClient);
export const $api = createClient(fetchClient);
