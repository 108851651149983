import { bodyResponseType } from '@shared/api';

import { CreateTender } from './CreateTender';
import { EditTender } from './EditTender';

type TenderDrawerProps = {
  onClose: () => void;
} & (
  | {
      type: 'create';
      open: boolean;
      id?: undefined;
      afterCreate?: (createdTender: bodyResponseType<'post', '/tender/'>) => void;
    }
  | {
      type: 'edit';
      open?: undefined;
      id?: string;
      afterCreate?: undefined;
    }
);

export function TenderDrawer({ type, open, onClose, id, afterCreate }: TenderDrawerProps) {
  return (
    <>
      {type === 'create' ? (
        <CreateTender
          open={open}
          onClose={onClose}
          afterCreate={afterCreate}
        />
      ) : (
        <EditTender
          onClose={onClose}
          id={id}
        />
      )}
    </>
  );
}
