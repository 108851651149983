import { SupplierHasContract, SupplierStatus, SupplierType } from '@entities';
import { HandbookOption } from '@shared/model';

interface SupplierOptions {
  status: HandbookOption<SupplierStatus>[];
  has_contract: HandbookOption<SupplierHasContract>[];
  type: HandbookOption<SupplierType>[];
}

export const SUPPLIER_OPTIONS: SupplierOptions = {
  has_contract: [
    {
      value: 'Есть',
      content: 'Есть',
    },
    {
      value: 'Нет',
      content: 'Нет',
    },
  ],
  status: [
    {
      value: 'Производитель',
      content: 'Производитель',
    },
    {
      value: 'Вендор',
      content: 'Вендор',
    },
    {
      value: 'Дистрибьютер',
      content: 'Дистрибьютер',
    },
    {
      value: 'Дилер',
      content: 'Дилер',
    },
    {
      value: 'Работы',
      content: 'Работы',
    },
    {
      value: 'Услуги',
      content: 'Услуги',
    },
  ],
  type: [
    {
      value: 'Постоянный',
      content: 'Постоянный',
    },
    {
      value: 'Разовый',
      content: 'Разовый',
    },
    {
      value: 'ОФР',
      content: 'ОФР',
    },
  ],
};
