import { numericFormatter } from 'react-number-format';

export function formatToPrice(value: number | string, withSuffix?: boolean) {
  return numericFormatter((+value / 100).toString(), {
    thousandSeparator: ' ',
    decimalScale: 2,
    decimalSeparator: ',',
    suffix: withSuffix ? ' ₽' : '',
  });
}
