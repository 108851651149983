import { Button, useToaster } from '@gravity-ui/uikit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { $legalEntitiesHooks, $suppliersHooks } from '@entities';
import { bodyResponseType } from '@shared/api';
import { DATE_FORMATS, MAX_FILE_SIZE, NOT_ALLOWED_FILE_EXTENSIONS } from '@shared/consts';
import { cn, useFieldsWithPreview } from '@shared/lib';
import { FieldWithPreview, RightSidebar } from '@shared/ui';

import { SUPPLIER_CONTRACT_OPTIONS } from '../consts';
import { supplierContractsSchema, SupplierContractsSchema } from '../model';

export interface SupplierContractsFormProps {
  onValid: SubmitHandler<SupplierContractsSchema>;
  onValuesChange?: (values: SupplierContractsSchema) => void;
  response?: bodyResponseType<'get', '/handbook/supplier-contracts/{id}'>;
  onInvalid?: SubmitErrorHandler<SupplierContractsSchema>;
  onClickCancelButton?: () => void;
  initialEditable?: boolean;
  disabled?: boolean;
  hideBottomPanel?: boolean;
  rightSidebarButtons?: boolean;
  defaultValues?: SupplierContractsSchema;
  cancelButtonText?: string;
  submitButtonText?: string;
  compactBottomPanel?: boolean;
}

export function SupplierContractsForm({
  onValid,
  onValuesChange,
  response,
  onInvalid,
  initialEditable = false,
  onClickCancelButton,
  disabled = false,
  hideBottomPanel = false,
  defaultValues,
  cancelButtonText = 'Отмена',
  submitButtonText = 'Применить',
  compactBottomPanel = false,
}: SupplierContractsFormProps) {
  const { add } = useToaster();

  const form = useForm<SupplierContractsSchema>({
    resolver: yupResolver(supplierContractsSchema),
    mode: 'all',
    defaultValues,
    resetOptions: {
      keepDirtyValues: true,
      keepDefaultValues: false,
    },
  });

  const { createFieldRef, isFieldEditable, selectField, selectedFieldPath, resetSelectedField } =
    useFieldsWithPreview({
      form: form,
      initialEditable,
      disabled,
    });

  const values = form.watch();

  const [filterDeferrals, setFilterDeferrals] = useState('');
  // Queries
  const [filterLegalEntities, setFilterLegalEntities] = useState('');
  const getHandbookLegalEntitiesInfiniteQuery = $legalEntitiesHooks.useGetAll(
    {
      query: filterLegalEntities,
      limit: 30,
    },
    selectedFieldPath === 'buyer_id'
  );
  const flattedLegalEntities = useMemo(
    () => getHandbookLegalEntitiesInfiniteQuery.data?.pages.flat(),
    [getHandbookLegalEntitiesInfiniteQuery.data]
  );

  const [filterSuppliers, setFilterSuppliers] = useState('');
  const getHandbookSuppliersInfiniteQuery = $suppliersHooks.useGetAll(
    {
      query: filterSuppliers,
      limit: 30,
    },
    selectedFieldPath === 'supplier_id'
  );
  const flattedSuppliers = useMemo(
    () => getHandbookSuppliersInfiniteQuery.data?.pages.flat(),
    [getHandbookSuppliersInfiniteQuery.data]
  );
  //

  const [fileFieldsLoading, setFileFieldsLoading] = useState<
    Record<Extract<typeof selectedFieldPath, 'file'>, boolean>
  >({ file: false });

  const isFilesLoading = Object.values(fileFieldsLoading).some(
    fileFieldLoading => fileFieldLoading
  );

  useDeepCompareEffect(() => {
    onValuesChange?.(values);
  }, [values]);

  useDeepCompareEffect(() => {
    if (response) form.reset(response);
  }, [response || {}]);

  useEffect(() => {
    if (Object.values(form.formState.errors).length > 0 && form.formState.isSubmitted)
      add({
        title: 'Не все обязательные поля заполнены',
        name: 'validation-fields-error',
        theme: 'warning',
      });
  }, [form.formState.submitCount]);

  return (
    <form
      className="flex flex-col overflow-hidden grow"
      onSubmit={form.handleSubmit(onValid, onInvalid)}
    >
      <FieldWithPreview>
        <FieldWithPreview.Radio
          ref={createFieldRef('status')}
          control={form.control}
          edit={isFieldEditable('status')}
          name="Статус"
          options={SUPPLIER_CONTRACT_OPTIONS['status']}
          defaultOption={
            response?.status ? { value: response.status, content: response.status } : undefined
          }
          path="status"
          resetSelectedField={resetSelectedField}
          onFieldSelect={() => selectField('status')}
          radioButtonProps={{ disabled }}
          required
        />
        <FieldWithPreview.Select
          control={form.control}
          path="buyer_id"
          name="Покупатель"
          edit={isFieldEditable('buyer_id')}
          ref={createFieldRef('buyer_id')}
          onFieldSelect={() => selectField('buyer_id')}
          options={
            flattedLegalEntities?.map(buyer => ({
              content: buyer.name,
              value: buyer.id,
            })) || []
          }
          defaultOptions={
            // TODO:
            response?.buyer_id
              ? [
                  {
                    content: response.buyer_value,
                    value: response.buyer_id,
                  },
                ]
              : undefined
          }
          filter={filterLegalEntities}
          onFilterChange={filter => setFilterLegalEntities(filter)}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            disabled,
            placeholder: 'Выбрать',
            loading:
              getHandbookLegalEntitiesInfiniteQuery.isFetching ||
              getHandbookLegalEntitiesInfiniteQuery.isFetchingNextPage ||
              getHandbookLegalEntitiesInfiniteQuery.isLoading ||
              getHandbookLegalEntitiesInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookLegalEntitiesInfiniteQuery.fetchNextPage();
            },
          }}
          required
        />
        <FieldWithPreview.Select
          control={form.control}
          path="supplier_id"
          name="Поставщик"
          edit={isFieldEditable('supplier_id')}
          ref={createFieldRef('supplier_id')}
          onFieldSelect={() => selectField('supplier_id')}
          options={
            flattedSuppliers?.map(supplier => ({
              content: supplier.name,
              value: supplier.id,
            })) || []
          }
          defaultOptions={
            // TODO:
            response?.supplier_id
              ? [
                  {
                    content: response.supplier_value,
                    value: response.supplier_id,
                  },
                ]
              : undefined
          }
          filter={filterSuppliers}
          onFilterChange={filter => setFilterSuppliers(filter)}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            disabled,
            placeholder: 'Выбрать',
            loading:
              getHandbookSuppliersInfiniteQuery.isFetching ||
              getHandbookSuppliersInfiniteQuery.isFetchingNextPage ||
              getHandbookSuppliersInfiniteQuery.isLoading ||
              getHandbookSuppliersInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookSuppliersInfiniteQuery.fetchNextPage();
            },
          }}
        />
        <FieldWithPreview.Text
          ref={createFieldRef('object')}
          control={form.control}
          path="object"
          name="Предмет"
          edit={isFieldEditable('object')}
          onFieldSelect={() => selectField('object')}
          textInputProps={{ disabled }}
          required
        />
        <FieldWithPreview.Text
          ref={createFieldRef('number')}
          control={form.control}
          path="number"
          name="Номер"
          edit={isFieldEditable('number')}
          onFieldSelect={() => selectField('number')}
          textInputProps={{ disabled }}
          required
        />
        <FieldWithPreview.Date
          ref={createFieldRef('date')}
          control={form.control}
          path="date"
          edit={isFieldEditable('date')}
          onFieldSelect={() => selectField('date')}
          name="Дата"
          format={DATE_FORMATS.fullDate}
          formatPreview={DATE_FORMATS.fullDate}
          datePickerProps={{
            placeholder: 'Дата и время',
            disabled,
          }}
          required
        />
        <FieldWithPreview.Currency
          ref={createFieldRef('price')}
          control={form.control}
          path="price"
          edit={isFieldEditable('price')}
          onFieldSelect={() => selectField('price')}
          name="Цена"
          numberFormatProps={{
            disabled,
            min: 0,
          }}
          required
        />
        <FieldWithPreview.TextArea
          ref={createFieldRef('comment')}
          control={form.control}
          path="comment"
          edit={isFieldEditable('comment')}
          onFieldSelect={() => selectField('comment')}
          name="Комментарий"
          wrapperProps={{
            textAlign: 'start',
          }}
          textAreaProps={{
            disabled,
          }}
        />
        <FieldWithPreview.File
          control={form.control}
          path="file"
          name="Файл договора"
          edit={isFieldEditable('file')}
          ref={createFieldRef('file')}
          onFieldSelect={() => selectField('file')}
          maxSize={MAX_FILE_SIZE}
          notAllowedExtension={NOT_ALLOWED_FILE_EXTENSIONS}
          disabled={disabled}
          onLoadingChange={loading => setFileFieldsLoading(prev => ({ ...prev, file: loading }))}
          required
        />
        <FieldWithPreview.Radio
          ref={createFieldRef('deferral')}
          control={form.control}
          edit={isFieldEditable('deferral')}
          name="Отсрочка"
          options={SUPPLIER_CONTRACT_OPTIONS['deferral']}
          defaultOption={
            response?.deferral
              ? { value: response.deferral, content: response.deferral }
              : undefined
          }
          path="deferral"
          resetSelectedField={resetSelectedField}
          onFieldSelect={() => selectField('deferral')}
          radioButtonProps={{ disabled }}
          required
        />
        <FieldWithPreview.Select
          ref={createFieldRef('edo')}
          control={form.control}
          path="edo"
          name="ЭДО"
          edit={isFieldEditable('edo')}
          options={SUPPLIER_CONTRACT_OPTIONS.edo.filter(edo =>
            edo.content.includes(filterDeferrals)
          )}
          defaultOptions={
            response?.edo ? [{ content: response.edo, value: response.edo }] : undefined
          }
          filter={filterDeferrals}
          delayFilter={0}
          onFilterChange={setFilterDeferrals}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          onFieldSelect={() => selectField('edo')}
          selectProps={{
            disabled,
          }}
        />
      </FieldWithPreview>
      {!hideBottomPanel && (
        <RightSidebar.BottomPanel>
          <Button
            className={cn({ 'ml-auto': compactBottomPanel })}
            size="xl"
            view={compactBottomPanel ? 'flat' : 'normal'}
            width={'max'}
            onClick={onClickCancelButton}
            disabled={disabled || isFilesLoading}
          >
            {cancelButtonText}
          </Button>
          <Button
            size="xl"
            view="action"
            type="submit"
            width={'max'}
            onClick={form.handleSubmit(onValid, onInvalid)}
            disabled={disabled || isFilesLoading}
          >
            {submitButtonText}
          </Button>
        </RightSidebar.BottomPanel>
      )}
    </form>
  );
}
