import { useInfiniteQuery } from '@tanstack/react-query';

import { $api, fetchClient, ParametersQueryType } from '@shared/api';

import { EmployeeCheckbox } from '../model';

// export const useQueryGetHandbookEmployees = (
//   query: ParametersQueryType<'get', '/handbook/employees'>
// ) =>
//   $mock.useQuery(
//     'get',
//     '/handbook/employees',
//     { params: { query: query } },
//     { placeholderData: keepPreviousData }
//   );

export const useQueryGetHandbookEmployees = (
  query: ParametersQueryType<'get', '/handbook/employees'>,
  enabled: boolean
) =>
  useInfiniteQuery({
    queryKey: ['/handbook/employees messenger', query],
    queryFn: async ({ pageParam }) => {
      // const { data } = await mockClient.GET('/handbook/employees', {
      const { data } = await fetchClient.GET('/handbook/employees', {
        params: { query: { ...query, offset: pageParam * query.limit } },
      });

      if (!data?.list) return [];
      const employeesCheckbox: EmployeeCheckbox[] = data.list.map(emp => ({
        checked: false,
        name: emp.full_name || '',
        // image: emp.photo,
        id: emp.id || '',
      }));
      return employeesCheckbox;
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage?.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    throwOnError: false,
    enabled,
  });

export const useMutatePostStart = () => $api.useMutation('post', '/chat/start');
