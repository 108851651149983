/* eslint-disable @typescript-eslint/no-unused-vars */
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';

import { $api, $mock, bodyRequestType, fetchClient, mockClient } from '@shared/api';

const useGetRelatedCommercialOfferQuery = (id: string, enabled: boolean) =>
  $api.useQuery(
    'get',
    '/offer/related/{id}',
    { params: { path: { id: id } } },
    { enabled, refetchOnWindowFocus: false }
  );

const useGetCommercialOfferQuery = (id: string, enabled: boolean) =>
  $api.useQuery(
    'get',
    '/offer/{id}',
    { params: { path: { id: id } } },
    { enabled, refetchOnWindowFocus: false }
  );

const updateCommercialOfferMutation = () => $api.useMutation('put', '/offer/{id}');
const deleteCommercialOfferMutation = () => $api.useMutation('delete', '/offer/{id}');

const createCommercialOfferMutation = () => $api.useMutation('post', '/offer/');

function useGetAllCommericalOffersInfiniteQuery(
  body: bodyRequestType<'post', '/offer/all'>,
  enabled: boolean
) {
  return useInfiniteQuery({
    queryKey: ['/offer/all', body],
    queryFn: async ({ pageParam }) => {
      const { data } = await fetchClient.POST('/offer/all', {
        // const { data } = await mockClient.POST('/offer/all', {
        body: { ...body, offset: pageParam },
      });

      return data?.offers || [];
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage?.length === 0) {
        return undefined;
      }
      return lastPageParam + body.limit;
    },
    enabled,
  });
}

export const $commercialOfferHooks = {
  getAll: useGetAllCommericalOffersInfiniteQuery,
  getById: useGetCommercialOfferQuery,
  update: updateCommercialOfferMutation,
  delete: deleteCommercialOfferMutation,
  create: createCommercialOfferMutation,
  related: useGetRelatedCommercialOfferQuery,
};
