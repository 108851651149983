import { useToaster } from '@gravity-ui/uikit';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import { $competitorsHooks } from '@entities';
import { competitorsFormAtom, CreateHandbookDialog } from '@features';
import { bodyResponseType } from '@shared/api';
import { RightSidebar } from '@shared/ui';

import { CompetitorsLayout } from './CompetitorsLayout';

interface CreateCompetitorsProps {
  open: boolean;
  onClose: () => void;
  afterCreate?: (createdCompetitor: bodyResponseType<'post', '/handbook/competitors'>) => void;
  withDialog?: boolean;
}

export function CreateCompetitors({
  open,
  onClose,
  afterCreate,
  withDialog,
}: CreateCompetitorsProps) {
  const { add } = useToaster();

  const queryClient = useQueryClient();

  const [competitorsCreation, setCompetitorsCreation] = useAtom(competitorsFormAtom);

  // Queries
  const createCompetitorsMutation = $competitorsHooks.createMutation();

  //

  return (
    <CompetitorsLayout
      formProps={{
        defaultValues: competitorsCreation,
        onValuesChange: values => {
          setCompetitorsCreation(values);
        },
        onClickCancelButton: () => {
          setCompetitorsCreation(undefined);
          onClose();
        },
        compactBottomPanel: true,
        // disabled: underApproval,
        initialEditable: true,
        onValid: async values => {
          await createCompetitorsMutation
            .mutateAsync({
              body: values,
            })
            .then(createdCompetitor => {
              add({
                name: 'create-competitors-success',
                theme: 'success',
                content: 'Карточка Конкурента успешна создана!',
              });
              queryClient.invalidateQueries({ queryKey: ['/competitors/all'] });
              afterCreate?.(createdCompetitor);

              setCompetitorsCreation(undefined);
              onClose();
            })
            .catch(() => {
              add({
                name: 'create-competitors-error',
                theme: 'danger',
                content: 'Ошибка при создании карточки Конкурента',
              });
            });
        },
        onInvalid: errors => {
          console.log('@errors', errors);
        },
      }}
      renderForm={formComponent =>
        withDialog ? (
          <CreateHandbookDialog
            onClose={onClose}
            open={open}
            title="Создание карточки Конкурента"
          >
            {formComponent}
          </CreateHandbookDialog>
        ) : (
          <RightSidebar
            open={open}
            onClose={onClose}
          >
            <RightSidebar.Header
              title="Создание карточки Конкурента"
              onClose={onClose}
            />
            {formComponent}
          </RightSidebar>
        )
      }
    />
  );
}
