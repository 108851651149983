import { $api } from '@shared/api';

// tabs

export const useContractGetTabsQuery = () => {
  return $api.useQuery('get', '/contract/tabs');
};
export const useContractDeleteTabMutation = () => {
  return $api.useMutation('delete', '/contract/tabs/{name}');
};

export const useContractCreateTabMutation = () => {
  return $api.useMutation('post', '/contract/tabs');
};

export const useContractUpdateTabMutation = () => {
  return $api.useMutation('put', '/contract/tabs/{name}');
};
