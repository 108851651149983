import { useToaster } from '@gravity-ui/uikit';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import { $legalEntitiesHooks } from '@entities';
import { CreateHandbookDialog, legalEntitiesFormAtom } from '@features';
import { bodyResponseType } from '@shared/api';
import { RightSidebar } from '@shared/ui';

import { LegalEntitiesLayout } from './LegalEntitiesLayout';

interface CreateLegalEntitiesProps {
  open: boolean;
  onClose: () => void;
  afterCreate?: (createdLegalEntity: bodyResponseType<'post', '/handbook/legal-entities'>) => void;
  withDialog?: boolean;
}

export function CreateLegalEntities({
  open,
  onClose,
  afterCreate,
  withDialog,
}: CreateLegalEntitiesProps) {
  const { add } = useToaster();

  const queryClient = useQueryClient();

  const [legalEntitiesCreation, setLegalEntitiesCreation] = useAtom(legalEntitiesFormAtom);

  // Queries
  const createLegalEntitiesMutation = $legalEntitiesHooks.createMutation();

  //

  return (
    <LegalEntitiesLayout
      formProps={{
        defaultValues: legalEntitiesCreation,
        onValuesChange: values => {
          setLegalEntitiesCreation(values);
        },
        onClickCancelButton: () => {
          setLegalEntitiesCreation(undefined);
          onClose();
        },
        compactBottomPanel: true,
        // disabled: underApproval,
        initialEditable: true,
        onValid: async values => {
          await createLegalEntitiesMutation
            .mutateAsync({
              body: values,
            })
            .then(createdLegalEntity => {
              add({
                name: 'create-legalEntities-success',
                theme: 'success',
                content: 'Карточка Нашей Организации успешна создана!',
              });
              queryClient.invalidateQueries({ queryKey: ['/legal-entities/all'] });
              afterCreate?.(createdLegalEntity);

              setLegalEntitiesCreation(undefined);
              onClose();
            })
            .catch(() => {
              add({
                name: 'create-legalEntities-error',
                theme: 'danger',
                content: 'Ошибка при создании карточки Нашей Организации',
              });
            });
        },
        onInvalid: errors => {
          console.log('@errors', errors);
        },
      }}
      renderForm={formComponent =>
        withDialog ? (
          <CreateHandbookDialog
            onClose={onClose}
            open={open}
            title="Создание карточки Нашей Организации"
          >
            {formComponent}
          </CreateHandbookDialog>
        ) : (
          <RightSidebar
            open={open}
            onClose={onClose}
          >
            <RightSidebar.Header
              title="Создание карточки Нашей Организации"
              onClose={onClose}
            />
            {formComponent}
          </RightSidebar>
        )
      }
    />
  );
}
