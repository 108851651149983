import { EmployeeType } from '@entities';
import { HandbookOption } from '@shared/model';

interface EmployeeOptions {
  type: HandbookOption<EmployeeType>[];
}

export const EMPLOYEES_OPTIONS: EmployeeOptions = {
  type: [
    { value: 'Внешний', content: 'Внешний' },
    { value: 'Внутренний', content: 'Внутренний' },
  ],
};
