import { TenderSchema } from '@features';
import { bodyResponseType } from '@shared/api';
import { roundPercentAndCurrencyFromBackend, roundPercentAndCurrencyToBackend } from '@shared/lib';

export function percentAndCurrencyValuesToBackend(formValues: TenderSchema): TenderSchema {
  return {
    ...formValues,
    margin: roundPercentAndCurrencyToBackend(formValues.margin),
    min_margin: roundPercentAndCurrencyToBackend(formValues.min_margin),
    final_margin: roundPercentAndCurrencyToBackend(formValues.final_margin),
    decrease_percent: roundPercentAndCurrencyToBackend(formValues.decrease_percent),
    cost_price: roundPercentAndCurrencyToBackend(formValues.cost_price),
    tender_support: roundPercentAndCurrencyToBackend(formValues.tender_support),
    base_cost_price: roundPercentAndCurrencyToBackend(formValues.base_cost_price),
    specialist_min_cost_price: roundPercentAndCurrencyToBackend(
      formValues.specialist_min_cost_price
    ),
    min_cost_price: roundPercentAndCurrencyToBackend(formValues.min_cost_price),
    final_cost_price: roundPercentAndCurrencyToBackend(formValues.final_cost_price),
  };
}

export function percentAndCurrencyValuesFromBackend(formValues: TenderSchema): TenderSchema {
  return {
    ...formValues,
    margin: roundPercentAndCurrencyFromBackend(formValues.margin),
    min_margin: roundPercentAndCurrencyFromBackend(formValues.min_margin),
    final_margin: roundPercentAndCurrencyFromBackend(formValues.final_margin),
    decrease_percent: roundPercentAndCurrencyFromBackend(formValues.decrease_percent),
    cost_price: roundPercentAndCurrencyFromBackend(formValues.cost_price),
    tender_support: roundPercentAndCurrencyFromBackend(formValues.tender_support),
    base_cost_price: roundPercentAndCurrencyFromBackend(formValues.base_cost_price),
    specialist_min_cost_price: roundPercentAndCurrencyFromBackend(
      formValues.specialist_min_cost_price
    ),
    min_cost_price: roundPercentAndCurrencyFromBackend(formValues.min_cost_price),
    final_cost_price: roundPercentAndCurrencyFromBackend(formValues.final_cost_price),
  };
}

export function checkIsNotBlankRelations(
  relations: bodyResponseType<'get', '/tender/related/{id}'>
) {
  if (
    (relations?.coordinations && relations.coordinations.some(obj => Object.keys(obj).length)) ||
    (relations?.contracts && relations.contracts.some(obj => Object.keys(obj).length)) ||
    (relations.offer && Object.keys(relations.offer).length)
  )
    return true;
  return false;
}

export function countRelations(relations?: bodyResponseType<'get', '/tender/related/{id}'>) {
  if (!relations || !Object.keys(relations).length) return 0;
  let coordinations = 0;
  let contracts = 0;
  let offer = 0;
  if (relations?.coordinations) {
    for (const obj of relations.coordinations) {
      if (Object.keys(obj).length) coordinations++;
    }
  }
  if (relations?.contracts) {
    for (const obj of relations.contracts) {
      if (Object.keys(obj).length) contracts++;
    }
  }
  if (relations?.offer && Object.keys(relations.offer).length) offer++;
  return coordinations + contracts + offer;
}
