import { useToaster } from '@gravity-ui/uikit';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import { $paymentDetailsHooks } from '@entities';
import { CreateHandbookDialog, paymentDetailsFormAtom } from '@features';
import { bodyResponseType } from '@shared/api';
import { RightSidebar } from '@shared/ui';

import { PaymentDetailsLayout } from './PaymentDetailsLayout';

interface CreatePaymentDetailsProps {
  open: boolean;
  onClose: () => void;
  afterCreate?: (
    createdPaymentDetail: bodyResponseType<'post', '/handbook/payment-details'>
  ) => void;
  withDialog?: boolean;
}

export function CreatePaymentDetails({
  open,
  onClose,
  afterCreate,
  withDialog,
}: CreatePaymentDetailsProps) {
  const { add } = useToaster();

  const queryClient = useQueryClient();

  const [paymentDetailsCreation, setPaymentDetailsCreation] = useAtom(paymentDetailsFormAtom);

  // Queries
  const createPaymentDetailsMutation = $paymentDetailsHooks.createMutation();

  //

  return (
    <PaymentDetailsLayout
      formProps={{
        defaultValues: paymentDetailsCreation,
        onValuesChange: values => {
          setPaymentDetailsCreation(values);
        },
        onClickCancelButton: () => {
          setPaymentDetailsCreation(undefined);
          onClose();
        },
        compactBottomPanel: true,
        // disabled: underApproval,
        initialEditable: true,
        onValid: async values => {
          const valuesWithoutWithType: typeof values = {
            ...values,
            counterparty_with_type: undefined,
          };
          await createPaymentDetailsMutation
            .mutateAsync({
              body: valuesWithoutWithType,
            })
            .then(createdPaymentDetail => {
              add({
                name: 'create-paymentDetails-success',
                theme: 'success',
                content: 'Карточка Платежных Реквизитов успешна создана!',
              });
              queryClient.invalidateQueries({ queryKey: ['/payment-details/all'] });
              afterCreate?.(createdPaymentDetail);

              setPaymentDetailsCreation(undefined);
              onClose();
            })
            .catch(() => {
              add({
                name: 'create-paymentDetails-error',
                theme: 'danger',
                content: 'Ошибка при создании карточки Платежных Реквизитов',
              });
            });
        },
        onInvalid: errors => {
          console.log('@errors', errors);
        },
      }}
      renderForm={formComponent =>
        withDialog ? (
          <CreateHandbookDialog
            onClose={onClose}
            open={open}
            title="Создание карточки Платежных Реквизитов"
          >
            {formComponent}
          </CreateHandbookDialog>
        ) : (
          <RightSidebar
            open={open}
            onClose={onClose}
          >
            <RightSidebar.Header
              title="Создание карточки Платежных Реквизитов"
              onClose={onClose}
            />
            {formComponent}
          </RightSidebar>
        )
      }
    />
  );
}
