import { Tabs as TabsGravityUI, TabsProps } from '@gravity-ui/uikit';
import { ComponentRef, useCallback, useEffect, useRef } from 'react';

export function TabsWithScroll({ className, ...tabsProps }: TabsProps) {
  const tabsRef = useRef<ComponentRef<typeof TabsGravityUI>>(null);

  const tabsOnWheel = useCallback((e: WheelEvent) => {
    e.preventDefault();
    if (tabsRef.current) tabsRef.current.scrollLeft += e.deltaY;
  }, []);

  useEffect(() => {
    const currentTabsRef = tabsRef.current;

    if (currentTabsRef) {
      currentTabsRef.addEventListener('wheel', tabsOnWheel);

      return () => {
        if (currentTabsRef) currentTabsRef.removeEventListener('wheel', tabsOnWheel);
      };
    }
  }, [tabsOnWheel]);

  return (
    <TabsGravityUI
      ref={tabsRef}
      className={`shadow-none overflow-x-scroll flex-nowrap hide-scrollbar ${className}`}
      {...tabsProps}
    />
  );
}
