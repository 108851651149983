/* eslint-disable @typescript-eslint/no-unused-vars */
import { useInfiniteQuery } from '@tanstack/react-query';

import {
  $api,
  $mock,
  bodyRequestType,
  fetchClient,
  mockClient,
  ParametersQueryType,
} from '@shared/api';

const useGetRelatedContractQuery = (id: string, enabled: boolean) =>
  $api.useQuery(
    'get',
    '/contract/related/{id}',
    { params: { path: { id: id } } },
    { enabled, refetchOnWindowFocus: false }
  );

const useGetContractQuery = (id: string, enabled: boolean) =>
  $api.useQuery(
    'get',
    '/contract/{id}',
    { params: { path: { id: id } } },
    { enabled, refetchOnWindowFocus: false }
  );

const updateContractMutation = () => $api.useMutation('put', '/contract/{id}');
const deleteContractMutation = () => $api.useMutation('delete', '/contract/{id}');

const createContractMutation = () => $api.useMutation('post', '/contract/');

function useGetAllContractsInfiniteQuery(
  body: bodyRequestType<'post', '/contract/all'>,
  enabled: boolean
) {
  return useInfiniteQuery({
    queryKey: ['/contract/all', body],
    queryFn: async ({ pageParam }) => {
      const { data } = await fetchClient.POST('/contract/all', {
        // const { data } = await mockClient.POST('/contract/all', {
        body: { ...body, offset: pageParam },
      });

      return data?.contracts || [];
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage?.length === 0) {
        return undefined;
      }
      return lastPageParam + body.limit;
    },
    enabled,
  });
}

function useGetTendersAndOffersInfiniteQuery(
  query: ParametersQueryType<'get', '/contract/related'>,
  enabled: boolean
) {
  return useInfiniteQuery({
    queryKey: ['/contract/related', query],
    queryFn: async ({ pageParam }) => {
      const { data } = await fetchClient.GET('/contract/related', {
        // const { data } = await mockClient.GET('/contract/related', {
        params: { query: { ...query, offset: pageParam } },
      });

      return data || [];
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage?.length === 0) {
        return undefined;
      }
      return lastPageParam + query.limit;
    },
    enabled,
  });
}

export const $contractHooks = {
  getAll: useGetAllContractsInfiniteQuery,
  getById: useGetContractQuery,
  update: updateContractMutation,
  delete: deleteContractMutation,
  create: createContractMutation,
  offersAndTenders: useGetTendersAndOffersInfiniteQuery,
  related: useGetRelatedContractQuery,
};
