import { bodyResponseType } from '@shared/api';

import { CreateDepartments } from './CreateDepartments';
import { EditDepartments } from './EditDepartments';

type DepartmentsDrawerProps = {
  onClose: () => void;
  withDialog?: boolean;
} & (
  | {
      type: 'create';
      open: boolean;
      id?: undefined;
      afterCreate?: (createdDepartment: bodyResponseType<'post', '/handbook/departments'>) => void;
    }
  | {
      type: 'edit';
      open?: undefined;
      id?: string;
      afterCreate?: undefined;
    }
);

export function DepartmentsDrawer({
  type,
  open,
  onClose,
  id,
  afterCreate,
  withDialog,
}: DepartmentsDrawerProps) {
  return (
    <>
      {type === 'create' ? (
        <CreateDepartments
          open={open}
          onClose={onClose}
          afterCreate={afterCreate}
          withDialog={withDialog}
        />
      ) : (
        <EditDepartments
          onClose={onClose}
          id={id}
        />
      )}
    </>
  );
}
