import { Button, useToaster } from '@gravity-ui/uikit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import useDeepCompareEffect from 'use-deep-compare-effect';

import {
  $contactsHooks,
  $paymentDetailsHooks,
  $purchaseObjectsHooks,
  $regionsHooks,
  $supplierContractsHooks,
} from '@entities';
import { bodyResponseType } from '@shared/api';
import { MAX_FILE_SIZE, NOT_ALLOWED_FILE_EXTENSIONS } from '@shared/consts';
import { cn, useFieldsWithPreview } from '@shared/lib';
import { FieldWithPreview, RightSidebar } from '@shared/ui';

import { SUPPLIER_OPTIONS } from '../consts';
import { suppliersSchema, SuppliersSchema } from '../model';

export interface SuppliersFormProps {
  onValid: SubmitHandler<SuppliersSchema>;
  onValuesChange?: (values: SuppliersSchema) => void;
  response?: bodyResponseType<'get', '/handbook/suppliers/{id}'>;
  onInvalid?: SubmitErrorHandler<SuppliersSchema>;
  onClickCancelButton?: () => void;
  initialEditable?: boolean;
  disabled?: boolean;
  hideBottomPanel?: boolean;
  rightSidebarButtons?: boolean;
  defaultValues?: SuppliersSchema;
  cancelButtonText?: string;
  submitButtonText?: string;
  compactBottomPanel?: boolean;
}

export function SuppliersForm({
  onValid,
  onValuesChange,
  response,
  onInvalid,
  initialEditable = false,
  onClickCancelButton,
  disabled = false,
  hideBottomPanel = false,
  defaultValues,
  cancelButtonText = 'Отмена',
  submitButtonText = 'Применить',
  compactBottomPanel = false,
}: SuppliersFormProps) {
  const { add } = useToaster();

  const form = useForm<SuppliersSchema>({
    resolver: yupResolver(suppliersSchema),
    mode: 'all',
    defaultValues,
    resetOptions: {
      keepDirtyValues: true,
      keepDefaultValues: false,
    },
  });

  const { createFieldRef, isFieldEditable, selectField, selectedFieldPath, resetSelectedField } =
    useFieldsWithPreview({
      form: form,
      initialEditable,
      disabled,
    });

  const values = form.watch();

  const [filterStatuses, setFilterStatuses] = useState('');
  const [filterTypes, setFilterTypes] = useState('');
  // Queries
  const [filterRegions, setFilterRegions] = useState('');
  const getHandbookRegionsInfiniteQuery = $regionsHooks.useGetAll(
    {
      query: filterRegions,
      limit: 30,
    },
    selectedFieldPath === 'region_id'
  );
  const flattedRegions = useMemo(
    () => getHandbookRegionsInfiniteQuery.data?.pages.flat(),
    [getHandbookRegionsInfiniteQuery.data]
  );

  const [filterSupplierContracts, setFilterSupplierContracts] = useState('');
  const getHandbookSupplierContractsInfiniteQuery = $supplierContractsHooks.useGetAll(
    {
      query: filterSupplierContracts,
      limit: 30,
    },
    selectedFieldPath === 'contract_id'
  );
  const flattedSupplierContracts = useMemo(
    () => getHandbookSupplierContractsInfiniteQuery.data?.pages.flat(),
    [getHandbookSupplierContractsInfiniteQuery.data]
  );

  const [filterPurchaseObjects, setFilterPurchaseObjects] = useState('');
  const getHandbookPurchaseObjectsInfiniteQuery = $purchaseObjectsHooks.useGetAll(
    {
      query: filterPurchaseObjects,
      limit: 30,
    },
    selectedFieldPath === 'purchase_object_id'
  );
  const flattedPurchaseObjects = useMemo(
    () => getHandbookPurchaseObjectsInfiniteQuery.data?.pages.flat(),
    [getHandbookPurchaseObjectsInfiniteQuery.data]
  );

  const [filterContacts, setFilterContacts] = useState('');
  const getHandbookContactsInfiniteQuery = $contactsHooks.useGetAll(
    {
      query: filterContacts,
      limit: 30,
    },
    selectedFieldPath === 'contact_ids'
  );
  const flattedContacts = useMemo(
    () => getHandbookContactsInfiniteQuery.data?.pages.flat(),
    [getHandbookContactsInfiniteQuery.data]
  );

  const [filterPaymentDetails, setFilterPaymentDetails] = useState('');
  const getHandbookPaymentDetailsInfiniteQuery = $paymentDetailsHooks.useGetAll(
    {
      query: filterPaymentDetails,
      limit: 30,
    },
    selectedFieldPath === 'payment_detail_id'
  );
  const flattedPaymentDetails = useMemo(
    () => getHandbookPaymentDetailsInfiniteQuery.data?.pages.flat(),
    [getHandbookPaymentDetailsInfiniteQuery.data]
  );
  //

  const [fileFieldsLoading, setFileFieldsLoading] = useState<
    Record<Extract<typeof selectedFieldPath, 'files'>, boolean>
  >({ files: false });

  const isFilesLoading = Object.values(fileFieldsLoading).some(
    fileFieldLoading => fileFieldLoading
  );

  useDeepCompareEffect(() => {
    onValuesChange?.(values);
  }, [values]);

  useDeepCompareEffect(() => {
    if (response) form.reset(response);
  }, [response || {}]);

  useEffect(() => {
    if (Object.values(form.formState.errors).length > 0 && form.formState.isSubmitted)
      add({
        title: 'Не все обязательные поля заполнены',
        name: 'validation-fields-error',
        theme: 'warning',
      });
  }, [form.formState.submitCount]);

  return (
    <form
      className="flex flex-col overflow-hidden grow"
      onSubmit={form.handleSubmit(onValid, onInvalid)}
    >
      <FieldWithPreview>
        <FieldWithPreview.Text
          ref={createFieldRef('name')}
          control={form.control}
          path="name"
          name="Краткое наименование"
          edit={isFieldEditable('name')}
          onFieldSelect={() => selectField('name')}
          textInputProps={{ disabled }}
          required
        />
        <FieldWithPreview.Text
          ref={createFieldRef('full_name')}
          control={form.control}
          path="full_name"
          name="Полное наименование"
          edit={isFieldEditable('full_name')}
          onFieldSelect={() => selectField('full_name')}
          textInputProps={{ disabled }}
        />
        <FieldWithPreview.Text
          ref={createFieldRef('trademark')}
          control={form.control}
          path="trademark"
          name="Товарный знак"
          edit={isFieldEditable('trademark')}
          onFieldSelect={() => selectField('trademark')}
          textInputProps={{ disabled }}
          required
        />
        <FieldWithPreview.Text
          ref={createFieldRef('inn')}
          control={form.control}
          path="inn"
          name="ИНН"
          edit={isFieldEditable('inn')}
          onFieldSelect={() => selectField('inn')}
          textInputProps={{ disabled }}
          required
        />
        <FieldWithPreview.Text
          ref={createFieldRef('kpp')}
          control={form.control}
          path="kpp"
          name="КПП"
          edit={isFieldEditable('kpp')}
          onFieldSelect={() => selectField('kpp')}
          textInputProps={{ disabled }}
          required
        />
        <FieldWithPreview.Select
          control={form.control}
          path="region_id"
          name="Регион фактического нахождения офиса"
          edit={isFieldEditable('region_id')}
          ref={createFieldRef('region_id')}
          options={
            flattedRegions?.map(region => ({
              content: region.name,
              value: region.id,
            })) || []
          }
          defaultOptions={
            response
              ? [
                  {
                    content: response.region_value,
                    value: response.region_id,
                  },
                ]
              : undefined
          }
          filter={filterRegions}
          onFilterChange={filter => setFilterRegions(filter)}
          onFieldSelect={() => selectField('region_id')}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            disabled,
            placeholder: 'Выбрать',
            loading:
              getHandbookRegionsInfiniteQuery.isFetching ||
              getHandbookRegionsInfiniteQuery.isFetchingNextPage ||
              getHandbookRegionsInfiniteQuery.isLoading ||
              getHandbookRegionsInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookRegionsInfiniteQuery.fetchNextPage();
            },
          }}
          required
        />
        <FieldWithPreview.Select
          ref={createFieldRef('type')}
          control={form.control}
          path="type"
          name="Тип поставщика"
          edit={isFieldEditable('type')}
          options={SUPPLIER_OPTIONS.type.filter(type => type.content.includes(filterTypes))}
          defaultOptions={
            response?.type ? [{ content: response.type, value: response.type }] : undefined
          }
          filter={filterTypes}
          delayFilter={0}
          onFilterChange={filter => setFilterTypes(filter)}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          onFieldSelect={() => selectField('type')}
          selectProps={{
            disabled,
          }}
          required
        />
        <FieldWithPreview.Select
          ref={createFieldRef('status')}
          control={form.control}
          path="status"
          name="Статус поставщика"
          edit={isFieldEditable('status')}
          options={SUPPLIER_OPTIONS.status.filter(status =>
            status.content.includes(filterStatuses)
          )}
          defaultOptions={
            response?.status ? [{ content: response.status, value: response.status }] : undefined
          }
          filter={filterStatuses}
          delayFilter={0}
          onFilterChange={filter => setFilterStatuses(filter)}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          onFieldSelect={() => selectField('status')}
          selectProps={{
            disabled,
          }}
          required
        />
        <FieldWithPreview.Radio
          ref={createFieldRef('has_contract')}
          control={form.control}
          edit={isFieldEditable('has_contract')}
          name="Есть договор"
          options={SUPPLIER_OPTIONS['has_contract']}
          defaultOption={
            response?.has_contract
              ? { value: response.has_contract, content: response.has_contract }
              : undefined
          }
          path="has_contract"
          resetSelectedField={resetSelectedField}
          onFieldSelect={() => selectField('has_contract')}
          radioButtonProps={{ disabled }}
          required
        />
        <FieldWithPreview.Select
          show={values.has_contract === 'Есть'}
          control={form.control}
          path="contract_id"
          name="Договор"
          edit={isFieldEditable('contract_id')}
          ref={createFieldRef('contract_id')}
          options={
            flattedSupplierContracts?.map(supplierContract => ({
              content: supplierContract.number,
              value: supplierContract.id,
            })) || []
          }
          defaultOptions={
            response?.contract_id
              ? [
                  {
                    content: response.contract_value,
                    value: response.contract_id,
                  },
                ]
              : undefined
          }
          filter={filterSupplierContracts}
          onFilterChange={filter => setFilterSupplierContracts(filter)}
          onFieldSelect={() => selectField('contract_id')}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            disabled,
            placeholder: 'Выбрать',
            loading:
              getHandbookSupplierContractsInfiniteQuery.isFetching ||
              getHandbookSupplierContractsInfiniteQuery.isFetchingNextPage ||
              getHandbookSupplierContractsInfiniteQuery.isLoading ||
              getHandbookSupplierContractsInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookSupplierContractsInfiniteQuery.fetchNextPage();
            },
          }}
        />
        <FieldWithPreview.Select
          control={form.control}
          path="purchase_object_id"
          name="Объект поставок"
          edit={isFieldEditable('purchase_object_id')}
          ref={createFieldRef('purchase_object_id')}
          options={
            flattedPurchaseObjects?.map(purchaseObject => ({
              content: purchaseObject.object,
              value: purchaseObject.id,
            })) || []
          }
          defaultOptions={
            response
              ? [
                  {
                    content: response.purchase_object_value,
                    value: response.purchase_object_id,
                  },
                ]
              : undefined
          }
          filter={filterPurchaseObjects}
          onFilterChange={filter => setFilterPurchaseObjects(filter)}
          onFieldSelect={() => selectField('purchase_object_id')}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            disabled,
            placeholder: 'Выбрать',
            loading:
              getHandbookPurchaseObjectsInfiniteQuery.isFetching ||
              getHandbookPurchaseObjectsInfiniteQuery.isFetchingNextPage ||
              getHandbookPurchaseObjectsInfiniteQuery.isLoading ||
              getHandbookPurchaseObjectsInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookPurchaseObjectsInfiniteQuery.fetchNextPage();
            },
          }}
          required
        />
        <FieldWithPreview.Text
          ref={createFieldRef('website')}
          control={form.control}
          path="website"
          name="Сайт"
          edit={isFieldEditable('website')}
          onFieldSelect={() => selectField('website')}
          textInputProps={{ disabled }}
          required
          isLink
        />
        <FieldWithPreview.Tags
          ref={createFieldRef('tags')}
          control={form.control}
          path="tags"
          name="Теги (краткое описание)"
          edit={isFieldEditable('tags')}
          onFieldSelect={() => selectField('tags')}
          textInputProps={{ disabled }}
        />
        <FieldWithPreview.MultiFile
          control={form.control}
          path="files"
          name="Файлы: прайсы, каталоги, презентации, прочее"
          edit={isFieldEditable('files')}
          onFieldSelect={() => selectField('files')}
          ref={createFieldRef('files')}
          maxSize={MAX_FILE_SIZE}
          notAllowedExtension={NOT_ALLOWED_FILE_EXTENSIONS}
          onLoadingChange={loading => setFileFieldsLoading(prev => ({ ...prev, files: loading }))}
          disabled={disabled}
          required
        />
        <FieldWithPreview.MultiSelect
          ref={createFieldRef('contact_ids')}
          control={form.control}
          path="contact_ids"
          edit={isFieldEditable('contact_ids')}
          onFieldSelect={() => selectField('contact_ids')}
          name="Контакты"
          options={
            flattedContacts?.map(contact => ({
              content: contact?.full_name,
              value: contact.id,
            })) || []
          }
          defaultOptions={response?.contact_ids?.map((contact, i) => ({
            content: response?.contact_values?.[i],
            value: contact,
          }))}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          filter={filterContacts}
          onFilterChange={filter => setFilterContacts(filter)}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
            loading:
              getHandbookContactsInfiniteQuery.isFetching ||
              getHandbookContactsInfiniteQuery.isFetchingNextPage ||
              getHandbookContactsInfiniteQuery.isLoading ||
              getHandbookContactsInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookContactsInfiniteQuery.fetchNextPage();
            },
          }}
          required
        />
        <FieldWithPreview.Tags
          ref={createFieldRef('addresses')}
          control={form.control}
          path="addresses"
          name="Адреса"
          edit={isFieldEditable('addresses')}
          onFieldSelect={() => selectField('addresses')}
          textInputProps={{ disabled }}
        />
        <FieldWithPreview.Select
          ref={createFieldRef('payment_detail_id')}
          control={form.control}
          path="payment_detail_id"
          edit={isFieldEditable('payment_detail_id')}
          onFieldSelect={() => selectField('payment_detail_id')}
          name="Платежные реквизиты"
          options={
            flattedPaymentDetails?.map(paymentDetail => ({
              content: paymentDetail?.bank_name,
              value: paymentDetail.id,
            })) || []
          }
          defaultOptions={
            response?.payment_detail_id
              ? [
                  {
                    content: response.payment_detail_value,
                    value: response.payment_detail_id,
                  },
                ]
              : undefined
          }
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          required
          filter={filterPaymentDetails}
          onFilterChange={filter => setFilterPaymentDetails(filter)}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
            loading:
              getHandbookPaymentDetailsInfiniteQuery.isFetching ||
              getHandbookPaymentDetailsInfiniteQuery.isFetchingNextPage ||
              getHandbookPaymentDetailsInfiniteQuery.isLoading ||
              getHandbookPaymentDetailsInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookPaymentDetailsInfiniteQuery.fetchNextPage();
            },
          }}
        />
      </FieldWithPreview>
      {!hideBottomPanel && (
        <RightSidebar.BottomPanel>
          <Button
            className={cn({ 'ml-auto': compactBottomPanel })}
            size="xl"
            view={compactBottomPanel ? 'flat' : 'normal'}
            width={'max'}
            onClick={onClickCancelButton}
            disabled={disabled || isFilesLoading}
          >
            {cancelButtonText}
          </Button>
          <Button
            size="xl"
            view="action"
            type="submit"
            width={'max'}
            onClick={form.handleSubmit(onValid, onInvalid)}
            disabled={disabled || isFilesLoading}
          >
            {submitButtonText}
          </Button>
        </RightSidebar.BottomPanel>
      )}
    </form>
  );
}
