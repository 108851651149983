import { useCallback, useState } from 'react';

import { CRMTableSettings } from '@features/table/model';

import { SortType } from '../../ui/TableHoverColumnWrapper';

export const useTableColumnSort = <Keys extends string[]>(
  tableSettings: CRMTableSettings<Keys>
) => {
  const [sortOrder, setSortOrder] = useState<SortType>(() => '');
  const [sortColumn, setSortColumn] = useState<Keys[number] | ''>('');

  const changeSortOrder = useCallback(
    (newSort: SortType, newSortColumn: Keys[number] | '') => {
      if (tableSettings.some(settings => settings.id === newSortColumn && settings.isSelected)) {
        if (sortColumn === newSortColumn) {
          setSortOrder(newSort);
          return;
        }
        if (newSort !== sortOrder) setSortOrder(newSort);
        setSortColumn(newSortColumn);
      } else {
        setSortOrder('');
        setSortColumn('');
      }
    },
    [sortOrder, sortColumn, tableSettings]
  );

  return {
    sortOrder,
    sortColumn,
    changeSortOrder,
  };
};
