import { useToaster } from '@gravity-ui/uikit';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import { $departmentsHooks } from '@entities';
import { CreateHandbookDialog, departmentsFormAtom } from '@features';
import { bodyResponseType } from '@shared/api';
import { RightSidebar } from '@shared/ui';

import { DepartmentsLayout } from './DepartmentsLayout';

interface CreateDepartmentsProps {
  open: boolean;
  onClose: () => void;
  afterCreate?: (createdDepartment: bodyResponseType<'post', '/handbook/departments'>) => void;
  withDialog?: boolean;
}

export function CreateDepartments({
  open,
  onClose,
  afterCreate,
  withDialog,
}: CreateDepartmentsProps) {
  const { add } = useToaster();

  const queryClient = useQueryClient();

  const [departmentsCreation, setDepartmentsCreation] = useAtom(departmentsFormAtom);

  // Queries
  const createDepartmentsMutation = $departmentsHooks.createMutation();

  //

  return (
    <DepartmentsLayout
      formProps={{
        defaultValues: departmentsCreation,
        onValuesChange: values => {
          setDepartmentsCreation(values);
        },
        onClickCancelButton: () => {
          setDepartmentsCreation(undefined);
          onClose();
        },
        compactBottomPanel: true,
        // disabled: underApproval,
        initialEditable: true,
        onValid: async values => {
          await createDepartmentsMutation
            .mutateAsync({
              body: values,
            })
            .then(createdDepartment => {
              add({
                name: 'create-departments-success',
                theme: 'success',
                content: 'Карточка Подразделения успешна создана!',
              });
              queryClient.invalidateQueries({ queryKey: ['/departments/all'] });
              afterCreate?.(createdDepartment);

              setDepartmentsCreation(undefined);
              onClose();
            })
            .catch(() => {
              add({
                name: 'create-departments-error',
                theme: 'danger',
                content: 'Ошибка при создании карточки Подразделения',
              });
            });
        },
        onInvalid: errors => {
          console.log('@errors', errors);
        },
      }}
      renderForm={formComponent =>
        withDialog ? (
          <CreateHandbookDialog
            onClose={onClose}
            open={open}
            title="Создание карточки Подразделения"
          >
            {formComponent}
          </CreateHandbookDialog>
        ) : (
          <RightSidebar
            open={open}
            onClose={onClose}
          >
            <RightSidebar.Header
              title="Создание карточки Подразделения"
              onClose={onClose}
            />
            {formComponent}
          </RightSidebar>
        )
      }
    />
  );
}
