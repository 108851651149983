import { Button, useToaster } from '@gravity-ui/uikit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { bodyResponseType } from '@shared/api';
import { MAX_FILE_SIZE, NOT_ALLOWED_FILE_EXTENSIONS } from '@shared/consts';
import { cn, useFieldsWithPreview } from '@shared/lib';
import { FieldWithPreview, RightSidebar } from '@shared/ui';

import { KNOWLEDGE_BASE_OPTIONS } from '../consts';
import { knowledgeBasesSchema, KnowledgeBasesSchema } from '../model';

export interface KnowledgeBasesFormProps {
  onValid: SubmitHandler<KnowledgeBasesSchema>;
  onValuesChange?: (values: KnowledgeBasesSchema) => void;
  response?: bodyResponseType<'get', '/handbook/knowledge-bases/{id}'>;
  onInvalid?: SubmitErrorHandler<KnowledgeBasesSchema>;
  onClickCancelButton?: () => void;
  initialEditable?: boolean;
  disabled?: boolean;
  hideBottomPanel?: boolean;
  rightSidebarButtons?: boolean;
  defaultValues?: KnowledgeBasesSchema;
  cancelButtonText?: string;
  submitButtonText?: string;
  compactBottomPanel?: boolean;
}

export function KnowledgeBasesForm({
  onValid,
  onValuesChange,
  response,
  onInvalid,
  initialEditable = false,
  onClickCancelButton,
  disabled = false,
  hideBottomPanel = false,
  defaultValues,
  cancelButtonText = 'Отмена',
  submitButtonText = 'Применить',
  compactBottomPanel = false,
}: KnowledgeBasesFormProps) {
  const { add } = useToaster();

  const form = useForm<KnowledgeBasesSchema>({
    resolver: yupResolver(knowledgeBasesSchema),
    mode: 'all',
    defaultValues,
    resetOptions: {
      keepDirtyValues: true,
      keepDefaultValues: false,
    },
  });

  const { createFieldRef, isFieldEditable, selectField, selectedFieldPath, resetSelectedField } =
    useFieldsWithPreview({
      form: form,
      initialEditable,
      disabled,
    });

  const values = form.watch();

  const [filterTypes, setFilterTypes] = useState('');

  const [fileFieldsLoading, setFileFieldsLoading] = useState<
    Record<Extract<typeof selectedFieldPath, 'documents'>, boolean>
  >({ documents: false });

  const isFilesLoading = Object.values(fileFieldsLoading).some(
    fileFieldLoading => fileFieldLoading
  );

  useDeepCompareEffect(() => {
    onValuesChange?.(values);
  }, [values]);

  useDeepCompareEffect(() => {
    if (response) form.reset(response);
  }, [response || {}]);

  useEffect(() => {
    if (Object.values(form.formState.errors).length > 0 && form.formState.isSubmitted)
      add({
        title: 'Не все обязательные поля заполнены',
        name: 'validation-fields-error',
        theme: 'warning',
      });
  }, [form.formState.submitCount]);

  return (
    <form
      className="flex flex-col overflow-hidden grow"
      onSubmit={form.handleSubmit(onValid, onInvalid)}
    >
      <FieldWithPreview>
        <FieldWithPreview.Select
          ref={createFieldRef('type')}
          control={form.control}
          path="type"
          edit={isFieldEditable('type')}
          onFieldSelect={() => selectField('type')}
          name="Вид"
          options={KNOWLEDGE_BASE_OPTIONS['type']}
          defaultOptions={
            response?.type ? [{ content: response.type, value: response.type }] : undefined
          }
          filter={filterTypes}
          delayFilter={0}
          onFilterChange={setFilterTypes}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
          }}
        />
        <FieldWithPreview.Radio
          ref={createFieldRef('status')}
          control={form.control}
          edit={isFieldEditable('status')}
          name="Статус"
          options={KNOWLEDGE_BASE_OPTIONS['status']}
          defaultOption={
            response?.status ? { value: response.status, content: response.status } : undefined
          }
          path="status"
          resetSelectedField={resetSelectedField}
          onFieldSelect={() => selectField('status')}
          radioButtonProps={{ disabled }}
        />
        <FieldWithPreview.Text
          ref={createFieldRef('document_name')}
          control={form.control}
          path="document_name"
          name="Название документа"
          edit={isFieldEditable('document_name')}
          onFieldSelect={() => selectField('document_name')}
          textInputProps={{ disabled }}
          required
        />
        <FieldWithPreview.Text
          ref={createFieldRef('source')}
          control={form.control}
          path="source"
          name="Ссылка на источник"
          edit={isFieldEditable('source')}
          onFieldSelect={() => selectField('source')}
          textInputProps={{ disabled }}
          isLink
        />
        <FieldWithPreview.TextArea
          ref={createFieldRef('description')}
          control={form.control}
          path="description"
          name="Краткое описание"
          edit={isFieldEditable('description')}
          onFieldSelect={() => selectField('description')}
          wrapperProps={{ textAlign: 'start' }}
          textAreaProps={{ disabled }}
        />
        <FieldWithPreview.Tags
          ref={createFieldRef('tags')}
          control={form.control}
          path="tags"
          name="Теги"
          edit={isFieldEditable('tags')}
          onFieldSelect={() => selectField('tags')}
          textInputProps={{ disabled }}
        />
        <FieldWithPreview.Radio
          ref={createFieldRef('related_process')}
          control={form.control}
          edit={isFieldEditable('related_process')}
          name="Связанный процесс"
          options={KNOWLEDGE_BASE_OPTIONS['related_process']}
          defaultOption={
            response?.related_process
              ? { value: response.related_process, content: response.related_process }
              : undefined
          }
          path="related_process"
          resetSelectedField={resetSelectedField}
          onFieldSelect={() => selectField('related_process')}
          radioButtonProps={{ disabled }}
        />
        <FieldWithPreview.MultiFile
          control={form.control}
          path="documents"
          name="Документы"
          edit={isFieldEditable('documents')}
          onFieldSelect={() => selectField('documents')}
          ref={createFieldRef('documents')}
          maxSize={MAX_FILE_SIZE}
          notAllowedExtension={NOT_ALLOWED_FILE_EXTENSIONS}
          onLoadingChange={loading =>
            setFileFieldsLoading(prev => ({ ...prev, documents: loading }))
          }
          disabled={disabled}
          required
        />
      </FieldWithPreview>
      {!hideBottomPanel && (
        <RightSidebar.BottomPanel>
          <Button
            className={cn({ 'ml-auto': compactBottomPanel })}
            size="xl"
            view={compactBottomPanel ? 'flat' : 'normal'}
            width={'max'}
            onClick={onClickCancelButton}
            disabled={disabled || isFilesLoading}
          >
            {cancelButtonText}
          </Button>
          <Button
            size="xl"
            view="action"
            type="submit"
            width={'max'}
            onClick={form.handleSubmit(onValid, onInvalid)}
            disabled={disabled || isFilesLoading}
          >
            {submitButtonText}
          </Button>
        </RightSidebar.BottomPanel>
      )}
    </form>
  );
}
