import { Text } from '@gravity-ui/uikit';

export interface HistoryItemProps {
  title: string;
  description: string;
  additional: string;
  variant?: 'default' | 'danger';
}

export function HistoryItem({
  additional,
  description,
  title,
  variant = 'default',
}: HistoryItemProps) {
  return (
    <div className="flex flex-col gap-1">
      <div className="flex items-center justify-between gap-1">
        <Text
          color={variant === 'danger' ? 'complementary' : 'danger'}
          variant="subheader-1"
          className="truncate"
        >
          {title}
        </Text>
        <Text
          color={variant === 'danger' ? 'secondary' : 'danger'}
          variant="caption-2"
          className="shrink-0"
        >
          {additional}
        </Text>
      </div>
      <Text
        color="complementary"
        variant="body-1"
      >
        {description}
      </Text>
    </div>
  );
}
