import { bodyResponseType } from '@shared/api';

import { CreateRestrictionAdvantageRequirements } from './CreateRestrictionAdvantageRequirements';
import { EditRestrictionAdvantageRequirements } from './EditRestrictionAdvantageRequirements';

type RestrictionAdvantageRequirementsDrawerProps = {
  onClose: () => void;
  withDialog?: boolean;
} & (
  | {
      type: 'create';
      open: boolean;
      id?: undefined;
      afterCreate?: (
        createdRestrictionAdvantageRequirement: bodyResponseType<
          'post',
          '/handbook/restriction-advantage-requirements'
        >
      ) => void;
    }
  | {
      type: 'edit';
      open?: undefined;
      id?: string;
      afterCreate?: undefined;
    }
);

export function RestrictionAdvantageRequirementsDrawer({
  type,
  open,
  onClose,
  id,
  afterCreate,
  withDialog,
}: RestrictionAdvantageRequirementsDrawerProps) {
  return (
    <>
      {type === 'create' ? (
        <CreateRestrictionAdvantageRequirements
          open={open}
          onClose={onClose}
          afterCreate={afterCreate}
          withDialog={withDialog}
        />
      ) : (
        <EditRestrictionAdvantageRequirements
          onClose={onClose}
          id={id}
        />
      )}
    </>
  );
}
