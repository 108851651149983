import { Button, useToaster } from '@gravity-ui/uikit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import useDeepCompareEffect from 'use-deep-compare-effect';

import {
  $contactsHooks,
  $paymentDetailsHooks,
  $purchaseObjectsHooks,
  $regionsHooks,
} from '@entities';
import { bodyResponseType } from '@shared/api';
import { MAX_FILE_SIZE, NOT_ALLOWED_FILE_EXTENSIONS } from '@shared/consts';
import { cn, useFieldsWithPreview } from '@shared/lib';
import { FieldWithPreview, RightSidebar } from '@shared/ui';

import { legalEntitiesSchema, LegalEntitiesSchema } from '../model';

export interface LegalEntitiesFormProps {
  onValid: SubmitHandler<LegalEntitiesSchema>;
  onValuesChange?: (values: LegalEntitiesSchema) => void;
  response?: bodyResponseType<'get', '/handbook/legal-entities/{id}'>;
  onInvalid?: SubmitErrorHandler<LegalEntitiesSchema>;
  onClickCancelButton?: () => void;
  initialEditable?: boolean;
  disabled?: boolean;
  hideBottomPanel?: boolean;
  rightSidebarButtons?: boolean;
  defaultValues?: LegalEntitiesSchema;
  cancelButtonText?: string;
  submitButtonText?: string;
  compactBottomPanel?: boolean;
}

export function LegalEntitiesForm({
  onValid,
  onValuesChange,
  response,
  onInvalid,
  initialEditable = false,
  onClickCancelButton,
  disabled = false,
  hideBottomPanel = false,
  defaultValues,
  cancelButtonText = 'Отмена',
  submitButtonText = 'Применить',
  compactBottomPanel = false,
}: LegalEntitiesFormProps) {
  const { add } = useToaster();

  const form = useForm<LegalEntitiesSchema>({
    resolver: yupResolver(legalEntitiesSchema),
    mode: 'all',
    defaultValues,
    resetOptions: {
      keepDirtyValues: true,
      keepDefaultValues: false,
    },
  });

  const { createFieldRef, isFieldEditable, selectField, selectedFieldPath, resetSelectedField } =
    useFieldsWithPreview({
      form: form,
      initialEditable,
      disabled,
    });

  const values = form.watch();

  // Queries
  const [filterRegions, setFilterRegions] = useState('');
  const getHandbookRegionsInfiniteQuery = $regionsHooks.useGetAll(
    {
      query: filterRegions,
      limit: 30,
    },
    selectedFieldPath === 'region_id'
  );
  const flattedRegions = useMemo(
    () => getHandbookRegionsInfiniteQuery.data?.pages.flat(),
    [getHandbookRegionsInfiniteQuery.data]
  );

  const [filterPurchaseObjects, setFilterPurchaseObjects] = useState('');
  const getHandbookPurchaseObjectsInfiniteQuery = $purchaseObjectsHooks.useGetAll(
    {
      query: filterPurchaseObjects,
      limit: 30,
    },
    selectedFieldPath === 'purchase_object_id'
  );
  const flattedPurchaseObjects = useMemo(
    () => getHandbookPurchaseObjectsInfiniteQuery.data?.pages.flat(),
    [getHandbookPurchaseObjectsInfiniteQuery.data]
  );

  const [filterContacts, setFilterContacts] = useState('');
  const getHandbookContactsInfiniteQuery = $contactsHooks.useGetAll(
    {
      query: filterContacts,
      limit: 30,
    },
    selectedFieldPath === 'contact_ids'
  );
  const flattedContacts = useMemo(
    () => getHandbookContactsInfiniteQuery.data?.pages.flat(),
    [getHandbookContactsInfiniteQuery.data]
  );

  const [filterPaymentDetails, setFilterPaymentDetails] = useState('');
  const getHandbookPaymentDetailsInfiniteQuery = $paymentDetailsHooks.useGetAll(
    {
      query: filterPaymentDetails,
      limit: 30,
    },
    selectedFieldPath === 'payment_detail_id'
  );
  const flattedPaymentDetails = useMemo(
    () => getHandbookPaymentDetailsInfiniteQuery.data?.pages.flat(),
    [getHandbookPaymentDetailsInfiniteQuery.data]
  );
  //

  const [fileFieldsLoading, setFileFieldsLoading] = useState<
    Record<Extract<typeof selectedFieldPath, 'files'>, boolean>
  >({ files: false });

  const isFilesLoading = Object.values(fileFieldsLoading).some(
    fileFieldLoading => fileFieldLoading
  );

  useDeepCompareEffect(() => {
    onValuesChange?.(values);
  }, [values]);

  useDeepCompareEffect(() => {
    if (response) form.reset(response);
  }, [response || {}]);

  useEffect(() => {
    if (Object.values(form.formState.errors).length > 0 && form.formState.isSubmitted)
      add({
        title: 'Не все обязательные поля заполнены',
        name: 'validation-fields-error',
        theme: 'warning',
      });
  }, [form.formState.submitCount]);

  return (
    <form
      className="flex flex-col overflow-hidden grow"
      onSubmit={form.handleSubmit(onValid, onInvalid)}
    >
      <FieldWithPreview>
        <FieldWithPreview.Text
          ref={createFieldRef('name')}
          control={form.control}
          path="name"
          name="Наименование"
          edit={isFieldEditable('name')}
          onFieldSelect={() => selectField('name')}
          textInputProps={{ disabled }}
          required
        />
        <FieldWithPreview.Text
          ref={createFieldRef('inn')}
          control={form.control}
          path="inn"
          name="ИНН"
          edit={isFieldEditable('inn')}
          onFieldSelect={() => selectField('inn')}
          textInputProps={{ disabled }}
          required
        />
        <FieldWithPreview.Text
          ref={createFieldRef('kpp')}
          control={form.control}
          path="kpp"
          name="КПП"
          edit={isFieldEditable('kpp')}
          onFieldSelect={() => selectField('kpp')}
          textInputProps={{ disabled }}
        />
        <FieldWithPreview.Select
          control={form.control}
          path="region_id"
          name="Регион"
          edit={isFieldEditable('region_id')}
          ref={createFieldRef('region_id')}
          options={
            flattedRegions?.map(region => ({
              content: region.name,
              value: region.id,
            })) || []
          }
          defaultOptions={
            response
              ? [
                  {
                    content: response.region_value,
                    value: response.region_id,
                  },
                ]
              : undefined
          }
          filter={filterRegions}
          onFilterChange={filter => setFilterRegions(filter)}
          onFieldSelect={() => selectField('region_id')}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            disabled,
            placeholder: 'Выбрать',
            loading:
              getHandbookRegionsInfiniteQuery.isFetching ||
              getHandbookRegionsInfiniteQuery.isFetchingNextPage ||
              getHandbookRegionsInfiniteQuery.isLoading ||
              getHandbookRegionsInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookRegionsInfiniteQuery.fetchNextPage();
            },
          }}
        />
        <FieldWithPreview.Select
          control={form.control}
          path="purchase_object_id"
          name="Объект поставок"
          edit={isFieldEditable('purchase_object_id')}
          ref={createFieldRef('purchase_object_id')}
          options={
            flattedPurchaseObjects?.map(purchaseObject => ({
              content: purchaseObject.object,
              value: purchaseObject.id,
            })) || []
          }
          defaultOptions={
            response
              ? [
                  {
                    content: response.purchase_object_value,
                    value: response.purchase_object_id,
                  },
                ]
              : undefined
          }
          filter={filterPurchaseObjects}
          onFilterChange={filter => setFilterPurchaseObjects(filter)}
          onFieldSelect={() => selectField('purchase_object_id')}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            disabled,
            placeholder: 'Выбрать',
            loading:
              getHandbookPurchaseObjectsInfiniteQuery.isFetching ||
              getHandbookPurchaseObjectsInfiniteQuery.isFetchingNextPage ||
              getHandbookPurchaseObjectsInfiniteQuery.isLoading ||
              getHandbookPurchaseObjectsInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookPurchaseObjectsInfiniteQuery.fetchNextPage();
            },
          }}
        />
        <FieldWithPreview.Text
          ref={createFieldRef('website')}
          control={form.control}
          path="website"
          name="Сайт"
          edit={isFieldEditable('website')}
          onFieldSelect={() => selectField('website')}
          textInputProps={{ disabled }}
          isLink
        />
        <FieldWithPreview.Tags
          ref={createFieldRef('tags')}
          control={form.control}
          path="tags"
          name="Теги (краткое описание)"
          edit={isFieldEditable('tags')}
          onFieldSelect={() => selectField('tags')}
          textInputProps={{ disabled }}
        />
        <FieldWithPreview.MultiFile
          control={form.control}
          path="files"
          name="Файлы: прайсы, каталоги, презентации, прочее"
          edit={isFieldEditable('files')}
          onFieldSelect={() => selectField('files')}
          ref={createFieldRef('files')}
          maxSize={MAX_FILE_SIZE}
          notAllowedExtension={NOT_ALLOWED_FILE_EXTENSIONS}
          onLoadingChange={loading => setFileFieldsLoading(prev => ({ ...prev, files: loading }))}
          disabled={disabled}
          required
        />
        <FieldWithPreview.MultiSelect
          ref={createFieldRef('contact_ids')}
          control={form.control}
          path="contact_ids"
          edit={isFieldEditable('contact_ids')}
          onFieldSelect={() => selectField('contact_ids')}
          name="Контакты"
          options={
            flattedContacts?.map(contact => ({
              content: contact?.full_name,
              value: contact.id,
            })) || []
          }
          defaultOptions={response?.contact_ids?.map((contact, i) => ({
            content: response?.contact_values?.[i],
            value: contact,
          }))}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          filter={filterContacts}
          onFilterChange={filter => setFilterContacts(filter)}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
            loading:
              getHandbookContactsInfiniteQuery.isFetching ||
              getHandbookContactsInfiniteQuery.isFetchingNextPage ||
              getHandbookContactsInfiniteQuery.isLoading ||
              getHandbookContactsInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookContactsInfiniteQuery.fetchNextPage();
            },
          }}
        />
        <FieldWithPreview.Tags
          ref={createFieldRef('addresses')}
          control={form.control}
          path="addresses"
          name="Адреса"
          edit={isFieldEditable('addresses')}
          onFieldSelect={() => selectField('addresses')}
          textInputProps={{ disabled }}
        />
        <FieldWithPreview.Select
          ref={createFieldRef('payment_detail_id')}
          control={form.control}
          path="payment_detail_id"
          edit={isFieldEditable('payment_detail_id')}
          onFieldSelect={() => selectField('payment_detail_id')}
          name="Платежные реквизиты"
          options={
            flattedPaymentDetails?.map(paymentDetail => ({
              content: paymentDetail?.bank_name,
              value: paymentDetail.id,
            })) || []
          }
          defaultOptions={
            response?.payment_detail_id
              ? [
                  {
                    content: response.payment_detail_value,
                    value: response.payment_detail_id,
                  },
                ]
              : undefined
          }
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          required
          filter={filterPaymentDetails}
          onFilterChange={filter => setFilterPaymentDetails(filter)}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
            loading:
              getHandbookPaymentDetailsInfiniteQuery.isFetching ||
              getHandbookPaymentDetailsInfiniteQuery.isFetchingNextPage ||
              getHandbookPaymentDetailsInfiniteQuery.isLoading ||
              getHandbookPaymentDetailsInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookPaymentDetailsInfiniteQuery.fetchNextPage();
            },
          }}
        />
      </FieldWithPreview>
      {!hideBottomPanel && (
        <RightSidebar.BottomPanel>
          <Button
            className={cn({ 'ml-auto': compactBottomPanel })}
            size="xl"
            view={compactBottomPanel ? 'flat' : 'normal'}
            width={'max'}
            onClick={onClickCancelButton}
            disabled={disabled || isFilesLoading}
          >
            {cancelButtonText}
          </Button>
          <Button
            size="xl"
            view="action"
            type="submit"
            width={'max'}
            onClick={form.handleSubmit(onValid, onInvalid)}
            disabled={disabled || isFilesLoading}
          >
            {submitButtonText}
          </Button>
        </RightSidebar.BottomPanel>
      )}
    </form>
  );
}
