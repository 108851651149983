import { ColumnsFilters } from '@features';

import { ColumnsAgreementsTable } from '../model';

export const TABLE_COLUMNS_WITH_FILTER: Record<ColumnsAgreementsTable[number], ColumnsFilters> = {
  'Дата, номер': 'dateWithTime',
  Наименование: 'search',
  'Тендер/КП': 'select',
  Заказчик: 'select',
  'Запрашивающий согласование': 'select',
  Согласовант: 'select',
  Себестоимость: 'currency',
  'Предлагаемая специалистом цена': 'currency',
  'Маржа предлагаемая': 'number',
  'Обоснование предложения цены': 'search',
  'Согласны с предложением специалиста?': 'select',
  'Маржа плановая': 'number',
  'Минимальная цена/цена КП согласования': 'currency',
  'Причина отказа': 'select',
  'Комментарий причины отказа': 'search',
  'Файл расчета': 'boolean',
  'Файл КП': 'boolean',
  'Файл Заявки': 'boolean',
  'Результат согласования': 'select',
  Комментарий: 'search',
  'Тип согласования': 'select',
};
