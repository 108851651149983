import { atom } from 'jotai';

import { DEFAULT_FILTERS } from '../../constants';

export const chatFiltersAtom = atom(DEFAULT_FILTERS);
export const resetFiltersAtom = atom(null, (_, set) => {
  set(chatFiltersAtom, DEFAULT_FILTERS);
});

export const isChatFiltersDirtyAtom = atom(get => {
  const chatFilters = get(chatFiltersAtom);

  return Object.values(chatFilters).some(filter => filter !== null);
});
