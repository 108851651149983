import { EmployeeType } from '@entities';
import { yupRu } from '@shared/config';
import { schemaFields } from '@shared/lib';

export const employeesSchema = yupRu.object({
  /** @description Ф.И.О. @gotags: validate:"required" */
  name_initials: schemaFields.text().required(),
  /** @description ФИО полностью @gotags: validate:"required" */
  full_name: schemaFields.text().required(),
  /**
   * @description Тип сотрудника (например, постоянный, временный и т.д.) @gotags: validate:"required|min_len:3|max_len:255" enums:"Внутренний,Внешний"
   * @enum {string}
   */
  type: schemaFields.radio<EmployeeType>(['Внешний', 'Внутренний']),
  /** @description Фотография сотрудника URL @gotags: validate:"min_len:3|max_len:255" */
  photo: schemaFields.file(),
  /** @description Должность сотрудника @gotags: validate:"required|min_len:3|max_len:255" */
  position: schemaFields.text(),
  /** @description Подразделение, в котором работает сотрудник @gotags: swaggertype:"string" */
  department_id: schemaFields.id(),
  /** @description Разряд @gotags: validate:"min_len:3|max_len:255" */
  rank: schemaFields.text(),
  /** @description Доступ  */
  blocked_at: yupRu.string().transform(v => v || undefined),
  /** @description Мобильный телефон сотрудника */
  mobile_phone: schemaFields.phone(),
  /** @description Рабочий телефон сотрудника */
  work_phone: schemaFields.phone(),
  /** @description Электронная почта сотрудника @gotags: validate:"email" */
  email: schemaFields.email().required(),
  /** @description Примечание или дополнительная информация о сотруднике @gotags: validate:"min_len:3|max_len:255" */
  note: schemaFields.textArea(),
  /** @description Количество прямых подчиненных */
  direct_subordinate_ids: schemaFields.multiSelect(),
  /** @description Количество косвенных подчиненных */
  indirect_subordinate_ids: schemaFields.multiSelect(),
});

export type EmployeesSchema = yupRu.InferType<typeof employeesSchema>;
