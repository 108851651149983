import { ReactNode } from 'react';

import { CustomersForm, CustomersFormProps } from '@features';

interface CustomersLayoutProps {
  renderForm: (formComponent: ReactNode) => ReactNode;
  formProps: CustomersFormProps;
}

export function CustomersLayout({ renderForm, formProps }: CustomersLayoutProps) {
  return <>{renderForm(<CustomersForm {...formProps} />)}</>;
}
