import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { CalendarDrawer, ContractDrawer, ContractsTable } from '@widgets';
import { URLS } from '@shared/consts';
import { useNavigateTo } from '@shared/lib';
import { HeroWithButton } from '@shared/ui';

export function Contracts() {
  const { navigateTo } = useNavigateTo();

  const contractId = useParams()[URLS.processes.contractsDynamicParams.id];

  const [createContractOpen, setCreateContractOpen] = useState(false);

  const [calendarDrawerOpen, setCalendarDrawerOpen] = useState(false);

  const [selectedOfferIndex, setSelectedOfferIndex] = useState<number | null>(null);

  return (
    <>
      <HeroWithButton
        title="Контракты"
        buttonOnClick={() => setCreateContractOpen(true)}
      />
      <ContractsTable
        selectedOfferIndex={selectedOfferIndex}
        onRowClick={(id, index) => {
          navigateTo(URLS.processes.contracts + '/' + id);
          setSelectedOfferIndex(index);
        }}
        calendarButtonAction={() => {
          setCalendarDrawerOpen(true);
        }}
      />
      <ContractDrawer
        id={contractId}
        type="edit"
        onClose={() => navigateTo(URLS.processes.contracts)}
      />
      <ContractDrawer
        type="create"
        open={createContractOpen}
        onClose={() => setCreateContractOpen(false)}
      />
      <CalendarDrawer
        open={calendarDrawerOpen}
        onClose={() => setCalendarDrawerOpen(false)}
        entityType="Контракты"
      />
    </>
  );
}
