import { ReactNode } from 'react';

import { LegalEntitiesForm, LegalEntitiesFormProps } from '@features';

interface LegalEntitiesLayoutProps {
  renderForm: (formComponent: ReactNode) => ReactNode;
  formProps: LegalEntitiesFormProps;
}

export function LegalEntitiesLayout({ renderForm, formProps }: LegalEntitiesLayoutProps) {
  return <>{renderForm(<LegalEntitiesForm {...formProps} />)}</>;
}
