import { EditAgreement } from './EditAgreement';

type AgreementDrawerProps = {
  onClose: () => void;
  id?: string;
};

export function AgreementDrawer({ onClose, id }: AgreementDrawerProps) {
  return (
    <EditAgreement
      onClose={onClose}
      id={id}
    />
  );
}
