import { Button, useToaster } from '@gravity-ui/uikit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { bodyResponseType } from '@shared/api';
import { cn, useFieldsWithPreview } from '@shared/lib';
import { FieldWithPreview, RightSidebar } from '@shared/ui';

import { regionsSchema, RegionsSchema } from '../model';

export interface RegionsFormProps {
  onValid: SubmitHandler<RegionsSchema>;
  onValuesChange?: (values: RegionsSchema) => void;
  response?: bodyResponseType<'get', '/handbook/regions/{id}'>;
  onInvalid?: SubmitErrorHandler<RegionsSchema>;
  onClickCancelButton?: () => void;
  initialEditable?: boolean;
  disabled?: boolean;
  hideBottomPanel?: boolean;
  rightSidebarButtons?: boolean;
  defaultValues?: RegionsSchema;
  hideTitleField?: boolean;
  cancelButtonText?: string;
  submitButtonText?: string;
  compactBottomPanel?: boolean;
}

export function RegionsForm({
  onValid,
  onValuesChange,
  response,
  onInvalid,
  initialEditable = false,
  onClickCancelButton,
  disabled = false,
  hideBottomPanel = false,
  defaultValues,
  cancelButtonText = 'Отмена',
  submitButtonText = 'Применить',
  compactBottomPanel = false,
}: RegionsFormProps) {
  const { add } = useToaster();

  const form = useForm<RegionsSchema>({
    resolver: yupResolver(regionsSchema),
    mode: 'all',
    defaultValues,
    resetOptions: {
      keepDirtyValues: true,
      keepDefaultValues: false,
    },
  });

  const { createFieldRef, isFieldEditable, selectField } = useFieldsWithPreview({
    form: form,
    initialEditable,
    disabled,
  });

  const values = form.watch();

  useDeepCompareEffect(() => {
    onValuesChange?.(values);
  }, [values]);

  useDeepCompareEffect(() => {
    if (response) form.reset(response);
  }, [response || {}]);

  useEffect(() => {
    if (Object.values(form.formState.errors).length > 0 && form.formState.isSubmitted)
      add({
        title: 'Не все обязательные поля заполнены',
        name: 'validation-fields-error',
        theme: 'warning',
      });
  }, [form.formState.submitCount]);

  return (
    <form
      className="flex flex-col overflow-hidden grow"
      onSubmit={form.handleSubmit(onValid, onInvalid)}
    >
      <FieldWithPreview>
        <FieldWithPreview.Text
          ref={createFieldRef('name')}
          control={form.control}
          path="name"
          name="Наименование"
          edit={isFieldEditable('name')}
          onFieldSelect={() => selectField('name')}
          textInputProps={{ disabled }}
          required
        />
        <FieldWithPreview.Text
          ref={createFieldRef('code')}
          control={form.control}
          path="code"
          name="Код региона"
          edit={isFieldEditable('code')}
          onFieldSelect={() => selectField('code')}
          textInputProps={{ disabled }}
        />
      </FieldWithPreview>
      {!hideBottomPanel && (
        <RightSidebar.BottomPanel>
          <Button
            className={cn({ 'ml-auto': compactBottomPanel })}
            size="xl"
            view={compactBottomPanel ? 'flat' : 'normal'}
            width={'max'}
            onClick={onClickCancelButton}
            disabled={disabled}
          >
            {cancelButtonText}
          </Button>
          <Button
            size="xl"
            view="action"
            type="submit"
            width={'max'}
            onClick={form.handleSubmit(onValid, onInvalid)}
            disabled={disabled}
          >
            {submitButtonText}
          </Button>
        </RightSidebar.BottomPanel>
      )}
    </form>
  );
}
