import {
  FoldableListItem,
  FoldableListProps,
} from '@gravity-ui/components/build/esm/components/CookieConsent/components/FoldableList/types';
import { ChevronLeft, ChevronRight } from '@gravity-ui/icons';
import {
  ArrowToggle,
  Button,
  ButtonProps,
  Checkbox,
  Disclosure,
  DisclosureProps,
  Icon,
  IconProps,
  Label,
  LabelProps,
  Link,
  Text,
  TextProps,
  useDirection,
} from '@gravity-ui/uikit';
import { ReactNode, RefAttributes, useState } from 'react';

import { cn } from '@shared/lib';

export interface MyFoldableListItem extends Omit<FoldableListItem, 'text'> {
  listItem: ReactNode;
  textProps?: TextProps;
  buttonProps?: Omit<ButtonProps, 'onClick'> & React.RefAttributes<HTMLElement>;
  iconProps?: IconProps;
  iconPosition?: 'end' | 'start';
  onUpdate?: DisclosureProps['onUpdate'];
  expanded?: boolean;
  keepMounted?: boolean;
  arrowPosition?: 'end' | 'start';
  className?: string;
  arrowSize?: number;
  arrowClassname?: string;
  rightLabel?: LabelProps & RefAttributes<HTMLDivElement>;
}

interface ExpandListProps extends Omit<FoldableListProps, 'items'> {
  items: MyFoldableListItem[];
  hasCheckboxes?: boolean;
  alternativeIconColor?: boolean;
}

export const ExpandList = ({
  items,
  className,
  onChooseItem,
  hasCheckboxes = false,
  alternativeIconColor,
}: ExpandListProps) => {
  const direction = useDirection();
  const [checkedItems, setChecked] = useState<number[]>(() =>
    items.reduce((acc: number[], item: MyFoldableListItem, index: number) => {
      if (item.checked) {
        acc.push(index);
      }

      return acc;
    }, [])
  );

  const onCheckItem = (index: number) => {
    return () => {
      let newState;

      if (checkedItems.includes(index)) {
        newState = checkedItems.filter(itemIndex => itemIndex !== index);
      } else {
        newState = [...checkedItems, index];
      }

      onChooseItem?.(newState);
      setChecked(newState);
    };
  };

  return (
    <div className={className}>
      {items.map(
        (
          {
            title,
            listItem,
            link,
            checked,
            disabled,
            defaultExpand,
            buttonProps,
            arrowPosition = 'end',
            arrowSize = 20,
            arrowClassname,
            iconProps,
            onUpdate,
            expanded,
            keepMounted,
            className,
            textProps,
            rightLabel,
          },
          index
        ) => {
          const isChecked = checkedItems.includes(index);

          return (
            <Disclosure
              key={index}
              size="l"
              defaultExpanded={defaultExpand}
              onUpdate={onUpdate}
              expanded={expanded}
              keepMounted={keepMounted}
              className={className}
            >
              <Disclosure.Summary>
                {({ onClick, expanded }) => (
                  <>
                    {hasCheckboxes && (
                      <Checkbox
                        checked={checked || isChecked}
                        size="l"
                        onChange={onCheckItem(index)}
                        disabled={disabled}
                      />
                    )}
                    <Button
                      view="flat"
                      size="l"
                      width="max"
                      {...buttonProps}
                      onClick={onClick}
                      className={cn('w-full px-4 gap-4 transition-none', buttonProps?.className)}
                    >
                      {arrowPosition === 'start' && (
                        <ArrowToggle
                          direction={expanded ? 'bottom' : 'right'}
                          size={arrowSize}
                          className={cn('text-misc-heavy-hover mr-2', arrowClassname)}
                        />
                      )}
                      {iconProps && (
                        <Icon
                          size={20}
                          {...iconProps}
                          className={cn('transition-colors', iconProps?.className, {
                            'text-line-brand': alternativeIconColor,
                          })}
                        />
                      )}
                      {/* <div className="w-[116px] align-middle"> */}
                      <Text
                        {...textProps}
                        className={textProps?.className}
                      >
                        {title}
                      </Text>
                      {/* {titleLabel ? <Label>{titleLabel}</Label> : null} */}
                      {/* </div> */}
                      {rightLabel && (
                        <Label
                          size="xs"
                          theme="success"
                          {...rightLabel}
                        />
                      )}
                      {arrowPosition === 'end' && (
                        <ArrowToggle
                          direction={expanded ? 'bottom' : 'right'}
                          size={arrowSize}
                          className={cn('text-misc-heavy-hover ml-4', arrowClassname)}
                        />
                      )}
                    </Button>
                  </>
                )}
              </Disclosure.Summary>
              <div>
                {listItem}
                {link ? (
                  <Link
                    {...link}
                    href={link.href}
                    target="_blank"
                  >
                    {link.title}
                    <Icon
                      data={direction === 'rtl' ? ChevronLeft : ChevronRight}
                      size={16}
                    />
                  </Link>
                ) : null}
              </div>
            </Disclosure>
          );
        }
      )}
    </div>
  );
};
