import { dateTime } from '@gravity-ui/date-utils';

import { CRMTableData } from '@features/table';
import { components } from '@shared/api';
import { DATE_FORMATS } from '@shared/consts';
import { formatToPrice } from '@shared/lib';

import { ColumnsHandbookTable } from '../model';

const parseDate = (date: string, withTime?: boolean) =>
  dateTime({ input: date }).format(
    !withTime ? DATE_FORMATS.fullDate : DATE_FORMATS.fullDateWithTimeRounded
  );

const parseFloat = (float: number): number => {
  return float / 100;
};

const parseMultiple = (names: string[]): string => {
  try {
    return names.join(' \n');
  } catch {
    return '';
  }
};

export const parseTableData = (
  tableData: NonNullable<components['schemas']['handbook.KnowledgeBases']['list']>
): CRMTableData<ColumnsHandbookTable> => {
  return tableData.map(table => {
    return {
      'Дата, номер': table.created_at ? `${parseDate(table.created_at)} №${table.id}` : '',
      Вид: table.type || '',
      Статус: table.status || '',
      'Название документа': table.document_name || '',
      'Ссылка на источник': table.source || '',
      'Краткое описание': table.description || '',
      Теги: table.tags ? parseMultiple(table.tags) : '',
      'Связанный процесс': table.related_process || '',
      Документы: table.documents ? parseMultiple(table.documents) : '',
    };
  });
};
