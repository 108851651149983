import { dateTime } from '@gravity-ui/date-utils';
import { useToaster } from '@gravity-ui/uikit';
import { useMemo } from 'react';

import { CRMTableData } from '@features';
import { ColumnsHandbooksTable, HandbooksTable } from '@widgets';
import { $api } from '@shared/api';
import { DATE_FORMATS } from '@shared/consts';
import { useNavigateTo } from '@shared/lib';
import { HeroWithButton } from '@shared/ui';

import { getHandbookUrl } from '../lib';

export function Handbooks() {
  const { navigateTo } = useNavigateTo();
  const { add } = useToaster();
  const getHandbookAllQuery = $api.useQuery('get', '/handbook/all');
  const data: CRMTableData<ColumnsHandbooksTable> = useMemo(
    () =>
      getHandbookAllQuery.data?.list
        ?.map(handbook => ({
          Название: handbook?.name || '',
          Тип: handbook.is_system ? 'Системный' : 'Пользовательский',
          'Дата изменения': dateTime({ input: handbook.updated_at }).format(DATE_FORMATS.fullDate),
        }))
        .sort((prev, cur) => prev.Название.localeCompare(cur.Название)) ?? [],
    [getHandbookAllQuery.data]
  );

  return (
    <>
      <HeroWithButton title="Справочники" />
      <HandbooksTable
        data={data}
        onRowClick={row => {
          const foundHandbook = getHandbookAllQuery?.data?.list?.find(
            handbook => handbook.name === row.Название
          );
          if (foundHandbook) navigateTo(getHandbookUrl(foundHandbook));
          else {
            add({
              name: 'get-row-handbook-error',
              title: 'Произошла неизвестная ошибка получения данного справочника',
              theme: 'danger',
            });
          }
        }}
      />
    </>
  );
}
