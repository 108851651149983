import { useAtomValue, useSetAtom } from 'jotai';
import { nanoid } from 'nanoid';
import { useEffect, useRef, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { useQueryGetUser } from '@entities';
import { FilterChat, FilterChatProps, resetFiltersAtom, SendMessageForm } from '@features';
import { ChatHeader, ChatMessages, ChatSettingsModal } from '@widgets';
import { $api, components, fetchClient } from '@shared/api';
import { subscribe } from '@shared/api/centrifuge';
import { URLS } from '@shared/consts';
import { useNavigateTo } from '@shared/lib';

import {
  dialogsAtom,
  PERSON_MESSAGE_GROUPS_MOCK,
  PROJECT_MESSAGE_GROUPS_MOCK,
  SETTINGS_HISTORY_MOCK,
  SETTINGS_MEDIA_MOCK,
  SETTINGS_USERS_MOCK,
} from '../model';

export function MessengerChatId() {
  const { navigateTo } = useNavigateTo();

  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const currentChatId = useParams()[URLS.messengerDynamicParams.id];
  useEffect(() => {
    if (!currentChatId) navigateTo(URLS.messenger);
  }, [currentChatId]);

  const dialogs = useAtomValue(dialogsAtom);
  const currentDialog = dialogs.find(dialog => dialog.id === currentChatId);

  // TODO: убрать, когда будет подключен бэк
  const INITIAL_MESSAGE_GROUPS =
    currentDialog?.type === 'project' ? PROJECT_MESSAGE_GROUPS_MOCK : PERSON_MESSAGE_GROUPS_MOCK;

  const sendMessageMutation = $api.useMutation('post', '/chat/{id}');

  const currentUser = useQueryGetUser();

  const chatQuery = $api.useQuery(
    'get',
    '/chat/{id}',
    {
      params: {
        path: {
          id: currentChatId as string,
        },
      },
    },
    {
      enabled: Boolean(currentChatId),
    }
  );

  const chatMessagesQuery = $api.useQuery(
    'post',
    '/chat/messages/{id}',
    {
      params: {
        path: {
          id: currentChatId as string,
        },
      },
      body: {
        stage: 'STAGE_ALL',
        authors: [],
        content_type: 'ALL',
        limit: 1000,
        timestamp: '2025-10-06T16:56:53.944Z',
      },
    },
    {
      enabled: Boolean(currentChatId),
    }
  );

  useEffect(() => {
    if (!currentChatId) return;

    const sub = subscribe(`chat:${currentChatId}`, () =>
      fetchClient
        .GET('/chat/token/{id}', {
          params: {
            path: {
              id: currentChatId as string,
            },
          },
        })
        .then(res => res.data?.message || '')
    );

    sub?.on(
      'publication',
      ({
        data,
      }: {
        data: {
          data: {
            author_id: string;
            chat_id: string;
            message: string;
            id: string;
            timestamp: Date;
          };
        };
      }) => {
        setMessagesSent(msgs => ({
          messages: [
            {
              message: data.data.message,
              author_id: data.data.author_id,
              id: data.data.id,
              timestamp: new Date(new Date().getTime() + 3 * 3600 * 1000).toISOString(),
              author: chatQuery.data?.participants?.find(user => user.id === data.data.author_id),
            },
            ...msgs.messages,
          ],
        }));
      }
    );

    sub?.subscribe();

    return () => {
      sub?.unsubscribe();
    };
  }, [currentChatId]);

  // НАЧАЛО фильтры
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const resetFilters = useSetAtom(resetFiltersAtom);

  const [messagesSent, setMessagesSent] = useState<{
    messages: components['schemas']['chat.Message'][];
  }>({ messages: [] });

  // TODO: заменить значением с бэка (пользователи, состоящие в группе)
  const users = INITIAL_MESSAGE_GROUPS.reduce<FilterChatProps['users']>((users, messageGroup) => {
    const userIds = users.map(({ id }) => id);

    const usersFromThisMessageGroup = messageGroup.messages
      .filter(({ type, user }) => {
        if (type !== 'user' || userIds.includes(user.id)) return false;
        userIds.push(user.id);
        return true;
      })
      .map(message => ({
        id: message.user!.id,
        name: message.user!.name,
        avatarImageURL: message.user!.avatar.src,
      }));

    users.push(...usersFromThisMessageGroup);

    return users;
  }, []);

  // TODO: заменить значением с бэка
  const allowedStatuses: FilterChatProps['allowedStatuses'] =
    currentDialog?.type === 'project'
      ? PROJECT_MESSAGE_GROUPS_MOCK.map(({ stage }) => {
          switch (stage) {
            case 'OFFER':
              return 'commercial-offer';
            case 'CONTRACT':
              return 'contracts';
            case 'TENDER':
              return 'bargaining';
          }
        })
      : undefined;

  // КОНЕЦ фильтры

  const chatMessagesRef = useRef<HTMLDivElement>(null);
  // const scrollChatMessagesToBottom = () => {
  //   chatMessagesRef.current?.scrollTo({ top: chatMessagesRef.current.scrollHeight });
  // };

  // useEffect(() => {
  //   Promise.resolve()
  //     .then(() => {
  //       resetFilters();
  //       setCurrentMessageGroups(
  //         structuredClone(
  //           currentDialog?.type === 'project'
  //             ? PROJECT_MESSAGE_GROUPS_MOCK
  //             : PERSON_MESSAGE_GROUPS_MOCK
  //         )
  //       );
  //     })
  //     .then(scrollChatMessagesToBottom);
  // }, [currentDialog?.id, currentDialog?.type, resetFilters]);

  if (!currentDialog) {
    return (
      <Navigate
        to={URLS.messenger}
        replace
      />
    );
  }

  return (
    <>
      <ChatSettingsModal
        open={isSettingsOpen}
        onClose={() => setIsSettingsOpen(false)}
        history={SETTINGS_HISTORY_MOCK}
        users={SETTINGS_USERS_MOCK}
        media={SETTINGS_MEDIA_MOCK}
      />
      <div className="flex flex-col grow overflow-hidden">
        {currentDialog.type === 'person' && (
          <ChatHeader
            type="person"
            avatarImageURL={currentDialog.avatar.src || ''}
            avatarName={currentDialog.avatar.name}
            name={currentDialog.name}
            onClickFilter={() => setIsFilterOpen(prevIsFilterOpen => !prevIsFilterOpen)}
            onClickSettings={() => setIsSettingsOpen(true)}
          />
        )}
        {currentDialog.type === 'project' && (
          <ChatHeader
            type="project"
            countOfMembers={currentDialog.membersCount}
            projectName={currentDialog.projectName}
            status={currentDialog.stage}
            onClickFilter={() => setIsFilterOpen(prevIsFilterOpen => !prevIsFilterOpen)}
            onClickSettings={() => setIsSettingsOpen(true)}
          />
        )}
        <div className="overflow-hidden flex grow bg-base-generic">
          <div className="overflow-hidden flex flex-col grow">
            <ChatMessages
              ref={chatMessagesRef}
              messageGroups={
                [...(chatMessagesQuery.data?.messages || []), ...messagesSent.messages].length
                  ? [...(chatMessagesQuery.data?.messages || []), ...messagesSent.messages].sort(
                      (a, b) =>
                        new Date(a.timestamp || '').getTime() -
                        new Date(b.timestamp || '').getTime()
                    )
                  : []
              }
            />
            <SendMessageForm
              key={currentChatId}
              onValid={({ message }) => {
                setMessagesSent(ms => ({
                  messages: [
                    {
                      message: message,
                      author: currentUser.data,
                      id: nanoid(),
                      author_id: currentUser.data?.id,
                      timestamp: new Date(new Date().getTime() + 3 * 3600 * 1000).toISOString(),
                    },
                    ...ms.messages,
                  ],
                }));

                sendMessageMutation.mutate({
                  params: {
                    path: {
                      id: currentChatId as string,
                    },
                  },
                  body: {
                    message,
                  },
                });
              }}
            />
          </div>
          <FilterChat
            open={isFilterOpen}
            allowedStatuses={allowedStatuses}
            hideActionType={currentDialog.type === 'person'}
            users={users}
            onClose={() => setIsFilterOpen(false)}
          />
        </div>
      </div>
    </>
  );
}
