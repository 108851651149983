import { TabsItemProps, useToaster } from '@gravity-ui/uikit';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { ChatSidebar, Dialog } from '@widgets';
import { bodyRequestType } from '@shared/api';

import { useQueryPostChatAll } from '../api';
import { activeTabAtom, dialogsAtom } from '../model';
import { useQueryGetUser } from '@entities';

interface TabsPropsWithStage extends Omit<TabsItemProps, 'id'> {
  id: bodyRequestType<'post', '/chat/all'>['stage'];
}

export function MessengerLayout() {
  const [dialogs, setDialogs] = useAtom(dialogsAtom);
  const [activeTab, setActiveTab] = useAtom(activeTabAtom);
  const toaster = useToaster();

  // const bodyResponse: bodyResponseType<'post', '/chat/all'>['chats']
  const query = useQueryPostChatAll({
    count: 0,
    page: 0,
    stage: activeTab || 'ALL',
  });
  // TODO: переделать запрос на бесконечный скролл и в другой компонент поместить
  const getChatAllData = query.data;
  const user = useQueryGetUser()
  const { error, isLoading } = query;

  function countTabTotal(...args: Array<number | undefined>) {
    return args.reduce(
      (accumulator: number, currentValue) =>
        currentValue ? accumulator + currentValue : accumulator,
      0
    );
  }

  const tabs: TabsPropsWithStage[] = [
    {
      id: 'ALL',
      title: 'Все диалоги',
      counter: countTabTotal(
        getChatAllData?.total_contract,
        getChatAllData?.total_offer,
        getChatAllData?.total_personal,
        getChatAllData?.total_tender
      ),
    },
    // {
    //   id: 'PEOPLE',
    //   title: 'Люди',
    //   counter: countTabTotal(getChatAllData?.total_personal),
    // },
    // {
    //   id: 'PROJECTS',
    //   title: 'Проекты',
    //   counter: countTabTotal(
    //     getChatAllData?.total_contract,
    //     getChatAllData?.total_offer,
    //     getChatAllData?.total_tender
    //   ),
    // },
    // {
    //   id: 'OFFERS',
    //   title: 'КП',
    //   counter: countTabTotal(getChatAllData?.total_offer),
    // },
    // {
    //   id: 'TENDERS',
    //   title: 'Торги',
    //   counter: countTabTotal(getChatAllData?.total_tender),
    // },
    // {
    //   id: 'CONTRACT',
    //   title: 'Контракты',
    //   counter: countTabTotal(getChatAllData?.total_contract),
    // },
  ];

  useEffect(() => {
    if (error) {
      toaster.add({
        name: 'chat-all-error',
        title: 'Ошибка получения списка чатов',
        theme: 'danger',
        isClosable: true,
        autoHiding: 5000,
      });
      return;
    }
    if (getChatAllData) {
      setDialogs(() =>
        getChatAllData.chats.map(chat => {
          const base: Partial<Dialog> = {
            id: chat.id,
            lastMessageText: chat.last_message.message,
            lastMessageTimestamp: chat.last_message.timestamp as ReturnType<Date['toISOString']>,
          };
          const personal: Partial<Dialog> = !('stage' in chat)
            ? (() => {
              const interlocutor = chat.participants?.find(p => p.id !== user.data?.id)
              const title = interlocutor ? interlocutor.full_name : (user.data?.full_name ?? 'Неизвестный')

              return {
                type: 'person',
                name: title,
                avatar: {
                  name: title,
                  src: chat.last_message.avatar,
                },
                online: false, // TODO: пока на бэке нет онлайна по ручке /chat/all,
              }
            })()
            : {};

          const project: Partial<Dialog> = {
            type: 'project',
            projectName: chat.title,
            membersCount: chat.participants.length,
            lastMessageSender: chat.last_message.name,
          };
          const offer: Partial<Dialog> =
            chat.stage === 'OFFER'
              ? {
                  ...project,
                  stage: 'OFFERS',
                }
              : {};
          const contract: Partial<Dialog> =
            chat.stage === 'CONTRACT'
              ? {
                  ...project,
                  stage: 'CONTRACT',
                }
              : {};
          const tender: Partial<Dialog> =
            chat.stage === 'TENDER'
              ? {
                  ...project,
                  stage: 'TENDERS',
                }
              : {};
          return Object.assign(base, personal, offer, contract, tender);
        })
      );
    }
  }, [getChatAllData, error]);

  return (
    <div className="flex h-[calc(100vh-53px)]">
      {!error && (
        <ChatSidebar
          dialogs={dialogs} // TODO: заменить моки на данные с бэка
          tabs={tabs}
          activeTab={activeTab}
          onTabChange={setActiveTab}
          loading={isLoading}
        />
      )}
      <Outlet />
    </div>
  );
}
