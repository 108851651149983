import { Xmark } from '@gravity-ui/icons';
import { Button, Divider, Icon } from '@gravity-ui/uikit';

import { objectKeysSafeToArray } from '@shared/lib';

import { filtersToDisplayFormat } from '../lib';
import { CRMTableFilters } from '../model';

interface TableFiltersDisplayListProps<T extends string[]> {
  filters: CRMTableFilters<T>;
  deleteFilterHandle: (filterName: string) => void;
  resetHandle: () => void;
  contentFilters: Record<T[number], string | undefined>;
}

export const TableFiltersDisplayList = <ColumnNames extends string[]>({
  filters,
  contentFilters,
  resetHandle,
  deleteFilterHandle,
}: TableFiltersDisplayListProps<ColumnNames>) => {
  const hasFilters = objectKeysSafeToArray(filters).some(key => {
    const fil = filters[key];
    return fil.type === 'select' || fil.type === 'selectWinners' ? fil.value?.length : fil.value;
  });

  if (!hasFilters) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4 px-5 pb-4">
      <Divider />

      <div className="flex flex-row items-center flex-wrap overflow-hidden gap-2">
        {objectKeysSafeToArray(filters).map(key => {
          const filter = filters[key];
          const displayText = filtersToDisplayFormat(filter, contentFilters[key]);

          if (!displayText) {
            return null;
          }

          return (
            <div
              key={key}
              className="g-label g-label_theme_clear g-label_size_m max-w-[400px]"
            >
              <div className="flex flex-row items-center overflow-hidden pl-3 pr-2">
                <div className="whitespace-nowrap overflow-hidden text-ellipsis mr-1">{key}</div>
                <div className="whitespace-nowrap overflow-hidden text-ellipsis text-text-secondary shrink-[50000]">
                  {' : ' + displayText}
                </div>
              </div>
              <Button
                pin="clear-round"
                onClick={() => deleteFilterHandle(key)}
              >
                <Icon
                  data={Xmark}
                  size={12}
                />
              </Button>
            </div>
          );
        })}

        <Button
          view="flat"
          className="text-text-danger"
          onClick={resetHandle}
        >
          Сбросить фильтры
        </Button>
      </div>
    </div>
  );
};
