import { yupRu } from '@shared/config';
import { passwordRegex } from '@shared/lib';

export const newPasswordSchema = yupRu.object({
  password: yupRu
    .string()
    .matches(passwordRegex, {
      message:
        'Не менее 6 символов, использование заглавных и строчных букв латинского алфавита, символов и чисел',
      excludeEmptyString: true,
    })
    .required('Введите пароль'),
  passwordConfirm: yupRu
    .string()
    .matches(passwordRegex, {
      message:
        'Не менее 6 символов, использование заглавных и строчных букв латинского алфавита, символов и чисел',
      excludeEmptyString: true,
    })
    .oneOf([yupRu.ref('password')], 'Пароли не совпадают')
    .required('Подтвердите пароль'),
});
