import { TabsItemProps } from '@gravity-ui/uikit';
import { atomWithStorage } from 'jotai/utils';

import { LOCAL_STORAGE } from '@shared/consts';
import { customStorage } from '@shared/lib';

export const tableTabsAtom = atomWithStorage<TabsItemProps[]>(
  LOCAL_STORAGE.commercialOffersTabs,
  customStorage<TabsItemProps[]>().getItem(LOCAL_STORAGE.commercialOffersTabs, [
    { id: 'Все', title: 'Все' },
  ]),
  customStorage()
);

export const selectedTabAtom = atomWithStorage<string | null>(
  LOCAL_STORAGE.commercialOffersSelectedTab,
  customStorage<string | null>().getItem(LOCAL_STORAGE.commercialOffersSelectedTab, null),
  customStorage()
);

export const frontKeyBackendValueColumns: {
  'Дата, номер': { Дата: 'created_at'; Номер: 'id' };
  Наименование: 'title';
  Ответственный: 'responsible_value';
  Заказчик: 'customer_value';
  'Цель КП': 'purpose';
  'Статус КП': 'status';
  'Дата перевода': 'transfer_at';
  'Регион поставки': 'delivery_region_value';
  'Адрес поставки': 'delivery_address';
  'Срок поставки': 'delivery_deadline';
  Основание: 'basis_purchase';
  // 'Как получен запрос': 'request_source';
  // 'Ссылка в ЕИС': 'eis_link';
  'Окончание подачи': 'submission_deadline';
  'Срок проведения закупки с': 'producement_start_at';
  'Срок проведения закупки по': 'producement_end_at';
  'Документы запроса': 'documents';
  'Объект закупки': 'purchase_object_value';
  Комментарий: 'comment';
  'Файл расчета': 'calculation_file';
  Себестоимость: 'cost_price';
  'Цена предложения': 'offer_price';
  'Маржа, %': 'margin';
  'Файл КП': 'offer_file';
  'Дата КП': 'document_date';
  Поставщик: 'provider_value';
  'Согласовать цену с руководителем': 'manager_approved';
  'Предлагаемая специалистом цена': 'proposed_price';
  'Обоснование предложения цены': 'price_justification';
  'Комментарий согласований': 'approval_comment';
  'Итоговая цена': 'final_price';
  Победитель: 'winner_value';
} = {
  'Дата, номер': { Дата: 'created_at', Номер: 'id' },
  Наименование: 'title',
  Ответственный: 'responsible_value',
  Заказчик: 'customer_value',
  'Цель КП': 'purpose',
  'Статус КП': 'status',
  'Дата перевода': 'transfer_at',
  'Регион поставки': 'delivery_region_value',
  'Адрес поставки': 'delivery_address',
  'Срок поставки': 'delivery_deadline',
  Основание: 'basis_purchase',
  // 'Как получен запрос': 'request_source',
  // 'Ссылка в ЕИС': 'eis_link',
  'Окончание подачи': 'submission_deadline',
  'Срок проведения закупки с': 'producement_start_at',
  'Срок проведения закупки по': 'producement_end_at',
  'Документы запроса': 'documents',
  'Объект закупки': 'purchase_object_value',
  Комментарий: 'comment',
  'Файл расчета': 'calculation_file',
  Себестоимость: 'cost_price',
  'Цена предложения': 'offer_price',
  'Маржа, %': 'margin',
  'Файл КП': 'offer_file',
  'Дата КП': 'document_date',
  Поставщик: 'provider_value',
  'Согласовать цену с руководителем': 'manager_approved',
  'Предлагаемая специалистом цена': 'proposed_price',
  'Обоснование предложения цены': 'price_justification',
  'Комментарий согласований': 'approval_comment',
  'Итоговая цена': 'final_price',
  Победитель: 'winner_value',
};

export const frontKeyBackendValueSorting: {
  'Дата, номер': 'created_at';
  Наименование: 'title';
  Ответственный: 'responsible_value';
  Заказчик: 'customer_value';
  'Цель КП': 'purpose';
  'Статус КП': 'status';
  'Дата перевода': 'transfer_at';
  'Регион поставки': 'delivery_region_value';
  'Адрес поставки': 'delivery_address';
  'Срок поставки': 'delivery_deadline';
  Основание: 'basis_purchase';
  // 'Как получен запрос': 'request_source';
  // 'Ссылка в ЕИС': 'eis_link';
  'Окончание подачи': 'submission_deadline';
  'Срок проведения закупки с': 'producement_start_at';
  'Срок проведения закупки по': 'producement_end_at';
  'Документы запроса': 'documents'; // нет поля
  'Объект закупки': 'purchase_object_value';
  Комментарий: 'comment';
  'Файл расчета': 'calculation_file';
  Себестоимость: 'cost_price';
  'Цена предложения': 'offer_price';
  'Маржа, %': 'margin';
  'Файл КП': 'offer_file';
  'Дата КП': 'document_date';
  Поставщик: 'provider_value';
  'Согласовать цену с руководителем': 'manager_approved';
  'Предлагаемая специалистом цена': 'proposed_price';
  'Обоснование предложения цены': 'price_justification';
  'Комментарий согласований': 'approval_comment';
  'Итоговая цена': 'final_price';
  Победитель: 'winner_value';
} = { ...frontKeyBackendValueColumns, 'Дата, номер': 'created_at' };

export const frontKeyBackendValueFilters: {
  'Дата, номер': { from: 'min_created_at'; to: 'max_created_at' };
  Наименование: 'title';
  Ответственный: 'responsible_ids';
  Заказчик: 'customer_ids';
  'Цель КП': 'purpose';
  'Статус КП': 'status';
  'Дата перевода': { from: 'min_transfer_at'; to: 'max_transfer_at' };
  'Регион поставки': 'delivery_region';
  'Адрес поставки': 'delivery_address';
  'Срок поставки': 'delivery_deadline';
  Основание: 'basis_purchase';
  // 'Как получен запрос': 'request_sources';
  // 'Ссылка в ЕИС': 'eis_link';
  'Окончание подачи': { from: 'min_submission_deadline'; to: 'max_submission_deadline' };
  'Срок проведения закупки с': { from: 'min_producement_start_at'; to: 'max_producement_start_at' };
  'Срок проведения закупки по': { from: 'min_producement_end_at'; to: 'max_producement_end_at' };
  'Документы запроса': 'documents';
  'Объект закупки': 'purchase_object_ids';
  Комментарий: 'comment';
  'Файл расчета': 'calculation_file';
  Себестоимость: { from: 'min_cost_price'; to: 'max_cost_price' };
  'Цена предложения': { from: 'min_offer_price'; to: 'max_offer_price' };
  'Маржа, %': { from: 'min_margin'; to: 'max_margin' };
  'Файл КП': 'offer_file';
  'Дата КП': { from: 'min_document_date'; to: 'max_document_date' };
  Поставщик: 'provider_ids';
  'Согласовать цену с руководителем': 'manager_approved';
  'Предлагаемая специалистом цена': { from: 'min_proposed_price'; to: 'max_proposed_price' };
  'Обоснование предложения цены': 'price_justification';
  'Комментарий согласований': 'approval_comment';
  'Итоговая цена': { from: 'min_final_price'; to: 'max_final_price' };
  Победитель: 'winners';
} = {
  'Дата, номер': { from: 'min_created_at', to: 'max_created_at' },
  Наименование: 'title',
  Ответственный: 'responsible_ids',
  Заказчик: 'customer_ids',
  'Цель КП': 'purpose',
  'Статус КП': 'status',
  'Дата перевода': { from: 'min_transfer_at', to: 'max_transfer_at' },
  'Регион поставки': 'delivery_region',
  'Адрес поставки': 'delivery_address',
  'Срок поставки': 'delivery_deadline',
  Основание: 'basis_purchase',
  // 'Как получен запрос': 'request_sources',
  // 'Ссылка в ЕИС': 'eis_link',
  'Окончание подачи': { from: 'min_submission_deadline', to: 'max_submission_deadline' },
  'Срок проведения закупки с': { from: 'min_producement_start_at', to: 'max_producement_start_at' },
  'Срок проведения закупки по': { from: 'min_producement_end_at', to: 'max_producement_end_at' },
  'Документы запроса': 'documents',
  'Объект закупки': 'purchase_object_ids',
  Комментарий: 'comment',
  'Файл расчета': 'calculation_file',
  Себестоимость: { from: 'min_cost_price', to: 'max_cost_price' },
  'Цена предложения': { from: 'min_offer_price', to: 'max_offer_price' },
  'Маржа, %': { from: 'min_margin', to: 'max_margin' },
  'Файл КП': 'offer_file',
  'Дата КП': { from: 'min_document_date', to: 'max_document_date' },
  Поставщик: 'provider_ids',
  'Согласовать цену с руководителем': 'manager_approved',
  'Предлагаемая специалистом цена': { from: 'min_proposed_price', to: 'max_proposed_price' },
  'Обоснование предложения цены': 'price_justification',
  'Комментарий согласований': 'approval_comment',
  'Итоговая цена': { from: 'min_final_price', to: 'max_final_price' },
  Победитель: 'winners',
};
