// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { $api, $mock, ParametersPathType } from '../../../../shared/api';

export function useGetSystemHandbookQuery(
  name: ParametersPathType<'get', '/handbook/system/{name}'>['name'],
  enabled = true
) {
  // return $mock.useQuery('get', '/handbook/system/{name}', { params: { path: { name: name } } });
  return $api.useQuery(
    'get',
    '/handbook/system/{name}',
    { params: { path: { name: name } } },
    { enabled }
  );
}
