import { dateTime } from '@gravity-ui/date-utils';
import { useCallback, useMemo, useState } from 'react';

import { DATE_FORMATS } from '@shared/consts';
import { objectKeysSafeToArray } from '@shared/lib';

import {
  ColumnsFilters,
  CRMTableFilters,
  DateFilterValueType,
  FilterBooleanType,
  FilterSearchType,
  FilterSelectType,
  FilterWinnersType,
  NumericFilterValueType,
} from '../../model';
import { setInitFilters } from '../helpers';

export const useTableFilters = <Keys extends string[]>(
  initialFilters: Record<Keys[number], ColumnsFilters>
) => {
  const [selectedFilters, setSelectedFilters] = useState<CRMTableFilters<Keys>>(() =>
    setInitFilters(initialFilters)
  );

  const sidebarFilters = useMemo<
    Record<
      Keys[number],
      FilterSelectType | FilterBooleanType | FilterSearchType | FilterWinnersType
    >
  >(() => {
    return objectKeysSafeToArray(selectedFilters).reduce(
      (acc, key) => {
        const filter = selectedFilters[key];
        if (
          filter &&
          (filter.type === 'select' ||
            filter.type === 'selectWinners' ||
            filter.type === 'boolean' ||
            filter.type === 'search')
        ) {
          acc[key] = filter;
        }
        return acc;
      },
      {} as Record<
        string,
        FilterSelectType | FilterBooleanType | FilterSearchType | FilterWinnersType
      >
    );
  }, [selectedFilters]);

  const updateFiltersByDateColumns = useCallback(
    (name: Keys[number], type: 'fullDate' | 'fullDateWithTime', from?: number, to?: number) => {
      if (typeof from === 'undefined' && typeof to === 'undefined') {
        return;
      }
      const format =
        type === 'fullDate' ? DATE_FORMATS.fullDate : DATE_FORMATS.fullDateWithTimeRounded;
      const dateValue: DateFilterValueType =
        from && to
          ? {
              from: dateTime({ input: from }).format(format),
              to: dateTime({ input: to }).format(format),
            }
          : from
            ? { from: dateTime({ input: from }).format(format), to: undefined }
            : { from: undefined, to: dateTime({ input: to! }).format(format) };

      setSelectedFilters(prev => {
        const updatedFilters = structuredClone(prev);
        const filter = structuredClone(updatedFilters[name]);
        if (filter && (filter.type === 'date' || filter.type === 'dateWithTime')) {
          filter.value = dateValue;
          updatedFilters[name] = filter;
        }
        return updatedFilters;
      });
    },
    []
  );

  const updateFiltersByCurrencyOrMarginColumns = useCallback(
    (name: Keys[number], from?: number, to?: number) => {
      if (typeof from === 'undefined' && typeof to === 'undefined') {
        return;
      }
      const numVal: NumericFilterValueType =
        from && to ? { from, to } : from ? { from, to: undefined } : { from: undefined, to: to! };
      setSelectedFilters(prev => {
        const updatedFilters = structuredClone(prev);
        const filter = structuredClone(updatedFilters[name]);
        if (filter && (filter.type === 'currency' || filter.type === 'margin')) {
          filter.value = numVal;
          updatedFilters[name] = filter;
        }
        return updatedFilters;
      });
    },
    []
  );

  const deleteFilterValueByName = useCallback((name: Keys[number]) => {
    setSelectedFilters(prev => {
      const updatedFilters = structuredClone(prev);
      const filter = structuredClone(updatedFilters[name]);
      if (filter) {
        if (filter.type === 'select' || filter.type === 'selectWinners') {
          filter.value = [];
        } else {
          filter.value = undefined;
        }
        updatedFilters[name] = filter;
      }
      return updatedFilters;
    });
  }, []);

  const resetFilters = () => {
    setSelectedFilters(setInitFilters(initialFilters));
  };

  return {
    selectedFilters,
    setSelectedFilters,
    sidebarFilters,
    updateFiltersByDateColumns,
    updateFiltersByCurrencyOrMarginColumns,
    deleteFilterValueByName,
    resetFilters,
  };
};
