import { ReactNode } from 'react';

import { PurchaseObjectsForm, PurchaseObjectsFormProps } from '@features';

interface PurchaseObjectsLayoutProps {
  renderForm: (formComponent: ReactNode) => ReactNode;
  formProps: PurchaseObjectsFormProps;
}

export function PurchaseObjectsLayout({ renderForm, formProps }: PurchaseObjectsLayoutProps) {
  return <>{renderForm(<PurchaseObjectsForm {...formProps} />)}</>;
}
