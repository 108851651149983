import { FC, ReactNode, RefObject, useCallback, useEffect, useRef, useState } from 'react';

interface InfiniteScrollProps {
  rootRef?: RefObject<HTMLDivElement>;
  loadAfter: () => void;
  loadBefore?: () => void;
  hasAfter: boolean;
  hasBefore?: boolean;
  loader?: ReactNode;
  children?: ReactNode;
  endMessageBegin?: ReactNode;
  endMessageEnd?: ReactNode;
  rootMarginBegin?: string;
  thresholdBegin?: number;
  rootMarginEnd?: string;
  thresholdEnd?: number;
  horizontal?: boolean;
}

type TPreviousScrollWidth = number | undefined;
type TPreviousScrollHeight = number | undefined;

export const InfiniteScroll: FC<InfiniteScrollProps> = ({
  rootRef,
  loadBefore,
  loadAfter,
  hasAfter,
  hasBefore,
  loader,
  children,
  endMessageBegin,
  endMessageEnd,
  rootMarginBegin,
  thresholdBegin,
  rootMarginEnd,
  thresholdEnd,
  horizontal,
}) => {
  const sentinelRefBegin = useRef<HTMLDivElement>(null);
  const sentinelRefEnd = useRef<HTMLDivElement>(null);
  const observerRefBefore = useRef<IntersectionObserver | null>(null);
  const observerRefAfter = useRef<IntersectionObserver | null>(null);

  const [previousScrollWidth, setPreviousScrollWidth] = useState<TPreviousScrollWidth>(undefined);
  const [previousScrollHeight, setPreviousScrollHeight] =
    useState<TPreviousScrollHeight>(undefined);

  useEffect(() => {
    if (rootRef?.current && horizontal) {
      setPreviousScrollWidth(rootRef.current.scrollWidth);
    } else if (rootRef?.current && !horizontal) {
      setPreviousScrollHeight(rootRef.current.scrollHeight);
    }
  }, []);

  const handleIntersectBefore = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      if (!loadBefore) return;
      // Check if the sentinel element is intersecting, and if so, call the load function
      const firstEntry = entries[0];
      if (firstEntry && firstEntry.isIntersecting && hasBefore) {
        loadBefore();
        const slider = rootRef?.current;
        if (slider && previousScrollWidth) {
          slider.scrollLeft = slider.scrollWidth - previousScrollWidth;
          setPreviousScrollWidth(slider.scrollWidth);
        } else if (slider && previousScrollHeight) {
          slider.scrollTop = slider.scrollHeight - previousScrollHeight;
          setPreviousScrollHeight(slider.scrollHeight);
        }
      }
    },
    [loadBefore, hasBefore]
  );
  const handleIntersectAfter = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      // Check if the sentinel element is intersecting, and if so, call the load function
      const lastEntry = entries[entries.length - 1];
      if (lastEntry && lastEntry.isIntersecting && hasAfter) {
        loadAfter();
      }
    },
    [loadAfter, hasAfter]
  );

  useEffect(() => {
    // Create a new IntersectionObserver when the component mounts
    observerRefBefore.current = new IntersectionObserver(handleIntersectBefore, {
      root: rootRef?.current,
      rootMargin: rootMarginBegin || '1px',
      threshold: thresholdBegin || 0,
    });

    observerRefAfter.current = new IntersectionObserver(handleIntersectAfter, {
      root: rootRef?.current,
      rootMargin: rootMarginEnd || '1px',
      threshold: thresholdEnd || 0,
    });

    // Attach the observer to the sentinel element
    if (sentinelRefBegin.current) {
      observerRefBefore.current.observe(sentinelRefBegin.current);
    }

    if (sentinelRefEnd.current) {
      observerRefAfter.current.observe(sentinelRefEnd.current);
    }

    // Clean up the observer when the component unmounts
    return () => {
      if (observerRefBefore.current) {
        observerRefBefore.current.disconnect();
      }
      if (observerRefAfter.current) {
        observerRefAfter.current.disconnect();
      }
    };
  }, [loadBefore, handleIntersectBefore, loadAfter, handleIntersectAfter]);

  useEffect(() => {
    // When the hasMore prop changes, disconnect the previous observer and reattach it to the new sentinel element
    if (observerRefBefore.current && sentinelRefBegin.current) {
      observerRefBefore.current.disconnect();
      observerRefBefore.current.observe(sentinelRefBegin.current);
    }
  }, [hasBefore]);
  useEffect(() => {
    // When the hasMore prop changes, disconnect the previous observer and reattach it to the new sentinel element
    if (observerRefAfter.current && sentinelRefEnd.current) {
      observerRefAfter.current.disconnect();
      observerRefAfter.current.observe(sentinelRefEnd.current);
    }
  }, [hasAfter]);

  return (
    <>
      {!hasBefore && endMessageBegin}
      <div ref={sentinelRefBegin}>{hasBefore && loader}</div>
      {children}
      <div ref={sentinelRefEnd}>{hasAfter && loader}</div>
      {!hasAfter && endMessageEnd}
    </>
  );
};
