/* eslint-disable @typescript-eslint/no-unused-vars */
import './index.sass';

import { DelayedTextInput } from '@gravity-ui/components';
import { ArrowLeft, CommentPlus, Comments } from '@gravity-ui/icons';
import {
  Avatar,
  Button,
  Checkbox,
  Divider,
  Icon,
  Dialog as ModalDialog,
  Spin,
  TabsItemProps,
  Text,
  TextInput,
  User,
  useToaster,
} from '@gravity-ui/uikit';
import { useQueryPostChatAll } from '@pages/messenger/api';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { DialogItem } from '@entities';
import { SearchDialogs, TabsWithScroll } from '@features';
import { bodyRequestType } from '@shared/api';
import { URLS } from '@shared/consts';
import { useNavigateTo } from '@shared/lib';
import { InfiniteScroll } from '@shared/ui';

import { useMutatePostStart, useQueryGetHandbookEmployees } from '../../api';
import { Dialog, EmployeeCheckbox } from '../../model';

interface ChatSidebarProps {
  dialogs: Dialog[];
  tabs: TabsItemProps[];
  activeTab: bodyRequestType<'post', '/chat/all'>['stage'] | null;
  onTabChange?: (tabId: bodyRequestType<'post', '/chat/all'>['stage']) => void;
  loading: boolean;
}

const PER_PAGE_LIMIT = 60;
const MESSAGES_LIMIT = 30;

export function ChatSidebar({ tabs, dialogs, activeTab, onTabChange, loading }: ChatSidebarProps) {
  const toaster = useToaster();
  const currentDialogId = useParams()[URLS.messengerDynamicParams.id];
  const { navigateTo } = useNavigateTo();

  const [searchValue, setSearchValue] = useState('');

  const [debouncedSearchValue] = useDebounce(searchValue, 500);

  const searchDialogs = useMemo(() => {
    return dialogs.filter(dialog => {
      if (dialog.type === 'person')
        return dialog.name.toLocaleLowerCase().includes(debouncedSearchValue.toLocaleLowerCase());
      if (dialog.type === 'project')
        return dialog.projectName
          ?.toLocaleLowerCase()
          .includes(debouncedSearchValue.toLocaleLowerCase());
    });
  }, [debouncedSearchValue, dialogs]);

  // Модалка создания чата
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

  const [isCreateGroupChat, setIsCreateGroupChat] = useState(false);
  const [dialogNameValue, setDialogNameValue] = useState('');
  const [searchEmployeesValue, setSearchEmployeesValue] = useState('');
  const [employeesPage, setEmployeesPage] = useState(0);
  const [employeesData, setEmployeesData] = useState<Array<EmployeeCheckbox[]>>([]);
  // Групповые чаты вне мвп, поэтому даем выбрать только одного сотрудника
  const [isEmployeeChecked, setIsEmployeeChecked] = useState(false);
  const [employeeChecked, setEmployeeChecked] = useState<EmployeeCheckbox | null>(null);

  const chatsQuery = useQueryPostChatAll({
    count: 1000,
    page: 0,
    stage: 'PEOPLE',
  });

  const getEmployees = useQueryGetHandbookEmployees(
    {
      query: searchEmployeesValue,
      page: employeesPage,
      limit: PER_PAGE_LIMIT,
    },
    isCreateDialogOpen
  );

  useEffect(() => {
    if (getEmployees.data?.pages) setEmployeesData(getEmployees.data.pages);
  }, [getEmployees.data?.pages.length, getEmployees.data?.pages[0]]);

  const createDialog = useMutatePostStart();

  return (
    <>
      <div className="pt-8 w-[376px] flex flex-col gap-6 border-r border-base-generic">
        <Text
          variant="display-1"
          className="px-5"
        >
          Мессенджер
        </Text>
        <div className="flex flex-col gap-3">
          <div className="relative overflow-x-hidden mx-5 border-b-[1px] border-solid border-b-base-float-announcement">
            <TabsWithScroll
              activeTab={activeTab ? (activeTab as string) : undefined}
              className="pr-[72px] py-0"
              onSelectTab={onTabChange}
              items={tabs}
            />
          </div>
          <div className="flex px-5 gap-2">
            <SearchDialogs
              value={searchValue}
              onUpdate={setSearchValue}
              delay={300}
            />
            <Button
              size="l"
              view="outlined"
              onClick={() => setIsCreateDialogOpen(true)}
            >
              <Icon data={CommentPlus} />
            </Button>
          </div>
        </div>
        <div className="px-5 overflow-y-auto hide-scrollbar flex flex-col">
          {loading ? (
            <Spin
              size="xl"
              className="self-center"
            />
          ) : (
            <div className="flex flex-col gap-2 pt-2 pb-6">
              {searchDialogs.map((searchDialog, index) => {
                const selected = searchDialog.id === currentDialogId;
                // TODO: брать значение с бэка
                // const lastMessageSendedBy =
                //   searchDialog.lastMessage.type === 'bot'
                //     ? searchDialog.lastMessage.bot.name
                //     : searchDialog.lastMessage.type === 'user'
                //       ? searchDialog.lastMessage.user.name
                //       : 'Событие сервера';

                return (
                  <Fragment key={searchDialog.id}>
                    {!!index && (
                      <div className="py-1">
                        <Divider />
                      </div>
                    )}
                    <Link to={`${URLS.messenger}/${searchDialog.id}`}>
                      {searchDialog.type === 'person' && (
                        <DialogItem
                          type="person"
                          avatarName={searchDialog.avatar.name}
                          lastMessageTimestamp={searchDialog.lastMessageTimestamp}
                          lastMessageText={searchDialog.lastMessageText} // TODO: продумать вариант, когда последним сообщением был не текст, а файл/изображение
                          name={searchDialog.name}
                          avatarImageURL={searchDialog.avatar.src}
                          online={searchDialog.online}
                          selected={selected}
                        />
                      )}
                      {searchDialog.type === 'project' && (
                        <DialogItem
                          type="project"
                          lastMessageTimestamp={searchDialog.lastMessageTimestamp}
                          lastMessageSender={searchDialog.lastMessageSender}
                          lastMessageText={searchDialog.lastMessageText} // TODO: продумать вариант, когда последним сообщением был не текст, а файл/изображение
                          projectName={searchDialog.projectName}
                          stage={searchDialog.stage}
                          selected={selected}
                        />
                      )}
                    </Link>
                  </Fragment>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <ModalDialog
        open={isCreateDialogOpen}
        onTransitionEnter={() => {
          getEmployees.refetch();
        }}
        onClose={() => setIsCreateDialogOpen(false)}
        onOutsideClick={() => setIsCreateDialogOpen(false)}
        onEscapeKeyDown={() => setIsCreateDialogOpen(false)}
        size="s"
        className="rounded-2xl w-[495px]"
      >
        <ModalDialog.Header
          caption={'Создать групповой чат'}
          insertBefore={
            <Button
              size="l"
              view="flat"
              onClick={() => setIsCreateGroupChat(false)}
              className="mr-1"
            >
              <Icon data={ArrowLeft} />
            </Button>
          }
        />
        <ModalDialog.Body className="flex flex-col gap-4">
          {isCreateGroupChat ? (
            <>
              <TextInput
                label="Название:"
                size="l"
                value={dialogNameValue}
                onUpdate={value => {
                  setDialogNameValue(value);
                }}
              />
              <hr className="my-1 bg-line-generic  opacity-10" />
            </>
          ) : (
            <Button
              view="flat-info"
              size="xl"
              selected
              className="flex justify-center px-3 py-4 items-center h-[62px]"
              onClick={() => setIsCreateGroupChat(true)}
            >
              <div className="flex flex-col gap-1 items-center justify-center">
                <Icon data={Comments} />
                <Text variant="body-1">Создать групповой чат</Text>
              </div>
            </Button>
          )}
          <DelayedTextInput
            placeholder="Поиск"
            size="l"
            value={searchEmployeesValue}
            onUpdate={value => {
              setSearchEmployeesValue(value);
              setEmployeesPage(0);
            }}
            delay={300}
          />
          <div className="h-[340px] hide-scrollbar overflow-y-auto flex flex-col gap-[12px] employees-container">
            <InfiniteScroll
              hasAfter={getEmployees.hasNextPage && !getEmployees.error}
              loadAfter={async () => {
                if (!getEmployees.isFetching && !getEmployees.isFetchingNextPage) {
                  const { data } = await getEmployees.fetchNextPage();
                  if (data) {
                    const currentPage = data.pages.length - 1;
                    setEmployeesPage(currentPage);
                  }
                }
              }}
              loader={
                getEmployees.isFetching && (
                  <Spin
                    size="xl"
                    className="mx-auto"
                  />
                )
              }
            >
              {employeesData.map((page, pageIndex) =>
                page?.map((emp, i) =>
                  emp ? (
                    isCreateGroupChat ? (
                      <Checkbox
                        key={`${pageIndex}${i}`}
                        checked={emp.checked}
                        disabled={
                          chatsQuery.data?.chats
                            ?.map(chat => chat.participant_ids)
                            .flat()
                            .includes(emp.id) ?? false
                        }
                        onUpdate={checked => {
                          setEmployeesData(prev => {
                            return prev.map((curEmpArray, curPageIndex) =>
                              curPageIndex !== pageIndex
                                ? curEmpArray
                                : curEmpArray.map((v, prevIndex) => {
                                    // TODO: пока нет создания групповых чатов
                                    if (i === prevIndex && checked !== isEmployeeChecked) {
                                      setIsEmployeeChecked(checked);
                                      setEmployeeChecked(
                                        checked ? { ...v, checked: checked } : null
                                      );
                                      return { ...v, checked: checked };
                                    }
                                    return v;
                                  })
                            );
                          });
                        }}
                        className="items-center flex-row-reverse"
                      >
                        <User
                          size="l"
                          name={emp.name}
                          avatar={
                            <Avatar
                              size="l"
                              text={emp.name}
                              title={emp.name}
                              imgUrl={''} // TODO: пока на автомоках
                              alt={emp.name}
                            />
                          }
                        />
                      </Checkbox>
                    ) : (
                      <Button
                        key={`${pageIndex}${i}`}
                        view="flat"
                        onClick={() => {
                          createDialog.mutate({ body: { id: emp.id } });
                        }}
                        className="h-auto justify-start pl-0"
                        loading={
                          createDialog.isPending &&
                          employeesData[pageIndex] &&
                          employeesData[pageIndex][i]?.id === emp.id
                        }
                      >
                        <User
                          size="l"
                          name={emp.name}
                          avatar={
                            <Avatar
                              size="l"
                              text={emp.name}
                              title={emp.name}
                              imgUrl={''} // TODO: пока на автомоках
                              alt={emp.name}
                            />
                          }
                        />
                      </Button>
                    )
                  ) : undefined
                )
              )}
            </InfiniteScroll>
            {/* {getEmployees.isFetchingNextPage && (
              <Spin
                size="xl"
                className="mx-auto"
              />
            )} */}
          </div>
        </ModalDialog.Body>
        {isCreateGroupChat ? (
          <ModalDialog.Footer
            onClickButtonCancel={() => setIsCreateDialogOpen(false)}
            propsButtonApply={{
              disabled: dialogNameValue.length < 3,
              loading: createDialog.isPending,
              size: 'l',
            }}
            propsButtonCancel={{ size: 'l', view: 'flat-secondary' }}
            onClickButtonApply={async () => {
              if (employeeChecked?.id) {
                const data = await createDialog
                  .mutateAsync({ body: { id: employeeChecked.id } })
                  .catch(error =>
                    toaster.add({
                      name: '/start Error',
                      content: 'Ошибка при создании чата',
                      theme: 'danger',
                    })
                  );
                if (!data) return;
                toaster.add({
                  name: '/start Success',
                  content: `Чат с сотрудником ${employeeChecked.name} создан`,
                  theme: 'success',
                });
                setIsCreateDialogOpen(false);
                setEmployeeChecked(null);
                navigateTo(URLS.messenger + '/' + data.id);
              } else
                toaster.add({
                  name: '/start noEmail',
                  content: 'Не выбран сотрудник',
                  theme: 'info',
                });
            }}
            textButtonApply="Создать"
            textButtonCancel="Закрыть"
          />
        ) : (
          <ModalDialog.Footer
            textButtonCancel="Закрыть"
            propsButtonCancel={{ size: 'l', view: 'flat-secondary' }}
          />
        )}
      </ModalDialog>
    </>
  );
}
