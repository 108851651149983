import { useToaster } from '@gravity-ui/uikit';
import { useAtomValue, useSetAtom } from 'jotai';

import { AuthForm, AuthFormProps } from '@features/auth-form';
import { URLS } from '@shared/consts';
import { useNavigateTo } from '@shared/lib';
import { tokenAtom } from '@shared/model/store';

import { usePostAuthSigninMutation } from '../api';
import { emailAuthAtom, loginSchema, resetAuthCredentials } from '../model';

export function Login() {
  const { navigateTo } = useNavigateTo();
  const emailValue = useAtomValue(emailAuthAtom);
  const resetCredentials = useSetAtom(resetAuthCredentials);
  const { add } = useToaster();
  const setToken = useSetAtom(tokenAtom);

  const postAuthSigninMutation = usePostAuthSigninMutation();

  const authProps: AuthFormProps<typeof loginSchema> = {
    schema: loginSchema,
    defaultValues: {
      email: emailValue,
      password: '',
    },
    async onValid({ email, password }) {
      try {
        const token = (await postAuthSigninMutation.mutateAsync({ body: { email, password } }))
          .token;
        setToken(token ? token : null);
        // удаляем из локал стораджа данные по авторизации
        resetCredentials();
        window.location.reload();
      } catch {
        add({
          name: 'invalid-credentials',
          title: 'Ошибка авторизации',
          theme: 'danger',
        });
      }
    },
    title: 'Авторизация',
    inputs: {
      email: {
        type: 'text',
        props: {
          placeholder: 'Почта',
          autoComplete: 'email',
        },
      },
      password: {
        type: 'password',
        props: {
          placeholder: 'Пароль',
          autoComplete: 'current-password',
        },
      },
    },
    buttons: {
      wrapperClassName: 'content-stretch',
      primaryButtonProps: {
        children: 'Войти',
        className: 'w-full',
        loading: postAuthSigninMutation.isPending,
      },
      secondButtonProps: {
        children: 'Забыли пароль',
        view: 'flat-danger',
        onClick: () => {
          navigateTo(URLS.auth.resetPassword);
        },
        className: 'w-full',
      },
    },
  };

  return <AuthForm {...authProps} />;
}
