import { dateTime } from '@gravity-ui/date-utils';

export function getIsSubmissionDeadlineExpired(submissionDeadline: string) {
  const submissionDeadlineDate = dateTime({
    input: submissionDeadline,
    timeZone: 'EAT',
  });

  const todayDate = dateTime({
    input: Date.now(),
    timeZone: 'EAT',
  });

  return submissionDeadlineDate.startOf('day').subtract(8, 'hour').isBefore(todayDate);
}
