import { Avatar, Text } from '@gravity-ui/uikit';

import { cn, parseISOString } from '@shared/lib';

import { formatLastMessageSendedAt } from '../lib';
import { DialogItemProps, ProcessStage } from '../model';

function stageToRussian(stage: ProcessStage) {
  switch (stage) {
    case 'CONTRACT':
      return 'Контракт';
    case 'TENDERS':
      return 'Торг';
    case 'OFFERS':
      return 'КП';
    default:
      return '';
  }
}
export function DialogItem({
  type,
  lastMessageSender,
  lastMessageText,
  online,
  selected,
  avatarImageURL,
  avatarName,
  lastMessageTimestamp,
  name,
  projectName,
  stage,
}: DialogItemProps) {
  const formattedLastMessageSendedAt = formatLastMessageSendedAt(
    parseISOString(lastMessageTimestamp)
  );
  return (
    <div
      className={cn('flex gap-3', {
        'bg-base-info-light outline rounded-[1px] outline-8 outline-base-info-light': selected,
      })}
    >
      <Avatar
        size="l"
        className="shrink-0"
        view={type === 'project' ? 'outlined' : 'filled'}
        text={type === 'project' ? stageToRussian(stage) : avatarName}
        alt={type === 'project' ? `${stageToRussian(stage)} ${projectName}` : avatarName}
        imgUrl={type === 'project' ? '' : avatarImageURL || ''}
        loading="lazy"
      />
      <div className="flex flex-col grow justify-center overflow-hidden">
        <div className="flex gap-2 items-center justify-between">
          <Text
            color="primary"
            variant="subheader-1"
            className="truncate relative pr-2.5"
          >
            {type === 'project' && `${stageToRussian(stage)} ${projectName}`}
            {type === 'person' && (
              <>
                {name}
                {online && (
                  <div className="absolute w-1.5 h-1.5 top-0 right-0 rounded-full bg-text-danger" />
                )}
              </>
            )}
          </Text>
          <Text
            color="secondary"
            variant="caption-1"
            className="shrink-0"
          >
            {formattedLastMessageSendedAt === '01.01.01' ? '' : formattedLastMessageSendedAt}
          </Text>
        </div>
        {type === 'project' && (
          <Text
            color="primary"
            variant="body-short"
            className="truncate"
          >
            {lastMessageSender}
          </Text>
        )}
        <Text
          color="secondary"
          variant="body-short"
          className="truncate"
        >
          {lastMessageText}
        </Text>
      </div>
    </div>
  );
}
