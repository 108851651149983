import { SupplierContractDeferral, SupplierContractEdo, SupplierContractStatus } from '@entities';
import { HandbookOption } from '@shared/model';

interface ContactOptions {
  status: HandbookOption<SupplierContractStatus>[];
  deferral: HandbookOption<SupplierContractDeferral>[];
  edo: HandbookOption<SupplierContractEdo>[];
}

export const SUPPLIER_CONTRACT_OPTIONS: ContactOptions = {
  deferral: [
    {
      value: 'Да',
      content: 'Да',
    },
    {
      value: 'Нет',
      content: 'Нет',
    },
  ],
  status: [
    {
      value: 'Действует',
      content: 'Действует',
    },
    {
      value: 'Не действует',
      content: 'Не действует',
    },
  ],
  edo: [
    {
      value: 'Есть и ДС подписан',
      content: 'Есть и ДС подписан',
    },
    {
      value: 'Есть и ДС не подписан',
      content: 'Есть и ДС не подписан',
    },
    {
      value: 'Нет',
      content: 'Нет',
    },
  ],
};
