import { ReactNode } from 'react';

import { CompetitorsForm, CompetitorsFormProps } from '@features';

interface CompetitorsLayoutProps {
  renderForm: (formComponent: ReactNode) => ReactNode;
  formProps: CompetitorsFormProps;
}

export function CompetitorsLayout({ renderForm, formProps }: CompetitorsLayoutProps) {
  return <>{renderForm(<CompetitorsForm {...formProps} />)}</>;
}
