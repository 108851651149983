import { useState } from 'react';

import {
  CRMTable,
  CRMTableColumns,
  CRMTableData,
  CRMTableSettings,
  useTableColumnSort,
} from '@features';

import { ColumnsSystemHandbookTable } from '../model';

type SystemHandbookTableProps = {
  data: string[];
};

export const SystemHandbookTable = ({ data }: SystemHandbookTableProps) => {
  const [tableData] = useState<CRMTableData<ColumnsSystemHandbookTable>>(
    data.map(value => ({ Название: value }))
  );

  const columns: CRMTableColumns<ColumnsSystemHandbookTable> = [
    {
      id: 'Название',
      meta: { selectedByDefault: true },
      placeholder: '',
      name: () => columnWithHover('Название'),
    },
  ];

  const initialSettings: CRMTableSettings<ColumnsSystemHandbookTable> = columns.map(col => ({
    id: col.id,
    isSelected: true,
  }));

  const { sortOrder, sortColumn, changeSortOrder } = useTableColumnSort(initialSettings);

  const columnWithHover = (name: ColumnsSystemHandbookTable[number]) => {
    return (
      <CRMTable.HoverColumnWrapper
        sort={sortOrder}
        iconPosition="end"
        content={name}
        columnName={name}
        currentColumnName={sortColumn}
        onSortTypeChange={(newSort, colName) => {
          changeSortOrder(newSort, colName);
          // setIsUpdateConfig(true);
        }}
      />
    );
  };

  // КОНЕЦ столбцы таблицы

  const [searchAttributesValue, setSearchAttributesValue] = useState('');

  function searchAttributes(value: string) {
    // TODO добавить обработку отправки запроса на сервер
    setSearchAttributesValue(value);
  }

  return (
    <CRMTable>
      <CRMTable.SearchAndActionsPanel
        searchValue={searchAttributesValue}
        onUpdate={searchAttributes}
        placeholder="Поиск по названию"
      />

      <CRMTable.Table<ColumnsSystemHandbookTable>
        hideScrollbar
        className="table-without-checkbox"
        data={tableData}
        columns={columns}
        settings={[]}
        updateSettings={() => {}}
        selectedIds={[]}
        onSelectionChange={() => {}}
        getRowDescriptor={(_, index) => {
          return {
            id: index.toString(),
            disabled: false,
          };
        }}
      />
    </CRMTable>
  );
};
