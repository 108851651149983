import { ReactNode } from 'react';

import { ContactsForm, ContactsFormProps } from '@features';

interface ContactsLayoutProps {
  renderForm: (formComponent: ReactNode) => ReactNode;
  formProps: ContactsFormProps;
}

export function ContactsLayout({ renderForm, formProps }: ContactsLayoutProps) {
  return <>{renderForm(<ContactsForm {...formProps} />)}</>;
}
