import {
  NotificationProps,
  Notifications,
  NotificationsPopupWrapper,
} from '@gravity-ui/components';
import { ArrowRightFromSquare, BellDot, CommentDot, Magnifier, Moon } from '@gravity-ui/icons';
import { ActionBar } from '@gravity-ui/navigation';
import { Box, Button, Icon, Loader, Overlay, Popup, Text, User } from '@gravity-ui/uikit';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';
import { useEffect, useRef, useState } from 'react';

import { useQueryGetUser, userAtom } from '@entities';
import { GlobalSearchDialog } from '@features';
import { $api, components, fetchClient } from '@shared/api';
import { subscribe } from '@shared/api/centrifuge';
import { URLS } from '@shared/consts';
import { cn, useNavigateTo } from '@shared/lib';
import { changeTheme, tokenAtom } from '@shared/model';

import './Header.css';

import { dateTime } from '@gravity-ui/date-utils';

const now = new Date();

export const Header = () => {
  const { navigateTo } = useNavigateTo();
  const setTheme = useSetAtom(changeTheme);
  const setToken = useSetAtom(tokenAtom);
  const setUser = useSetAtom(userAtom);

  const getUser = useQueryGetUser();

  useEffect(() => {
    if (getUser.data) setUser(getUser.data);
  }, [getUser.data]);

  const [isOpenSearch, setIsOpenSearch] = useState(false);

  const [isOpenNotifications, setIsOpenNotifications] = useState(false);

  const [isOpenMessages] = useState(false);

  const ref = useRef(null);

  const currentUser = useQueryGetUser();
  const [isActiveNotifications, setActiveNotifications] = useState(
    currentUser.data?.has_unread_notifications
  );
  const [notifications, setNotifications] = useState<components['schemas']['Notification'][]>([]);

  async function Logout() {
    setToken(null);
    navigateTo('/auth');
  }

  const notificationInfiniteQuery = useInfiniteQuery({
    queryKey: ['/notification/all'],
    queryFn: async ({ pageParam }) => {
      const { data } = await fetchClient.POST('/user/notifications', {
        body: {
          created_at: dateTime({input: pageParam}).add(3, 'hour'),
          limit: 10,
        },
      });

      return data?.list || [];
    },
    initialPageParam: now,
    getNextPageParam: lastPage => {
      if (lastPage?.length === 0) {
        return undefined;
      }

      return lastPage.at(-1)?.created_at;
    },
  });

  useEffect(() => {
    if (!currentUser.data?.id) return;
    const sub = subscribe(`system:${currentUser.data?.id}`, () =>
      fetchClient
        .GET('/chat/token/{id}', {
          params: {
            path: {
              id: 'system',
            },
          },
        })
        .then(res => res.data?.message ?? '')
    );

    sub?.on(
      'publication',
      ({ data }: { data?: { data: components['schemas']['Notification'] } }) => {
        console.log(data);
        if (!data?.data) return;
        setNotifications(prev => [...(prev || []), data?.data]);
        setActiveNotifications(true);
      }
    );

    sub?.subscribe();

    return () => sub?.unsubscribe();
  }, [currentUser.data?.id]);

  const readMutation = $api.useMutation('put', '/user/notifications');

  return (
    <header>
      <ActionBar
        aria-label="Header"
        className="py-1 !h-auto"
      >
        <ActionBar.Section type="primary">
          <ActionBar.Group pull="right">
            {/* Поиск */}
            <ActionBar.Item className="h-full flex items-center justify-center">
              <Button
                onClick={() => setIsOpenSearch(!isOpenSearch)}
                ref={ref}
                view="flat"
                size="xl"
              >
                <Icon
                  data={Magnifier}
                  className="text-text-misc"
                  size="18"
                />
                <Text variant="caption-2">Поиск</Text>
              </Button>
              <GlobalSearchDialog
                open={isOpenSearch}
                onClose={() => setIsOpenSearch(false)}
              />
            </ActionBar.Item>

            <ActionBar.Separator />

            {/* Уведомления */}
            <ActionBar.Item className="h-full flex items-center justify-center">
              <Button
                onClick={() => {
                  if (isActiveNotifications) setActiveNotifications(false);
                  const lastCreatedAt = [
                    ...notifications,
                    ...(notificationInfiniteQuery.data?.pages.flat() || []),
                  ].at(0)?.created_at;
                  setIsOpenNotifications(!isOpenNotifications);
                  readMutation.mutate({
                    body: {
                      created_at: lastCreatedAt,
                    },
                  });
                }}
                ref={ref}
                size="xl"
                view="flat"
              >
                <Icon
                  data={BellDot}
                  className={cn('text-text-misc notification-dot', {
                    active: isActiveNotifications,
                  })}
                  size="18"
                />
              </Button>
              <Popup
                open={isOpenNotifications}
                anchorRef={ref}
              >
                <NotificationsPopupWrapper>
                  <Notifications
                    notifications={
                      [
                        ...notifications,
                        ...(notificationInfiniteQuery.data?.pages.flat() ?? []),
                      ].map(
                        (notification, i) =>
                          ({
                            id: `notification-${i}`,
                            title: (
                              <Text
                                variant="subheader-2"
                                color="primary"
                                className="mb-2"
                              >
                                {notification.title}
                              </Text>
                            ),
                            content: (
                              <p
                                className="notification"
                                dangerouslySetInnerHTML={{
                                  __html: notification.description || '',
                                }}
                              />
                            ),
                            formattedDate: (() => {
                              const lastMessageDate = dateTime({ input: notification.created_at });
                              return lastMessageDate.isSame(Date.now(), 'day')
                                ? lastMessageDate.format('HH:mm')
                                : lastMessageDate.format('DD.MM.YY');
                            })(),
                          }) as NotificationProps
                      ) ?? []
                    }
                    emptyTitle={
                      !notificationInfiniteQuery.data?.pages.length && 'Нет новых уведомлений'
                    }
                  />
                </NotificationsPopupWrapper>
              </Popup>
            </ActionBar.Item>

            {/* Перейти на страницу мессенджера */}
            <ActionBar.Item className="h-full flex items-center justify-center">
              <Button
                onClick={() => navigateTo(URLS.messenger)}
                ref={ref}
                size="xl"
                view="flat"
              >
                <Icon
                  data={CommentDot}
                  className="text-text-misc"
                  size="18"
                />
              </Button>
              <Popup
                open={isOpenMessages}
                anchorRef={ref}
              >
                Чаты
              </Popup>
            </ActionBar.Item>

            <ActionBar.Separator />

            {/* Аватар / Профиль */}
            <ActionBar.Item className="h-full flex items-center justify-center">
              <Button
                title="Профиль"
                view="flat"
                className="h-full flex items-center justify-center px-0"
                onClick={() => navigateTo(URLS.profile)}
              >
                <Box
                  position="relative"
                  className="pl-2 pr-4"
                >
                  {getUser.data ? (
                    <User
                      avatar={{
                        title: getUser.data.name_initials,
                        alt: getUser.data.name_initials,
                        fallbackImgUrl: undefined,
                        loading: 'lazy',
                        imgUrl: getUser.data.photo || '',
                      }}
                      name={getUser.data.name_initials}
                      description={getUser.data.position}
                      size="s"
                    />
                  ) : (
                    <User
                      avatar={{
                        title: 'Пользователь',
                        alt: 'Пользователь',
                        fallbackImgUrl: undefined,
                        loading: 'lazy',
                      }}
                      name={'Пользователь'}
                      description={'Битуджи Тех'}
                      size="s"
                    />
                  )}
                  <Overlay
                    visible={getUser.isLoading || getUser.isError}
                    className="size-full"
                  >
                    <Loader />
                  </Overlay>
                </Box>
              </Button>
            </ActionBar.Item>

            <ActionBar.Separator />

            {/* Смена темы */}
            <ActionBar.Item className="h-full flex items-center justify-center">
              <Button
                title="Сменить тему"
                size="xl"
                view="flat"
                onClick={setTheme}
              >
                <Icon
                  data={Moon}
                  className="text-text-misc"
                  size="18"
                />
              </Button>
            </ActionBar.Item>
            <ActionBar.Separator />
            <ActionBar.Item className="h-full flex items-center justify-center">
              <Button
                title="Выход"
                onClick={() => Logout()}
                size="xl"
                view="flat"
              >
                <Icon
                  data={ArrowRightFromSquare}
                  className="text-text-misc"
                  size="18"
                />
              </Button>
            </ActionBar.Item>
          </ActionBar.Group>
        </ActionBar.Section>
      </ActionBar>
    </header>
  );
};
