import { LabelProps } from '@gravity-ui/uikit';

import { ContractBasis, ContractFZ, ContractStage } from '../model';

export const convertContractStageToLabelTheme = (stage: ContractStage): LabelProps['theme'] => {
  switch (stage) {
    case 'Проект':
      return 'normal';
    case 'Заключен':
      return 'info';
    case 'Исполнен':
      return 'success';
    case 'Расторгнут':
      return 'danger';
    case 'Рассчитан':
      return 'utility';
  }
};

export const convertContractFZToLabelTheme = (fz: ContractFZ): LabelProps['theme'] => {
  switch (fz) {
    case '223-ФЗ':
    case '44-ФЗ':
      return 'normal';
    case 'КОМ.':
      return 'info';
  }
};

export const convertContractBasisToLabelTheme = (basis: ContractBasis): LabelProps['theme'] => {
  switch (basis) {
    case 'Тендер':
      return 'normal';
    case 'Прямая закупка без договора':
      return 'success';
    case 'Прямая закупка с договором':
      return 'utility';
  }
};
