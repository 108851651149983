import { Magnifier } from '@gravity-ui/icons';
import { Button, ButtonProps, Icon } from '@gravity-ui/uikit';

type SearchInChatButtonProps = Omit<ButtonProps, 'size' | 'view' | 'children'>;

export function SearchInChatButton(props: SearchInChatButtonProps) {
  return (
    <Button
      {...props}
      size="l"
      view="flat"
    >
      <Icon data={Magnifier} />
    </Button>
  );
}
