import { ReactNode } from 'react';

import { PaymentDetailsForm, PaymentDetailsFormProps } from '@features';

interface PaymentDetailsLayoutProps {
  renderForm: (formComponent: ReactNode) => ReactNode;
  formProps: PaymentDetailsFormProps;
}

export function PaymentDetailsLayout({ renderForm, formProps }: PaymentDetailsLayoutProps) {
  return <>{renderForm(<PaymentDetailsForm {...formProps} />)}</>;
}
