import { Comments } from '@gravity-ui/icons';
import { Button, Icon, RadioButton, Spin, Text, Tooltip, useToaster } from '@gravity-ui/uikit';
import { useQueryClient } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';

import {
  $agreementHooks,
  $contractHooks,
  createdCompetitorForViewAtom,
  createdCustomerForViewAtom,
  createdEmployeeForViewAtom,
  createdLegalEntityForViewAtom,
  createdPurchaseObjectForViewAtom,
  createdRegionForViewAtom,
  createdSupplierForViewAtom,
  userAtom,
} from '@entities';
import { $commercialOfferHooks, CommercialOfferStatuses } from '@entities/commercial-offers-entity';
import {
  createdTenderForViewAtom,
  getAgreementSubmitCommercialOffer,
  getIsProducementEndAtExpired,
  getIsSubmissionDeadlineExpired,
} from '@features';
import { URLS } from '@shared/consts';
import { roundPercentAndCurrencyToBackend, useNavigateTo } from '@shared/lib';
import { RightSidebar } from '@shared/ui';

import {
  checkIsNotBlankRelations,
  countRelations,
  percentAndCurrencyValuesFromBackend,
  percentAndCurrencyValuesToBackend,
} from '../lib';
import { CommercialOfferLayout } from './CommercialOfferLayout';
import { CommercialOfferRelations } from './CommercialOfferRelations';

type EditCommercialOfferProps = {
  id?: string;
  onClose: () => void;
};

export function EditCommercialOffer({ id, onClose }: EditCommercialOfferProps) {
  const [isOpen, setIsOpen] = useState(false);

  const queryClient = useQueryClient();

  const user = useAtomValue(userAtom);

  const { navigateTo } = useNavigateTo();
  const { add } = useToaster();

  const [activeTab, setActiveTab] = useState<'project' | 'relations'>('project');

  // atoms
  const setCreatedTenderForView = useSetAtom(createdTenderForViewAtom);
  const setCreatedEmployeeForView = useSetAtom(createdEmployeeForViewAtom);
  const setCreatedCustomerForView = useSetAtom(createdCustomerForViewAtom);
  const setCreatedRegionForView = useSetAtom(createdRegionForViewAtom);
  const setCreatedPurchaseObjectForView = useSetAtom(createdPurchaseObjectForViewAtom);
  const setCreatedSupplierForView = useSetAtom(createdSupplierForViewAtom);
  const setCreatedLegalEntityForView = useSetAtom(createdLegalEntityForViewAtom);
  const setCreatedCompetitorForView = useSetAtom(createdCompetitorForViewAtom);
  //

  // Queries
  const createAgreementMutation = $agreementHooks.create();

  const createContractQuery = $contractHooks.create();

  const getCommercialOfferQuery = $commercialOfferHooks.getById(id || '', !!id);
  const updateCommercialOfferMutation = $commercialOfferHooks.update();
  const deleteCommercialOfferMutation = $commercialOfferHooks.delete();

  const getCommercialOfferRelatedQuery = $commercialOfferHooks.related(id || '', !!id);
  //

  useEffect(() => {
    if (getCommercialOfferQuery.error) {
      navigateTo(URLS.processes.commercialOffers);
      add({
        theme: 'danger',
        name: 'update-offer-error',
        title: 'Произошла ошибка при получении КП',
      });
    }
  }, [getCommercialOfferQuery.isFetching]);

  // const commercialOfferValues = commercialOfferForm.watch();

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (id) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
      setCreatedTenderForView(undefined);
      setCreatedEmployeeForView(undefined);
      setCreatedCustomerForView(undefined);
      setCreatedRegionForView(undefined);
      setCreatedPurchaseObjectForView(undefined);
      setCreatedSupplierForView(undefined);
      setCreatedLegalEntityForView(undefined);
      setCreatedCompetitorForView(undefined);
    }
  }, [id]);

  // useEffect(() => {
  //   if (getCommercialOfferQuery.data) {
  //     const dataWithoutPercentFormat: CommercialOfferSchema = {
  //       ...getCommercialOfferQuery.data,
  //     };
  //     commercialOfferForm.reset(percentAndCurrencyValuesFromBackend(dataWithoutPercentFormat));
  //   }
  // }, [getCommercialOfferQuery.data]);

  useEffect(() => {
    // Уведомления о создании карточки согласования
    if (createAgreementMutation.isSuccess) {
      add({
        theme: 'success',
        name: 'create-agreement-success',
        title: 'Согласование создано',
      });
    }
    if (createAgreementMutation.isError) {
      add({
        theme: 'danger',
        name: 'create-agreement-error',
        title: 'Не удалось создать Согласование',
      });
    }
  }, [createAgreementMutation.isPending]);

  useEffect(() => {
    // Уведомления об обновлении карточки КП
    if (updateCommercialOfferMutation.isSuccess) {
      add({
        theme: 'success',
        name: 'update-offer-success',
        title: 'Карточка КП обновлена успешно',
      });
    }
    if (updateCommercialOfferMutation.isError) {
      add({
        theme: 'danger',
        name: 'update-offer-error',
        title: 'Ошибка при обновлении карточки КП',
      });
    }
  }, [updateCommercialOfferMutation.isPending]);

  return (
    <CommercialOfferLayout
      formProps={{
        onClickCancelButton: () => {
          setIsOpen(false);
          handleClose();
        },
        response: getCommercialOfferQuery.data
          ? {
              ...getCommercialOfferQuery.data,
              ...percentAndCurrencyValuesFromBackend(getCommercialOfferQuery.data),
            }
          : undefined,
        hide: activeTab !== 'project',
        disabled:
          getCommercialOfferQuery.data?.is_under_approval ||
          !getCommercialOfferQuery.data?.is_editable ||
          getCommercialOfferQuery.data?.['status'] === 'Торги опубликованы' ||
          getCommercialOfferQuery.data?.['status'] === 'Торги не отработаны' ||
          getCommercialOfferQuery.data?.['status'] === 'Выигран' ||
          getCommercialOfferQuery.data?.['status'] === 'Проигран' ||
          getCommercialOfferQuery.data?.['status'] === 'Отмена' ||
          getCommercialOfferQuery.data?.['status'] === 'Не подано',
        allowedStatuses: values => {
          if (
            !getCommercialOfferQuery.data?.is_blocked_work_params ||
            !getCommercialOfferQuery.data
          ) {
            return ['Подача'];
          }

          if (getCommercialOfferQuery.data.status === 'Подача') {
            const statuses: CommercialOfferStatuses[] = ['Подача', 'Ожидание'];

            return statuses;
          }

          if (getCommercialOfferQuery.data.status === 'Ожидание') {
            const statuses: CommercialOfferStatuses[] = ['Отказ', 'Отмена', 'Ожидание'];

            const isProducementEndAtExpired = getIsProducementEndAtExpired(
              values.producement_end_at
            );

            const isSubmissionDeadlineExpired = getIsSubmissionDeadlineExpired(
              values.submission_deadline
            );
            if (isSubmissionDeadlineExpired) statuses.push('Не подано');

            if (values.purpose === 'Закупка ЕП') {
              return ['Выигран', 'Проигран', ...statuses];
            }

            if (values.purpose === 'Обоснование НМЦК') {
              if (isProducementEndAtExpired) statuses.push('Торги не отработаны');
              return ['Торги опубликованы', ...statuses];
            }
          }

          if (getCommercialOfferQuery.data.purpose === 'Обоснование НМЦК') {
            return ['Торги опубликованы', 'Торги не отработаны', 'Отказ', 'Отмена', 'Не подано'];
          }

          if (getCommercialOfferQuery.data.purpose === 'Закупка ЕП') {
            return ['Выигран', 'Проигран', 'Отказ', 'Отмена', 'Не подано'];
          }

          return ['Подача'];
        },
        hideTitleField: true,
        onValid: async values => {
          if (!id) return;
          // Закрыть для редактирования при следующих статусах КП
          const isEditable = !(
            values['status'] === 'Торги опубликованы' ||
            values['status'] === 'Торги не отработаны' ||
            values['status'] === 'Выигран' ||
            values['status'] === 'Проигран' ||
            values['status'] === 'Отказ' ||
            values['status'] === 'Отмена' ||
            values['status'] === 'Не подано'
          );

          const prevIsBlockedWorkParams = !!getCommercialOfferQuery.data?.is_blocked_work_params;

          const valuesWithoutWinnerWithType: typeof values = {
            ...values,
            winner_with_type: undefined,
          };
          // Апдейт карточки
          await updateCommercialOfferMutation
            .mutateAsync({
              params: { path: { id: id } },
              body: {
                data: {
                  ...percentAndCurrencyValuesToBackend(valuesWithoutWinnerWithType),
                  // is_under_approval: true,
                  is_editable: isEditable,
                  is_blocked_work_params:
                    prevIsBlockedWorkParams ||
                    getAgreementSubmitCommercialOffer(
                      valuesWithoutWinnerWithType,
                      prevIsBlockedWorkParams
                    ),
                },
                meta: { id: id, user_name: user?.full_name },
              },
            })
            .then(async () => {
              queryClient.invalidateQueries({ queryKey: ['/offer/all'] });
              // Создание карточки согласования
              if (values['status'] === 'Отказ') {
                await createAgreementMutation.mutateAsync({
                  body: {
                    assigned_to_id: getCommercialOfferQuery.data?.responsible_manager_id,
                    coordination_type: 'REJECTION',
                    entity_id: id,
                    entity_type: 'OFFER',
                  },
                });
                onClose();
                return;
              }
              if (
                !prevIsBlockedWorkParams &&
                getAgreementSubmitCommercialOffer(values, prevIsBlockedWorkParams)
              ) {
                await createAgreementMutation.mutateAsync({
                  body: {
                    assigned_to_id: getCommercialOfferQuery.data?.responsible_manager_id,
                    coordination_type: 'OFFER_PRICE',
                    entity_id: id,
                    entity_type: 'OFFER',
                  },
                });
              }

              // Переход в Контракты
              if (
                values['purpose'] === 'Закупка ЕП' &&
                values['status'] === 'Выигран' &&
                values.final_price &&
                values.provider_id &&
                values.basis_purchase &&
                values.purchase_object_id
              ) {
                await createContractQuery
                  .mutateAsync({
                    // TODO: На бэке лишние поля
                    body: {
                      responsible_id: values['responsible_id'],
                      customer_id: values['customer_id'],
                      region_id: values.delivery_region_id,
                      object_ids: [values.purchase_object_id],
                      basis: values.basis_purchase,
                      delivery_address: values['delivery_address'],
                      delivery_term: values['delivery_deadline'],
                      actual_files: values['calculation_file']
                        ? [values['calculation_file']]
                        : undefined,
                      basis_files: values.offer_file ? [values.offer_file] : undefined,
                      supplier_id: values.provider_id,
                      contract_sum: roundPercentAndCurrencyToBackend(values.final_price),
                      name: 'Без названия',
                    },
                  })
                  .then(() => {
                    queryClient.invalidateQueries({ queryKey: ['/contract/all'] });
                    add({
                      theme: 'success',
                      name: 'create-contract-success',
                      content:
                        'Контракт Без названия создан! Добавьте уникальный номер, чтобы появилось название',
                      autoHiding: false,
                    });
                  })
                  .catch(() => {
                    add({
                      theme: 'danger',
                      name: 'create-contract-error',
                      title: 'Ошибка при автоматическом создании карточки Контракта',
                      autoHiding: false,
                    });
                  });
              }

              onClose();
            });
        },
        onInvalid: errors => console.log('@invalid', errors),
      }}
      renderForm={formComponent => (
        <RightSidebar
          id="sidebar-commercial-offer"
          onClose={() => {
            setIsOpen(false);
            handleClose();
          }}
          open={isOpen}
        >
          <RightSidebar.Header
            title={
              getCommercialOfferQuery.data?.title || 'Редактирование коммерческого предложения'
            }
            onClose={() => {
              setIsOpen(false);
              handleClose();
            }}
            dropdownMenuItems={
              user?.is_admin && !!id
                ? [
                    {
                      theme: 'danger',
                      text: 'Удалить',
                      action: () => {
                        deleteCommercialOfferMutation
                          .mutateAsync({ params: { path: { id: id } } })
                          .then(() => {
                            add({
                              theme: 'success',
                              name: 'delete-offer-success',
                              title: 'Карточка удалена успешно',
                            });
                            queryClient.invalidateQueries({ queryKey: ['/offer/all'] });
                            onClose();
                          })
                          .catch(() => {
                            add({
                              theme: 'danger',
                              name: 'delete-offer-error',
                              title: 'Ошибка при удалении карточки',
                            });
                          });
                      },
                    },
                  ]
                : undefined
            }
          />
          <div className="px-8 pb-8 flex justify-between">
            <RadioButton
              size="l"
              onUpdate={setActiveTab}
              options={[
                { value: 'project', content: 'О проекте' },
                {
                  value: 'relations',
                  content: (
                    <span>
                      Связи
                      <Text
                        variant="body-1"
                        color="hint"
                        className="ml-1"
                      >
                        {countRelations(getCommercialOfferRelatedQuery.data)}
                      </Text>
                    </span>
                  ),
                },
              ]}
            />
            <Tooltip
              content="Перейти в чат"
              openDelay={200}
            >
              <Button
                size="l"
                onClick={() =>
                  navigateTo(URLS.messenger + '/' + getCommercialOfferQuery.data?.chat_id || '')
                }
              >
                <Icon data={Comments} />
                Чат
              </Button>
            </Tooltip>
          </div>
          {formComponent}
          {activeTab === 'relations' && (
            <>
              {(getCommercialOfferRelatedQuery.isLoading || getCommercialOfferQuery.isLoading) && (
                <Spin
                  size="l"
                  className="mx-8"
                />
              )}
              {(getCommercialOfferRelatedQuery.isError || getCommercialOfferQuery.isError) && (
                <Text
                  className="mx-8"
                  color="danger-heavy"
                  variant="body-2"
                >
                  Ошибка загрузки связей
                </Text>
              )}
              {getCommercialOfferRelatedQuery.data &&
              checkIsNotBlankRelations(getCommercialOfferRelatedQuery.data) &&
              getCommercialOfferQuery.data ? (
                <CommercialOfferRelations
                  relations={getCommercialOfferRelatedQuery.data}
                  offer={getCommercialOfferQuery.data}
                />
              ) : (
                countRelations(getCommercialOfferRelatedQuery.data) === 0 && (
                  <Text
                    className="mx-8 font-semibold"
                    variant="body-2"
                  >
                    Связей не обнаружено
                  </Text>
                )
              )}
            </>
          )}
        </RightSidebar>
      )}
    />
  );
}
