import { CommercialOfferSchema } from '../model';

export function getAgreementSubmit(values: CommercialOfferSchema, is_blocked_work_params: boolean) {
  const withoutZakupkaEP = !!(
    !is_blocked_work_params &&
    values.calculation_file &&
    typeof values.cost_price === 'number' &&
    typeof values.offer_price === 'number' &&
    typeof values.margin === 'number' &&
    values.offer_file &&
    values.provider_id
  );
  if (values.purpose === 'Закупка ЕП') {
    return !!(withoutZakupkaEP && values.proposed_price && values.price_justification);
  }
  return withoutZakupkaEP && values.purpose === 'Обоснование НМЦК';
}
