import { nanoid } from 'nanoid';

import { LOCAL_STORAGE } from '@shared/consts';
import { customStorage } from '@shared/lib';

import { fetchClient } from '../client';
import { bodyResponseType, ParametersPathType } from '../model';

// const getAWSCredentials = async () =>
//   await fetchClient
//     .GET('/user/aws-presigned')
//     .catch(error => console.log(error))
//     .then(data => data?.data);

const uploadFile = (
  path: ParametersPathType<'post', '/uploads/{uuid}/{web_uuid}/{file_name}'>,
  file: FormData
) =>
  // @ts-expect-error HACK: ошибка в типизации openapi-typescript
  fetchClient.POST('/uploads/{uuid}/{web_uuid}/{file_name}', { params: { path }, body: file });

export const uploadFileToS3 = async (file: File) => {
  // const data = await getAWSCredentials();
  // if (!data) throw new Error('Не получены credentials от AWS для s3');
  // const uuid = nanoid(10);
  // const formData = new FormData();
  // data['key'] = data.key + '/' + uuid + '/' + file.name;
  // for (const key of objectKeysSafeToArray(data).sort()) {
  //   if (key !== 'url') formData.append(key, data[key]!);
  // }
  // formData.append('file', file);
  // return {
  //   key: data.key,
  //   fetch: fetch(data.url, {
  //     body: formData,
  //     method: 'POST',
  //   }),
  // };
  const userId = customStorage<null | bodyResponseType<'get', '/user/'>>().getItem(
    LOCAL_STORAGE.user,
    null
  );
  if (!userId) throw new Error('Текущий пользователь не найден, невозможно загрузить файл');
  const uuid = nanoid(10);
  const formData = new FormData();
  const keyToBack = 'uploads/' + userId.id + '/' + uuid + '/' + file.name;
  formData.append('file', file);
  return {
    key: keyToBack,
    fetch: uploadFile({ file_name: file.name, uuid: userId.id, web_uuid: uuid }, formData),
  };
};

export async function downloadFileFromServer(url: string, fileName: string) {
  try {
    const token = customStorage<string | null>().getItem(LOCAL_STORAGE.token, null);
    const isGosplan = url.includes('https://zakupki.gov.ru');
    const gosplanHeaders = new Headers({
      'Access-Control-Expose-Headers': 'Content-Disposition',
      'Content-type': 'application/json',
    });
    if (!token) throw new Error('Не авторизован');
    const headers = new Headers({
      Authorization: token,
      'Content-type': 'application/json',
    });
    let gosplanFilename = 'file';
    await fetch(url, {
      method: 'get',
      mode: isGosplan ? 'no-cors' : 'cors',
      referrerPolicy: 'no-referrer',
      headers: isGosplan ? gosplanHeaders : headers,
    })
      .then(res => {
        console.log(res);
        if (!res.ok) {
          throw Error(res.statusText);
        }

        // We are reading the *Content-Disposition* header for getting the original filename given from the server
        const header = res.headers.get('Content-Disposition');
        const parts = header?.split(';');
        const maybeFilename = parts?.[1]?.split('=')?.[1]?.replaceAll('"', '');
        if (maybeFilename) gosplanFilename = maybeFilename;

        return res.blob();
      })
      .then(res => {
        if (!res.size) throw new Error('Невозможно получить файл');
        const aElement = document.createElement('a');
        aElement.setAttribute(
          'download',
          isGosplan && gosplanFilename ? gosplanFilename : fileName
        );
        const href = URL.createObjectURL(res);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      });
  } catch (e) {
    console.log(e);
    throw new Error('Невозможно получить файл');
  }
}
