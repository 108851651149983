import { atom, ExtractAtomValue } from 'jotai';
import { UseFormReturn } from 'react-hook-form';

import {
  createdCompetitorForViewAtom,
  createdCustomerForViewAtom,
  createdLegalEntityForViewAtom,
} from '@entities';

import { TenderSchema } from '../schemas';

export const tenderFormAtom = atom<UseFormReturn<TenderSchema> | null>(null);

export const createdCustomerOrganizerForViewAtom =
  atom<ExtractAtomValue<typeof createdCustomerForViewAtom>>();
export const createdLegalEntityParticipateAsForViewAtom =
  atom<ExtractAtomValue<typeof createdLegalEntityForViewAtom>>();
export const createdLegalEntityWinnerForViewAtom =
  atom<ExtractAtomValue<typeof createdLegalEntityForViewAtom>>();
export const createdCompetitorParticipantsForViewAtom =
  atom<ExtractAtomValue<typeof createdCompetitorForViewAtom>>();
