import { CounterpartyType } from '@entities';
import { yupRu } from '@shared/config';
import { schemaFields } from '@shared/lib';

export const paymentDetailsSchema = yupRu.object({
  /** @description Наименование банка */
  bank_name: schemaFields.text().required(),
  /** @description Контрагент */
  counterparty_id: yupRu
    .string()
    .required()
    .transform(v => v || undefined),
  /** @description Тип контрагента */
  counterparty_type: schemaFields
    .radio<CounterpartyType>(['user', 'customer', 'supplier'])
    .required(),
  counterparty_with_type: yupRu
    .string<`${string}/${CounterpartyType}`>()
    .transform(value => value || undefined),
  /** @description Р/с получателя */
  recipient_rs: schemaFields.text().required(),
  /** @description БИК банка получателя */
  recipient_bank_bik: schemaFields.text().required(),
  /** @description К/с банка получателя */
  recipient_bank_ks: schemaFields.text(),
  /** @description Л/с (при наличии) */
  personal_account: schemaFields.text(),
});

export type PaymentDetailsSchema = yupRu.InferType<typeof paymentDetailsSchema>;
