import { SupplierContractDeferral, SupplierContractEdo, SupplierContractStatus } from '@entities';
import { yupRu } from '@shared/config';
import { schemaFields } from '@shared/lib';

export const supplierContractsSchema = yupRu.object({
  /**
   * @description Статус договорае действует
   * @enum {string}
   */
  status: schemaFields.radio<SupplierContractStatus>(['Действует', 'Не действует']).required(),
  /** @description Покупатель */
  buyer_id: schemaFields.id().required(),
  /** @description Поставщик */
  supplier_id: schemaFields.id(),
  /** @description Предмет договора */
  object: schemaFields.text().required(),
  /** @description Номер договора */
  number: schemaFields.text().required(),
  /**
   * Format: date-time
   * @description Дата договора
   */
  date: schemaFields.date().required(),
  /** @description Цена договора */
  price: schemaFields.currency().required(),
  /** @description Комментарий */
  comment: schemaFields.textArea(),
  /** @description Файл договора */
  file: schemaFields.file().required(),
  /**
   * @description Отсрочка
   * @enum {string}
   */
  deferral: schemaFields.radio<SupplierContractDeferral>(['Да', 'Нет']).required(),
  /**
   * @description ЭДО
   * @enum {string}
   */
  edo: schemaFields.radio<SupplierContractEdo>([
    'Есть и ДС не подписан',
    'Есть и ДС подписан',
    'Нет',
  ]),
});

export type SupplierContractsSchema = yupRu.InferType<typeof supplierContractsSchema>;
