import { ReactNode } from 'react';

import { DepartmentsForm, DepartmentsFormProps } from '@features';

interface DepartmentsLayoutProps {
  renderForm: (formComponent: ReactNode) => ReactNode;
  formProps: DepartmentsFormProps;
}

export function DepartmentsLayout({ renderForm, formProps }: DepartmentsLayoutProps) {
  return <>{renderForm(<DepartmentsForm {...formProps} />)}</>;
}
