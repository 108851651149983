import { yupRu } from '@shared/config';
import { PriorityUnionStrings } from '@shared/model';

import { emailRegex, phoneRegex } from '../validation';

const idFieldSchema = () => yupRu.string().transform(v => v || undefined);
const textFieldSchema = () =>
  yupRu
    .string()
    .min(3)
    .max(255)
    .transform(v => v || undefined);
const textAreaFieldSchema = () =>
  yupRu
    .string()
    .min(3)
    .max(255)
    .transform(v => v || undefined);
const currencyFieldSchema = () =>
  yupRu
    .number()
    .min(0.01)
    .transform(v => (typeof v === 'number' ? v : undefined));
const percentageFieldSchema = () =>
  yupRu
    .number()
    .max(99.99)
    .transform(v => (typeof v === 'number' ? v : undefined));
const radioFieldSchema = <OneOf extends string>(oneOf: OneOf[]) =>
  yupRu
    .string()
    .oneOf<OneOf>(oneOf)
    .transform(v => v || undefined);
const dateFieldSchema = () => yupRu.string().transform(v => v || undefined);
// MultiDate не использовался
const fileFieldSchema = () => yupRu.string().transform(v => v || undefined);
const multiFileFieldSchema = () =>
  yupRu.array(yupRu.string().required()).transform(v => (v.length ? v : undefined));
const selectFieldSchema = () => yupRu.string().transform(v => v || undefined);
const multiSelectFieldSchema = () =>
  yupRu.array(yupRu.string().required()).transform(v => (v.length ? v : undefined));
const phoneFieldSchema = () =>
  yupRu
    .string()
    .matches(phoneRegex, {
      message: 'Некорректный номер телефона',
      excludeEmptyString: true,
    })
    .transform(v => v || undefined);
const progressFieldSchema = () =>
  yupRu
    .number()
    .min(0)
    .max(100)
    .transform(v => v || undefined);
const emailFieldSchema = () =>
  yupRu
    .string()
    .matches(emailRegex, {
      message: 'Некорректный e-mail',
      excludeEmptyString: true,
    })
    .transform(value => value || undefined);
const priorityStringFieldSchema = () =>
  yupRu
    .string()
    .oneOf<PriorityUnionStrings>(['1', '2', '3', '4', '5'])
    .transform(v => v || undefined);
const tagsFieldSchema = () =>
  yupRu.array(textFieldSchema().required()).transform(v => (v.length ? v : undefined));

export const schemaFields = {
  id: idFieldSchema,
  text: textFieldSchema,
  textArea: textAreaFieldSchema,
  currency: currencyFieldSchema,
  percentage: percentageFieldSchema,
  radio: radioFieldSchema,
  date: dateFieldSchema,
  file: fileFieldSchema,
  multiFile: multiFileFieldSchema,
  select: selectFieldSchema,
  multiSelect: multiSelectFieldSchema,
  phone: phoneFieldSchema,
  progress: progressFieldSchema,
  email: emailFieldSchema,
  priorityString: priorityStringFieldSchema,
  tags: tagsFieldSchema,
};
