import { bodyResponseType } from '@shared/api';

import { CreatePaymentDetails } from './CreatePaymentDetails';
import { EditPaymentDetails } from './EditPaymentDetails';

type PaymentDetailsDrawerProps = {
  onClose: () => void;
  withDialog?: boolean;
} & (
  | {
      type: 'create';
      open: boolean;
      id?: undefined;
      afterCreate?: (
        createdPaymentDetail: bodyResponseType<'post', '/handbook/payment-details'>
      ) => void;
    }
  | {
      type: 'edit';
      open?: undefined;
      id?: string;
      afterCreate?: undefined;
    }
);

export function PaymentDetailsDrawer({
  type,
  open,
  onClose,
  id,
  afterCreate,
  withDialog,
}: PaymentDetailsDrawerProps) {
  return (
    <>
      {type === 'create' ? (
        <CreatePaymentDetails
          open={open}
          onClose={onClose}
          afterCreate={afterCreate}
          withDialog={withDialog}
        />
      ) : (
        <EditPaymentDetails
          onClose={onClose}
          id={id}
        />
      )}
    </>
  );
}
