import { atomWithStorage } from 'jotai/utils';

import { bodyResponseType } from '@shared/api';
import { LOCAL_STORAGE } from '@shared/consts';
import { customStorage } from '@shared/lib';

export const userAtom = atomWithStorage<null | bodyResponseType<'get', '/user/'>>(
  LOCAL_STORAGE.user,
  customStorage<null | bodyResponseType<'get', '/user/'>>().getItem(LOCAL_STORAGE.user, null),
  customStorage()
);
