import { ArrowLeft, Xmark } from '@gravity-ui/icons';
import { Button, Dialog, Icon } from '@gravity-ui/uikit';
import { ReactNode } from 'react';

interface CreateHandbookDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  children?: ReactNode;
}

export function CreateHandbookDialog({
  onClose,
  open,
  title,
  children,
}: CreateHandbookDialogProps) {
  return (
    <Dialog
      hasCloseButton={false}
      open={open}
      onClose={onClose}
      className="w-[688px] create-handbook-dialog"
    >
      <Dialog.Body className="p-0 h-[calc(100vh_-_96px)] hide-scrollbar flex-col flex ">
        <Dialog.Header
          caption={title}
          className="h-[72px] px-8"
          insertBefore={
            <Button
              size="l"
              view="flat"
              className="mr-1"
              onClick={onClose}
            >
              <Icon
                data={ArrowLeft}
                size={20}
              />
            </Button>
          }
          insertAfter={
            <Button
              view="flat"
              size="l"
              className="ml-auto"
              onClick={onClose}
            >
              <Icon
                data={Xmark}
                size={20}
              />
            </Button>
          }
        />
        {children}
      </Dialog.Body>
    </Dialog>
  );
}
