import { useToaster } from '@gravity-ui/uikit';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import { $okpd2sHooks } from '@entities';
import { CreateHandbookDialog, okpd2sFormAtom } from '@features';
import { bodyResponseType } from '@shared/api';
import { RightSidebar } from '@shared/ui';

import { Okpd2sLayout } from './Okpd2sLayout';

interface CreateOkpd2sProps {
  open: boolean;
  onClose: () => void;
  afterCreate?: (createdOkpd2: bodyResponseType<'post', '/handbook/okpd2s'>) => void;
  withDialog?: boolean;
}

export function CreateOkpd2s({ open, onClose, afterCreate, withDialog }: CreateOkpd2sProps) {
  const { add } = useToaster();

  const queryClient = useQueryClient();

  const [okpd2sCreation, setOkpd2sCreation] = useAtom(okpd2sFormAtom);

  // Queries
  const createOkpd2sMutation = $okpd2sHooks.createMutation();

  //

  return (
    <Okpd2sLayout
      formProps={{
        defaultValues: okpd2sCreation,
        onValuesChange: values => {
          setOkpd2sCreation(values);
        },
        onClickCancelButton: () => {
          setOkpd2sCreation(undefined);
          onClose();
        },
        compactBottomPanel: true,
        // disabled: underApproval,
        initialEditable: true,
        onValid: async values => {
          await createOkpd2sMutation
            .mutateAsync({
              body: values,
            })
            .then(createdOkpd2 => {
              add({
                name: 'create-okpd2s-success',
                theme: 'success',
                content: 'Карточка ОКПД-2 успешна создана!',
              });
              queryClient.invalidateQueries({ queryKey: ['/okpd2s/all'] });
              afterCreate?.(createdOkpd2);

              setOkpd2sCreation(undefined);
              onClose();
            })
            .catch(() => {
              add({
                name: 'create-okpd2s-error',
                theme: 'danger',
                content: 'Ошибка при создании карточки ОКПД-2',
              });
            });
        },
        onInvalid: errors => {
          console.log('@errors', errors);
        },
      }}
      renderForm={formComponent =>
        withDialog ? (
          <CreateHandbookDialog
            onClose={onClose}
            open={open}
            title="Создание карточки ОКПД-2"
          >
            {formComponent}
          </CreateHandbookDialog>
        ) : (
          <RightSidebar
            open={open}
            onClose={onClose}
          >
            <RightSidebar.Header
              title="Создание карточки ОКПД-2"
              onClose={onClose}
            />
            {formComponent}
          </RightSidebar>
        )
      }
    />
  );
}
