import { LabelProps } from '@gravity-ui/uikit';

import { TenderPurchaseStatuses } from '../model/types/purchaseStatuses';

export const convertTenderPurchaseStatusToLabelTheme = (
  status: TenderPurchaseStatuses
): LabelProps['theme'] => {
  switch (status) {
    case 'В процессе':
      return 'normal';
    case 'Состоялась':
      return 'success';
    case 'Не состоялась':
    case 'Отменена':
      return 'danger';
    case 'Неизвестно':
      return 'clear';
  }
};
