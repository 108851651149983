import { dateTime } from '@gravity-ui/date-utils';
import { Text } from '@gravity-ui/uikit';

import { ProcessItem } from '@entities';
import { bodyResponseType } from '@shared/api';
import { DATE_FORMATS, URLS } from '@shared/consts';

const parseDate = (date: string, withTime?: boolean) =>
  dateTime({ input: date }).format(
    !withTime ? DATE_FORMATS.fullDate : DATE_FORMATS.fullDateWithTimeRounded
  );

export function ContractRelations({
  relations,
}: {
  relations: bodyResponseType<'get', '/contract/related/{id}'>;
}) {
  if (relations)
    return (
      <div className="flex flex-col px-8 overflow-y-scroll hide-scrollbar">
        {relations?.offer && Object.keys(relations.offer).length && (
          <div className="pb-8 flex flex-col">
            <Text
              variant="subheader-2"
              color="primary"
              className="mb-2"
            >
              Коммерческие предложения
            </Text>
            {relations.offer && (
              <ProcessItem
                dateAndNumber={
                  relations.offer.created_at
                    ? `${parseDate(relations.offer.created_at, true)}, №${relations.offer.id}`
                    : relations.offer.title
                }
                link={URLS.processes.commercialOffers + '/' + relations.offer.id}
                name={relations.offer.title}
              />
            )}
          </div>
        )}
        {relations?.tender && Object.keys(relations.tender).length && (
          <div className="pb-8 flex flex-col">
            <Text
              variant="subheader-2"
              color="primary"
              className="mb-2"
            >
              Торги
            </Text>
            {relations.tender && (
              <ProcessItem
                dateAndNumber={
                  relations.tender.created_at
                    ? `${parseDate(relations.tender.created_at, true)}, №${relations.tender.id}`
                    : relations.tender.title
                }
                link={URLS.processes.tenders + '/' + relations.tender.id}
                name={relations.tender.title}
              />
            )}
          </div>
        )}
      </div>
    );
}
