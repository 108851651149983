import { ReactNode } from 'react';

import { RegionsForm, RegionsFormProps } from '@features';

interface RegionsLayoutProps {
  renderForm: (formComponent: ReactNode) => ReactNode;
  formProps: RegionsFormProps;
}

export function RegionsLayout({ renderForm, formProps }: RegionsLayoutProps) {
  return <>{renderForm(<RegionsForm {...formProps} />)}</>;
}
