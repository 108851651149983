import { useToaster } from '@gravity-ui/uikit';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import { $purchaseObjectsHooks } from '@entities';
import { CreateHandbookDialog, purchaseObjectsFormAtom } from '@features';
import { bodyResponseType } from '@shared/api';
import { RightSidebar } from '@shared/ui';

import { PurchaseObjectsLayout } from './PurchaseObjectsLayout';

interface CreatePurchaseObjectsProps {
  open: boolean;
  onClose: () => void;
  afterCreate?: (
    createdPurchaseObject: bodyResponseType<'post', '/handbook/purchase-objects'>
  ) => void;
  withDialog?: boolean;
}

export function CreatePurchaseObjects({
  open,
  onClose,
  afterCreate,
  withDialog,
}: CreatePurchaseObjectsProps) {
  const { add } = useToaster();

  const queryClient = useQueryClient();

  const [purchaseObjectsCreation, setPurchaseObjectsCreation] = useAtom(purchaseObjectsFormAtom);

  // Queries
  const createPurchaseObjectsMutation = $purchaseObjectsHooks.createMutation();

  //

  return (
    <PurchaseObjectsLayout
      formProps={{
        defaultValues: purchaseObjectsCreation,
        onValuesChange: values => {
          setPurchaseObjectsCreation(values);
        },
        onClickCancelButton: () => {
          setPurchaseObjectsCreation(undefined);
          onClose();
        },
        compactBottomPanel: true,
        // disabled: underApproval,
        initialEditable: true,
        onValid: async values => {
          await createPurchaseObjectsMutation
            .mutateAsync({
              body: values,
            })
            .then(createdPurchaseObject => {
              add({
                name: 'create-purchaseObjects-success',
                theme: 'success',
                content: 'Карточка Объекта Закупки/Торгов успешна создана!',
              });
              queryClient.invalidateQueries({ queryKey: ['/purchase-objects/all'] });
              afterCreate?.(createdPurchaseObject);

              setPurchaseObjectsCreation(undefined);
              onClose();
            })
            .catch(() => {
              add({
                name: 'create-purchaseObjects-error',
                theme: 'danger',
                content: 'Ошибка при создании карточки Объекта Закупки/Торгов',
              });
            });
        },
        onInvalid: errors => {
          console.log('@errors', errors);
        },
      }}
      renderForm={formComponent =>
        withDialog ? (
          <CreateHandbookDialog
            onClose={onClose}
            open={open}
            title="Создание карточки Объекта Закупки/Торгов"
          >
            {formComponent}
          </CreateHandbookDialog>
        ) : (
          <RightSidebar
            open={open}
            onClose={onClose}
          >
            <RightSidebar.Header
              title="Создание карточки Объекта Закупки/Торгов"
              onClose={onClose}
            />
            {formComponent}
          </RightSidebar>
        )
      }
    />
  );
}
