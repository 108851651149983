import {
  convertContractBasisToLabelTheme,
  convertContractFZToLabelTheme,
  convertContractStageToLabelTheme,
} from '@entities';

export const OPTIONS = {
  basis: [
    {
      value: 'Прямая закупка без договора',
      content: 'Прямая закупка без договора',
      preview: convertContractBasisToLabelTheme('Прямая закупка без договора'),
    } as const,
    {
      value: 'Прямая закупка с договором',
      content: 'Прямая закупка с договором',
      preview: convertContractBasisToLabelTheme('Прямая закупка с договором'),
    } as const,
    {
      value: 'Тендер',
      content: 'Тендер',
      preview: convertContractBasisToLabelTheme('Тендер'),
    } as const,
  ],
  gozfz: [
    { value: 'ГОЗ', content: 'ГОЗ' },
    { value: 'НЕГОЗ', content: 'НЕГОЗ' },
  ],
  fz: [
    {
      value: '223-ФЗ',
      content: '223-ФЗ',
      preview: convertContractFZToLabelTheme('223-ФЗ'),
    } as const,
    { value: '44-ФЗ', content: '44-ФЗ', preview: convertContractFZToLabelTheme('44-ФЗ') } as const,
    { value: 'КОМ.', content: 'КОМ.', preview: convertContractFZToLabelTheme('КОМ.') } as const,
  ],
  stage: [
    {
      value: 'Заключен',
      content: 'Заключен',
      preview: convertContractStageToLabelTheme('Заключен'),
    } as const,
    {
      value: 'Исполнен',
      content: 'Исполнен',
      preview: convertContractStageToLabelTheme('Исполнен'),
    } as const,
    {
      value: 'Проект',
      content: 'Проект',
      preview: convertContractStageToLabelTheme('Проект'),
    } as const,
    {
      value: 'Рассчитан',
      content: 'Рассчитан',
      preview: convertContractStageToLabelTheme('Рассчитан'),
    } as const,
    {
      value: 'Расторгнут',
      content: 'Расторгнут',
      preview: convertContractStageToLabelTheme('Расторгнут'),
    } as const,
  ],
  customer_priority: [
    { value: '1', content: '1' },
    { value: '2', content: '2' },
    { value: '3', content: '3' },
    { value: '4', content: '4' },
    { value: '5', content: '5' },
  ],
  has_contract_security: [
    { value: 'Да', content: 'Да' },
    { value: 'Нет', content: 'Нет' },
  ],
  has_smp_restriction: [
    { value: 'Да', content: 'Да' },
    { value: 'Нет', content: 'Нет' },
  ],
  has_warranty_security: [
    { value: 'Да', content: 'Да' },
    { value: 'Нет', content: 'Нет' },
  ],
  maintenance: [
    { value: 'Нет', content: 'Нет' },
    { value: 'Казначейское', content: 'Казначейское' },
    { value: 'Банковское', content: 'Банковское' },
  ],
  days_type: [
    { value: 'Календарные', content: 'Календарные' },
    { value: 'Рабочие', content: 'Рабочие' },
  ],
};
