import { useToaster } from '@gravity-ui/uikit';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import { $eTradingPlatformsHooks } from '@entities';
import { CreateHandbookDialog, eTradingPlatformsFormAtom } from '@features';
import { bodyResponseType } from '@shared/api';
import { RightSidebar } from '@shared/ui';

import { ETradingPlatformsLayout } from './ETradingPlatformsLayout';

interface CreateETradingPlatformsProps {
  open: boolean;
  onClose: () => void;
  afterCreate?: (
    createdETradingPlatform: bodyResponseType<'post', '/handbook/e-trading-platforms'>
  ) => void;
  withDialog?: boolean;
}

export function CreateETradingPlatforms({
  open,
  onClose,
  afterCreate,
  withDialog,
}: CreateETradingPlatformsProps) {
  const { add } = useToaster();

  const queryClient = useQueryClient();

  const [eTradingPlatformsCreation, setETradingPlatformsCreation] =
    useAtom(eTradingPlatformsFormAtom);

  // Queries
  const createETradingPlatformsMutation = $eTradingPlatformsHooks.createMutation();

  //

  return (
    <ETradingPlatformsLayout
      formProps={{
        defaultValues: eTradingPlatformsCreation,
        onValuesChange: values => {
          setETradingPlatformsCreation(values);
        },
        onClickCancelButton: () => {
          setETradingPlatformsCreation(undefined);
          onClose();
        },
        compactBottomPanel: true,
        // disabled: underApproval,
        initialEditable: true,
        onValid: async values => {
          await createETradingPlatformsMutation
            .mutateAsync({
              body: values,
            })
            .then(createdETradingPlatform => {
              add({
                name: 'create-eTradingPlatforms-success',
                theme: 'success',
                content: 'Карточка Электронной Торговой Площадки успешна создана!',
              });
              queryClient.invalidateQueries({ queryKey: ['/e-trading-platforms/all'] });
              afterCreate?.(createdETradingPlatform);

              setETradingPlatformsCreation(undefined);
              onClose();
            })
            .catch(() => {
              add({
                name: 'create-eTradingPlatforms-error',
                theme: 'danger',
                content: 'Ошибка при создании карточки Электронной Торговой Площадки',
              });
            });
        },
        onInvalid: errors => {
          console.log('@errors', errors);
        },
      }}
      renderForm={formComponent =>
        withDialog ? (
          <CreateHandbookDialog
            onClose={onClose}
            open={open}
            title="Создание карточки Электронной Торговой Площадки"
          >
            {formComponent}
          </CreateHandbookDialog>
        ) : (
          <RightSidebar
            open={open}
            onClose={onClose}
          >
            <RightSidebar.Header
              title="Создание карточки Электронной Торговой Площадки"
              onClose={onClose}
            />
            {formComponent}
          </RightSidebar>
        )
      }
    />
  );
}
