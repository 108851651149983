import { RadioButtonOption } from '@gravity-ui/uikit';

export const SETTINGS_TABS: (RadioButtonOption & { disabled?: boolean })[] = [
  {
    value: 'members',
    content: 'Участники',
    disabled: false,
  },
  {
    value: 'media',
    content: 'Медиа',
    disabled: true,
  },
  {
    value: 'files',
    content: 'Файлы',
    disabled: true,
  },
  {
    value: 'links',
    content: 'Ссылки',
    disabled: true,
  },
  {
    value: 'history',
    content: 'История',
    disabled: true,
  },
];

export const DEFAULT_SETTING_TAB = 'members';
