import { Button, useToaster } from '@gravity-ui/uikit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { $knowledgeBasesHooks } from '@entities';
import { bodyResponseType } from '@shared/api';
import { cn, useFieldsWithPreview } from '@shared/lib';
import { FieldWithPreview, RightSidebar } from '@shared/ui';

import { RESTRICTION_ADVANTAGE_REQUIREMENT_OPTIONS } from '../consts';
import {
  restrictionAdvantageRequirementsSchema,
  RestrictionAdvantageRequirementsSchema,
} from '../model';

export interface RestrictionAdvantageRequirementsFormProps {
  onValid: SubmitHandler<RestrictionAdvantageRequirementsSchema>;
  onValuesChange?: (values: RestrictionAdvantageRequirementsSchema) => void;
  response?: bodyResponseType<'get', '/handbook/restriction-advantage-requirements/{id}'>;
  onInvalid?: SubmitErrorHandler<RestrictionAdvantageRequirementsSchema>;
  onClickCancelButton?: () => void;
  initialEditable?: boolean;
  disabled?: boolean;
  hideBottomPanel?: boolean;
  rightSidebarButtons?: boolean;
  defaultValues?: RestrictionAdvantageRequirementsSchema;
  cancelButtonText?: string;
  submitButtonText?: string;
  compactBottomPanel?: boolean;
}

export function RestrictionAdvantageRequirementsForm({
  onValid,
  onValuesChange,
  response,
  onInvalid,
  initialEditable = false,
  onClickCancelButton,
  disabled = false,
  hideBottomPanel = false,
  defaultValues,
  cancelButtonText = 'Отмена',
  submitButtonText = 'Применить',
  compactBottomPanel = false,
}: RestrictionAdvantageRequirementsFormProps) {
  const { add } = useToaster();

  const form = useForm<RestrictionAdvantageRequirementsSchema>({
    resolver: yupResolver(restrictionAdvantageRequirementsSchema),
    mode: 'all',
    defaultValues,
    resetOptions: {
      keepDirtyValues: true,
      keepDefaultValues: false,
    },
  });

  const { createFieldRef, isFieldEditable, selectField, selectedFieldPath, resetSelectedField } =
    useFieldsWithPreview({
      form: form,
      initialEditable,
      disabled,
    });

  const values = form.watch();

  const [filterStatuses, setFilterStatuses] = useState('');

  // Queries
  const [filterKnowledgeBases, setFilterKnowledgeBases] = useState('');
  const getHandbookKnowledgeBasesInfiniteQuery = $knowledgeBasesHooks.useGetAll(
    {
      query: filterKnowledgeBases,
      limit: 30,
    },
    selectedFieldPath === 'basis_id'
  );
  const flattedKnowledgeBases = useMemo(
    () => getHandbookKnowledgeBasesInfiniteQuery.data?.pages.flat(),
    [getHandbookKnowledgeBasesInfiniteQuery.data]
  );
  //

  useDeepCompareEffect(() => {
    onValuesChange?.(values);
  }, [values]);

  useDeepCompareEffect(() => {
    if (response) form.reset(response);
  }, [response || {}]);

  useEffect(() => {
    if (Object.values(form.formState.errors).length > 0 && form.formState.isSubmitted)
      add({
        title: 'Не все обязательные поля заполнены',
        name: 'validation-fields-error',
        theme: 'warning',
      });
  }, [form.formState.submitCount]);

  return (
    <form
      className="flex flex-col overflow-hidden grow"
      onSubmit={form.handleSubmit(onValid, onInvalid)}
    >
      <FieldWithPreview>
        <FieldWithPreview.Text
          ref={createFieldRef('name')}
          control={form.control}
          path="name"
          name="Наименование"
          edit={isFieldEditable('name')}
          onFieldSelect={() => selectField('name')}
          textInputProps={{ disabled }}
          required
        />
        <FieldWithPreview.Select
          ref={createFieldRef('status')}
          control={form.control}
          path="status"
          name="Статус"
          edit={isFieldEditable('status')}
          options={RESTRICTION_ADVANTAGE_REQUIREMENT_OPTIONS.status}
          defaultOptions={
            response?.status ? [{ content: response.status, value: response.status }] : undefined
          }
          filter={filterStatuses}
          onFilterChange={filter => setFilterStatuses(filter)}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          onFieldSelect={() => selectField('status')}
          selectProps={{
            disabled,
          }}
        />
        <FieldWithPreview.Select
          ref={createFieldRef('basis_id')}
          control={form.control}
          path="basis_id"
          edit={isFieldEditable('basis_id')}
          onFieldSelect={() => selectField('basis_id')}
          name="Основание"
          options={
            flattedKnowledgeBases?.map(basis => ({
              content: basis.document_name,
              value: basis.id,
            })) || []
          }
          defaultOptions={
            response?.basis_id
              ? [{ content: response.basis_value, value: response.basis_id }]
              : undefined
          }
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          filter={filterKnowledgeBases}
          onFilterChange={filter => setFilterKnowledgeBases(filter)}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
            loading:
              getHandbookKnowledgeBasesInfiniteQuery.isFetching ||
              getHandbookKnowledgeBasesInfiniteQuery.isFetchingNextPage ||
              getHandbookKnowledgeBasesInfiniteQuery.isLoading ||
              getHandbookKnowledgeBasesInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookKnowledgeBasesInfiniteQuery.fetchNextPage();
            },
          }}
        />
        <FieldWithPreview.TextArea
          ref={createFieldRef('usage_mechanism')}
          control={form.control}
          path="usage_mechanism"
          name="Механизм применения"
          edit={isFieldEditable('usage_mechanism')}
          onFieldSelect={() => selectField('usage_mechanism')}
          wrapperProps={{ textAlign: 'start' }}
          textAreaProps={{ disabled }}
        />
      </FieldWithPreview>
      {!hideBottomPanel && (
        <RightSidebar.BottomPanel>
          <Button
            className={cn({ 'ml-auto': compactBottomPanel })}
            size="xl"
            view={compactBottomPanel ? 'flat' : 'normal'}
            width={'max'}
            onClick={onClickCancelButton}
            disabled={disabled}
          >
            {cancelButtonText}
          </Button>
          <Button
            size="xl"
            view="action"
            type="submit"
            width={'max'}
            onClick={form.handleSubmit(onValid, onInvalid)}
            disabled={disabled}
          >
            {submitButtonText}
          </Button>
        </RightSidebar.BottomPanel>
      )}
    </form>
  );
}
