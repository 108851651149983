import { dateTime } from '@gravity-ui/date-utils';
import { Message, printValue, ValidationError } from 'yup';

import { DATE_FORMATS } from '@shared/consts';

import { pluralize } from '../../lib/helpers';

export interface MixedLocale {
  default?: Message;
  required?: Message;
  oneOf?: Message<{ values: unknown }>;
  notOneOf?: Message<{ values: unknown }>;
  notNull?: Message;
  notType?: Message;
  defined?: Message;
}

export interface StringLocale {
  length?: Message<{ length: number }>;
  min?: Message<{ min: number }>;
  max?: Message<{ max: number }>;
  matches?: Message<{ regex: RegExp }>;
  email?: Message<{ regex: RegExp }>;
  url?: Message<{ regex: RegExp }>;
  uuid?: Message<{ regex: RegExp }>;
  datetime?: Message;
  datetime_offset?: Message;
  datetime_precision?: Message<{ precision: number }>;
  trim?: Message;
  lowercase?: Message;
  uppercase?: Message;
}

export interface NumberLocale {
  min?: Message<{ min: number }>;
  max?: Message<{ max: number }>;
  lessThan?: Message<{ less: number }>;
  moreThan?: Message<{ more: number }>;
  positive?: Message<{ more: number }>;
  negative?: Message<{ less: number }>;
  integer?: Message;
}

export interface DateLocale {
  min?: Message<{ min: Date | string }>;
  max?: Message<{ max: Date | string }>;
}

export interface ObjectLocale {
  noUnknown?: Message;
}

export interface ArrayLocale {
  length?: Message<{ length: number }>;
  min?: Message<{ min: number }>;
  max?: Message<{ max: number }>;
}

export interface TupleLocale {
  notType?: Message;
}

export interface BooleanLocale {
  isValue?: Message;
}

export interface LocaleObject {
  mixed?: MixedLocale;
  string?: StringLocale;
  number?: NumberLocale;
  date?: DateLocale;
  boolean?: BooleanLocale;
  object?: ObjectLocale;
  array?: ArrayLocale;
  tuple?: TupleLocale;
}

export const mixed: Required<MixedLocale> = {
  default: 'Недействительное поле',
  required: 'Поле является обязательным',
  defined: 'Поле должно быть определено',
  notNull: '${path} не может быть пустым',
  oneOf: 'Должно содержать значение: ${values}',
  notOneOf: 'Не должно содержать значения: ${values}',
  notType: 'Некорректный формат',
};

export const string: Required<StringLocale> = {
  length: ({ length }) =>
    `Длина должна иметь ${length} ${pluralize(length, 'символ', 'символа', 'символов')}`,
  min: ({ min }) =>
    `Должен содержать как минимум ${min} ${pluralize(min, 'символ', 'символа', 'символов')}`,
  max: ({ max }) =>
    `Длина должна быть не более ${max} ${pluralize(max, 'символа', 'символов', 'символов')}`,
  matches: 'Необходимо совпадение со следующим регулярным выражением: "${regex}"',
  email: 'Некорректная почта',
  url: 'Некорректный URL',
  uuid: 'Некорректный UUID',
  datetime: 'Некорректный ISO-формат',
  datetime_precision: ({ precision }) =>
    `Должно являтся допустимым значением даты и времени ISO с точностью до секунды, состоящей ровно из ${precision} ${pluralize(precision, 'цифры', 'цифр', 'цифр')}`,
  datetime_offset:
    'Должно являтся допустимым значением даты и времени ISO с часовым поясом UTC "Z"',
  trim: 'Не должно содержать лишних пробелов',
  lowercase: 'Не должно содержать заглавных букв',
  uppercase: 'Не должно содержать строчных букв',
};

export const number: Required<NumberLocale> = {
  min: 'Значение должно быть больше или равно ${min}',
  max: 'Значение должно быть меньше или равно ${max}',
  lessThan: 'Значение должно быть меньше чем ${less}',
  moreThan: 'Значение должно быть больше ${more}',
  positive: 'Значение должно быть больше нуля',
  negative: 'Значение должно быть меньше нуля',
  integer: 'Значение должно быть целым числом',
};

export const date: Required<DateLocale> = {
  min: ({ min }) => {
    const timestamp = new Date(min).getTime();
    return `Не может быть раньше ${dateTime({ input: timestamp }).format(DATE_FORMATS.fullDateWithTime)}`;
  },
  max: ({ max }) => {
    const timestamp = new Date(max).getTime();
    return `Не может быть позже ${dateTime({ input: timestamp }).format(DATE_FORMATS.fullDateWithTime)}`;
  },
};

export const boolean: Required<BooleanLocale> = {
  isValue: 'Должно быть ${value}',
};

export const object: Required<ObjectLocale> = {
  noUnknown: 'Поле ${path} имеет неуказанные ключи: ${unknown}',
};

export const array: Required<ArrayLocale> = {
  min: ({ min }) =>
    `Не может содержать меньше ${min} ${pluralize(min, 'элемента', 'элементов', 'элементов')}`,
  max: ({ max }) =>
    `Не может содержать больше ${max} ${pluralize(max, 'элемента', 'элементов', 'элементов')}`,
  length: ({ length }) =>
    `Должен содержать ровно ${length} ${pluralize(length, 'элемент', 'элемента', 'элемента')}`,
};

export const tuple: Required<TupleLocale> = {
  notType: params => {
    const { path, value, spec } = params;
    const typeLen = spec.types.length;
    if (Array.isArray(value)) {
      if (value.length < typeLen)
        return `Значение кортежа ${path} содержит слишком мало элементов, ожидалась длина ${typeLen}, но получено ${
          value.length
        } для значения: \`${printValue(value, true)}\``;
      if (value.length > typeLen)
        return `Значение кортежа ${path} содержит слишком много элементов, ожидалась длина ${typeLen}, но получено ${
          value.length
        } для значения: \`${printValue(value, true)}\``;
    }

    return ValidationError.formatError(mixed.notType, params);
  },
};

export const ru = {
  mixed,
  string,
  number,
  date,
  object,
  array,
  boolean,
};
