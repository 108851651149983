import BigNumber from 'bignumber.js';

export function calculateMargin(уменьшаемое: number, вычитаемое: number) {
  if (уменьшаемое === 0) return 0;
  // console.log(
  //   `(${уменьшаемое} - ${вычитаемое}) * 100 / ${уменьшаемое} = ${((уменьшаемое - вычитаемое) * 100) / уменьшаемое}`
  // );
  // console.log(
  //   `(${уменьшаемое} - ${вычитаемое}) * 100 / ${уменьшаемое} = ${((уменьшаемое - вычитаемое) * 100) / уменьшаемое}`
  // );
  // console.log(
  //   `(${BigInt(уменьшаемое * 100)} - ${BigInt(+вычитаемое * 100)}) / ${BigInt(+уменьшаемое)} = ${Number(
  //     BigInt(+(((уменьшаемое - вычитаемое) * 100) / уменьшаемое).toFixed(2))
  //   )}`
  // );
  const result =
    +BigNumber(уменьшаемое)
      .minus(вычитаемое)
      .dividedBy(уменьшаемое)
      .times(100)
      .times(100)
      .toFixed(0) / 100;
  return result;
}
