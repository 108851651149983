import { Button, RadioButton, RadioButtonOption, Text, TextInput } from '@gravity-ui/uikit';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import {
  $agenciesHooks,
  $commercialOfferHooks,
  $competitorsHooks,
  $contactsHooks,
  $contractHooks,
  $customersHooks,
  $departmentsHooks,
  $employeesHooks,
  $eTradingPlatformsHooks,
  $knowledgeBasesHooks,
  $legalEntitiesHooks,
  $okpd2sHooks,
  $paymentDetailsHooks,
  $purchaseObjectsHooks,
  $regionsHooks,
  $restrictionAdvantageRequirementsHooks,
  $supplierContractsHooks,
  $suppliersHooks,
  $tenderHooks,
  SystemHandbookName,
  useGetSystemHandbookQuery,
} from '@entities';
import { ApiQueries } from '@shared/api';
import { objectKeysSafeToArray } from '@shared/lib';
import {
  ExpandList,
  MyFoldableListItem,
  RightSidebar,
  SelectWinnersWithSearch,
  SelectWithSearch,
} from '@shared/ui';

import { FilterBooleanType, FilterSearchType, FilterSelectType, FilterWinnersType } from '../model';

type SidebarFilter = FilterBooleanType | FilterSelectType | FilterSearchType | FilterWinnersType;

type CommonItemProps = Pick<
  MyFoldableListItem,
  | 'arrowPosition'
  | 'arrowSize'
  | 'buttonProps'
  | 'textProps'
  | 'arrowClassname'
  | 'className'
  | 'keepMounted'
>;

const conditionalOptions: RadioButtonOption[] = [
  { value: 'Да', content: 'Да' },
  { value: 'Нет', content: 'Нет' },
  { value: 'Не выбрано', content: 'Не выбрано' },
];

const commonItemProps: CommonItemProps = {
  arrowPosition: 'start',
  arrowSize: 16,
  buttonProps: {
    className: 'justify-start pl-2 w-full text-left',
    size: 'xl',
    pin: 'brick-brick',
  },
  textProps: {
    className: '!ml-0 ',
    variant: 'subheader-1',
  },
  arrowClassname: 'text-text-primary',
  className: 'shadow-outline-bottom',
  keepMounted: true,
};

interface TableFiltersSidebarProps<T extends string[]> {
  filters: Record<T[number], SidebarFilter>;
  onConfirm: (newFilterState: Record<T[number], SidebarFilter>) => void;
  open: boolean;
  onClose: () => void;
  columnsToSelectQuery?: Partial<Record<T[number], ApiQueries>>;
  columnsToSystemHandbook?: Partial<Record<T[number], SystemHandbookName>>;
  onColumnsContentChange: Dispatch<SetStateAction<Record<T[number], string | undefined>>>;
}

function SelectWithSearchWithoutItems({
  items,
  loading,
  onLoadMore,
  search,
  onSearchChange,
  onOpenChange,
  initialValue,
  onConfirm,
  onReset,
  onContentChange,
}: {
  items: { content: string; value: string }[];
  loading: boolean;
  onLoadMore: () => void;
  search: string;
  onSearchChange: (search: string) => void;
  onOpenChange: (open: boolean) => void;
  initialValue: string[];
  onConfirm: (value: string[]) => void;
  onReset: () => void;
  onContentChange: (content: string) => void;
}) {
  // const [filter, onFilter] = useState(search);
  // const load = useMemo(() => loading, [loading]);
  return (
    <SelectWithSearch
      delay={300}
      className="w-[286px] my-[6px] mr-auto ml-8 static"
      items={items}
      initialValue={initialValue}
      filterPlaceholder="Поиск"
      placeholder="Выберите"
      multiple
      size="l"
      popupClassName=" w-[286px]"
      textInputWrapperClassname="p-2 flex flex-col gap-1"
      onConfirm={onConfirm}
      onReset={onReset}
      loading={loading}
      onLoadMore={onLoadMore}
      filter={search}
      onFilterChange={v => {
        // onFilter(v);
        onSearchChange(v);
      }}
      onOpenChange={onOpenChange}
      onContentChange={onContentChange}
    />
  );
}
function renderSelect(
  apiQuery: ApiQueries,
  // openChange: Partial<Record<ApiQueries, boolean>>,
  // setOpenChange: Dispatch<SetStateAction<Partial<Record<ApiQueries, boolean>>>>,
  otherProps: {
    initialValue: string[];
    onConfirm: (value: string[]) => void;
    onReset: () => void;
    onContentChange: (content: string) => void;
  }
): () => JSX.Element {
  // if (!apiQuery) return;
  switch (apiQuery) {
    case 'agencies':
      return () => {
        const [enabled, setEnabled] = useState(false);
        const [filterAgencies, setFilterAgencies] = useState('');
        const getHandbookAgenciesInfiniteQuery = $agenciesHooks.useGetAll(
          {
            query: filterAgencies,
            limit: 30,
          },
          enabled
        );
        const flattedAgencies = useMemo(
          () => getHandbookAgenciesInfiniteQuery.data?.pages.flat(),
          [getHandbookAgenciesInfiniteQuery.data]
        );
        return (
          <SelectWithSearchWithoutItems
            items={
              flattedAgencies?.map(agency => ({ content: agency.name, value: agency.id })) || []
            }
            loading={
              getHandbookAgenciesInfiniteQuery.isFetching ||
              getHandbookAgenciesInfiniteQuery.isFetchingNextPage ||
              getHandbookAgenciesInfiniteQuery.isLoading ||
              getHandbookAgenciesInfiniteQuery.hasNextPage
            }
            onLoadMore={() => getHandbookAgenciesInfiniteQuery.fetchNextPage()}
            onSearchChange={s => setFilterAgencies(s)}
            onOpenChange={setEnabled}
            search={filterAgencies}
            {...otherProps}
          />
        );
      };
    case 'competitors':
      return () => {
        const [enabled, setEnabled] = useState(false);
        const [filterCompetitors, setFilterCompetitors] = useState('');
        const getHandbookCompetitorsInfiniteQuery = $competitorsHooks.useGetAll(
          {
            query: filterCompetitors,
            limit: 30,
          },
          enabled
        );
        const flattedCompetitors = useMemo(
          () => getHandbookCompetitorsInfiniteQuery.data?.pages.flat(),
          [getHandbookCompetitorsInfiniteQuery.data]
        );
        return (
          <SelectWithSearchWithoutItems
            items={
              flattedCompetitors?.map(competitor => ({
                content: competitor.name,
                value: competitor.id,
              })) || []
            }
            loading={
              getHandbookCompetitorsInfiniteQuery.isFetching ||
              getHandbookCompetitorsInfiniteQuery.isFetchingNextPage ||
              getHandbookCompetitorsInfiniteQuery.isLoading ||
              getHandbookCompetitorsInfiniteQuery.hasNextPage
            }
            onLoadMore={() => getHandbookCompetitorsInfiniteQuery.fetchNextPage()}
            onSearchChange={s => setFilterCompetitors(s)}
            onOpenChange={setEnabled}
            search={filterCompetitors}
            {...otherProps}
          />
        );
      };

    case 'contacts':
      return () => {
        const [enabled, setEnabled] = useState(false);
        const [filterContacts, setFilterContacts] = useState('');
        const getHandbookContactsInfiniteQuery = $contactsHooks.useGetAll(
          {
            query: filterContacts,
            limit: 30,
          },
          enabled
        );
        const flattedContacts = useMemo(
          () => getHandbookContactsInfiniteQuery.data?.pages.flat(),
          [getHandbookContactsInfiniteQuery.data]
        );
        return (
          <SelectWithSearchWithoutItems
            items={
              flattedContacts?.map(contact => ({
                content: contact.full_name,
                value: contact.id,
              })) || []
            }
            loading={
              getHandbookContactsInfiniteQuery.isFetching ||
              getHandbookContactsInfiniteQuery.isFetchingNextPage ||
              getHandbookContactsInfiniteQuery.isLoading ||
              getHandbookContactsInfiniteQuery.hasNextPage
            }
            onLoadMore={() => getHandbookContactsInfiniteQuery.fetchNextPage()}
            onSearchChange={s => setFilterContacts(s)}
            onOpenChange={setEnabled}
            search={filterContacts}
            {...otherProps}
          />
        );
      };

    case 'customers':
      return () => {
        const [enabled, setEnabled] = useState(false);
        const [filterCustomers, setFilterCustomers] = useState('');
        const getHandbookCustomersInfiniteQuery = $customersHooks.useGetAll(
          {
            query: filterCustomers,
            limit: 30,
          },
          enabled
        );
        const flattedCustomers = useMemo(
          () => getHandbookCustomersInfiniteQuery.data?.pages.flat(),
          [getHandbookCustomersInfiniteQuery.data]
        );
        return (
          <SelectWithSearchWithoutItems
            items={
              flattedCustomers?.map(customer => ({
                content: customer.full_name,
                value: customer.id,
              })) || []
            }
            loading={
              getHandbookCustomersInfiniteQuery.isFetching ||
              getHandbookCustomersInfiniteQuery.isFetchingNextPage ||
              getHandbookCustomersInfiniteQuery.isLoading ||
              getHandbookCustomersInfiniteQuery.hasNextPage
            }
            onLoadMore={() => {
              getHandbookCustomersInfiniteQuery.fetchNextPage();
            }}
            onSearchChange={setFilterCustomers}
            onOpenChange={setEnabled}
            search={filterCustomers}
            {...otherProps}
          />
        );
      };

    case 'departments':
      return () => {
        const [enabled, setEnabled] = useState(false);
        const [filterDepartments, setFilterDepartments] = useState('');
        const getHandbookDepartmentsInfiniteQuery = $departmentsHooks.useGetAll(
          {
            query: filterDepartments,
            limit: 30,
          },
          enabled
        );
        const flattedDepartments = useMemo(
          () => getHandbookDepartmentsInfiniteQuery.data?.pages.flat(),
          [getHandbookDepartmentsInfiniteQuery.data]
        );
        return (
          <SelectWithSearchWithoutItems
            items={
              flattedDepartments?.map(department => ({
                content: department.name,
                value: department.id,
              })) || []
            }
            loading={
              getHandbookDepartmentsInfiniteQuery.isFetching ||
              getHandbookDepartmentsInfiniteQuery.isFetchingNextPage ||
              getHandbookDepartmentsInfiniteQuery.isLoading ||
              getHandbookDepartmentsInfiniteQuery.hasNextPage
            }
            onLoadMore={() => getHandbookDepartmentsInfiniteQuery.fetchNextPage()}
            onSearchChange={s => setFilterDepartments(s)}
            onOpenChange={setEnabled}
            search={filterDepartments}
            {...otherProps}
          />
        );
      };
    case 'e-trading-platforms':
      return () => {
        const [enabled, setEnabled] = useState(false);
        const [filterETradingPlatforms, setFilterETradingPlatforms] = useState('');
        const getHandbookETradingPlatformsInfiniteQuery = $eTradingPlatformsHooks.useGetAll(
          {
            query: filterETradingPlatforms,
            limit: 30,
          },
          enabled
        );
        const flattedETradingPlatforms = useMemo(
          () => getHandbookETradingPlatformsInfiniteQuery.data?.pages.flat(),
          [getHandbookETradingPlatformsInfiniteQuery.data]
        );
        return (
          <SelectWithSearchWithoutItems
            items={
              flattedETradingPlatforms?.map(platform => ({
                content: platform.name,
                value: platform.id,
              })) || []
            }
            loading={
              getHandbookETradingPlatformsInfiniteQuery.isFetching ||
              getHandbookETradingPlatformsInfiniteQuery.isFetchingNextPage ||
              getHandbookETradingPlatformsInfiniteQuery.isLoading ||
              getHandbookETradingPlatformsInfiniteQuery.hasNextPage
            }
            onLoadMore={() => getHandbookETradingPlatformsInfiniteQuery.fetchNextPage()}
            onSearchChange={s => setFilterETradingPlatforms(s)}
            onOpenChange={setEnabled}
            search={filterETradingPlatforms}
            {...otherProps}
          />
        );
      };

    case 'employees':
      return () => {
        const [enabled, setEnabled] = useState(false);
        const [filterEmployees, setFilterEmployees] = useState('');
        const getHandbookEmployeesInfiniteQuery = $employeesHooks.useGetAll(
          {
            query: filterEmployees,
            limit: 30,
          },
          enabled
        );
        const flattedEmployees = useMemo(
          () => getHandbookEmployeesInfiniteQuery.data?.pages.flat(),
          [getHandbookEmployeesInfiniteQuery.data]
        );
        return (
          <SelectWithSearchWithoutItems
            items={
              flattedEmployees?.map(employee => ({
                content: employee.name_initials,
                value: employee.id,
              })) || []
            }
            loading={
              getHandbookEmployeesInfiniteQuery.isFetching ||
              getHandbookEmployeesInfiniteQuery.isFetchingNextPage ||
              getHandbookEmployeesInfiniteQuery.isLoading ||
              getHandbookEmployeesInfiniteQuery.hasNextPage
            }
            onLoadMore={() => getHandbookEmployeesInfiniteQuery.fetchNextPage()}
            onSearchChange={s => setFilterEmployees(s)}
            onOpenChange={setEnabled}
            search={filterEmployees}
            {...otherProps}
          />
        );
      };

    case 'knowledge-bases':
      return () => {
        const [enabled, setEnabled] = useState(false);
        const [filterKnowledgeBases, setFilterKnowledgeBases] = useState('');
        const getHandbookKnowledgeBasesInfiniteQuery = $knowledgeBasesHooks.useGetAll(
          {
            query: filterKnowledgeBases,
            limit: 30,
          },
          enabled
        );
        const flattedKnowledgeBases = useMemo(
          () => getHandbookKnowledgeBasesInfiniteQuery.data?.pages.flat(),
          [getHandbookKnowledgeBasesInfiniteQuery.data]
        );
        return (
          <SelectWithSearchWithoutItems
            items={
              flattedKnowledgeBases?.map(kb => ({ content: kb.document_name, value: kb.id })) || []
            }
            loading={
              getHandbookKnowledgeBasesInfiniteQuery.isFetching ||
              getHandbookKnowledgeBasesInfiniteQuery.isFetchingNextPage ||
              getHandbookKnowledgeBasesInfiniteQuery.isLoading ||
              getHandbookKnowledgeBasesInfiniteQuery.hasNextPage
            }
            onLoadMore={() => getHandbookKnowledgeBasesInfiniteQuery.fetchNextPage()}
            onSearchChange={s => setFilterKnowledgeBases(s)}
            onOpenChange={setEnabled}
            search={filterKnowledgeBases}
            {...otherProps}
          />
        );
      };

    case 'legal-entities':
      return () => {
        const [enabled, setEnabled] = useState(false);
        const [filterLegalEntities, setFilterLegalEntities] = useState('');
        const getHandbookLegalEntitiesInfiniteQuery = $legalEntitiesHooks.useGetAll(
          {
            query: filterLegalEntities,
            limit: 30,
          },
          enabled
        );
        const flattedLegalEntities = useMemo(
          () => getHandbookLegalEntitiesInfiniteQuery.data?.pages.flat(),
          [getHandbookLegalEntitiesInfiniteQuery.data]
        );
        return (
          <SelectWithSearchWithoutItems
            items={
              flattedLegalEntities?.map(entity => ({ content: entity.name, value: entity.id })) ||
              []
            }
            loading={
              getHandbookLegalEntitiesInfiniteQuery.isFetching ||
              getHandbookLegalEntitiesInfiniteQuery.isFetchingNextPage ||
              getHandbookLegalEntitiesInfiniteQuery.isLoading ||
              getHandbookLegalEntitiesInfiniteQuery.hasNextPage
            }
            onLoadMore={() => getHandbookLegalEntitiesInfiniteQuery.fetchNextPage()}
            onSearchChange={s => setFilterLegalEntities(s)}
            onOpenChange={setEnabled}
            search={filterLegalEntities}
            {...otherProps}
          />
        );
      };
    case 'okpd2s':
      return () => {
        const [enabled, setEnabled] = useState(false);
        const [filterOkpd2s, setFilterOkpd2s] = useState('');
        const getHandbookOkpd2sInfiniteQuery = $okpd2sHooks.useGetAll(
          {
            query: filterOkpd2s,
            limit: 30,
          },
          enabled
        );
        const flattedOkpd2s = useMemo(
          () => getHandbookOkpd2sInfiniteQuery.data?.pages.flat(),
          [getHandbookOkpd2sInfiniteQuery.data]
        );
        return (
          <SelectWithSearchWithoutItems
            items={flattedOkpd2s?.map(v => ({ content: v.value, value: v.value })) || []}
            loading={
              getHandbookOkpd2sInfiniteQuery.isFetching ||
              getHandbookOkpd2sInfiniteQuery.isFetchingNextPage ||
              getHandbookOkpd2sInfiniteQuery.isLoading ||
              getHandbookOkpd2sInfiniteQuery.hasNextPage
            }
            onLoadMore={() => getHandbookOkpd2sInfiniteQuery.fetchNextPage()}
            onSearchChange={s => setFilterOkpd2s(s)}
            onOpenChange={setEnabled}
            search={filterOkpd2s}
            {...otherProps}
          />
        );
      };

    case 'payment-details':
      return () => {
        const [enabled, setEnabled] = useState(false);
        const [filterPaymentDetails, setFilterPaymentDetails] = useState('');
        const getHandbookPaymentDetailsInfiniteQuery = $paymentDetailsHooks.useGetAll(
          {
            query: filterPaymentDetails,
            limit: 30,
          },
          enabled
        );
        const flattedPaymentDetails = useMemo(
          () => getHandbookPaymentDetailsInfiniteQuery.data?.pages.flat(),
          [getHandbookPaymentDetailsInfiniteQuery.data]
        );
        return (
          <SelectWithSearchWithoutItems
            items={flattedPaymentDetails?.map(v => ({ content: v.bank_name, value: v.id })) || []}
            loading={
              getHandbookPaymentDetailsInfiniteQuery.isFetching ||
              getHandbookPaymentDetailsInfiniteQuery.isFetchingNextPage ||
              getHandbookPaymentDetailsInfiniteQuery.isLoading ||
              getHandbookPaymentDetailsInfiniteQuery.hasNextPage
            }
            onLoadMore={() => getHandbookPaymentDetailsInfiniteQuery.fetchNextPage()}
            onSearchChange={s => setFilterPaymentDetails(s)}
            onOpenChange={setEnabled}
            search={filterPaymentDetails}
            {...otherProps}
          />
        );
      };

    case 'purchase-objects':
      return () => {
        const [enabled, setEnabled] = useState(false);
        const [filterPurchaseObjects, setFilterPurchaseObjects] = useState('');
        const getHandbookPurchaseObjectsInfiniteQuery = $purchaseObjectsHooks.useGetAll(
          {
            query: filterPurchaseObjects,
            limit: 30,
          },
          enabled
        );
        const flattedPurchaseObjects = useMemo(
          () => getHandbookPurchaseObjectsInfiniteQuery.data?.pages.flat(),
          [getHandbookPurchaseObjectsInfiniteQuery.data]
        );
        return (
          <SelectWithSearchWithoutItems
            items={
              flattedPurchaseObjects?.map(object => ({
                content: object.object,
                value: object.id,
              })) || []
            }
            loading={
              getHandbookPurchaseObjectsInfiniteQuery.isFetching ||
              getHandbookPurchaseObjectsInfiniteQuery.isFetchingNextPage ||
              getHandbookPurchaseObjectsInfiniteQuery.isLoading ||
              getHandbookPurchaseObjectsInfiniteQuery.hasNextPage
            }
            onLoadMore={() => getHandbookPurchaseObjectsInfiniteQuery.fetchNextPage()}
            onSearchChange={s => setFilterPurchaseObjects(s)}
            onOpenChange={setEnabled}
            search={filterPurchaseObjects}
            {...otherProps}
          />
        );
      };

    case 'regions':
      return () => {
        const [enabled, setEnabled] = useState(false);
        const [filterRegions, setFilterRegions] = useState('');
        const getHandbookRegionsInfiniteQuery = $regionsHooks.useGetAll(
          {
            query: filterRegions,
            limit: 30,
          },
          enabled
        );
        const flattedRegions = useMemo(
          () => getHandbookRegionsInfiniteQuery.data?.pages.flat(),
          [getHandbookRegionsInfiniteQuery.data]
        );
        return (
          <SelectWithSearchWithoutItems
            items={
              flattedRegions?.map(region => ({ content: region.name, value: region.id })) || []
            }
            loading={
              getHandbookRegionsInfiniteQuery.isFetching ||
              getHandbookRegionsInfiniteQuery.isFetchingNextPage ||
              getHandbookRegionsInfiniteQuery.isLoading ||
              getHandbookRegionsInfiniteQuery.hasNextPage
            }
            onLoadMore={() => getHandbookRegionsInfiniteQuery.fetchNextPage()}
            onSearchChange={s => setFilterRegions(s)}
            onOpenChange={setEnabled}
            search={filterRegions}
            {...otherProps}
          />
        );
      };

    case 'restriction-advantage-requirements':
      return () => {
        const [enabled, setEnabled] = useState(false);
        const [filterRestrictionAdvantageRequirements, setFilterRestrictionAdvantageRequirements] =
          useState('');
        const getHandbookRestrictionAdvantageRequirementsInfiniteQuery =
          $restrictionAdvantageRequirementsHooks.useGetAll(
            {
              query: filterRestrictionAdvantageRequirements,
              limit: 30,
            },
            enabled
          );
        const flattedRestrictionAdvantageRequirements = useMemo(
          () => getHandbookRestrictionAdvantageRequirementsInfiniteQuery.data?.pages.flat(),
          [getHandbookRestrictionAdvantageRequirementsInfiniteQuery.data]
        );
        return (
          <SelectWithSearchWithoutItems
            items={
              flattedRestrictionAdvantageRequirements?.map(val => ({
                content: val.name,
                value: val.id,
              })) || []
            }
            loading={
              getHandbookRestrictionAdvantageRequirementsInfiniteQuery.isFetching ||
              getHandbookRestrictionAdvantageRequirementsInfiniteQuery.isFetchingNextPage ||
              getHandbookRestrictionAdvantageRequirementsInfiniteQuery.isLoading ||
              getHandbookRestrictionAdvantageRequirementsInfiniteQuery.hasNextPage
            }
            onLoadMore={() =>
              getHandbookRestrictionAdvantageRequirementsInfiniteQuery.fetchNextPage()
            }
            onSearchChange={s => setFilterRestrictionAdvantageRequirements(s)}
            onOpenChange={setEnabled}
            search={filterRestrictionAdvantageRequirements}
            {...otherProps}
          />
        );
      };

    case 'supplier-contracts':
      return () => {
        const [enabled, setEnabled] = useState(false);
        const [filterSupplierContracts, setFilterSupplierContracts] = useState('');
        const getHandbookSupplierContractsInfiniteQuery = $supplierContractsHooks.useGetAll(
          {
            query: filterSupplierContracts,
            limit: 30,
          },
          enabled
        );
        const flattedSupplierContracts = useMemo(
          () => getHandbookSupplierContractsInfiniteQuery.data?.pages.flat(),
          [getHandbookSupplierContractsInfiniteQuery.data]
        );
        return (
          <SelectWithSearchWithoutItems
            items={
              flattedSupplierContracts?.map(contract => ({
                content: contract.number,
                value: contract.id,
              })) || []
            }
            loading={
              getHandbookSupplierContractsInfiniteQuery.isFetching ||
              getHandbookSupplierContractsInfiniteQuery.isFetchingNextPage ||
              getHandbookSupplierContractsInfiniteQuery.isLoading ||
              getHandbookSupplierContractsInfiniteQuery.hasNextPage
            }
            onLoadMore={() => getHandbookSupplierContractsInfiniteQuery.fetchNextPage()}
            onSearchChange={s => setFilterSupplierContracts(s)}
            onOpenChange={setEnabled}
            search={filterSupplierContracts}
            {...otherProps}
          />
        );
      };

    case 'suppliers':
      return () => {
        const [enabled, setEnabled] = useState(false);
        const [filterSuppliers, setFilterSuppliers] = useState('');
        const getHandbookSuppliersInfiniteQuery = $suppliersHooks.useGetAll(
          {
            query: filterSuppliers,
            limit: 30,
          },
          enabled
        );
        const flattedSuppliers = useMemo(
          () => getHandbookSuppliersInfiniteQuery.data?.pages.flat(),
          [getHandbookSuppliersInfiniteQuery.data]
        );
        return (
          <SelectWithSearchWithoutItems
            items={
              flattedSuppliers?.map(supplier => ({
                content: supplier.name,
                value: supplier.id,
              })) || []
            }
            loading={
              getHandbookSuppliersInfiniteQuery.isFetching ||
              getHandbookSuppliersInfiniteQuery.isFetchingNextPage ||
              getHandbookSuppliersInfiniteQuery.isLoading ||
              getHandbookSuppliersInfiniteQuery.hasNextPage
            }
            onLoadMore={() => getHandbookSuppliersInfiniteQuery.fetchNextPage()}
            onSearchChange={s => setFilterSuppliers(s)}
            onOpenChange={setEnabled}
            search={filterSuppliers}
            {...otherProps}
          />
        );
      };

    case 'commercial-offers':
      return () => {
        const [enabled, setEnabled] = useState(false);
        const [filterCommercialOffers, setFilterCommercialOffers] = useState('');
        const getHandbookCommercialOffersInfiniteQuery = $commercialOfferHooks.getAll(
          {
            search_value: filterCommercialOffers,
            limit: 30,
            filter: {},
          },
          enabled
        );
        const flattedCommercialOffers = useMemo(
          () => getHandbookCommercialOffersInfiniteQuery.data?.pages.flat(),
          [getHandbookCommercialOffersInfiniteQuery.data]
        );
        return (
          <SelectWithSearchWithoutItems
            items={
              flattedCommercialOffers?.map(offer => ({
                content: offer.title,
                value: offer.id,
              })) || []
            }
            loading={
              getHandbookCommercialOffersInfiniteQuery.isFetching ||
              getHandbookCommercialOffersInfiniteQuery.isFetchingNextPage ||
              getHandbookCommercialOffersInfiniteQuery.isLoading ||
              getHandbookCommercialOffersInfiniteQuery.hasNextPage
            }
            onLoadMore={() => getHandbookCommercialOffersInfiniteQuery.fetchNextPage()}
            onSearchChange={s => setFilterCommercialOffers(s)}
            onOpenChange={setEnabled}
            search={filterCommercialOffers}
            {...otherProps}
          />
        );
      };

    case 'contracts':
      return () => {
        const [enabled, setEnabled] = useState(false);
        const [filterContracts, setFilterContracts] = useState('');
        const getHandbookContractsInfiniteQuery = $contractHooks.getAll(
          {
            search_value: filterContracts,
            limit: 30,
          },
          enabled
        );
        const flattedContracts = useMemo(
          () => getHandbookContractsInfiniteQuery.data?.pages.flat(),
          [getHandbookContractsInfiniteQuery.data]
        );

        return (
          <SelectWithSearchWithoutItems
            items={
              flattedContracts?.map(contract => ({
                content: contract.name,
                // TODO: ошибка в Контрактах
                value: contract.id!,
              })) || []
            }
            loading={
              getHandbookContractsInfiniteQuery.isFetching ||
              getHandbookContractsInfiniteQuery.isFetchingNextPage ||
              getHandbookContractsInfiniteQuery.isLoading ||
              getHandbookContractsInfiniteQuery.hasNextPage
            }
            onLoadMore={() => getHandbookContractsInfiniteQuery.fetchNextPage()}
            onSearchChange={s => setFilterContracts(s)}
            onOpenChange={setEnabled}
            search={filterContracts}
            {...otherProps}
          />
        );
      };
    case 'tenders':
      return () => {
        const [enabled, setEnabled] = useState(false);
        const [filterTenders, setFilterTenders] = useState('');
        const getHandbookTendersInfiniteQuery = $tenderHooks.getAll(
          {
            search_value: filterTenders,
            limit: 30,
          },
          enabled
        );
        const flattedTenders = useMemo(
          () => getHandbookTendersInfiniteQuery.data?.pages.flat(),
          [getHandbookTendersInfiniteQuery.data]
        );
        return (
          <SelectWithSearchWithoutItems
            items={
              flattedTenders?.map(tender => ({ content: tender.title, value: tender.id })) || []
            }
            loading={
              getHandbookTendersInfiniteQuery.isFetching ||
              getHandbookTendersInfiniteQuery.isFetchingNextPage ||
              getHandbookTendersInfiniteQuery.isLoading ||
              getHandbookTendersInfiniteQuery.hasNextPage
            }
            onLoadMore={() => getHandbookTendersInfiniteQuery.fetchNextPage()}
            onSearchChange={s => setFilterTenders(s)}
            onOpenChange={setEnabled}
            search={filterTenders}
            {...otherProps}
          />
        );
      };
  }
}

export function TableFiltersSidebar<T extends string[]>({
  filters,
  onConfirm,
  onClose,
  open,
  columnsToSelectQuery,
  columnsToSystemHandbook,
  onColumnsContentChange,
}: TableFiltersSidebarProps<T>) {
  const [filtersState, setFiltersStates] = useState<Record<T[number], SidebarFilter>>(filters);

  function SelectComponentSystemHandbook({
    systemHandbookName,
    initialValue,
    onConfirm,
    onReset,
    onContentChange,
  }: {
    systemHandbookName: SystemHandbookName;
    initialValue: string[];
    onConfirm: (value: string[]) => void;
    onReset: () => void;
    onContentChange: (content: string) => void;
  }) {
    const [filter, onFilter] = useState('');
    const [enabled, setEnabled] = useState(false);

    const getSystemHandbook = useGetSystemHandbookQuery(systemHandbookName, enabled);

    return (
      <SelectWithSearch
        delay={0}
        className="w-[286px] my-[6px] mr-auto ml-8 static"
        items={
          getSystemHandbook.data
            ?.map(v => ({ content: v, value: v }))
            .filter(item => item.content.includes(filter)) || []
        }
        initialValue={initialValue}
        filterPlaceholder="Поиск"
        placeholder="Выберите"
        multiple
        size="l"
        popupClassName="w-[286px]"
        textInputWrapperClassname="p-2 flex flex-col gap-1"
        onConfirm={onConfirm}
        onReset={onReset}
        loading={getSystemHandbook.isLoading}
        filter={filter}
        onFilterChange={onFilter}
        onOpenChange={setEnabled}
        onContentChange={onContentChange}
      />
    );
  }

  const selectItems = useMemo<MyFoldableListItem[]>(() => {
    return objectKeysSafeToArray(filtersState).map(key => {
      const filter = filtersState[key];

      if (filter.type === 'select') {
        const apiQueryKey =
          columnsToSelectQuery && key in columnsToSelectQuery
            ? (columnsToSelectQuery[key] as ApiQueries)
            : undefined;

        const RenderedSelect = apiQueryKey
          ? renderSelect(apiQueryKey, {
              initialValue: filter.value || [],
              onConfirm: value => {
                setFiltersStates(prev => ({ ...prev, [key]: { type: filter.type, value } }));
              },
              onReset: () => {
                setFiltersStates(prev => ({ ...prev, [key]: { type: filter.type, value: [] } }));
              },
              onContentChange(content) {
                onColumnsContentChange(prev => ({ ...prev, [key]: content }));
              },
            })
          : undefined;

        return {
          listItem: RenderedSelect ? (
            <RenderedSelect />
          ) : columnsToSystemHandbook && key in columnsToSystemHandbook ? (
            <SelectComponentSystemHandbook
              systemHandbookName={columnsToSystemHandbook[key]!}
              initialValue={filter.value || []}
              onConfirm={value =>
                setFiltersStates(prev => ({ ...prev, [key]: { type: filter.type, value } }))
              }
              onReset={() =>
                setFiltersStates(prev => ({ ...prev, [key]: { type: filter.type, value: [] } }))
              }
              onContentChange={content => {
                onColumnsContentChange(prev => ({ ...prev, [key]: content }));
              }}
            />
          ) : undefined,
          ...commonItemProps,
          title: key,
          rightLabel: {
            children: filter.value?.length || undefined,
            className: 'absolute right-2 top-[12px]',
          },
        };
      }

      if (filter.type === 'selectWinners') {
        return {
          listItem: (
            <SelectWinnersWithSearch
              className="w-[286px] my-[6px] mr-auto ml-8 static"
              initialValue={filter.value}
              placeholder="Выберите"
              size="l"
              popupClassName=" w-[286px]"
              textInputWrapperClassname="p-2 flex flex-col gap-1"
              onConfirm={value => {
                setFiltersStates(prev => ({ ...prev, [key]: { type: filter.type, value } }));
              }}
              onReset={() => {
                setFiltersStates(prev => ({ ...prev, [key]: { type: filter.type, value: [] } }));
              }}
              onContentChange={content => {
                onColumnsContentChange(prev => ({ ...prev, [key]: content }));
              }}
            />
          ),
          ...commonItemProps,
          title: key,
          rightLabel: {
            children: filter.value?.length || undefined,
            className: 'absolute right-2 top-[12px]',
          },
        };
      }

      if (filter.type === 'search') {
        return {
          listItem: (
            <TextInput
              className="w-[286px] my-[6px] mr-auto ml-8"
              size="l"
              placeholder={key}
              value={filter.value}
              onUpdate={text =>
                setFiltersStates(prev => ({ ...prev, [key]: { type: filter.type, value: text } }))
              }
            />
          ),
          ...commonItemProps,
          title: key,
          rightLabel: {
            children: filter.value ? '1' : undefined,
            className: 'absolute right-2 top-[12px]',
          },
        };
      }

      let radioValue = null;
      switch (filter.value) {
        case 'false':
          radioValue = 'Нет';
          break;
        case 'true':
          radioValue = 'Да';
          break;
        default:
          radioValue = 'Не выбрано';
          break;
      }
      return {
        listItem: (
          <RadioButton
            className="w-[286px] my-[6px] mr-auto ml-8"
            value={radioValue}
            onUpdate={newValue => {
              let convertVal: FilterBooleanType['value'] = undefined;
              switch (newValue) {
                case 'Да':
                  convertVal = 'true';
                  break;
                case 'Нет':
                  convertVal = 'false';
                  break;
              }
              setFiltersStates(prev => ({
                ...prev,
                [key]: {
                  type: filter.type,
                  value: convertVal,
                },
              }));
            }}
            options={conditionalOptions}
          />
        ),
        ...commonItemProps,
        title: key,
        rightLabel: {
          children: filter.value ? '1' : undefined,
          className: 'absolute right-2 top-[12px]',
        },
      };
    });
  }, [filtersState]);

  const resetValues = () => {
    setFiltersStates(prev =>
      objectKeysSafeToArray(prev).reduce(
        (acc, fil) => {
          switch (prev[fil].type) {
            case 'select':
            case 'selectWinners':
              acc[fil] = { type: 'select', value: [] };
              break;
            case 'search':
              acc[fil] = { type: 'search', value: '' };
              break;
            case 'boolean':
              acc[fil] = { type: 'boolean', value: undefined };
              break;
          }
          return acc;
        },
        {} as Record<T[number], SidebarFilter>
      )
    );
  };

  useEffect(() => {
    setFiltersStates(filters);
  }, [filters]);

  return (
    <RightSidebar
      id="table-layout-filters"
      className="!w-[338px]"
      open={open}
      onClose={onClose}
    >
      <RightSidebar.Header
        className="pt-[26px] pb-[10px] pl-[32px] pr-[20px] items-center"
        title="Фильтры"
        titleProps={{
          variant: 'subheader-3',
        }}
        onClose={onClose}
      />

      <ExpandList
        items={selectItems}
        className="overflow-y-scroll w-full"
      />

      <RightSidebar.BottomPanel
        className="pt-[15px] px-[32px] pb-[28px] justify-end border-none"
        withoutShadow
      >
        <Button
          view="flat-secondary"
          size="l"
          onClick={() => resetValues()}
        >
          <Text>Сбросить</Text>
        </Button>
        <Button
          view="action"
          size="l"
          onClick={() => onConfirm(filtersState)}
        >
          <Text>Применить</Text>
        </Button>
      </RightSidebar.BottomPanel>
    </RightSidebar>
  );
}
