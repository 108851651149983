import ReactDOM from 'react-dom/client';

import App from '@app/App';

import '@gravity-ui/uikit/styles/fonts.css';
import '@gravity-ui/uikit/styles/styles.css';
import './index.sass';

async function enableMocking() {
  return;
  // if (!import.meta.env.DEV) {
  //   return;
  // }
  // const { worker } = await import('../mock/browser.js');
  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  // return worker.start();
}

enableMocking().then(() =>
  ReactDOM.createRoot(document.getElementById('root')!).render(
    // <React.StrictMode> HACK: with StrictMode sockets will not working cause of twice effect https://socket.io/how-to/use-with-react#disconnection
    <App />
    // </React.StrictMode>
  )
);
