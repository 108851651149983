export function generateTitle({
  customer,
  supplyObject,
  uniqueNumber,
}: {
  supplyObject: string;
  customer: string;
  uniqueNumber: string;
}) {
  if (customer && supplyObject && uniqueNumber) {
    return `${customer} / ${supplyObject} / ${uniqueNumber}`;
  }
  return '';
}
