import { SelectRenderOption } from '@gravity-ui/uikit';
import { ComponentProps, useState } from 'react';
import Marquee from 'react-fast-marquee';

interface CustomSelectOptionProps {
  option: ComponentProps<SelectRenderOption<string>>;
}

export const CustomSelectOption = ({ option }: CustomSelectOptionProps) => {
  const [isEntered, setIsEntered] = useState(false);

  return (
    <div
      onMouseEnter={() => setIsEntered(true)}
      onMouseLeave={() => setIsEntered(false)}
      className="h-7 overflow-hidden w-full flex items-center"
    >
      {isEntered ? (
        <Marquee
          className="w-full"
          loop={1}
        >
          {option.content}&nbsp;&nbsp;
        </Marquee>
      ) : (
        <span className="whitespace-nowrap text-ellipsis overflow-hidden w-full">
          {option.content}
        </span>
      )}
    </div>
  );
};
