interface IEnvironment {
  WS_URL: string;
  API_URL: string;
  URL: string;
}
export const ENVIRONMENT: IEnvironment = import.meta.env.DEV
  ? {
      WS_URL: 'wss://stage.api.b2gcrm.ru',
      API_URL: 'https://stage.api.b2gcrm.ru',
      URL: 'https://stage.b2gcrm.ru',
    }
  : {
      WS_URL: 'wss://stage.api.b2gcrm.ru',
      API_URL: 'https://stage.api.b2gcrm.ru',
      URL: 'https://stage.b2gcrm.ru',
    };
