import { bodyResponseType } from '@shared/api';

import { CreateOkpd2s } from './CreateOkpd2s';
import { EditOkpd2s } from './EditOkpd2s';

type Okpd2sDrawerProps = {
  onClose: () => void;
  withDialog?: boolean;
} & (
  | {
      type: 'create';
      open: boolean;
      id?: undefined;
      afterCreate?: (createdOkpd2: bodyResponseType<'post', '/handbook/okpd2s'>) => void;
    }
  | {
      type: 'edit';
      open?: undefined;
      id?: string;
      afterCreate?: undefined;
    }
);

export function Okpd2sDrawer({
  type,
  open,
  onClose,
  id,
  afterCreate,
  withDialog,
}: Okpd2sDrawerProps) {
  return (
    <>
      {type === 'create' ? (
        <CreateOkpd2s
          open={open}
          onClose={onClose}
          afterCreate={afterCreate}
          withDialog={withDialog}
        />
      ) : (
        <EditOkpd2s
          onClose={onClose}
          id={id}
        />
      )}
    </>
  );
}
