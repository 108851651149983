import { ReactNode } from 'react';

import { KnowledgeBasesForm, KnowledgeBasesFormProps } from '@features';

interface KnowledgeBasesLayoutProps {
  renderForm: (formComponent: ReactNode) => ReactNode;
  formProps: KnowledgeBasesFormProps;
}

export function KnowledgeBasesLayout({ renderForm, formProps }: KnowledgeBasesLayoutProps) {
  return <>{renderForm(<KnowledgeBasesForm {...formProps} />)}</>;
}
