import './index.sass';

import { Loader, Text } from '@gravity-ui/uikit';
import { useState } from 'react';
import { useDebounce } from 'use-debounce';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { $contactsHooks } from '@entities';
import { CRMTable } from '@features';
import { CRMTableColumns, CRMTableData, CRMTableSettings } from '@features/table';

import { parseTableData } from '../lib';
import { ColumnsHandbookTable } from '../model';

type ContactsTableProps = {
  selectedOfferIndex: number | null;
  onRowClick: (id: string, index: number) => void;
};

export const ContactsTable = ({ selectedOfferIndex, onRowClick }: ContactsTableProps) => {
  const [tableData, setTableData] = useState<CRMTableData<ColumnsHandbookTable>>([]);

  // КОНЕЦ фильтры сайдбар

  // НАЧАЛО столбцы таблицы

  const columns: CRMTableColumns<ColumnsHandbookTable> = (
    [
      {
        id: 'Дата, номер',
        template: item => <Text className="text-custom-text-warning">{item['Дата, номер']}</Text>,
      },
      { id: 'ФИО' },
      { id: 'Тип контактного лица' },
      { id: 'Статус контактного лица' },
      { id: 'Должность' },
      { id: 'Телефон' },
      { id: 'Эл. адрес' },
      { id: 'Комментарий' },
      { id: 'Организация' },
    ] as CRMTableColumns<ColumnsHandbookTable>
  ).map(column => ({
    ...column,
    placeholder: '',
    meta: { filter: true, selectedByDefault: true },
  })) as unknown[] as CRMTableColumns<ColumnsHandbookTable>;

  const initialSettings: CRMTableSettings<ColumnsHandbookTable> = columns.map(col => ({
    id: col.id,
    isSelected: true,
  }));

  const [tableSettings, setTableSettings] = useState<CRMTableSettings<ColumnsHandbookTable>>(
    structuredClone(initialSettings)
  );

  // КОНЕЦ столбцы таблицы

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const [searchAttributesValue, setSearchAttributesValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchAttributesValue, 500);

  function searchAttributes(value: string) {
    setSearchAttributesValue(value);
  }

  const tableDataInfiniteQuery = $contactsHooks.useGetAll(
    {
      query: debouncedSearchValue,
      limit: 30,
    },
    true
  );

  useDeepCompareEffect(() => {
    if (tableDataInfiniteQuery.data?.pages) {
      setTableData(parseTableData(tableDataInfiniteQuery.data.pages.flat()));
    }
  }, [tableDataInfiniteQuery.data || []]);

  return (
    <CRMTable>
      {/* поиск и кнопки фильтров, настройки таблиц */}
      <CRMTable.SearchAndActionsPanel
        searchValue={searchAttributesValue}
        onUpdate={searchAttributes}
        placeholder="Поиск по атрибутам"
      />

      <CRMTable.Table<ColumnsHandbookTable>
        hideScrollbar
        className="commercial-offer-table"
        isLoading={tableDataInfiniteQuery.isFetching}
        onIntersecting={() => {
          tableDataInfiniteQuery.fetchNextPage();
        }}
        data={tableData}
        columns={columns}
        settings={tableSettings}
        updateSettings={settings => {
          setTableSettings(settings);
          return Promise.resolve();
        }}
        selectedIds={selectedIds}
        onSelectionChange={ids => {
          setSelectedIds(ids);
        }}
        getRowDescriptor={(_, index) => {
          return {
            disabled: false,
            classNames: selectedOfferIndex === index ? ['table-select-row'] : [],
          };
        }}
        onRowClick={(item, index) => {
          if (tableDataInfiniteQuery.data) {
            const row = tableDataInfiniteQuery.data.pages.flat()[index];
            if (row) onRowClick(row.id, index);
          }
        }}
      />

      {(tableDataInfiniteQuery.isFetching || tableDataInfiniteQuery.isLoading) && (
        <div className="grow flex justify-center items-center">
          <Loader size="l" />
        </div>
      )}
    </CRMTable>
  );
};
