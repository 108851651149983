import { useToaster } from '@gravity-ui/uikit';
import { useAtom, useAtomValue } from 'jotai';

import { AuthForm, AuthFormProps } from '@features';
import { URLS } from '@shared/consts';
import { useNavigateTo } from '@shared/lib';

import { usePostAuthResetPasswordMutation } from '../api';
import { emailAuthAtom, newPasswordSchema, pinCodeAuthAtom, resetAuthCredentials } from '../model';

export const NewPassword = () => {
  const { navigateTo } = useNavigateTo();
  const emailValue = useAtomValue(emailAuthAtom);
  const pinCodeValue = useAtomValue(pinCodeAuthAtom);
  const [, resetCredentials] = useAtom(resetAuthCredentials);
  const postAuthResetPasswordMutation = usePostAuthResetPasswordMutation();
  const { add } = useToaster();

  const newPasswordProps: AuthFormProps<typeof newPasswordSchema> = {
    schema: newPasswordSchema,
    defaultValues: {
      password: '',
      passwordConfirm: '',
    },
    async onValid({ password }) {
      try {
        await postAuthResetPasswordMutation.mutateAsync({
          body: {
            password,
            email: emailValue,
            verification_code: pinCodeValue,
          },
        });
        resetCredentials();
        navigateTo(URLS.auth.default);
        add({
          name: 'success-new-password',
          title: 'Пароль успешно сброшен',
          theme: 'success',
        });
      } catch {
        add({
          name: 'invalid-new-password',
          title: 'Ошибка при смене пароля',
          theme: 'danger',
        });
      }
    },
    title: 'Придумайте новый пароль',
    inputs: {
      password: {
        type: 'password',
        props: {
          placeholder: 'Новый пароль',
          autoComplete: 'new-password',
        },
      },
      passwordConfirm: {
        type: 'password',
        props: {
          placeholder: 'Новый пароль еще раз',
        },
      },
    },
    buttons: {
      primaryButtonProps: {
        children: 'Сбросить пароль',
        className: 'w-full',
        loading: postAuthResetPasswordMutation.isPending,
      },
    },
  };

  return <AuthForm {...newPasswordProps} />;
};
