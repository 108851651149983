import { RadioButtonOption } from '@gravity-ui/uikit';

import { ChatFilterStatus } from '../model/types';

export const STATUS_OPTIONS: RadioButtonOption<ChatFilterStatus | 'all'>[] = [
  {
    value: 'all',
    content: 'Все',
  },
  {
    value: 'commercial-offer',
    content: 'Коммерческие предложения',
  },
  {
    value: 'bargaining',
    content: 'Торги',
  },
  {
    value: 'contracts',
    content: 'Контракты',
  },
];
