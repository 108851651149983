import { dateTime } from '@gravity-ui/date-utils';

import { CRMTableData } from '@features/table';
import { bodyResponseType } from '@shared/api';
import { DATE_FORMATS, URLS } from '@shared/consts';
import { formatToPrice } from '@shared/lib';

import { ColumnsCommercialOffersTable } from '../model';

const parseDate = (date: string, withTime?: boolean) =>
  dateTime({ input: date }).format(
    !withTime ? DATE_FORMATS.fullDate : DATE_FORMATS.fullDateWithTimeRounded
  );

const parseFloat = (float: number): number => {
  return float / 100;
};

const parseMultiple = (names: string[]): string => {
  try {
    return names.join(' \n');
  } catch {
    return '';
  }
};

const parseFiles = (files: string[]): string => {
  try {
    const keys = files.map(url => {
      return url.replace('https://stage.api.b2gcrm.ru/', '');
    });
    const fileNames = keys.map(key => {
      const split = key.split('/');
      return split.pop()!;
    });
    return parseMultiple(fileNames);
  } catch {
    return '';
  }
};

export const parseCommercialOfferTableData = (
  tableData: bodyResponseType<'post', '/offer/all'>['offers']
): CRMTableData<ColumnsCommercialOffersTable> => {
  return tableData.map(offer => {
    return {
      'Дата, номер': `${parseDate(offer.created_at)} №${offer.id}`,
      Наименование: offer.title,
      Ответственный: offer.responsible_value,
      Заказчик: offer.customer_value,
      'Цель КП': offer.purpose,
      'Статус КП': offer.status,
      'Причина отказа': offer.reject_reason || '',
      'Комментарий к причине отказа': offer.reject_description || '',
      Тендер: offer.tender_id ? `${URLS.processes.tenders}/${offer.tender_id}` : '',
      'Дата перевода': offer.transfer_at ? parseDate(offer.transfer_at) : '',
      'Регион поставки': offer.delivery_region_value,
      'Адрес поставки': offer.delivery_address,
      'Срок поставки': offer.delivery_deadline,
      Основание: offer.basis_purchase || '',
      // 'Как получен запрос': offer.request_source || '',
      // 'Ссылка в ЕИС': offer.eis_link || '',
      'Окончание подачи': parseDate(offer.submission_deadline, true),
      'Срок проведения закупки с': offer.producement_start_at
        ? parseDate(offer.producement_start_at)
        : '',
      'Срок проведения закупки по': offer.producement_end_at
        ? parseDate(offer.producement_end_at)
        : '',
      'Документы запроса': offer.documents ? parseFiles(offer.documents) : '',
      'Объект закупки': offer.purchase_object_value || '',
      Комментарий: offer.comment || '',
      'Файл расчета': offer.calculation_file ? parseFiles([offer.calculation_file]) : '',
      Себестоимость:
        typeof offer.offer_price === 'number' ? formatToPrice(offer.offer_price, true) : '',
      'Цена предложения':
        typeof offer.offer_price === 'number' ? formatToPrice(offer.offer_price, true) : '',
      'Маржа, %': offer.margin ? `${parseFloat(offer.margin)} %` : '',
      'Файл КП': offer.offer_file ? parseFiles([offer.offer_file]) : '',
      'Дата КП': offer.document_date ? parseDate(offer.document_date) : '',
      Поставщик: offer.provider_value || '',
      'Предлагаемая специалистом цена':
        typeof offer.proposed_price === 'number' ? formatToPrice(offer.proposed_price, true) : '',
      'Обоснование предложения цены': offer.price_justification || '',
      'Комментарий согласований': offer.approval_comment || '',
      'Итоговая цена':
        typeof offer.final_price === 'number' ? formatToPrice(offer.final_price, true) : '',
      Победитель: offer.winner_value || '',
    };
  });
};
