import { useToaster } from '@gravity-ui/uikit';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import { $employeesHooks } from '@entities';
import { CreateHandbookDialog, employeesFormAtom } from '@features';
import { bodyResponseType } from '@shared/api';
import { RightSidebar } from '@shared/ui';

import { EmployeesLayout } from './EmployeesLayout';

interface CreateEmployeesProps {
  open: boolean;
  onClose: () => void;
  afterCreate?: (createdEmployee: bodyResponseType<'post', '/handbook/employees'>) => void;
  withDialog?: boolean;
}

export function CreateEmployees({ open, onClose, afterCreate, withDialog }: CreateEmployeesProps) {
  const { add } = useToaster();

  const queryClient = useQueryClient();

  const [employeesCreation, setEmployeesCreation] = useAtom(employeesFormAtom);

  // Queries
  const createEmployeesMutation = $employeesHooks.create();

  //

  return (
    <EmployeesLayout
      formProps={{
        defaultValues: employeesCreation,
        onValuesChange: values => {
          setEmployeesCreation(values);
        },
        onClickCancelButton: () => {
          setEmployeesCreation(undefined);
          onClose();
        },
        compactBottomPanel: true,
        // disabled: underApproval,
        initialEditable: true,
        onValid: async values => {
          await createEmployeesMutation
            .mutateAsync({
              body: values,
            })
            .then(createdEmployee => {
              add({
                name: 'create-employees-success',
                theme: 'success',
                content: 'Карточка Сотрудника успешна создана!',
              });
              queryClient.invalidateQueries({ queryKey: ['/employees/all'] });
              afterCreate?.(createdEmployee);

              setEmployeesCreation(undefined);
              onClose();
            })
            .catch(() => {
              add({
                name: 'create-employees-error',
                theme: 'danger',
                content: 'Ошибка при создании карточки Сотрудника',
              });
            });
        },
        onInvalid: errors => {
          console.log('@errors', errors);
        },
      }}
      renderForm={formComponent =>
        withDialog ? (
          <CreateHandbookDialog
            onClose={onClose}
            open={open}
            title="Создание карточки Сотрудника"
          >
            {formComponent}
          </CreateHandbookDialog>
        ) : (
          <RightSidebar
            open={open}
            onClose={onClose}
          >
            <RightSidebar.Header
              title="Создание карточки Сотрудника"
              onClose={onClose}
            />
            {formComponent}
          </RightSidebar>
        )
      }
    />
  );
}
