import { HandbookOption, PriorityUnionStrings } from '@shared/model';

import { CustomerClientStatus, CustomerLevel, CustomerType } from '../model';

interface CustomerOptions {
  customer_type: HandbookOption<CustomerType>[];
  customer_level: HandbookOption<CustomerLevel>[];
  client_status: HandbookOption<CustomerClientStatus>[];
  priority: HandbookOption<PriorityUnionStrings>[];
}

export const CUSTOMERS_OPTIONS: CustomerOptions = {
  customer_type: [
    { value: 'Лид', content: 'Лид' },
    { value: 'Клиент', content: 'Клиент' },
  ],
  customer_level: [
    { value: 'Федеральный', content: 'Федеральный' },
    { value: 'Региональный', content: 'Региональный' },
    { value: 'Муниципальный', content: 'Муниципальный' },
    { value: 'Коммерческий', content: 'Коммерческий' },
  ],
  client_status: [
    { value: 'Новый', content: 'Новый' },
    { value: 'Разовый', content: 'Разовый' },
    { value: 'Стандартный', content: 'Стандартный' },
    { value: 'Ключевой', content: 'Ключевой' },
  ],
  priority: [
    { value: '1', content: '1' },
    { value: '2', content: '2' },
    { value: '3', content: '3' },
    { value: '4', content: '4' },
    { value: '5', content: '5' },
  ],
};
