import { Plus } from '@gravity-ui/icons';
import { Button, ButtonProps, Icon, Text } from '@gravity-ui/uikit';

interface HeroWithButtonProps {
  title: string;
  buttonOnClick?: ButtonProps['onClick'];
}

export function HeroWithButton({ title, buttonOnClick }: HeroWithButtonProps) {
  return (
    <div className="min-h-21 px-5 pt-8 pb-4 flex flex-row justify-between items-center">
      <Text variant="display-1">{title}</Text>
      {buttonOnClick && (
        <Button
          size="l"
          view="action"
          onClick={buttonOnClick}
        >
          <Icon data={Plus} />
          <Text>Добавить</Text>
        </Button>
      )}
    </div>
  );
}
