import { RestrictionAdvantageRequirementStatus } from '@entities';
import { HandbookOption } from '@shared/model';

interface RestrictionAdvantageRequirementOptions {
  status: HandbookOption<RestrictionAdvantageRequirementStatus>[];
}

export const RESTRICTION_ADVANTAGE_REQUIREMENT_OPTIONS: RestrictionAdvantageRequirementOptions = {
  status: [
    {
      value: 'Действующий',
      content: 'Действующий',
    },
    {
      value: 'Недействующий',
      content: 'Недействующий',
    },
  ],
};
