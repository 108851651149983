import { Paperclip } from '@gravity-ui/icons';
import { Button, Icon, TextArea } from '@gravity-ui/uikit';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';

import { sendMessageSchema, SendMessageSchema } from '../model';

interface SendMessageFormProps {
  onValid: SubmitHandler<SendMessageSchema>;
  onInvalid?: SubmitErrorHandler<SendMessageSchema>;
}

export function SendMessageForm({ onValid: externalOnValid, onInvalid }: SendMessageFormProps) {
  const sendMessageForm = useForm<SendMessageSchema>({
    defaultValues: {
      message: '',
    },
    resolver: yupResolver(sendMessageSchema),
  });

  const onValid = (values: SendMessageSchema) => {
    externalOnValid(values);
    sendMessageForm.reset();
  };

  return (
    <div className="bg-base-background border-t border-line-generic shadow-select p-2">
      <form
        className="flex gap-2 items-end border border-line-generic rounded-lg pl-3 pr-1 py-[3px]"
        onSubmit={sendMessageForm.handleSubmit(onValid, onInvalid)}
      >
        <Controller
          control={sendMessageForm.control}
          name="message"
          render={({ field }) => (
            <TextArea
              {...field}
              placeholder="Сообщение..."
              size="l"
              view="clear"
              maxRows={6}
              onKeyDown={event => {
                if (event.key === 'Enter' && !event.shiftKey) {
                  event.preventDefault();
                  sendMessageForm.handleSubmit(onValid, onInvalid)();
                }
              }}
              controlProps={{
                className: 'hide-scrollbar',
              }}
            />
          )}
        />
        <div className="flex gap-1">
          <Button
            size="l"
            view="flat-secondary"
          >
            <Icon data={Paperclip} />
          </Button>
          <Button
            size="l"
            view="action"
            type="submit"
          >
            Отправить
          </Button>
        </div>
      </form>
    </div>
  );
}
