import { ArrowRotateLeft, Magnifier } from '@gravity-ui/icons';
import { Dialog, Divider, Icon, Text, TextInput } from '@gravity-ui/uikit';
import { useEffect, useState } from 'react';
import { createSearchParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { pluralize, useNavigateTo } from '@shared/lib';

import './index.sass';

import { FileMock, PeopleMock, ProjectMock, SEARCH_MOCK } from '../../model/mock';
import { GlobalSearchList } from '../GlobalSearchList';

interface GlobalSearchDialogProps {
  open: boolean;
  onClose: () => void;
}

export function GlobalSearchDialog({ open, onClose }: GlobalSearchDialogProps) {
  const { navigateTo } = useNavigateTo();
  const [searchValue, setSearchValue] = useState('');
  const [searchData, setSearchData] = useState<{
    count: number;
    items: {
      keywords: string[];
      files: FileMock[];
      projects: ProjectMock[];
      peoples: PeopleMock[];
    } | null;
  }>({ count: 0, items: null });

  const [debouncedSearchValue] = useDebounce(searchValue, 500);

  useEffect(() => {
    if (!debouncedSearchValue) {
      setSearchData({ count: 0, items: null });
      return;
    }

    const keywords = SEARCH_MOCK.keywords.filter(keyword =>
      keyword.toLocaleLowerCase().includes(debouncedSearchValue.toLocaleLowerCase())
    );
    const files = SEARCH_MOCK.files.filter(({ name }) =>
      name.toLocaleLowerCase().includes(debouncedSearchValue.toLocaleLowerCase())
    );
    const projects = SEARCH_MOCK.projects.filter(({ name }) =>
      name.toLocaleLowerCase().includes(debouncedSearchValue.toLocaleLowerCase())
    );
    const peoples = SEARCH_MOCK.peoples.filter(({ name }) =>
      name.toLocaleLowerCase().includes(debouncedSearchValue.toLocaleLowerCase())
    );

    const count = keywords.length + files.length + projects.length + peoples.length;

    setSearchData({ count, items: count === 0 ? null : { keywords, files, projects, peoples } });
  }, [debouncedSearchValue]);

  function navigateToSearchPage() {
    if (!debouncedSearchValue) return;
    navigateTo('/search', {
      search: `?${createSearchParams({ query: debouncedSearchValue })}`,
    });
    setSearchValue('');
    onClose();
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="search"
      disableBodyScrollLock
      size="m"
      className="search-dialog"
      onEnterKeyDown={navigateToSearchPage}
    >
      <Dialog.Header
        caption={
          <div className="flex gap-[17px] flex-col px-5 pt-5">
            <Text variant="subheader-3">Поиск по CRM</Text>
            <TextInput
              startContent={
                <Icon
                  data={Magnifier}
                  className="text-text-secondary"
                />
              }
              placeholder="Название или атрибуты"
              size="xl"
              hasClear
              className="main-search-input w-full"
              value={searchValue}
              onUpdate={setSearchValue}
            />
          </div>
        }
      />
      <Dialog.Body className="hide-scrollbar px-5">
        {searchData.items ? (
          <GlobalSearchList
            className="py-6"
            files={searchData.items.files}
            peoples={searchData.items.peoples}
            projects={searchData.items.projects}
            insertBefore={
              <>
                <div className="flex flex-col gap-2">
                  <Text
                    variant="subheader-1"
                    color="primary"
                  >
                    По ключевым словам
                  </Text>
                  {!!searchValue && (
                    <button className="flex items-center gap-2 py-2">
                      <Icon
                        data={Magnifier}
                        className="text-text-secondary"
                      />
                      <Text
                        variant="body-2"
                        color="primary"
                      >
                        {searchValue}
                      </Text>
                    </button>
                  )}
                  {searchData.items.keywords.map((keyword, index) => (
                    <button
                      key={index}
                      className="flex items-center gap-2 py-2"
                    >
                      <Icon
                        data={ArrowRotateLeft}
                        className="text-text-secondary"
                      />
                      <Text
                        variant="body-2"
                        color="primary"
                        className="truncate"
                        title={keyword}
                      >
                        {keyword}
                      </Text>
                    </button>
                  ))}
                </div>
                <Divider />
              </>
            }
            closeOnNavigate
            close={onClose}
          />
        ) : (
          <div className="w-full h-full flex items-center justify-center">
            <Icon
              data={Magnifier}
              className="text-base-generic"
              size="310"
            />
          </div>
        )}
      </Dialog.Body>
      {searchData.items && (
        <div className="p-2 border-t border-line-generic">
          <Dialog.Footer
            textButtonApply={`Показать ${searchData.count} ${pluralize(searchData.count, 'результат', 'результата', 'результатов')}`}
            propsButtonApply={{ size: 'xl', className: 'w-full' }}
            onClickButtonApply={navigateToSearchPage}
          />
        </div>
      )}
    </Dialog>
  );
}
