import { useToaster } from '@gravity-ui/uikit';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import { $contractHooks } from '@entities';
import {
  // IsCentralized,
  ContractSchema,
} from '@features';
import { fromCustomBooleanSchemaToBoolean } from '@shared/lib';
import { RightSidebar } from '@shared/ui';

import { convertCustomerPriorityToNumber, percentAndCurrencyValuesToBackend } from '../lib';
import { contractCreationAtom } from '../model';
import { ContractLayout } from './ContractLayout';

interface CreateContractProps {
  open: boolean;
  onClose: () => void;
  afterCreate?: (contract: ContractSchema) => void;
}

export function CreateContract({ open, onClose, afterCreate }: CreateContractProps) {
  const { add } = useToaster();

  const queryClient = useQueryClient();

  const [contractCreation, setContractCreation] = useAtom(contractCreationAtom);

  const createContractMutation = $contractHooks.create();

  return (
    <ContractLayout
      formProps={{
        defaultValues: contractCreation,
        onValuesChange: setContractCreation,
        hideTitleField: false,
        submitButtonText: 'Создать',
        initialEditable: true,
        onClickCancelButton: () => {
          setContractCreation(undefined);
          onClose();
        },
        onValid: async values => {
          await createContractMutation.mutateAsync({
            body: {
              ...values,
              ...percentAndCurrencyValuesToBackend(values),
              has_contract_security: fromCustomBooleanSchemaToBoolean(values.has_contract_security),
              has_smp_restriction: fromCustomBooleanSchemaToBoolean(values.has_smp_restriction),
              has_warranty_security: fromCustomBooleanSchemaToBoolean(values.has_warranty_security),
              customer_priority: convertCustomerPriorityToNumber(values.customer_priority),
              // TODO: сделать необязательными или убрать на бэке:
              // customer_value: '',
              // trading_platform: '',
              // supplier_value: '',
              // object_value: '',
              // id: '',
              // contract_number: '',
              // maintenance: 'Банковское',
              // signing_date: '',
              // stage: 'Заключен',
            },
          });
          if (createContractMutation.status === 'error') {
            return add({
              name: 'create-contract-validation-error',
              theme: 'danger',
              content: 'Ошибка при создании карточки Контрактов',
            });
          }
          add({
            name: 'create-contract-success',
            theme: 'success',
            content: 'Карточка Контрактов успешна создана!',
          });
          queryClient.invalidateQueries({ queryKey: ['/contract/all'] });
          setContractCreation(undefined);
          onClose();
          afterCreate?.(values);
        },
        onInvalid: errors => {
          add({
            name: 'create-contract-invalid',
            content: 'Есть незаполненные поля',
            theme: 'warning',
          });
          console.log('@errors', errors);
        },
      }}
      renderForm={formComponent => (
        <RightSidebar
          open={open}
          onClose={onClose}
        >
          <RightSidebar.Header
            title="Создание контракта"
            onClose={onClose}
          />
          {formComponent}
        </RightSidebar>
      )}
    />
  );
}
