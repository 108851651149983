/* eslint-disable @typescript-eslint/no-unused-vars */

import { Comments } from '@gravity-ui/icons';
import {
  Button,
  DropdownMenu,
  Icon,
  RadioButton,
  Spin,
  Text,
  Tooltip,
  useToaster,
} from '@gravity-ui/uikit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  $agreementHooks,
  $contractHooks,
  $tenderHooks,
  createdCompetitorForViewAtom,
  createdCustomerForViewAtom,
  createdEmployeeForViewAtom,
  createdETradingPlatformForViewAtom,
  createdPurchaseObjectForViewAtom,
  createdRegionForViewAtom,
  createdRestrictionAdvantageRequirementForViewAtom,
  userAtom,
} from '@entities';
import { getAgreementSubmitTender, getIsSubmissionDeadlineExpired, TenderSchema } from '@features';
import {
  createdCompetitorParticipantsForViewAtom,
  createdCustomerOrganizerForViewAtom,
  createdLegalEntityParticipateAsForViewAtom,
  createdLegalEntityWinnerForViewAtom,
  tenderSchema,
} from '@features/tender-form/model';
import { URLS } from '@shared/consts';
import {
  fromBooleanToYesNo,
  fromCustomBooleanSchemaToBoolean,
  roundPercentAndCurrencyToBackend,
  useNavigateTo,
} from '@shared/lib';
import { RightSidebar } from '@shared/ui';

import {
  checkIsNotBlankRelations,
  countRelations,
  percentAndCurrencyValuesFromBackend,
  percentAndCurrencyValuesToBackend,
} from '../lib';
import { TenderLayout } from './TenderLayout';
import { TenderRelations } from './TenderRelations';

interface EditTenderProps {
  id: string | undefined;
  onClose: () => void;
}

export function EditTender({ id, onClose }: EditTenderProps) {
  const user = useAtomValue(userAtom);
  const [isOpen, setIsOpen] = useState(false);

  const queryClient = useQueryClient();

  const { navigateTo } = useNavigateTo();
  const { add } = useToaster();

  const [activeTab, setActiveTab] = useState<'project' | 'relations'>('project');

  const setCreatedEmployeeForView = useSetAtom(createdEmployeeForViewAtom);
  const setCreatedETradingPlatformForView = useSetAtom(createdETradingPlatformForViewAtom);
  const setCreatedCustomerForView = useSetAtom(createdCustomerForViewAtom);
  const setCreatedCustomerOrganizerForView = useSetAtom(createdCustomerOrganizerForViewAtom);
  const setCreatedPurchaseObjectForView = useSetAtom(createdPurchaseObjectForViewAtom);
  const setCreatedLegalEntityParticipateAsForView = useSetAtom(
    createdLegalEntityParticipateAsForViewAtom
  );
  const setCreatedRestrictionAdvantageRequirementForView = useSetAtom(
    createdRestrictionAdvantageRequirementForViewAtom
  );
  const setCreatedRegionForView = useSetAtom(createdRegionForViewAtom);
  const setCreatedLegalEntityWinnerForView = useSetAtom(createdLegalEntityWinnerForViewAtom);
  const setCreatedCompetitorForView = useSetAtom(createdCompetitorForViewAtom);
  const setCreatedCompetitorParticipantsForView = useSetAtom(
    createdCompetitorParticipantsForViewAtom
  );

  // Queries
  const createAgreementMutation = $agreementHooks.create();

  const createContractQuery = $contractHooks.create();

  const getTenderQuery = $tenderHooks.getById(id || '', !!id);
  const updateTenderMutation = $tenderHooks.update();
  const deleteTenderMutation = $tenderHooks.delete();
  const getTenderRelatedQuery = $tenderHooks.related(id || '', !!id);
  //

  useEffect(() => {
    if (getTenderQuery.error) {
      navigateTo(URLS.processes.tenders);
      add({
        theme: 'danger',
        name: 'update-offer-error',
        title: 'Произошла ошибка при получении карточки Торгов',
      });
    }
  }, [getTenderQuery.isFetching]);

  useEffect(() => {
    if (id) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
      setCreatedEmployeeForView(undefined);
      setCreatedETradingPlatformForView(undefined);
      setCreatedCustomerForView(undefined);
      setCreatedCustomerOrganizerForView(undefined);
      setCreatedPurchaseObjectForView(undefined);
      setCreatedLegalEntityParticipateAsForView(undefined);
      setCreatedRestrictionAdvantageRequirementForView(undefined);
      setCreatedRegionForView(undefined);
      setCreatedLegalEntityWinnerForView(undefined);
      setCreatedCompetitorForView(undefined);
      setCreatedCompetitorParticipantsForView(undefined);
    }
  }, [id]);

  useEffect(() => {
    // Уведомления о создании карточки согласования
    if (createAgreementMutation.isSuccess) {
      add({
        theme: 'success',
        name: 'create-agreement-success',
        title: 'Согласование создано',
      });
    }
    if (createAgreementMutation.isError) {
      add({
        theme: 'danger',
        name: 'create-agreement-error',
        title: 'Не удалось создать Согласование',
      });
    }
  }, [createAgreementMutation.isPending]);

  useEffect(() => {
    // Уведомления об обновлении карточки КП
    if (updateTenderMutation.isSuccess) {
      add({
        theme: 'success',
        name: 'update-tender-success',
        title: 'Карточка Торгов обновлена успешно',
      });
    }
    if (updateTenderMutation.isError) {
      add({
        theme: 'danger',
        name: 'update-tender-error',
        title: 'Ошибка при обновлении карточки Торгов',
      });
    }
  }, [updateTenderMutation.isPending]);

  return (
    <TenderLayout
      formProps={{
        response: getTenderQuery.data
          ? {
              ...getTenderQuery.data,
              ...percentAndCurrencyValuesFromBackend({
                ...getTenderQuery.data,
                customer_priorities: getTenderQuery.data.customer_priorities?.[0]?.toString(),
                // TODO: ошибка в схеме, поле должно быть обязательным
                customer_ids: getTenderQuery.data.customer_ids || [],
              }),
              is_centralized: fromBooleanToYesNo(getTenderQuery.data.is_centralized),
              customer_priorities: getTenderQuery.data.customer_priorities?.[0]?.toString(),
            }
          : undefined,
        editTender: true,
        hide: activeTab !== 'project',
        disabled:
          getTenderQuery.data?.['is_under_approval'] ||
          !getTenderQuery.data?.is_editable ||
          getTenderQuery.data?.['purchase_status'] === 'Отменена' ||
          getTenderQuery.data?.['purchase_status'] === 'Состоялась' ||
          getTenderQuery.data?.['purchase_status'] === 'Не состоялась',
        onClickCancelButton: () => {
          setIsOpen(false);
        },
        initialEditable: false,
        allowedStatuses: values => {
          if (!getTenderQuery.data?.is_blocked_work_params || !getTenderQuery.data)
            return ['Подача'];

          if (getTenderQuery.data.status === 'Подача') {
            return ['Подача', 'Ожидание'];
          }

          if (getIsSubmissionDeadlineExpired(values['submission_deadline'])) {
            return ['Ожидание', 'Выигран', 'Проигран', 'Отклонение', 'Не подано'];
          }

          return ['Ожидание', 'Выигран', 'Проигран', 'Отклонение', 'Отказ'];
        },
        allowedPurchaseStatuses: values => {
          switch (values['status']) {
            case 'Подача': {
              return ['В процессе', 'Отменена', 'Неизвестно'];
            }
            case 'Ожидание': {
              return ['В процессе', 'Отменена', 'Неизвестно'];
            }
            case 'Не подано': {
              return ['В процессе', 'Состоялась', 'Не состоялась', 'Отменена', 'Неизвестно'];
            }
            case 'Отказ': {
              return ['В процессе', 'Состоялась', 'Не состоялась', 'Отменена', 'Неизвестно'];
            }
            case 'Выигран': {
              return ['Состоялась'];
            }
            case 'Отклонение': {
              return ['Состоялась', 'Не состоялась', 'Отменена', 'Неизвестно'];
            }
            case 'Проигран': {
              return ['Состоялась'];
            }
          }
        },
        onValid: async values => {
          if (!id) return;
          // Закрыть для редактирования при следующих статусах Торгов
          const isEditable = !(
            values['status'] === 'Выигран' ||
            values['status'] === 'Проигран' ||
            values['status'] === 'Не подано' ||
            values['status'] === 'Отказ' ||
            values['purchase_status'] === 'Отменена' ||
            values['purchase_status'] === 'Состоялась' ||
            values['purchase_status'] === 'Не состоялась'
          );

          const prevIsBlockedWorkParams = !!getTenderQuery.data?.is_blocked_work_params;

          const valuesWithoutWinnerWithType: typeof values = {
            ...values,
            winner_with_type: undefined,
          };
          await updateTenderMutation
            .mutateAsync({
              body: {
                ...percentAndCurrencyValuesToBackend(valuesWithoutWinnerWithType),
                is_editable: isEditable,
                is_blocked_work_params:
                  prevIsBlockedWorkParams ||
                  getAgreementSubmitTender(values, prevIsBlockedWorkParams),
                is_centralized: fromCustomBooleanSchemaToBoolean(values.is_centralized),
                customer_priorities: values.customer_priorities
                  ? [+values.customer_priorities]
                  : undefined,
              },
              params: {
                path: {
                  id,
                },
              },
            })
            .then(async () => {
              queryClient.invalidateQueries({ queryKey: ['/tender/all'] });
              if (values['status'] === 'Отказ') {
                await createAgreementMutation.mutateAsync({
                  body: {
                    assigned_to_id: getTenderQuery.data?.responsible_supervisor_id,
                    coordination_type: 'REJECTION',
                    entity_id: id,
                    entity_type: 'TENDER',
                  },
                });
                onClose();
                return;
              }
              if (
                !prevIsBlockedWorkParams &&
                getAgreementSubmitTender(values, prevIsBlockedWorkParams)
              ) {
                await createAgreementMutation.mutateAsync({
                  body: {
                    assigned_to_id: getTenderQuery.data?.responsible_supervisor_id,
                    coordination_type: 'MIN_PRICE',
                    entity_id: id,
                    entity_type: 'TENDER',
                  },
                });
              }
              // Переход в Контракты
              // TODO: zero-value, т.к. создание без них невозможно
              if (
                values['purchase_status'] === 'Состоялась' &&
                values['status'] === 'Выигран' &&
                values.final_cost_price
              ) {
                await Promise.all(
                  values['customer_ids'].map(
                    async (customerId, index) =>
                      await createContractQuery
                        .mutateAsync({
                          // TODO:
                          body: {
                            responsible_id: values['responsible_id'],
                            customer_id: customerId,
                            region_id: values.delivery_region_id,
                            object_ids: values.object_ids,
                            gozfz: values.goz,
                            supplier_id: values.participate_as_id,
                            trading_platform_id: values.trading_platform_id,
                            contract_sum: roundPercentAndCurrencyToBackend(values.final_cost_price),
                            customer_priority: values.customer_priorities
                              ? +values.customer_priorities
                              : undefined,
                            basis: 'Тендер',
                            name: 'Без названия',
                          },
                        })
                        // Уведомления о создании карточки контрактов
                        .then(() => {
                          queryClient.invalidateQueries({ queryKey: ['/contract/all'] });
                          add({
                            theme: 'success',
                            name: `create-contract-success-${index}`,
                            content: 'Контракт создан для заказчика №' + (index + 1),
                          });
                        })
                        .catch(() => {
                          add({
                            theme: 'danger',
                            name: `create-contract-error-${index}`,
                            content: 'Не удалось создать Контракт для заказчика №' + (index + 1),
                          });
                        })
                  )
                );
              }
              onClose();
            });
        },
        onInvalid: errors => console.log('@errors', errors),
      }}
      renderForm={formComponent => (
        <RightSidebar
          id="sidebar-commercial-offer"
          onClose={() => {
            setIsOpen(false);
            onClose();
          }}
          open={isOpen}
        >
          <RightSidebar.Header
            title={getTenderQuery.data?.title || 'Редактирование торгов'}
            onClose={() => {
              setIsOpen(false);
              onClose();
            }}
            dropdownMenuItems={
              user?.is_admin && !!id
                ? [
                    {
                      theme: 'danger',
                      text: 'Удалить',
                      action: () => {
                        deleteTenderMutation
                          .mutateAsync({ params: { path: { id: id } } })
                          .then(() => {
                            add({
                              theme: 'success',
                              name: 'delete-tender-success',
                              title: 'Карточка удалена успешно',
                            });
                            queryClient.invalidateQueries({ queryKey: ['/tender/all'] });
                            onClose();
                          })
                          .catch(() => {
                            add({
                              theme: 'danger',
                              name: 'delete-tender-error',
                              title: 'Ошибка при удалении карточки',
                            });
                          });
                      },
                    },
                  ]
                : undefined
            }
          />
          <div className="px-8 pb-8 flex justify-between">
            <RadioButton
              size="l"
              onUpdate={setActiveTab}
              options={[
                { value: 'project', content: 'О проекте' },
                {
                  value: 'relations',
                  content: (
                    <span>
                      Связи
                      <Text
                        variant="body-1"
                        color="hint"
                        className="ml-1"
                      >
                        {countRelations(getTenderRelatedQuery.data)}
                      </Text>
                    </span>
                  ),
                },
              ]}
            />
            <Tooltip
              content="Перейти в чат"
              openDelay={200}
            >
              <Button
                size="l"
                onClick={() =>
                  navigateTo(URLS.messenger + '/' + getTenderQuery.data?.chat_id || '')
                }
              >
                <Icon data={Comments} />
                Чат
              </Button>
            </Tooltip>
          </div>
          {formComponent}
          {activeTab === 'relations' && (
            <>
              {(getTenderRelatedQuery.isLoading || getTenderQuery.isLoading) && (
                <Spin
                  size="l"
                  className="mx-8"
                />
              )}
              {(getTenderRelatedQuery.isError || getTenderQuery.isError) && (
                <Text
                  className="mx-8"
                  color="danger-heavy"
                  variant="body-2"
                >
                  Ошибка загрузки связей
                </Text>
              )}
              {getTenderRelatedQuery.data &&
              checkIsNotBlankRelations(getTenderRelatedQuery.data) &&
              getTenderQuery.data ? (
                <TenderRelations
                  relations={getTenderRelatedQuery.data}
                  tender={getTenderQuery.data}
                />
              ) : (
                countRelations(getTenderRelatedQuery.data) === 0 && (
                  <Text
                    className="mx-8 font-semibold"
                    variant="body-2"
                  >
                    Связей не обнаружено
                  </Text>
                )
              )}
            </>
          )}
        </RightSidebar>
      )}
    />
  );
}
