import { useToaster } from '@gravity-ui/uikit';
import { useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';

import { $supplierContractsHooks, userAtom } from '@entities';
import { URLS } from '@shared/consts';
import {
  roundPercentAndCurrencyFromBackend,
  roundPercentAndCurrencyToBackend,
  useNavigateTo,
} from '@shared/lib';
import { RightSidebar } from '@shared/ui';

import { SupplierContractsLayout } from './SupplierContractsLayout';

interface EditSupplierContractsProps {
  id: string | undefined;
  onClose: () => void;
}

export function EditSupplierContracts({ id, onClose }: EditSupplierContractsProps) {
  const user = useAtomValue(userAtom);
  const [isOpen, setIsOpen] = useState(false);

  const queryClient = useQueryClient();

  const { navigateTo } = useNavigateTo();
  const { add } = useToaster();

  // Queries

  const getSupplierContractQuery = $supplierContractsHooks.useGetById(id || '', !!id);
  const updateSupplierContractsMutation = $supplierContractsHooks.updateByIdMutation();
  const deleteSupplierContractsMutation = $supplierContractsHooks.deleteByIdMutation();
  //

  useEffect(() => {
    if (getSupplierContractQuery.error) {
      navigateTo(URLS.handbook.custom);
      add({
        theme: 'danger',
        name: 'get-supplierContract-error',
        title: 'Произошла ошибка при получении карточки Договора с поставщиками',
      });
    }
  }, [getSupplierContractQuery.isFetching]);

  useEffect(() => {
    if (id) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [id]);

  useEffect(() => {
    // Уведомления об обновлении карточки
    if (updateSupplierContractsMutation.isSuccess) {
      add({
        theme: 'success',
        name: 'update-supplierContracts-success',
        title: 'Карточка Договора с поставщиками обновлена успешно',
      });
    }
    if (updateSupplierContractsMutation.isError) {
      add({
        theme: 'danger',
        name: 'update-supplierContracts-error',
        title: 'Ошибка при обновлении карточки Договора с поставщиками',
      });
    }
  }, [updateSupplierContractsMutation.isPending]);

  return (
    <SupplierContractsLayout
      formProps={{
        response: getSupplierContractQuery.data
          ? {
              ...getSupplierContractQuery.data,
              price: roundPercentAndCurrencyFromBackend(getSupplierContractQuery.data.price),
            }
          : undefined,
        onClickCancelButton: () => {
          onClose();
        },
        initialEditable: false,
        onValid: async values => {
          if (!id) return;

          await updateSupplierContractsMutation
            .mutateAsync({
              body: { ...values, price: roundPercentAndCurrencyToBackend(values.price) },
              params: {
                path: {
                  id,
                },
              },
            })
            .then(async () => {
              queryClient.invalidateQueries({ queryKey: ['/supplier-contracts/all'] });

              onClose();
            });
        },
        onInvalid: errors => console.log('@errors', errors),
      }}
      renderForm={formComponent => (
        <RightSidebar
          id="sidebar-supplierContract"
          onClose={() => {
            setIsOpen(false);
            onClose();
          }}
          open={isOpen}
        >
          <RightSidebar.Header
            title={'Редактирование карточки Договора с поставщиками'}
            onClose={() => {
              setIsOpen(false);
              onClose();
            }}
            dropdownMenuItems={
              user?.is_admin && !!id
                ? [
                    {
                      theme: 'danger',
                      text: 'Удалить',
                      action: () => {
                        deleteSupplierContractsMutation
                          .mutateAsync({ params: { path: { id: id } } })
                          .then(() => {
                            add({
                              theme: 'success',
                              name: 'delete-supplier-contracts-success',
                              title: 'Карточка удалена успешно',
                            });
                            queryClient.invalidateQueries({
                              queryKey: ['/handbook/supplier-contracts'],
                            });
                            onClose();
                          })
                          .catch(() => {
                            add({
                              theme: 'danger',
                              name: 'delete-supplier-contracts-error',
                              title: 'Ошибка при удалении карточки',
                            });
                          });
                      },
                    },
                  ]
                : undefined
            }
          />
          {formComponent}
        </RightSidebar>
      )}
    />
  );
}
