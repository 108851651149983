import { Funnel } from '@gravity-ui/icons';
import { Button, ButtonProps, Icon } from '@gravity-ui/uikit';
import { useAtomValue } from 'jotai';

import { cn } from '@shared/lib';

import { isChatFiltersDirtyAtom } from '../../model';

import './index.sass';

type FilterChatButtonProps = Omit<ButtonProps, 'size' | 'view' | 'children'>;

export function FilterChatButton({ className, ...props }: FilterChatButtonProps) {
  const isChatFiltersDirty = useAtomValue(isChatFiltersDirtyAtom);

  return (
    <Button
      {...props}
      size="l"
      view="flat"
      className={cn(className, {
        'filter-chat-button': isChatFiltersDirty,
      })}
    >
      <Icon data={Funnel} />
    </Button>
  );
}
