import { KnowledgeBaseRelatedProcess, KnowledgeBaseStatus, KnowledgeBaseType } from '@entities';
import { HandbookOption } from '@shared/model';

interface KnowledgeBaseOptions {
  type: HandbookOption<KnowledgeBaseType>[];
  status: HandbookOption<KnowledgeBaseStatus>[];
  related_process: HandbookOption<KnowledgeBaseRelatedProcess>[];
}

export const KNOWLEDGE_BASE_OPTIONS: KnowledgeBaseOptions = {
  type: [
    { content: 'НПА', value: 'НПА' },
    { content: 'Разъяснение ФОИВ', value: 'Разъяснение ФОИВ' },
    { content: 'Решение суда', value: 'Решение суда' },
    { content: 'Решение ФАС', value: 'Решение ФАС' },
    { content: 'Разъяснение заказчиков', value: 'Разъяснение заказчиков' },
    { content: 'Шаблоны', value: 'Шаблоны' },
    { content: 'База знаний', value: 'База знаний' },
  ],
  status: [
    { content: 'Действующий', value: 'Действующий' },
    { content: 'Недействующий', value: 'Недействующий' },
  ],
  related_process: [
    { content: 'Контракт', value: 'Контракт' },
    { content: 'Торги', value: 'Торги' },
  ],
};
