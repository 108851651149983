import { Button, SelectRenderPopup, Text } from '@gravity-ui/uikit';
import { ComponentProps } from 'react';

interface CustomSelectPopupProps {
  renderFilter: ComponentProps<SelectRenderPopup>['renderFilter'];
  renderList: ComponentProps<SelectRenderPopup>['renderList'];
  onSecondaryButtonClick?: () => void;
  secondaryButtonText?: string;
  onPrimaryButtonClick: () => void;
  primaryButtonText: string;
}

export const CustomSelectPopup = ({
  renderFilter,
  renderList,
  onSecondaryButtonClick,
  secondaryButtonText,
  onPrimaryButtonClick,
  primaryButtonText,
}: CustomSelectPopupProps) => {
  return (
    <div className="max-h-[384px]">
      {renderFilter()}
      <div className="max-h-[268px] overflow-y-scroll">{renderList()}</div>
      <div className="flex gap-2 p-2">
        {secondaryButtonText && (
          <Button
            size="l"
            width="max"
            onClick={onSecondaryButtonClick}
          >
            <Text variant="body-1">{secondaryButtonText}</Text>
          </Button>
        )}
        <Button
          size="l"
          width="max"
          onClick={onPrimaryButtonClick}
          view="action"
        >
          <Text variant="body-1">{primaryButtonText}</Text>
        </Button>
      </div>
    </div>
  );
};
