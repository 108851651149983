import { useInfiniteQuery } from '@tanstack/react-query';

import { fetchClient, ParametersQueryType } from '@shared/api';

export function useGetHandbookWinnersInfiniteQuery(
  query: ParametersQueryType<'get', '/handbook/winners'>,
  enabled: boolean
) {
  return useInfiniteQuery({
    queryKey: ['/handbook/winners', query],
    queryFn: async ({ pageParam }) => {
      const { data } = await fetchClient.GET('/handbook/winners', {
        // const { data } = await mockClient.GET('/handbook/winners', {
        params: { query: { ...query, offset: pageParam } },
      });

      return data?.list ?? [];
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage?.length === 0) {
        return undefined;
      }
      return lastPageParam + query.limit;
    },
    enabled,
  });
}
