/* eslint-disable @typescript-eslint/no-unused-vars */
import { useInfiniteQuery } from '@tanstack/react-query';

import {
  $api,
  $mock,
  bodyRequestType,
  fetchClient,
  mockClient,
  ParametersPathType,
  ParametersQueryType,
} from '@shared/api';

function useGetHandbookPurchaseObjectsInfiniteQuery(
  query: ParametersQueryType<'get', '/handbook/purchase-objects'>,
  enabled: boolean
) {
  return useInfiniteQuery({
    queryKey: ['/handbook/purchase-objects', query],
    queryFn: async ({ pageParam }) => {
      const { data } = await fetchClient.GET('/handbook/purchase-objects', {
        // const { data } = await mockClient.GET('/handbook/purchase-objects', {
        params: { query: { ...query, offset: pageParam } },
      });

      return data?.list || [];
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage?.length === 0) {
        return undefined;
      }
      return lastPageParam + query.limit;
    },
    enabled,
  });
}

const createHandbookPurchaseObjectsMutation = () =>
  $api.useMutation('post', '/handbook/purchase-objects');
// const createHandbookPurchaseObjects = (
//   body: bodyRequestType<'post', '/handbook/purchase-objects'>
// ) => createHandbookPurchaseObjectsMutation.mutateAsync({ body: body });

const useGetByIdHandbookPurchaseObjects = (
  id: ParametersPathType<'get', '/handbook/purchase-objects/{id}'>['id'],
  enabled: boolean
) =>
  $api.useQuery(
    'get',
    '/handbook/purchase-objects/{id}',
    { params: { path: { id: id } } },
    { enabled }
  );

const updateHandbookPurchaseObjectsMutation = () =>
  $api.useMutation('put', '/handbook/purchase-objects/{id}');
const deleteHandbookPurchaseObjectsMutation = () =>
  $api.useMutation('delete', '/handbook/purchase-objects/{id}');

export const $purchaseObjectsHooks = {
  useGetAll: useGetHandbookPurchaseObjectsInfiniteQuery,
  useGetById: useGetByIdHandbookPurchaseObjects,
  createMutation: createHandbookPurchaseObjectsMutation,
  deleteByIdMutation: deleteHandbookPurchaseObjectsMutation,
  updateByIdMutation: updateHandbookPurchaseObjectsMutation,
};
