import { TabsItemProps } from '@gravity-ui/uikit';
import { atomWithStorage } from 'jotai/utils';

import { LOCAL_STORAGE } from '@shared/consts';
import { customStorage } from '@shared/lib';

export const tableTabsAtom = atomWithStorage<TabsItemProps[]>(
  LOCAL_STORAGE.tendersTabs,
  customStorage<TabsItemProps[]>().getItem(LOCAL_STORAGE.tendersTabs, [
    { id: 'Все', title: 'Все' },
  ]),
  customStorage()
);

export const selectedTabAtom = atomWithStorage<string | null>(
  LOCAL_STORAGE.tendersSelectedTab,
  customStorage<string | null>().getItem(LOCAL_STORAGE.tendersSelectedTab, null),
  customStorage()
);

export const frontKeyBackendValueColumns: {
  'Дата, номер': { Дата: 'created_at'; Номер: 'id' };
  Наименование: 'title';
  Ответственный: 'responsible_value';
  'Статус заявки': 'status';
  'Причина отказа': 'reject_reason';
  'Статус закупки': 'purchase_status';
  'Номер извещения': 'notice_number';
  'Ссылка на процедуру': 'procedure_link';
  'Объект торгов': 'object_values';
  'Участвуем от': 'participate_as_value';
  Комментарий: 'comment';
  'Ограничения, преимущества и доп. требования': 'additional_information';
  'Срок поставки': 'delivery_at';
  Себестоимость: 'base_cost_price';
  'Маржа начальная': 'margin';
  'Файл расчета процедуры': 'calculation_files';
  'Согласовать минимальную цену с руководителем': 'is_under_approval';
  'Файл заявки': 'request_file';
  'Предлагаемая специалистом минимальная цена': 'specialist_min_cost_price';
  'Обоснование предложения минимальной цены': 'min_cost_price_reason';
  'Комментарий согласования': 'approve_comment';
  'Минимальная цена': 'min_cost_price';
  'Маржа минимальная': 'min_margin';
  'Итоговая цена': 'final_cost_price';
  'Процент снижения': 'decrease_percent';
  'Маржа итоговая': 'final_margin';
  Победитель: 'winner_value';
  Участники: 'participant_values';
  'Электронная торговая площадка': 'trading_platform_value';
  ФЗ: 'fz';
  'Закупка централизованная или совместная?': 'is_centralized';
  Заказчики: 'customer_values';
  'Приоритет заказчика': 'customer_priorities';
  'Начальная цена': 'cost_price';
  'Способ проведения': 'implementation_method';
  'Обеспечение заявки': 'tender_support';
  'Окончание подачи': 'submission_deadline';
  'Проведение процедуры': 'procedure_at';
  'Подведение итогов': 'results_at';
  Документация: 'documentation_files';
  Разъяснения: 'comments_files';
  Протоколы: 'protocol_files';
  'Регион поставки': 'delivery_region_value';
  'Адрес поставки': 'delivery_address';
  'Пояснение к причине отказа': 'reject_description';
  Отклонение: 'decline';
  'Основание и причина отклонения': 'decline_reason';
  'Причина почему не состоялась?': 'no_take_place_reason';
  'В рамках ГОЗ по 275-ФЗ': 'goz';
  Организатор: 'organizer_value';
  'Руководитель ответственного': 'responsible_supervisor_value';
} = {
  'Дата, номер': { Дата: 'created_at', Номер: 'id' },
  Наименование: 'title',
  Ответственный: 'responsible_value',
  'Статус заявки': 'status',
  'Причина отказа': 'reject_reason',
  'Статус закупки': 'purchase_status',
  'Номер извещения': 'notice_number',
  'Ссылка на процедуру': 'procedure_link',
  'Объект торгов': 'object_values',
  'Участвуем от': 'participate_as_value',
  Комментарий: 'comment',
  'Ограничения, преимущества и доп. требования': 'additional_information',
  'Срок поставки': 'delivery_at',
  Себестоимость: 'base_cost_price',
  'Маржа начальная': 'margin',
  'Файл расчета процедуры': 'calculation_files',
  'Согласовать минимальную цену с руководителем': 'is_under_approval',
  'Файл заявки': 'request_file',
  'Предлагаемая специалистом минимальная цена': 'specialist_min_cost_price',
  'Обоснование предложения минимальной цены': 'min_cost_price_reason',
  'Комментарий согласования': 'approve_comment',
  'Минимальная цена': 'min_cost_price',
  'Маржа минимальная': 'min_margin',
  'Итоговая цена': 'final_cost_price',
  'Процент снижения': 'decrease_percent',
  'Маржа итоговая': 'final_margin',
  Победитель: 'winner_value',
  Участники: 'participant_values',
  'Электронная торговая площадка': 'trading_platform_value',
  ФЗ: 'fz',
  'Закупка централизованная или совместная?': 'is_centralized',
  Заказчики: 'customer_values',
  'Приоритет заказчика': 'customer_priorities', // TODO: поле во множественном числе, number[]
  'Начальная цена': 'cost_price',
  'Способ проведения': 'implementation_method',
  'Обеспечение заявки': 'tender_support',
  'Окончание подачи': 'submission_deadline',
  'Проведение процедуры': 'procedure_at',
  'Подведение итогов': 'results_at',
  Документация: 'documentation_files',
  Разъяснения: 'comments_files',
  Протоколы: 'protocol_files',
  'Регион поставки': 'delivery_region_value',
  'Адрес поставки': 'delivery_address',
  'Пояснение к причине отказа': 'reject_description',
  Отклонение: 'decline',
  'Основание и причина отклонения': 'decline_reason',
  'Причина почему не состоялась?': 'no_take_place_reason',
  'В рамках ГОЗ по 275-ФЗ': 'goz',
  Организатор: 'organizer_value',
  'Руководитель ответственного': 'responsible_supervisor_value',
};

export const frontKeyBackendValueSorting: {
  'Дата, номер': 'created_at';
  Наименование: 'title';
  Ответственный: 'responsible_value';
  'Статус заявки': 'status';
  'Причина отказа': 'reject_reason';
  'Статус закупки': 'purchase_status';
  'Номер извещения': 'notice_number';
  'Ссылка на процедуру': 'procedure_link';
  'Объект торгов': 'object_values';
  'Участвуем от': 'participate_as_value';
  Комментарий: 'comment';
  'Ограничения, преимущества и доп. требования': 'additional_information';
  'Срок поставки': 'delivery_at';
  Себестоимость: 'base_cost_price';
  'Маржа начальная': 'margin';
  'Файл расчета процедуры': 'calculation_files';
  'Согласовать минимальную цену с руководителем': 'is_under_approval';
  'Файл заявки': 'request_file';
  'Предлагаемая специалистом минимальная цена': 'specialist_min_cost_price';
  'Обоснование предложения минимальной цены': 'min_cost_price_reason';
  'Комментарий согласования': 'approve_comment';
  'Минимальная цена': 'min_cost_price';
  'Маржа минимальная': 'min_margin';
  'Итоговая цена': 'final_cost_price';
  'Процент снижения': 'decrease_percent';
  'Маржа итоговая': 'final_margin';
  Победитель: 'winner_value';
  Участники: 'participant_values';
  'Электронная торговая площадка': 'trading_platform_value';
  ФЗ: 'fz';
  'Закупка централизованная или совместная?': 'is_centralized';
  Заказчики: 'customer_values';
  'Приоритет заказчика': 'customer_priorities';
  'Начальная цена': 'cost_price';
  'Способ проведения': 'implementation_method';
  'Обеспечение заявки': 'tender_support';
  'Окончание подачи': 'submission_deadline';
  'Проведение процедуры': 'procedure_at';
  'Подведение итогов': 'results_at';
  Документация: 'documentation_files';
  Разъяснения: 'comments_files';
  Протоколы: 'protocol_files';
  'Регион поставки': 'delivery_region_value';
  'Адрес поставки': 'delivery_address';
  'Пояснение к причине отказа': 'reject_description';
  Отклонение: 'decline';
  'Основание и причина отклонения': 'decline_reason';
  'Причина почему не состоялась?': 'no_take_place_reason';
  'В рамках ГОЗ по 275-ФЗ': 'goz';
  Организатор: 'organizer_value';
  'Руководитель ответственного': 'responsible_supervisor_value';
} = { ...frontKeyBackendValueColumns, 'Дата, номер': 'created_at' };

// 'Сумма контракта в рублях': { from: 'contract_sum_min'; to: 'contract_sum_max' };
// 'Номер контракта, текстовое поле': 'contract_number';
// 'Дата подписания контракта': { from: 'signing_date_start'; to: 'signing_date_end' };
export const frontKeyBackendValueFilters: {
  'Дата, номер': { from: 'min_created_at'; to: 'max_created_at' };
  Наименование: 'title';
  Ответственный: 'responsible_ids';
  'Статус заявки': 'statuses';
  Отклонение: 'decline'; // TODO: Нет на бэке
  'Основание и причина отклонения': 'decline_reason'; // TODO: Нет на бэке
  'Причина отказа': 'reject_reasons';
  'Пояснение к причине отказа': 'reject_description'; // TODO: Нет на бэке
  'Статус закупки': 'purchase_statuses';
  'Причина почему не состоялась?': 'no_take_place_reason'; // TODO: Нет на бэке
  'Номер извещения': 'notice_number';
  'Ссылка на процедуру': 'procedure_link';
  'Электронная торговая площадка': 'trading_platform_ids';
  'В рамках ГОЗ по 275-ФЗ': 'goz'; // TODO: Нет на бэке
  ФЗ: 'fzs';
  'Закупка централизованная или совместная?': 'is_centralized';
  Заказчики: 'customer_ids';
  'Приоритет заказчика': { from: 'customer_priority_min'; to: 'customer_priority_max' };
  Организатор: 'organizer_id';
  'Объект торгов': 'object_ids';
  'Начальная цена': { from: 'cost_price_min'; to: 'cost_price_max' };
  'Участвуем от': 'participate_as_ids';
  Комментарий: 'comment';
  'Способ проведения': 'implementation_methods';
  'Ограничения, преимущества и доп. требования': 'additional_information';
  'Обеспечение заявки': { from: 'tender_support_min'; to: 'tender_support_max' };
  'Окончание подачи': { from: 'min_submission_deadline'; to: 'max_submission_deadline' };
  'Проведение процедуры': { from: 'min_procedure_at'; to: 'max_procedure_at' };
  'Подведение итогов': { from: 'min_results_at'; to: 'max_results_at' };
  Документация: 'documentation_files';
  Разъяснения: 'comments_file';
  Протоколы: 'protocols_file';
  'Регион поставки': 'delivery_region_ids';
  'Адрес поставки': 'delivery_address';
  'Срок поставки': { from: 'min_delivery_at'; to: 'max_delivery_at' };
  Себестоимость: { from: 'base_cost_price_min'; to: 'base_cost_price_max' };
  'Маржа начальная': { from: 'margin_min'; to: 'margin_max' };
  'Файл расчета процедуры': 'calculation_files';
  'Файл заявки': 'request_file';
  'Согласовать минимальную цену с руководителем': 'is_under_approval';
  'Предлагаемая специалистом минимальная цена': {
    from: 'specialist_min_cost_price_min';
    to: 'specialist_min_cost_price_max';
  };
  'Обоснование предложения минимальной цены': 'min_cost_price_reason';
  'Комментарий согласования': 'approve_comment';
  'Минимальная цена': { from: 'min_cost_price_min'; to: 'min_cost_price_max' };
  'Маржа минимальная': { from: 'min_margin_min'; to: 'min_margin_max' };
  'Итоговая цена': { from: 'final_cost_price_min'; to: 'final_cost_price_max' };
  'Процент снижения': { from: 'decrease_percent_min'; to: 'decrease_percent_max' };
  'Маржа итоговая': { from: 'final_margin_min'; to: 'final_margin_max' };
  Победитель: 'winners';
  Участники: 'participant_ids';
  'Руководитель ответственного': 'responsible_supervisor_ids';
} = {
  'Дата, номер': { from: 'min_created_at', to: 'max_created_at' },
  Наименование: 'title',
  Ответственный: 'responsible_ids',
  'Статус заявки': 'statuses',
  Отклонение: 'decline', // TODO: Нет на бэке
  'Основание и причина отклонения': 'decline_reason', // TODO: Нет на бэке
  'Причина отказа': 'reject_reasons',
  'Пояснение к причине отказа': 'reject_description', // TODO: Нет на бэке
  'Статус закупки': 'purchase_statuses',
  'Причина почему не состоялась?': 'no_take_place_reason', // TODO: Нет на бэке
  'Номер извещения': 'notice_number',
  'Ссылка на процедуру': 'procedure_link',
  'Электронная торговая площадка': 'trading_platform_ids',
  'В рамках ГОЗ по 275-ФЗ': 'goz', // TODO: Нет на бэке
  ФЗ: 'fzs',
  'Закупка централизованная или совместная?': 'is_centralized',
  Заказчики: 'customer_ids',
  'Приоритет заказчика': { from: 'customer_priority_min', to: 'customer_priority_max' },
  Организатор: 'organizer_id',
  'Объект торгов': 'object_ids',
  'Начальная цена': { from: 'cost_price_min', to: 'cost_price_max' },
  'Участвуем от': 'participate_as_ids',
  Комментарий: 'comment',
  'Способ проведения': 'implementation_methods',
  'Ограничения, преимущества и доп. требования': 'additional_information',
  'Обеспечение заявки': { from: 'tender_support_min', to: 'tender_support_max' },
  'Окончание подачи': { from: 'min_submission_deadline', to: 'max_submission_deadline' },
  'Проведение процедуры': { from: 'min_procedure_at', to: 'max_procedure_at' },
  'Подведение итогов': { from: 'min_results_at', to: 'max_results_at' },
  Документация: 'documentation_files',
  Разъяснения: 'comments_file',
  Протоколы: 'protocols_file',
  'Регион поставки': 'delivery_region_ids',
  'Адрес поставки': 'delivery_address',
  'Срок поставки': { from: 'min_delivery_at', to: 'max_delivery_at' },
  Себестоимость: { from: 'base_cost_price_min', to: 'base_cost_price_max' },
  'Маржа начальная': { from: 'margin_min', to: 'margin_max' },
  'Файл расчета процедуры': 'calculation_files',
  'Файл заявки': 'request_file',
  'Согласовать минимальную цену с руководителем': 'is_under_approval',
  'Предлагаемая специалистом минимальная цена': {
    from: 'specialist_min_cost_price_min',
    to: 'specialist_min_cost_price_max',
  },
  'Обоснование предложения минимальной цены': 'min_cost_price_reason',
  'Комментарий согласования': 'approve_comment',
  'Минимальная цена': { from: 'min_cost_price_min', to: 'min_cost_price_max' },
  'Маржа минимальная': { from: 'min_margin_min', to: 'min_margin_max' },
  'Итоговая цена': { from: 'final_cost_price_min', to: 'final_cost_price_max' },
  'Процент снижения': { from: 'decrease_percent_min', to: 'decrease_percent_max' },
  'Маржа итоговая': { from: 'final_margin_min', to: 'final_margin_max' },
  Победитель: 'winners',
  Участники: 'participant_ids',
  'Руководитель ответственного': 'responsible_supervisor_ids',
};
