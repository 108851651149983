export const OPTIONS_MOCK = {
  'Статус заявки': [
    { value: 'Подача', content: 'Подача', preview: 'normal' } as const,
    { value: 'Ожидание', content: 'Ожидание', preview: 'normal' } as const,
    { value: 'Выигран', content: 'Выигран', preview: 'success' } as const,
    { value: 'Проигран', content: 'Проигран', preview: 'danger' } as const,
    { value: 'Отклонение', content: 'Отклонение', preview: 'danger' } as const,
    { value: 'Не подано', content: 'Не подано', preview: 'clear' } as const,
    { value: 'Отказ', content: 'Отказ', preview: 'danger' } as const,
  ],
  Отклонение: [
    { value: 'По объекту закупки', content: 'По объекту закупки' },
    { value: 'По информации и документам', content: 'По информации и документам' },
  ],
  'Причина отказа': [
    { value: 'Не успели', content: 'Не успели' },
    { value: 'Не рентабельно', content: 'Не рентабельно' },
    { value: 'Иная причина', content: 'Иная причина' },
  ],
  'Статус закупки': [
    { value: 'В процессе', content: 'В процессе', preview: 'normal' } as const,
    { value: 'Состоялась', content: 'Состоялась', preview: 'success' } as const,
    { value: 'Не состоялась', content: 'Не состоялась', preview: 'danger' } as const,
    { value: 'Отменена', content: 'Отменена', preview: 'danger' } as const,
    { value: 'Неизвестно', content: 'Неизвестно', preview: 'clear' } as const,
  ],
  'Причина почему не состоялась?': [
    { value: 'Не подано заявок', content: 'Не подано заявок' },
    { value: 'Всех отклонили', content: 'Всех отклонили' },
  ],
  'В рамках ГОЗ по 275-ФЗ': [
    { value: 'НЕГОЗ', content: 'НЕГОЗ' },
    { value: 'ГОЗ', content: 'ГОЗ' },
  ],
  ФЗ: [
    { value: '44-ФЗ', content: '44-ФЗ' },
    { value: '223-ФЗ', content: '223-ФЗ' },
    { value: 'КОМ.', content: 'КОМ.' },
  ],
  'Закупка централизованная или совместная?': [
    { value: 'Да', content: 'Да' },
    { value: 'Нет', content: 'Нет' },
  ],
  'Приоритет заказчика': [
    { value: '1', content: '1' },
    { value: '2', content: '2' },
    { value: '3', content: '3' },
    { value: '4', content: '4' },
    { value: '5', content: '5' },
  ],
  'Способ проведения': [
    { value: 'Электронный аукцион', content: 'Электронный аукцион' },
    { value: 'Запрос котировок', content: 'Запрос котировок' },
    { value: 'Запрос предложений', content: 'Запрос предложений' },
    { value: 'Закупка ЕП', content: 'Закупка ЕП' },
    { value: 'Конкурс', content: 'Конкурс' },
    {
      value: 'Запрос котировок в электронной форме',
      content: 'Запрос котировок в электронной форме',
    },
  ],
};
