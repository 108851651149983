import { useToaster } from '@gravity-ui/uikit';
import { useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';

import { $okpd2sHooks, userAtom } from '@entities';
import { URLS } from '@shared/consts';
import { useNavigateTo } from '@shared/lib';
import { RightSidebar } from '@shared/ui';

import { Okpd2sLayout } from './Okpd2sLayout';

interface EditOkpd2sProps {
  id: string | undefined;
  onClose: () => void;
}

export function EditOkpd2s({ id, onClose }: EditOkpd2sProps) {
  const user = useAtomValue(userAtom);
  const [isOpen, setIsOpen] = useState(false);

  const queryClient = useQueryClient();

  const { navigateTo } = useNavigateTo();
  const { add } = useToaster();

  // Queries

  const getOkpd2Query = $okpd2sHooks.useGetById(id || '', !!id);
  const updateOkpd2sMutation = $okpd2sHooks.updateByIdMutation();
  const deleteOkpd2sMutation = $okpd2sHooks.deleteByIdMutation();
  //

  useEffect(() => {
    if (getOkpd2Query.error) {
      navigateTo(URLS.handbook.custom);
      add({
        theme: 'danger',
        name: 'get-okpd2-error',
        title: 'Произошла ошибка при получении карточки ОКПД-2',
      });
    }
  }, [getOkpd2Query.isFetching]);

  useEffect(() => {
    if (id) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [id]);

  useEffect(() => {
    // Уведомления об обновлении карточки
    if (updateOkpd2sMutation.isSuccess) {
      add({
        theme: 'success',
        name: 'update-okpd2s-success',
        title: 'Карточка ОКПД-2 обновлена успешно',
      });
    }
    if (updateOkpd2sMutation.isError) {
      add({
        theme: 'danger',
        name: 'update-okpd2s-error',
        title: 'Ошибка при обновлении карточки ОКПД-2',
      });
    }
  }, [updateOkpd2sMutation.isPending]);

  return (
    <Okpd2sLayout
      formProps={{
        response: getOkpd2Query.data,
        onClickCancelButton: () => {
          onClose();
        },
        initialEditable: false,
        onValid: async values => {
          if (!id) return;

          await updateOkpd2sMutation
            .mutateAsync({
              body: values,
              params: {
                path: {
                  value: id,
                },
              },
            })
            .then(async () => {
              queryClient.invalidateQueries({ queryKey: ['/okpd2s/all'] });

              onClose();
            });
        },
        onInvalid: errors => console.log('@errors', errors),
      }}
      renderForm={formComponent => (
        <RightSidebar
          id="sidebar-okpd2"
          onClose={() => {
            setIsOpen(false);
            onClose();
          }}
          open={isOpen}
        >
          <RightSidebar.Header
            title={'Редактирование карточки ОКПД-2'}
            onClose={() => {
              setIsOpen(false);
              onClose();
            }}
            dropdownMenuItems={
              user?.is_admin && !!id
                ? [
                    {
                      theme: 'danger',
                      text: 'Удалить',
                      action: () => {
                        deleteOkpd2sMutation
                          .mutateAsync({ params: { path: { value: id } } })
                          .then(() => {
                            add({
                              theme: 'success',
                              name: 'delete-okpd2s-success',
                              title: 'Карточка удалена успешно',
                            });
                            queryClient.invalidateQueries({ queryKey: ['/handbook/okpd2s'] });
                            onClose();
                          })
                          .catch(() => {
                            add({
                              theme: 'danger',
                              name: 'delete-okpd2s-error',
                              title: 'Ошибка при удалении карточки',
                            });
                          });
                      },
                    },
                  ]
                : undefined
            }
          />
          {formComponent}
        </RightSidebar>
      )}
    />
  );
}
