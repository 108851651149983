import { ReactNode } from 'react';

import { Okpd2sForm, Okpd2sFormProps } from '@features';

interface Okpd2sLayoutProps {
  renderForm: (formComponent: ReactNode) => ReactNode;
  formProps: Okpd2sFormProps;
}

export function Okpd2sLayout({ renderForm, formProps }: Okpd2sLayoutProps) {
  return <>{renderForm(<Okpd2sForm {...formProps} />)}</>;
}
