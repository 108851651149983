import { ColumnsFilters } from '@features';

import { ColumnsTendersTable } from '../model';

export const TABLE_COLUMNS_WITH_FILTER: Record<ColumnsTendersTable[number], ColumnsFilters> = {
  'Дата, номер': 'date',
  Ответственный: 'select',
  Наименование: 'search',
  'Статус заявки': 'select',
  Отклонение: 'select',
  'Основание и причина отклонения': 'search',
  'Причина отказа': 'select',
  'Пояснение к причине отказа': 'search',
  'Статус закупки': 'select',
  'Причина почему не состоялась?': 'search',
  'Номер извещения': 'search',
  'Ссылка на процедуру': 'boolean',
  'Электронная торговая площадка': 'select',
  'В рамках ГОЗ по 275-ФЗ': 'select',
  ФЗ: 'select',
  'Закупка централизованная или совместная?': 'select',
  Заказчики: 'select',
  'Приоритет заказчика': 'priority',
  Организатор: 'select',
  'Объект торгов': 'select',
  'Начальная цена': 'currency',
  'Участвуем от': 'select',
  Комментарий: 'search',
  'Способ проведения': 'select',
  'Ограничения, преимущества и доп. требования': 'select',
  'Обеспечение заявки': 'currency',
  'Окончание подачи': 'dateWithTime',
  'Проведение процедуры': 'dateWithTime',
  'Подведение итогов': 'date',
  Документация: 'boolean',
  Разъяснения: 'boolean',
  Протоколы: 'boolean',
  'Регион поставки': 'select',
  'Адрес поставки': 'search',
  'Срок поставки': 'search',
  Себестоимость: 'currency',
  'Маржа начальная': 'number',
  'Файл расчета процедуры': 'boolean',
  'Файл заявки': 'boolean',
  'Согласовать минимальную цену с руководителем': 'boolean',
  'Предлагаемая специалистом минимальная цена': 'currency',
  'Обоснование предложения минимальной цены': 'search',
  'Комментарий согласования': 'search',
  'Минимальная цена': 'currency',
  'Маржа минимальная': 'number',
  'Итоговая цена': 'currency',
  'Процент снижения': 'number',
  'Маржа итоговая': 'number',
  Победитель: 'selectWinners',
  Участники: 'select',
  'Руководитель ответственного': 'select',
};
