import { yupRu } from '@shared/config';
import { schemaFields } from '@shared/lib';

import { CustomerClientStatus, CustomerLevel, CustomerType } from '../types';

export const customersSchema = yupRu.object({
  address: yupRu.string(),
  client_status: yupRu
    .string()
    .oneOf<CustomerClientStatus>(['Ключевой', 'Новый', 'Разовый', 'Стандартный']),
  comment: schemaFields.textArea(),
  contact_ids: yupRu.array(yupRu.string().required()),
  customer_level: yupRu
    .string()
    .oneOf<CustomerLevel>(['Коммерческий', 'Муниципальный', 'Региональный', 'Федеральный']),
  customer_type: yupRu.string().oneOf<CustomerType>(['Клиент', 'Лид']),
  full_name: yupRu.string().min(3).max(255).required(),
  head_responsible_id: yupRu.string(),
  higher_agency_id: yupRu.string(),
  inn: yupRu
    .string()
    .min(10)
    .max(12)
    .test({
      test: value => {
        if (value === undefined) return false;
        try {
          return !!BigInt(value);
        } catch {
          return false;
        }
      },
      message: 'Должен состоять из цифр',
    })
    .test({
      test: value => typeof value === 'string' && (value.length === 10 || value.length === 12),
      message: 'ИНН состоит из 10 или 12 арабских цифр',
    })
    .required(),
  kpp: yupRu
    .string()
    .test({
      test: value => {
        if (value === undefined) return false;
        try {
          return !!BigInt(value);
        } catch {
          return false;
        }
      },
      message: 'Должен состоять из цифр',
    })
    .min(9)
    .max(9)
    .transform(v => v || undefined)
    .required()
    .required(),
  name: yupRu
    .string()
    .required()
    .max(255)
    .test({
      test: value => !value || value.length >= 2,
      message: 'Должен содержать как минимум 2 символа',
    }),
  payment_detail_id: yupRu.string(),
  priority: schemaFields.priorityString(),
  responsible_id: yupRu.string(),
  unique_number: yupRu
    .string()
    .test({
      test: value => {
        if (value === undefined) return false;
        try {
          return !!BigInt(value);
        } catch {
          return false;
        }
      },
      message: 'Должен состоять из цифр',
    })
    .max(4)
    .required()
    .transform(v => v || undefined),
});

export type CustomersSchema = yupRu.InferType<typeof customersSchema>;
