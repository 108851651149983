import { RadioButtonOption } from '@gravity-ui/uikit';

import { ChatFilterType } from '../model/types';

export const TYPE_OPTIONS_WITHOUT_ACTION: RadioButtonOption<ChatFilterType | 'all'>[] = [
  {
    value: 'all',
    content: 'Все',
  },
  {
    value: 'text',
    content: 'Текст',
  },
  {
    value: 'image',
    content: 'Изображения',
  },
  {
    value: 'file',
    content: 'Файлы',
  },
];

export const TYPE_OPTIONS: RadioButtonOption<ChatFilterType | 'all'>[] = [
  ...TYPE_OPTIONS_WITHOUT_ACTION,
  {
    value: 'action',
    content: 'Действия',
  },
];
