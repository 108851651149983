import { DelayedTextInput } from '@gravity-ui/components';
import { ChevronDown, Xmark } from '@gravity-ui/icons';
import { Button, Icon, Select, SelectProps } from '@gravity-ui/uikit';
import { useEffect, useMemo, useState } from 'react';

import { components } from '@shared/api';

import './index.sass';

import { useGetHandbookWinnersInfiniteQuery } from '@entities';

type WinnerId = string;
export type WinnerType = components['schemas']['handbook.Winner']['type'];
export type WinnerValue = `${WinnerId}/${WinnerType}`;

interface SelectWinnersWithSearchProps
  extends Omit<
    SelectProps,
    | 'renderFilter'
    | 'hasClear'
    | 'filterable'
    | 'options'
    | 'value'
    | 'onUpdate'
    | 'renderCounter'
    | 'renderPopup'
    | 'renderControl'
    | 'open'
    | 'onOpenChange'
    | 'filter'
    | 'onFilterChange'
    | 'multiple'
    | 'filterPlaceholder'
  > {
  textInputClassname?: string;
  textInputWrapperClassname?: string;
  initialValue?: WinnerValue[];
  // onValueChange?: (value: WinnerValue[]) => void;
  onConfirm?: (value: WinnerValue[]) => void;
  onReset?: () => void;
  onContentChange: (content: string) => void;
}

// const isEqualSimpleArrays = (arr1: string[], arr2: string[]) => {
//   if (arr1.length !== arr2.length) {
//     return false;
//   }
//   for (let i = 0; i < arr1.length; i++) if (arr1[i] !== arr2[i]) return false;
//   return true;
// };

export function SelectWinnersWithSearch({
  textInputClassname,
  textInputWrapperClassname,
  initialValue,
  // onValueChange,
  onConfirm,
  onReset,
  onBlur,
  onContentChange,
  ...selectProps
}: SelectWinnersWithSearchProps) {
  const [value, setValue] = useState<WinnerValue[]>(initialValue || []);
  const [open, setOpen] = useState(false);

  const [filterWinners, setFilterWinners] = useState('');
  const getHandbookWinnersInfiniteQuery = useGetHandbookWinnersInfiniteQuery(
    {
      query: filterWinners,
      limit: 30,
    },
    open
  );
  const flattedWinners = useMemo(
    () => getHandbookWinnersInfiniteQuery.data?.pages.flat(),
    [getHandbookWinnersInfiniteQuery.data]
  );
  const [content, setContent] = useState<string | undefined>(
    initialValue
      ?.map(val => flattedWinners?.find(item => item.id === val)?.name || '')
      .filter(v => !!v)
      .join(', ')
  );

  const onUpdate = (newValue: WinnerValue[]) => {
    setContent(() => {
      const newContent = newValue
        .map(val => flattedWinners?.find(item => item.id === val)?.name || '')
        .filter(v => !!v)
        .join(', ');
      onContentChange(newContent);
      return newContent;
    });
    setValue(newValue);
  };

  const blurHandle = () => {
    // if (open) {
    //   if (!initialValue?.length) {
    //     setValue([]);
    //     return;
    //   }
    //   // const hasChanges = !isEqualSimpleArrays(value, initialValue);
    //   // if (hasChanges) {
    //   setValue(initialValue);
    //   // }
    // }
  };

  const renderFilter: SelectProps['renderFilter'] = ({
    value: filterValue,
    ref,
    onChange,
    onKeyDown,
  }) => {
    return (
      <div className={textInputWrapperClassname}>
        <DelayedTextInput
          delay={300}
          controlRef={ref}
          controlProps={{ size: 1 }}
          value={filterValue}
          onUpdate={onChange}
          onKeyDown={onKeyDown}
          className={textInputClassname}
          placeholder="Поиск"
        />
      </div>
    );
  };

  const renderPopup: SelectProps['renderPopup'] = ({ renderFilter, renderList }) => (
    <>
      {renderFilter()}
      {renderList()}
      <div className="w-full flex flex-row items-center gap-2 p-2 border-line-generic border-t border-solid">
        <Button
          view="normal"
          size="l"
          width="max"
          onClick={() => {
            onReset?.();
            setValue([]);
          }}
        >
          Сбросить
        </Button>
        <Button
          view="action"
          size="l"
          width="max"
          onClick={() => {
            onConfirm?.(value);
            setOpen(false);
          }}
        >
          Сохранить
        </Button>
      </div>
    </>
  );

  const renderControl: SelectProps['renderControl'] = ({ onKeyDown }) => (
    <div className="g-select-control g-select-control_size_l g-select-control_pin_round-round g-select-control_has-clear g-select-control_has-value">
      <button
        onClick={() => {
          blurHandle();
          setOpen(prev => !prev);
        }}
        onKeyDown={onKeyDown}
        className="g-select-control__button g-select-control__button_size_l g-select-control__button_view_normal g-select-control__button_pin_round-round"
      >
        {value.length > 0 ? (
          <span className="g-select-control__option-text">{content}</span>
        ) : (
          <span className="text-text-hint g-select-control__placeholder">Выберите</span>
        )}
      </button>
      {value.length > 0 && (
        <button
          onClick={() => {
            onReset?.();
            setValue([]);
          }}
          className="g-select-clear g-select-clear_size_l select-clear-button"
        >
          <Icon
            data={Xmark}
            className="text-text-secondary select-clear-icon"
          />
        </button>
      )}
      <Icon
        data={ChevronDown}
        className="text-text-secondary shrink-0"
        size={18}
      />
    </div>
  );

  useEffect(() => {
    setValue(initialValue || []);
  }, [initialValue]);

  return (
    <Select
      multiple
      hasClear={true}
      renderFilter={renderFilter}
      filterable={true}
      options={
        flattedWinners?.map(winner => ({
          content: winner.name,
          value: `${winner.id}/${winner.type}`,
        })) || []
      }
      value={value}
      onUpdate={newValue => {
        onUpdate(newValue as WinnerValue[]);
      }}
      renderPopup={renderPopup}
      renderControl={renderControl}
      open={open}
      onOpenChange={setOpen}
      onBlur={e => {
        onBlur?.(e);
        blurHandle();
      }}
      loading={
        getHandbookWinnersInfiniteQuery.isFetching ||
        getHandbookWinnersInfiniteQuery.isFetchingNextPage ||
        getHandbookWinnersInfiniteQuery.isLoading
      }
      onLoadMore={() => getHandbookWinnersInfiniteQuery.fetchNextPage()}
      filter={filterWinners}
      filterPlaceholder="Поиск"
      onFilterChange={setFilterWinners}
      {...selectProps}
    />
  );
}
