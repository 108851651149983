import { useToaster } from '@gravity-ui/uikit';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import { $suppliersHooks } from '@entities';
import { CreateHandbookDialog, suppliersFormAtom } from '@features';
import { bodyResponseType } from '@shared/api';
import { RightSidebar } from '@shared/ui';

import { SuppliersLayout } from './SuppliersLayout';

interface CreateSuppliersProps {
  open: boolean;
  onClose: () => void;
  afterCreate?: (createdSupplier: bodyResponseType<'post', '/handbook/suppliers'>) => void;
  withDialog?: boolean;
}

export function CreateSuppliers({ open, onClose, afterCreate, withDialog }: CreateSuppliersProps) {
  const { add } = useToaster();

  const queryClient = useQueryClient();

  const [suppliersCreation, setSuppliersCreation] = useAtom(suppliersFormAtom);

  // Queries
  const createSuppliersMutation = $suppliersHooks.createMutation();

  //

  return (
    <SuppliersLayout
      formProps={{
        defaultValues: suppliersCreation,
        onValuesChange: values => {
          setSuppliersCreation(values);
        },
        onClickCancelButton: () => {
          setSuppliersCreation(undefined);
          onClose();
        },
        compactBottomPanel: true,
        // disabled: underApproval,
        initialEditable: true,
        onValid: async values => {
          await createSuppliersMutation
            .mutateAsync({
              body: values,
            })
            .then(createdSupplier => {
              add({
                name: 'create-suppliers-success',
                theme: 'success',
                content: 'Карточка Поставщика успешна создана!',
              });
              queryClient.invalidateQueries({ queryKey: ['/suppliers/all'] });
              afterCreate?.(createdSupplier);

              setSuppliersCreation(undefined);
              onClose();
            })
            .catch(() => {
              add({
                name: 'create-suppliers-error',
                theme: 'danger',
                content: 'Ошибка при создании карточки Поставщика',
              });
            });
        },
        onInvalid: errors => {
          console.log('@errors', errors);
        },
      }}
      renderForm={formComponent =>
        withDialog ? (
          <CreateHandbookDialog
            onClose={onClose}
            open={open}
            title="Создание карточки Поставщика"
          >
            {formComponent}
          </CreateHandbookDialog>
        ) : (
          <RightSidebar
            open={open}
            onClose={onClose}
          >
            <RightSidebar.Header
              title="Создание карточки Поставщика"
              onClose={onClose}
            />
            {formComponent}
          </RightSidebar>
        )
      }
    />
  );
}
