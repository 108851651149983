import { ReactNode } from 'react';

import { AgenciesForm, AgenciesFormProps } from '@features';

interface AgenciesLayoutProps {
  renderForm: (formComponent: ReactNode) => ReactNode;
  formProps: AgenciesFormProps;
}

export function AgenciesLayout({ renderForm, formProps }: AgenciesLayoutProps) {
  return <>{renderForm(<AgenciesForm {...formProps} />)}</>;
}
