/* eslint-disable @typescript-eslint/no-unused-vars */
import { useInfiniteQuery } from '@tanstack/react-query';

import {
  $api,
  $mock,
  bodyRequestType,
  fetchClient,
  mockClient,
  ParametersPathType,
  ParametersQueryType,
} from '@shared/api';

function useGetHandbookRegionsInfiniteQuery(
  query: ParametersQueryType<'get', '/handbook/regions'>,
  enabled: boolean
) {
  return useInfiniteQuery({
    queryKey: ['/handbook/regions', query],
    queryFn: async ({ pageParam }) => {
      const { data } = await fetchClient.GET('/handbook/regions', {
        // const { data } = await mockClient.GET('/handbook/regions', {
        params: { query: { ...query, offset: pageParam } },
      });

      return data?.list || [];
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage?.length === 0) {
        return undefined;
      }
      return lastPageParam + query.limit;
    },
    enabled,
  });
}

const createHandbookRegionsMutation = () => $api.useMutation('post', '/handbook/regions');
// const createHandbookRegions = (body: bodyRequestType<'post', '/handbook/regions'>) =>
//   createHandbookRegionsMutation.mutateAsync({ body: body });

const useGetByIdHandbookRegions = (
  id: ParametersPathType<'get', '/handbook/regions/{id}'>['id'],
  enabled: boolean
) => $api.useQuery('get', '/handbook/regions/{id}', { params: { path: { id: id } } }, { enabled });

const updateHandbookRegionsMutation = () => $api.useMutation('put', '/handbook/regions/{id}');
const deleteHandbookRegionsMutation = () => $api.useMutation('delete', '/handbook/regions/{id}');

export const $regionsHooks = {
  useGetAll: useGetHandbookRegionsInfiniteQuery,
  useGetById: useGetByIdHandbookRegions,
  createMutation: createHandbookRegionsMutation,
  deleteByIdMutation: deleteHandbookRegionsMutation,
  updateByIdMutation: updateHandbookRegionsMutation,
};
