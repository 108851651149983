import { Button, useToaster } from '@gravity-ui/uikit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { CounterpartyType, useGetHandbookCounterpartiesInfiniteQuery } from '@entities';
import { bodyResponseType } from '@shared/api';
import { cn, useFieldsWithPreview } from '@shared/lib';
import { FieldWithPreview, RightSidebar } from '@shared/ui';

import { paymentDetailsSchema, PaymentDetailsSchema } from '../model';

export interface PaymentDetailsFormProps {
  onValid: SubmitHandler<PaymentDetailsSchema>;
  onValuesChange?: (values: PaymentDetailsSchema) => void;
  response?: bodyResponseType<'get', '/handbook/payment-details/{id}'>;
  onInvalid?: SubmitErrorHandler<PaymentDetailsSchema>;
  onClickCancelButton?: () => void;
  initialEditable?: boolean;
  disabled?: boolean;
  hideBottomPanel?: boolean;
  rightSidebarButtons?: boolean;
  defaultValues?: PaymentDetailsSchema;
  cancelButtonText?: string;
  submitButtonText?: string;
  compactBottomPanel?: boolean;
}

export function PaymentDetailsForm({
  onValid,
  onValuesChange,
  response,
  onInvalid,
  initialEditable = false,
  onClickCancelButton,
  disabled = false,
  hideBottomPanel = false,
  defaultValues,
  cancelButtonText = 'Отмена',
  submitButtonText = 'Применить',
  compactBottomPanel = false,
}: PaymentDetailsFormProps) {
  const { add } = useToaster();

  const form = useForm<PaymentDetailsSchema>({
    resolver: yupResolver(paymentDetailsSchema),
    mode: 'all',
    defaultValues,
    resetOptions: {
      keepDirtyValues: true,
      keepDefaultValues: false,
    },
  });

  const { createFieldRef, isFieldEditable, selectField, selectedFieldPath, resetSelectedField } =
    useFieldsWithPreview({
      form: form,
      initialEditable,
      disabled,
    });

  const values = form.watch();

  // Queries
  const [filterCounterparties, setFilterCounterparties] = useState('');
  const getHandbookCounterpartiesInfiniteQuery = useGetHandbookCounterpartiesInfiniteQuery(
    {
      query: filterCounterparties,
      limit: 30,
    },
    selectedFieldPath === 'counterparty_with_type'
  );
  const flattedCounterparties = useMemo(
    () => getHandbookCounterpartiesInfiniteQuery.data?.pages.flat(),
    [getHandbookCounterpartiesInfiniteQuery.data]
  );
  //

  useDeepCompareEffect(() => {
    onValuesChange?.(values);
  }, [values]);

  useDeepCompareEffect(() => {
    if (response)
      form.reset({
        ...response,
        counterparty_with_type:
          response.counterparty_id && response.counterparty_type
            ? `${response.counterparty_id}/${response.counterparty_type}`
            : undefined,
      });
  }, [response || {}]);

  useEffect(() => {
    if (Object.values(form.formState.errors).length > 0 && form.formState.isSubmitted)
      add({
        title: 'Не все обязательные поля заполнены',
        name: 'validation-fields-error',
        theme: 'warning',
      });
  }, [form.formState.submitCount]);

  return (
    <form
      className="flex flex-col overflow-hidden grow"
      onSubmit={form.handleSubmit(onValid, onInvalid)}
    >
      <FieldWithPreview>
        <FieldWithPreview.Text
          ref={createFieldRef('bank_name')}
          control={form.control}
          path="bank_name"
          name="Наименование банка"
          edit={isFieldEditable('bank_name')}
          onFieldSelect={() => selectField('bank_name')}
          textInputProps={{ disabled }}
          required
        />
        <FieldWithPreview.Select
          control={form.control}
          path="counterparty_with_type"
          name="Контрагент"
          edit={isFieldEditable('counterparty_with_type')}
          ref={createFieldRef('counterparty_with_type')}
          onFieldSelect={() => selectField('counterparty_with_type')}
          options={
            flattedCounterparties?.map(counterparty => ({
              content: counterparty.value,
              value: counterparty.id + '/' + counterparty.type,
            })) || []
          }
          defaultOptions={
            response && response.counterparty_id && response.counterparty_type
              ? [
                  {
                    content: response.counterparty_value,
                    value: response.counterparty_id + '/' + response.counterparty_type,
                  },
                ]
              : undefined
          }
          filter={filterCounterparties}
          onFilterChange={filter => setFilterCounterparties(filter)}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          required
          selectProps={{
            disabled,
            placeholder: 'Выбрать',
            onUpdate: ([counterpartyWithType]) => {
              if (counterpartyWithType) {
                const [counterpartyId, counterpartyType] = counterpartyWithType.split('/');
                form.setValue('counterparty_id', counterpartyId!);
                form.setValue('counterparty_type', counterpartyType as CounterpartyType);
              } else {
                form.resetField('counterparty_type', { defaultValue: undefined });
                form.resetField('counterparty_id', { defaultValue: undefined });
                form.resetField('counterparty_with_type', { defaultValue: undefined });
              }
            },
            loading:
              getHandbookCounterpartiesInfiniteQuery.isFetching ||
              getHandbookCounterpartiesInfiniteQuery.isFetchingNextPage ||
              getHandbookCounterpartiesInfiniteQuery.isLoading ||
              getHandbookCounterpartiesInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookCounterpartiesInfiniteQuery.fetchNextPage();
            },
          }}
        />
        <FieldWithPreview.Text
          ref={createFieldRef('recipient_rs')}
          control={form.control}
          path="recipient_rs"
          name="Р/с получателя"
          edit={isFieldEditable('recipient_rs')}
          onFieldSelect={() => selectField('recipient_rs')}
          textInputProps={{ disabled }}
          required
        />
        <FieldWithPreview.Text
          ref={createFieldRef('recipient_bank_bik')}
          control={form.control}
          path="recipient_bank_bik"
          name="БИК банка получателя"
          edit={isFieldEditable('recipient_bank_bik')}
          onFieldSelect={() => selectField('recipient_bank_bik')}
          textInputProps={{ disabled }}
          required
        />
        <FieldWithPreview.Text
          ref={createFieldRef('recipient_bank_ks')}
          control={form.control}
          path="recipient_bank_ks"
          name="К/с банка получателя"
          edit={isFieldEditable('recipient_bank_ks')}
          onFieldSelect={() => selectField('recipient_bank_ks')}
          textInputProps={{ disabled }}
        />
        <FieldWithPreview.Text
          ref={createFieldRef('personal_account')}
          control={form.control}
          path="personal_account"
          name="Л/с (при наличии)"
          edit={isFieldEditable('personal_account')}
          onFieldSelect={() => selectField('personal_account')}
          textInputProps={{ disabled }}
        />
      </FieldWithPreview>
      {!hideBottomPanel && (
        <RightSidebar.BottomPanel>
          <Button
            className={cn({ 'ml-auto': compactBottomPanel })}
            size="xl"
            view={compactBottomPanel ? 'flat' : 'normal'}
            width={'max'}
            onClick={onClickCancelButton}
            disabled={disabled}
          >
            {cancelButtonText}
          </Button>
          <Button
            size="xl"
            view="action"
            type="submit"
            width={'max'}
            onClick={form.handleSubmit(onValid, onInvalid)}
            disabled={disabled}
          >
            {submitButtonText}
          </Button>
        </RightSidebar.BottomPanel>
      )}
    </form>
  );
}
