import { UseFormProps } from 'react-hook-form';

import { User } from '@entities';
import { LOCAL_STORAGE } from '@shared/consts';
import { customStorage } from '@shared/lib';

import { TenderSchema } from '../model';

export const DEFAULT_VALUES: UseFormProps<TenderSchema>['defaultValues'] = {
  responsible_id: customStorage<User>().getItem(LOCAL_STORAGE.user, null)?.id,
};
