import { yupRu } from '@shared/config';
import { schemaFields } from '@shared/lib';

export const legalEntitiesSchema = yupRu.object({
  /** @description Наименование */
  name: schemaFields.text().required(),
  /** @description ИНН */
  inn: yupRu
    .string()
    .min(10)
    .max(12)
    .test({
      test: value => {
        if (value === undefined) return false;
        try {
          return !!BigInt(value);
        } catch {
          return false;
        }
      },
      message: 'Должен состоять из цифр',
    })
    .test({
      test: value => typeof value === 'string' && (value.length === 10 || value.length === 12),
      message: 'ИНН состоит из 10 или 12 арабских цифр',
    })
    .required(),
  /** @description КПП */
  kpp: yupRu
    .string()
    .test({
      test: value => {
        if (value === undefined) return false;
        try {
          return !!BigInt(value);
        } catch {
          return false;
        }
      },
      message: 'Должен состоять из цифр',
    })
    .min(9)
    .max(9)
    .transform(v => v || undefined)
    .required(),
  /** @description Регион */
  region_id: schemaFields.select(),
  /** @description Объект поставок */
  purchase_object_id: schemaFields.select(),
  /** @description Сайт */
  website: schemaFields.text().url(),
  /** @description Теги (краткое описание) */
  tags: schemaFields.tags(),
  /** @description Файлы: прайсы, каталоги, презентации, прочее */
  files: schemaFields.multiFile(),
  /** @description Контакты: */
  contact_ids: schemaFields.multiSelect(),
  /** @description Адреса */
  addresses: schemaFields.tags(),
  /** @description Платежные реквизиты */
  payment_detail_id: schemaFields.select(),
});

export type LegalEntitiesSchema = yupRu.InferType<typeof legalEntitiesSchema>;
