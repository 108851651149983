import './TabsSettingModal.sass';

import { Check, Gear, Grip, Xmark } from '@gravity-ui/icons';
import {
  Button,
  DropdownMenu,
  Icon,
  Modal,
  Spin,
  TabsItemProps,
  Text,
  TextInput,
} from '@gravity-ui/uikit';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { CRMTableFilters, CRMTableSettings, filtersToDisplayFormat } from '@features/table/@x';
import { getDnDDragEndHandler, objectKeysSafeToArray } from '@shared/lib';

import { TabsSettingsModalRef } from '../model/types';

interface TabSettingsModalProps<T extends string[]> {
  tabs: TabsItemProps[];
  tableSettings?: CRMTableSettings<T>;
  filterSettings?: CRMTableFilters<T>;
  onConfirmChange: (tabs: TabsItemProps[]) => void;
  wordBlackList?: string[];
  onUpdateTab?: (tab: TabsItemProps, newName: string) => Promise<void>;
  onDeleteTab?: (tab: TabsItemProps) => Promise<void>;
  onCreateTab?: (tabName: string) => Promise<void>;
}

export const TabsSettingsModal = (<ColumnNames extends string[]>() => {
  return forwardRef<TabsSettingsModalRef, TabSettingsModalProps<ColumnNames>>(
    (
      {
        tabs,
        tableSettings,
        filterSettings,
        onConfirmChange,
        wordBlackList = [],
        onCreateTab,
        onDeleteTab,
        onUpdateTab,
      },
      ref
    ) => {
      const [open, setOpen] = useState(false);
      const [dragTabs, setDragTabs] = useState<TabsItemProps[]>(structuredClone(tabs));
      const [activeTabForEdit, setActiveTabForEdit] = useState<TabsItemProps | null>(null);
      const [editTabTitle, setEditTabTitle] = useState('');
      const [tabsSettingsFlow, setTabsSettingsFlow] = useState<'default' | 'create'>('default');
      const [newTabTitle, setNewTabTitle] = useState('');

      const [loading, setLoading] = useState(false);

      const wordBlackListToLowerCase = wordBlackList.map(w => w.toLowerCase());
      const disabledToCreate =
        loading ||
        !newTabTitle ||
        wordBlackListToLowerCase.includes(newTabTitle.toLowerCase()) ||
        dragTabs.some(t => t.title === newTabTitle);
      const disabledToEdit =
        !editTabTitle ||
        wordBlackListToLowerCase.includes(editTabTitle.toLowerCase()) ||
        dragTabs.some(t => t.id === editTabTitle);

      useImperativeHandle<TabsSettingsModalRef, TabsSettingsModalRef>(
        ref,
        () => ({
          open: () => setOpen(true),
          close: () => setOpen(false),
        }),
        []
      );

      const updateTabTitle = (tabId: string) => {
        setDragTabs(prev => prev.map(t => (t.id === tabId ? { ...t, title: editTabTitle } : t)));
        setActiveTabForEdit(null);
        setEditTabTitle('');
      };

      // НАЧАЛО Обработка запросов

      const createTabHandle = async () => {
        if (!newTabTitle) return;
        setLoading(true);
        try {
          await onCreateTab?.(newTabTitle);
          setOpen(false);
        } catch (err) {
          console.log(err);
        } finally {
          setLoading(false);
        }
      };

      const updateTabHandle = async () => {
        if (!activeTabForEdit || !editTabTitle) return;
        setLoading(true);
        try {
          await onUpdateTab?.(activeTabForEdit, editTabTitle);
          updateTabTitle(activeTabForEdit.id);
        } catch (err) {
          console.log(err);
        } finally {
          setLoading(false);
        }
      };

      const deleteTabHandle = async (delTab: TabsItemProps) => {
        setLoading(true);
        try {
          await onDeleteTab?.(delTab);
          setDragTabs(prev => prev.filter(el => el.id !== delTab.id));
        } catch (err) {
          console.log(err);
        } finally {
          setLoading(false);
        }
      };

      // КОНЕЦ Обработка запросов

      const confirmChange = () => {
        // TODO переписать когда будут реальные данные
        onConfirmChange(dragTabs);
        setOpen(false);
      };

      const reset = () => {
        setTabsSettingsFlow('default');
        setActiveTabForEdit(null);
        setEditTabTitle('');
        setNewTabTitle('');
      };

      const renderTabInfo = (dragTab: TabsItemProps) => (
        <div className="flex flex-row items-center py-3">
          <Icon
            data={Grip}
            className="shrink-0 text-text-hint"
          />
          <div className="flex-auto mx-4">
            <Text variant="body-2">{dragTab.title}</Text>
          </div>
          <DropdownMenu
            renderSwitcher={props => (
              <Button
                {...props}
                view="flat"
                size="xs"
              >
                <Icon data={Gear} />
              </Button>
            )}
            items={[
              {
                action: () => {
                  setActiveTabForEdit(dragTab);
                  setEditTabTitle(dragTab.title as string);
                },
                text: 'Переименовать',
              },
              {
                action: () => deleteTabHandle(dragTab),
                text: 'Удалить',
                className: 'text-text-danger',
              },
            ]}
            size="l"
            popupProps={{ placement: 'bottom-end', contentClassName: 'setting-popup' }}
            menuProps={{ className: 'setting-menu' }}
          />
        </div>
      );

      const renderRenameTabField = () => (
        <div className="flex items-center grow border gap-2 ml-8 mr-5 pl-3 pr-1 py-1 rounded-lg border-solid border-line-generic">
          <TextInput
            size="m"
            type="text"
            className="w-full"
            value={editTabTitle}
            onUpdate={setEditTabTitle}
            view="clear"
            controlProps={{ maxLength: 30 }}
          />
          {loading ? (
            <button
              className="g-button g-button_view_outlined-success g-button_size_m g-button_pin_round-round g-button_disabled"
              disabled
            >
              <Spin
                size="xs"
                className="m-auto"
              />
            </button>
          ) : (
            <Button
              view="outlined-success"
              size="m"
              disabled={disabledToEdit}
              onClick={() => updateTabHandle()}
            >
              <Icon data={Check} />
            </Button>
          )}
        </div>
      );

      const renderDragContent = () => (
        <DragDropContext onDragEnd={getDnDDragEndHandler(dragTabs, setDragTabs)}>
          <Droppable droppableId={'tabs'}>
            {provided => (
              <div
                ref={provided.innerRef}
                className="px-7 overflow-hidden"
                {...provided.droppableProps}
              >
                {dragTabs.map((dragTab, index) => (
                  <Draggable
                    key={dragTab.id}
                    draggableId={dragTab.id}
                    index={index}
                  >
                    {dragProvided => (
                      <div
                        {...dragProvided.draggableProps}
                        {...dragProvided.dragHandleProps}
                        ref={dragProvided.innerRef}
                        style={{
                          ...dragProvided.draggableProps.style,
                        }}
                      >
                        {activeTabForEdit?.id === dragTab.id
                          ? renderRenameTabField()
                          : renderTabInfo(dragTab)}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      );

      const renderCreateTabContent = () => (
        <div className="flex flex-col gap-6 pt-1 px-8">
          <TextInput
            size="l"
            type="text"
            className="w-full"
            value={newTabTitle}
            onUpdate={setNewTabTitle}
            placeholder="Новый фильтр для Контрактов"
            controlProps={{ maxLength: 30 }}
            onKeyDown={e => {
              if (e.key === 'Enter' && !disabledToCreate) {
                createTabHandle();
              }
            }}
          />
          {tableSettings && (
            <div>
              <Text variant="subheader-1">Отображать в таблице:</Text>
              <div className="flex flex-row items-center flex-wrap gap-2 mt-2">
                {tableSettings?.map(tSetting =>
                  !tSetting.isSelected ? null : (
                    <Text
                      key={tSetting.id}
                      className="border whitespace-nowrap overflow-hidden text-ellipsis px-[7px] py-[5px] rounded-md border-solid border-line-generic"
                    >
                      {tSetting.id}
                    </Text>
                  )
                )}
              </div>
            </div>
          )}
          {filterSettings && (
            <div>
              <Text variant="subheader-1">Фильтры:</Text>
              <div className="flex flex-row items-center flex-wrap gap-2 mt-2">
                {objectKeysSafeToArray(filterSettings).map(key => {
                  const filter = filterSettings[key];
                  const displayText = filtersToDisplayFormat(filter);

                  if (!displayText) {
                    return null;
                  }

                  return (
                    <Text
                      key={key}
                      className="border whitespace-nowrap overflow-hidden text-ellipsis px-[7px] py-[5px] rounded-md border-solid border-line-generic"
                    >
                      {key + ' '}
                      <Text className="text-text-complementary">{': ' + displayText}</Text>
                    </Text>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      );

      useEffect(() => {
        if (open) {
          setDragTabs(tabs);
        }
      }, [tabs, open]);

      useEffect(() => {
        if (tabsSettingsFlow !== 'create') {
          return;
        }
        const handleKeyDown = (e: KeyboardEvent) => {
          if (e.key === 'Escape') {
            setTabsSettingsFlow('default');
          }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, [tabsSettingsFlow]);

      return (
        <Modal
          open={open}
          onClose={confirmChange}
          className="bg-effects-shadow-heavy"
          onTransitionExited={reset}
          disableEscapeKeyDown={tabsSettingsFlow === 'create'}
        >
          <div className="flex flex-col w-[480px] max-h-[500px] shadow-modal rounded-lg;">
            <div className="flex flex-row items-center justify-between pl-8 pr-5 pt-[26px] pb-2.5">
              <Text variant="subheader-3">
                {tabsSettingsFlow === 'default' ? 'Быстрые фильтры' : 'Создать фильтр'}
              </Text>
              <Button
                size="l"
                view="flat"
                onClick={confirmChange} // TODO: переназначить сохранение свойств когда добавят кнопку
              >
                <Icon data={Xmark} />
              </Button>
            </div>

            <div className="overflow-y-auto">
              {tabsSettingsFlow === 'default' ? renderDragContent() : renderCreateTabContent()}
            </div>

            <div className="flex flex-row justify-end pt-[15px] pb-7 px-8 gap-4">
              {tabsSettingsFlow === 'default' ? (
                <Button
                  size="l"
                  onClick={() => setTabsSettingsFlow('create')}
                >
                  <Text>Создать фильтр</Text>
                </Button>
              ) : (
                <>
                  <Button
                    view="flat"
                    size="l"
                    onClick={() => setTabsSettingsFlow('default')}
                  >
                    <Text>Вернуться назад</Text>
                  </Button>
                  {loading ? (
                    <button
                      className="g-button g-button_view_action g-button_size_l g-button_pin_round-round g-button_disabled"
                      disabled
                    >
                      <Spin
                        size="xs"
                        className="m-auto"
                      />
                    </button>
                  ) : (
                    <Button
                      view="action"
                      size="l"
                      onClick={createTabHandle}
                      disabled={disabledToCreate}
                    >
                      <Text>Создать</Text>
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
        </Modal>
      );
    }
  );
})();
