import { $api, bodyRequestType, ParametersPathType } from '@shared/api';

export const useQueryPostChatAll = (bodyRequest: bodyRequestType<'post', '/chat/all'>) =>
  $api.useQuery('post', '/chat/all', { body: bodyRequest, params: {} }, { throwOnError: true });

export const useQueryGetChatId = (id: ParametersPathType<'get', '/chat/{id}'>['id']) =>
  $api.useQuery('get', '/chat/{id}', { params: { path: { id: id } } });
export const useQueryPostChatMessagesId = (
  id: ParametersPathType<'post', '/chat/messages/{id}'>['id'],
  body: bodyRequestType<'post', '/chat/messages/{id}'>,
  enabled: boolean
) =>
  $api.useQuery(
    'post',
    '/chat/messages/{id}',
    { params: { path: { id: id } }, body: body },
    { enabled, throwOnError: false }
  );
