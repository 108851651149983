import { Button, Divider, Text, useToaster } from '@gravity-ui/uikit';
import { yupResolver } from '@hookform/resolvers/yup';
// import { useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';

import {
  convertAgreementResultStatusFromBackend,
  convertAgreementTypeFromBackend,
} from '@entities';
import { bodyResponseType } from '@shared/api';
import { MAX_FILE_SIZE, NOT_ALLOWED_FILE_EXTENSIONS } from '@shared/consts';
import { calculateMargin, calculatePrice, useFieldsWithPreview } from '@shared/lib';
import { YesNo } from '@shared/model';
import { FieldWithPreview, RightSidebar } from '@shared/ui';

import { AGREEMENT_OPTIONS } from '../consts';
import { agreementSchema, AgreementSchema } from '../model';

export interface AgreementFormProps {
  response?: Omit<bodyResponseType<'get', '/coordination/{id}'>, 'is_proposal_agree'> & {
    is_proposal_agree: YesNo | undefined;
  };
  onClickCancelButtonValid: SubmitHandler<AgreementSchema>;
  onClickCancelButtonInvalid?: SubmitErrorHandler<AgreementSchema>;
  onClickAgreeButtonValid: SubmitHandler<AgreementSchema>;
  onClickAgreeButtonInvalid?: SubmitErrorHandler<AgreementSchema>;
  hide?: boolean;
  disabled?: boolean;
  initialValues?: AgreementSchema;
}

export function AgreementForm({
  response,
  disabled = false,
  initialValues,
  // cancelButtonText = 'Отмена',
  // submitButtonText = 'Согласовать',
  // onValuesChange,
  onClickAgreeButtonValid,
  onClickCancelButtonValid,
  onClickAgreeButtonInvalid,
  onClickCancelButtonInvalid,
}: AgreementFormProps) {
  // const user = useAtomValue(userAtom);
  // const userOptions = user ? [{ content: user.full_name, value: user.id }] : [];
  const { add } = useToaster();

  const agreementForm = useForm<AgreementSchema>({
    resolver: yupResolver(agreementSchema),
    values: initialValues,
    mode: 'all',
    resetOptions: {
      keepDirtyValues: true,
      keepDefaultValues: false,
    },
  });

  const values = agreementForm.watch();

  const { createFieldRef, resetSelectedField, selectField, selectedFieldPath, isFieldEditable } =
    useFieldsWithPreview({
      form: agreementForm,
      initialEditable: response?.status === 'PENDING' || values.status === 'PENDING',
      disabled,
    });

  useEffect(() => {
    if (
      Object.values(agreementForm.formState.errors).length > 0 &&
      agreementForm.formState.isSubmitted
    )
      add({
        title: 'Не все обязательные поля заполнены',
        name: 'validation-fields-error',
        theme: 'warning',
      });
  }, [agreementForm.formState.submitCount]);

  useEffect(() => {
    if (
      (values.is_proposal_agree === 'Да' || values.is_proposal_agree === undefined) &&
      values.status === 'PENDING'
    ) {
      // agreementForm.setValue('planned_margin', initialValues?.planned_margin);
      // agreementForm.setValue('min_cost_price', initialValues?.min_cost_price);
      agreementForm.reset({
        ...values,
        planned_margin: initialValues?.planned_margin,
        min_cost_price: initialValues?.min_cost_price,
      });
    } else if (values.is_proposal_agree === 'Нет' && values.status === 'PENDING') {
      agreementForm.reset({ ...values, planned_margin: undefined, min_cost_price: undefined });
      // agreementForm.setValue('planned_margin', 0);
      // agreementForm.setValue('min_cost_price', 0);
    }
  }, [values.is_proposal_agree]);

  useEffect(() => {
    if (
      Object.values(agreementForm.formState.errors).length > 0 &&
      agreementForm.formState.isSubmitted
    )
      add({
        title: 'Не все обязательные поля заполнены',
        name: 'validation-fields-error',
        theme: 'warning',
      });
  }, [agreementForm.formState.isSubmitted]);

  return (
    <form
      className="flex flex-col overflow-hidden grow"
      // onSubmit={agreementForm.handleSubmit(onValid, onInvalid)}
    >
      <FieldWithPreview>
        <Text
          variant="body-2"
          className="font-semibold uppercase"
        >
          Запрос на согласование
        </Text>

        {/* Запрос на согласование */}
        <FieldWithPreview.Text
          ref={createFieldRef('title')}
          control={agreementForm.control}
          path="title"
          edit={false}
          show={false}
          onFieldSelect={() => selectField('title')}
          name="Наименование"
          textInputProps={{
            disabled: true,
          }}
        />
        <FieldWithPreview.Text
          ref={createFieldRef('entity_card')}
          control={agreementForm.control}
          path="entity_card"
          edit={false}
          onFieldSelect={() => selectField('entity_card')}
          name={response?.entity_type === 'OFFER' ? 'КП' : 'Тендер'}
          textInputProps={{
            disabled: true,
          }}
          isLink
        />
        <FieldWithPreview.Select
          ref={createFieldRef('customer_id')}
          control={agreementForm.control}
          path="customer_id"
          edit={false}
          onFieldSelect={() => selectField('customer_id')}
          name="Заказчик"
          options={[]}
          defaultOptions={
            response &&
            response.tender &&
            response.tender.customer_values?.[0] &&
            response.tender.customer_ids?.[0]
              ? [
                  {
                    content: response.tender.customer_values[0],
                    value: response.tender.customer_ids[0],
                  },
                ]
              : response &&
                  response.offer &&
                  response.offer.customer_value &&
                  response.offer.customer_id
                ? [{ content: response.offer.customer_value, value: response.offer.customer_id }]
                : undefined
          }
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            disabled: true,
          }}
        />
        <FieldWithPreview.Select
          ref={createFieldRef('assigned_by_id')}
          control={agreementForm.control}
          path="assigned_by_id"
          edit={false}
          onFieldSelect={() => selectField('assigned_by_id')}
          name="Запрашивающий согласование"
          options={[]}
          defaultOptions={
            response && response.assigned_by_id && response.assigned_by_value
              ? [{ content: response.assigned_by_value, value: response.assigned_by_id }]
              : undefined
          }
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            disabled: true,
          }}
        />
        <FieldWithPreview.Select
          ref={createFieldRef('assigned_to_id')}
          control={agreementForm.control}
          path="assigned_to_id"
          edit={false}
          onFieldSelect={() => selectField('assigned_to_id')}
          name="Согласовант"
          options={[]}
          defaultOptions={
            response && response.assigned_to_id && response.assigned_to_value
              ? [{ content: response.assigned_to_value, value: response.assigned_to_id }]
              : undefined
          }
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            disabled: true,
          }}
        />
        <FieldWithPreview.Currency
          ref={createFieldRef('cost_price')}
          show={
            values.coordination_type === 'MIN_PRICE' || values.coordination_type === 'OFFER_PRICE'
          }
          control={agreementForm.control}
          path="cost_price"
          edit={false}
          onFieldSelect={() => selectField('cost_price')}
          name="Себестоимость"
          numberFormatProps={{
            disabled: true,
            min: 0,
          }}
          required
        />
        <FieldWithPreview.Currency
          show={
            values.coordination_type === 'MIN_PRICE' || values.coordination_type === 'OFFER_PRICE'
          }
          ref={createFieldRef('specialist_cost_price')}
          control={agreementForm.control}
          path="specialist_cost_price"
          edit={false}
          onFieldSelect={() => selectField('specialist_cost_price')}
          name={
            response?.offer?.purpose === 'Обоснование НМЦК'
              ? 'Цена предложения'
              : 'Предлагаемая специалистом цена'
          }
          numberFormatProps={{
            disabled: true,
            min: 0,
          }}
          required
        />
        <FieldWithPreview.Percentage
          show={
            values.coordination_type === 'MIN_PRICE' || values.coordination_type === 'OFFER_PRICE'
          }
          ref={createFieldRef('proposed_margin')}
          control={agreementForm.control}
          path="proposed_margin"
          edit={false}
          required
          onFieldSelect={() => selectField('proposed_margin')}
          name="Маржа предлагаемая"
          numberFormatProps={{
            disabled: true,
            max: 100,
          }}
        />
        <FieldWithPreview.TextArea
          ref={createFieldRef('price_justification')}
          show={
            values.coordination_type === 'MIN_PRICE' ||
            (values.coordination_type === 'OFFER_PRICE' &&
              response?.offer?.purpose === 'Закупка ЕП')
          }
          control={agreementForm.control}
          path="price_justification"
          edit={false}
          onFieldSelect={() => selectField('price_justification')}
          name="Обоснование предложения цены"
          textAreaProps={{
            disabled: true,
          }}
        />
        <FieldWithPreview.Radio
          show={
            values.coordination_type === 'MIN_PRICE' || values.coordination_type === 'OFFER_PRICE'
          }
          ref={createFieldRef('is_proposal_agree')}
          control={agreementForm.control}
          path="is_proposal_agree"
          edit={isFieldEditable('is_proposal_agree')}
          onFieldSelect={() => selectField('is_proposal_agree')}
          name="Согласны с предложением специалиста?"
          options={AGREEMENT_OPTIONS['is_proposal_agree']}
          defaultOption={
            response?.is_proposal_agree !== undefined
              ? { content: response.is_proposal_agree, value: response.is_proposal_agree }
              : undefined
          }
          resetSelectedField={resetSelectedField}
          radioButtonProps={{
            disabled,
          }}
        />
        <FieldWithPreview.Percentage
          show={
            (values.coordination_type === 'MIN_PRICE' ||
              values.coordination_type === 'OFFER_PRICE') &&
            values.is_proposal_agree !== undefined
          }
          ref={createFieldRef('planned_margin')}
          control={agreementForm.control}
          path="planned_margin"
          edit={
            values.is_proposal_agree === 'Да' || values.is_proposal_agree === undefined
              ? false
              : isFieldEditable('planned_margin')
          }
          onFieldSelect={() => selectField('planned_margin')}
          name="Маржа плановая"
          numberFormatProps={{
            disabled:
              disabled ||
              values.is_proposal_agree === 'Да' ||
              values.is_proposal_agree === undefined,
            max: 100,
            onValueChange: ({ floatValue }) => {
              if (
                (typeof floatValue === 'number' && floatValue >= 100) ||
                selectedFieldPath !== 'planned_margin'
              )
                return;
              const costPrice = values['cost_price'];
              if (typeof costPrice === 'number' && typeof floatValue === 'number') {
                agreementForm.setValue('min_cost_price', calculatePrice(costPrice, floatValue));
              }
            },
          }}
          required
        />
        <FieldWithPreview.Currency
          show={
            (values.coordination_type === 'MIN_PRICE' ||
              values.coordination_type === 'OFFER_PRICE') &&
            values.is_proposal_agree !== undefined
          }
          ref={createFieldRef('min_cost_price')}
          control={agreementForm.control}
          path="min_cost_price"
          edit={
            values.is_proposal_agree === 'Да' || values.is_proposal_agree === undefined
              ? false
              : isFieldEditable('min_cost_price')
          }
          onFieldSelect={() => selectField('min_cost_price')}
          name={
            values.coordination_type === 'MIN_PRICE'
              ? 'Минимальная цена'
              : values.coordination_type === 'OFFER_PRICE'
                ? 'Согласованная цена КП'
                : 'Минимальная цена/согласованная цена КП'
          }
          numberFormatProps={{
            disabled:
              disabled ||
              values.is_proposal_agree === 'Да' ||
              values.is_proposal_agree === undefined,
            min: 0,
            onValueChange: ({ floatValue }) => {
              if (
                (typeof floatValue === 'number' && floatValue <= 0) ||
                selectedFieldPath !== 'min_cost_price'
              )
                return;
              const costPrice = values['cost_price'];
              if (typeof costPrice === 'number' && typeof floatValue === 'number') {
                agreementForm.setValue('planned_margin', calculateMargin(floatValue, costPrice));
              }
            },
          }}
          required
        />
        <FieldWithPreview.Select
          path="reject_reason"
          ref={createFieldRef('reject_reason')}
          control={agreementForm.control}
          show={response?.coordination_type === 'REJECTION'}
          edit={false}
          onFieldSelect={() => selectField('reject_reason')}
          name="Причина отказа"
          options={
            response && response.entity_type === 'OFFER'
              ? AGREEMENT_OPTIONS['reject_reason_offer']
              : response && response.entity_type === 'TENDER'
                ? AGREEMENT_OPTIONS['reject_reason_tender']
                : []
          }
          defaultOptions={
            response && response.offer?.reject_reason
              ? [
                  {
                    content: response.offer.reject_reason,
                    value: response.offer.reject_reason,
                  },
                ]
              : response && response.tender?.reject_reason
                ? [
                    {
                      content: response.tender.reject_reason,
                      value: response.tender.reject_reason,
                    },
                  ]
                : undefined
          }
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            placeholder: 'Выбрать',
            disabled: true,
          }}
        />
        <FieldWithPreview.TextArea
          ref={createFieldRef('reject_description')}
          control={agreementForm.control}
          show={response?.coordination_type === 'REJECTION'}
          path="reject_description"
          edit={false}
          onFieldSelect={() => selectField('reject_description')}
          name="Комментарий к причине отказа"
          textAreaProps={{ disabled: true }}
        />
        <FieldWithPreview.MultiFile
          control={agreementForm.control}
          show={
            values.coordination_type === 'MIN_PRICE' || values.coordination_type === 'OFFER_PRICE'
          }
          path="calculation_file"
          name="Файл расчета"
          edit={false}
          ref={createFieldRef('calculation_file')}
          onFieldSelect={() => selectField('calculation_file')}
          maxSize={MAX_FILE_SIZE}
          notAllowedExtension={NOT_ALLOWED_FILE_EXTENSIONS}
          disabled={true}
          required
        />
        <FieldWithPreview.MultiFile
          path="offer_file"
          control={agreementForm.control}
          show={!!response?.offer && values.coordination_type === 'OFFER_PRICE'}
          name="Файл КП"
          edit={false}
          ref={createFieldRef('offer_file')}
          onFieldSelect={() => selectField('offer_file')}
          maxSize={MAX_FILE_SIZE}
          notAllowedExtension={NOT_ALLOWED_FILE_EXTENSIONS}
          disabled={true}
        />
        <FieldWithPreview.MultiFile
          path="request_file"
          control={agreementForm.control}
          show={!!response?.tender && values.coordination_type === 'MIN_PRICE'}
          name="Файл Заявки"
          edit={false}
          ref={createFieldRef('request_file')}
          onFieldSelect={() => selectField('request_file')}
          maxSize={MAX_FILE_SIZE}
          notAllowedExtension={NOT_ALLOWED_FILE_EXTENSIONS}
          disabled={true}
        />

        <Divider />
        <Text
          variant="body-2"
          className="font-semibold uppercase"
        >
          Решение
        </Text>

        {/* Решение */}
        <FieldWithPreview.Radio
          ref={createFieldRef('status')}
          control={agreementForm.control}
          path="status"
          edit={false}
          onFieldSelect={() => selectField('status')}
          name="Результат согласования"
          options={AGREEMENT_OPTIONS['status']}
          resetSelectedField={resetSelectedField}
          defaultOption={
            response
              ? {
                  value: response.status,
                  content: convertAgreementResultStatusFromBackend(response.status),
                  preview: AGREEMENT_OPTIONS['status'].find(
                    status => status.value === response.status
                  )?.preview,
                }
              : AGREEMENT_OPTIONS['status'][0]
          }
          radioButtonProps={{
            disabled: true,
          }}
        />
        <FieldWithPreview.TextArea
          ref={createFieldRef('comment')}
          control={agreementForm.control}
          path="comment"
          edit={isFieldEditable('comment')}
          onFieldSelect={() => selectField('comment')}
          name="Комментарий согласования"
          textAreaProps={{ disabled }}
        />

        <Divider />
        <Text
          variant="body-2"
          className="font-semibold uppercase"
        >
          Служебная информация
        </Text>

        {/* Служебная информация */}

        <FieldWithPreview.Select
          ref={createFieldRef('coordination_type')}
          control={agreementForm.control}
          path="coordination_type"
          edit={false}
          onFieldSelect={() => selectField('coordination_type')}
          name="Тип согласования"
          options={AGREEMENT_OPTIONS['coordination_type']}
          defaultOptions={
            response && response.coordination_type
              ? [
                  {
                    value: response.coordination_type,
                    content: convertAgreementTypeFromBackend(response.coordination_type),
                  },
                ]
              : undefined
          }
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{ disabled: true }}
        />
      </FieldWithPreview>
      {
        <RightSidebar.BottomPanel>
          <Button
            size="xl"
            view="normal"
            width="max"
            disabled={disabled}
            onClick={agreementForm.handleSubmit(
              onClickCancelButtonValid,
              onClickCancelButtonInvalid
            )}
          >
            Отклонить
          </Button>
          <Button
            size="xl"
            view="action"
            type="submit"
            width="max"
            disabled={disabled}
            onClick={agreementForm.handleSubmit(onClickAgreeButtonValid, onClickAgreeButtonInvalid)}
          >
            Согласовать
          </Button>
        </RightSidebar.BottomPanel>
      }
    </form>
  );
}
