import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

import { Dialog } from '@widgets';
import { bodyRequestType } from '@shared/api';
import { LOCAL_STORAGE } from '@shared/consts';
import { customStorage } from '@shared/lib';

// import { DIALOGS_MOCK } from '../mock';

export const dialogsAtom = atom<Dialog[]>([]);
export const activeTabAtom = atomWithStorage<bodyRequestType<'post', '/chat/all'>['stage'] | null>(
  LOCAL_STORAGE.activeTabChat,
  localStorage.getItem(LOCAL_STORAGE.activeTabChat) as
    | bodyRequestType<'post', '/chat/all'>['stage']
    | null,
  customStorage()
);
