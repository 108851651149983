import { $api } from '@shared/api';

// tabs

export const useTenderGetTabsQuery = () => {
  return $api.useQuery('get', '/tender/tabs');
};
export const useTenderDeleteTabMutation = () => {
  return $api.useMutation('delete', '/tender/tabs/{name}');
};

export const useTenderCreateTabMutation = () => {
  return $api.useMutation('post', '/tender/tabs');
};

export const useTenderUpdateTabMutation = () => {
  return $api.useMutation('put', '/tender/tabs/{name}');
};
