import { AgreementSchema } from '@features';
import { bodyResponseType } from '@shared/api';
import { roundPercentAndCurrencyFromBackend, roundPercentAndCurrencyToBackend } from '@shared/lib';

export function percentAndCurrencyValuesToBackend(formValues: AgreementSchema): AgreementSchema {
  return {
    ...formValues,
    cost_price: roundPercentAndCurrencyToBackend(formValues.cost_price),
    specialist_cost_price: roundPercentAndCurrencyToBackend(formValues.specialist_cost_price),
    proposed_margin: roundPercentAndCurrencyToBackend(formValues.proposed_margin),
    planned_margin: roundPercentAndCurrencyToBackend(formValues.planned_margin),
    min_cost_price: roundPercentAndCurrencyToBackend(formValues.min_cost_price),
  };
}

export function percentAndCurrencyValuesFromBackend(formValues: AgreementSchema): AgreementSchema {
  return {
    ...formValues,
    cost_price: roundPercentAndCurrencyFromBackend(formValues.cost_price),
    specialist_cost_price: roundPercentAndCurrencyFromBackend(formValues.specialist_cost_price),
    proposed_margin: roundPercentAndCurrencyFromBackend(formValues.proposed_margin),
    planned_margin: roundPercentAndCurrencyFromBackend(formValues.planned_margin),
    min_cost_price: roundPercentAndCurrencyFromBackend(formValues.min_cost_price),
  };
}

export function checkIsNotBlankRelations(agreement: bodyResponseType<'get', '/coordination/{id}'>) {
  if (
    (agreement.tender && Object.keys(agreement.tender).length) ||
    (agreement.offer && Object.keys(agreement.offer).length)
  )
    return true;
  return false;
}

export function countRelations(agreement?: bodyResponseType<'get', '/contract/related/{id}'>) {
  if (!agreement || !Object.keys(agreement).length) return 0;
  let tender = 0;
  let offer = 0;
  if (agreement?.offer && Object.keys(agreement.offer).length) offer++;
  if (agreement?.tender && Object.keys(agreement.tender).length) tender++;
  return tender + offer;
}
