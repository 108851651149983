import { yupRu } from '@shared/config';
import { emailRegex } from '@shared/lib';

export const resetPasswordSchema = yupRu.object({
  email: yupRu
    .string()
    .matches(emailRegex, {
      message: 'Некорректный e-mail',
      excludeEmptyString: true,
    })
    .required('Введите e-mail'),
});
