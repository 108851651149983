import { useToaster } from '@gravity-ui/uikit';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import { $supplierContractsHooks } from '@entities';
import { CreateHandbookDialog, supplierContractsFormAtom } from '@features';
import { bodyResponseType } from '@shared/api';
import { roundPercentAndCurrencyToBackend } from '@shared/lib';
import { RightSidebar } from '@shared/ui';

import { SupplierContractsLayout } from './SupplierContractsLayout';

interface CreateSupplierContractsProps {
  open: boolean;
  onClose: () => void;
  afterCreate?: (
    createdSupplierContract: bodyResponseType<'post', '/handbook/supplier-contracts'>
  ) => void;
  withDialog?: boolean;
}

export function CreateSupplierContracts({
  open,
  onClose,
  afterCreate,
  withDialog,
}: CreateSupplierContractsProps) {
  const { add } = useToaster();

  const queryClient = useQueryClient();

  const [supplierContractsCreation, setSupplierContractsCreation] =
    useAtom(supplierContractsFormAtom);

  // Queries
  const createSupplierContractsMutation = $supplierContractsHooks.createMutation();

  //

  return (
    <SupplierContractsLayout
      formProps={{
        defaultValues: supplierContractsCreation,
        onValuesChange: values => {
          setSupplierContractsCreation(values);
        },
        onClickCancelButton: () => {
          setSupplierContractsCreation(undefined);
          onClose();
        },
        compactBottomPanel: true,
        // disabled: underApproval,
        initialEditable: true,
        onValid: async values => {
          await createSupplierContractsMutation
            .mutateAsync({
              body: { ...values, price: roundPercentAndCurrencyToBackend(values.price) },
            })
            .then(createdSupplierContract => {
              add({
                name: 'create-supplierContracts-success',
                theme: 'success',
                content: 'Карточка Договора с поставщиками успешна создана!',
              });
              queryClient.invalidateQueries({ queryKey: ['/supplier-contracts/all'] });
              afterCreate?.(createdSupplierContract);

              setSupplierContractsCreation(undefined);
              onClose();
            })
            .catch(() => {
              add({
                name: 'create-supplierContracts-error',
                theme: 'danger',
                content: 'Ошибка при создании карточки Договора с поставщиками',
              });
            });
        },
        onInvalid: errors => {
          console.log('@errors', errors);
        },
      }}
      renderForm={formComponent =>
        withDialog ? (
          <CreateHandbookDialog
            onClose={onClose}
            open={open}
            title="Создание карточки Договора с поставщиками"
          >
            {formComponent}
          </CreateHandbookDialog>
        ) : (
          <RightSidebar
            open={open}
            onClose={onClose}
          >
            <RightSidebar.Header
              title="Создание карточки Договора с поставщиками"
              onClose={onClose}
            />
            {formComponent}
          </RightSidebar>
        )
      }
    />
  );
}
