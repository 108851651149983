import { dateTime } from '@gravity-ui/date-utils';

export function getIsProducementEndAtExpired(producementEndAt?: string) {
  if (!producementEndAt) return false;

  const producementEndAtDate = dateTime({
    input: producementEndAt,
    timeZone: 'EAT',
  });

  return producementEndAtDate.startOf('hour').isBefore(Date.now());
}
