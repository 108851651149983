import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { CalendarDrawer, CommercialOfferDrawer, CommercialOffersTable } from '@widgets';
import { URLS } from '@shared/consts';
import { useNavigateTo } from '@shared/lib';
import { HeroWithButton } from '@shared/ui';

export function CommercialOffers() {
  const { navigateTo } = useNavigateTo();

  const offerId = useParams()[URLS.processes.commercialOffersDynamicParams.id];

  const [createOfferOpen, setCreateOfferOpen] = useState(false);

  const [calendarDrawerOpen, setCalendarDrawerOpen] = useState(false);

  const [selectedOfferIndex, setSelectedOfferIndex] = useState<number | null>(null);

  return (
    <>
      <HeroWithButton
        title="Коммерческие предложения"
        buttonOnClick={() => setCreateOfferOpen(true)}
      />
      <CommercialOffersTable
        selectedOfferIndex={selectedOfferIndex}
        onRowClick={(id, index) => {
          navigateTo(URLS.processes.commercialOffers + '/' + id);
          setSelectedOfferIndex(index);
        }}
        calendarButtonAction={() => {
          setCalendarDrawerOpen(true);
        }}
      />
      <CommercialOfferDrawer
        id={offerId}
        type="edit"
        onClose={() => {
          navigateTo(URLS.processes.commercialOffers);
        }}
      />
      <CommercialOfferDrawer
        open={createOfferOpen}
        onClose={() => setCreateOfferOpen(false)}
        type="create"
      />
      <CalendarDrawer
        open={calendarDrawerOpen}
        onClose={() => setCalendarDrawerOpen(false)}
        entityType="КП"
      />
    </>
  );
}
