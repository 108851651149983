/* eslint-disable @typescript-eslint/no-unused-vars */
import { useInfiniteQuery } from '@tanstack/react-query';

import {
  $api,
  $mock,
  bodyRequestType,
  fetchClient,
  mockClient,
  ParametersPathType,
  ParametersQueryType,
} from '@shared/api';

function useGetHandbookSupplierContractsInfiniteQuery(
  query: ParametersQueryType<'get', '/handbook/supplier-contracts'>,
  enabled: boolean
) {
  return useInfiniteQuery({
    queryKey: ['/handbook/supplier-contracts', query],
    queryFn: async ({ pageParam }) => {
      const { data } = await fetchClient.GET('/handbook/supplier-contracts', {
        // const { data } = await mockClient.GET('/handbook/supplier-contracts', {
        params: { query: { ...query, offset: pageParam } },
      });

      return data?.list || [];
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage?.length === 0) {
        return undefined;
      }
      return lastPageParam + query.limit;
    },
    enabled,
  });
}

const createHandbookSupplierContractsMutation = () =>
  $api.useMutation('post', '/handbook/supplier-contracts');
// const createHandbookSupplierContracts = (
//   body: bodyRequestType<'post', '/handbook/supplier-contracts'>
// ) => createHandbookSupplierContractsMutation.mutateAsync({ body: body });

const useGetByIdHandbookSupplierContracts = (
  id: ParametersPathType<'get', '/handbook/supplier-contracts/{id}'>['id'],
  enabled: boolean
) =>
  $api.useQuery(
    'get',
    '/handbook/supplier-contracts/{id}',
    { params: { path: { id: id } } },
    { enabled }
  );

const updateHandbookSupplierContractsMutation = () =>
  $api.useMutation('put', '/handbook/supplier-contracts/{id}');
const deleteHandbookSupplierContractsMutation = () =>
  $api.useMutation('delete', '/handbook/supplier-contracts/{id}');

export const $supplierContractsHooks = {
  useGetAll: useGetHandbookSupplierContractsInfiniteQuery,
  useGetById: useGetByIdHandbookSupplierContracts,
  createMutation: createHandbookSupplierContractsMutation,
  deleteByIdMutation: deleteHandbookSupplierContractsMutation,
  updateByIdMutation: updateHandbookSupplierContractsMutation,
};
