import { useToaster } from '@gravity-ui/uikit';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import { $agenciesHooks } from '@entities';
import { agenciesFormAtom, CreateHandbookDialog } from '@features';
import { bodyResponseType } from '@shared/api';
import { RightSidebar } from '@shared/ui';

import { AgenciesLayout } from './AgenciesLayout';

interface CreateAgenciesProps {
  open: boolean;
  onClose: () => void;
  afterCreate?: (createdAgency: bodyResponseType<'post', '/handbook/agencies'>) => void;
  withDialog?: boolean;
}

export function CreateAgencies({ open, onClose, afterCreate, withDialog }: CreateAgenciesProps) {
  const { add } = useToaster();

  const queryClient = useQueryClient();

  const [agenciesCreation, setAgenciesCreation] = useAtom(agenciesFormAtom);

  // Queries
  const createAgenciesMutation = $agenciesHooks.createMutation();

  //

  return (
    <AgenciesLayout
      formProps={{
        defaultValues: agenciesCreation,
        onValuesChange: values => {
          setAgenciesCreation(values);
        },
        onClickCancelButton: () => {
          setAgenciesCreation(undefined);
          onClose();
        },
        compactBottomPanel: true,
        // disabled: underApproval,
        initialEditable: true,
        onValid: async values => {
          await createAgenciesMutation
            .mutateAsync({
              body: values,
            })
            .then(createdAgency => {
              add({
                name: 'create-agencies-success',
                theme: 'success',
                content: 'Карточка Ведомства успешна создана!',
              });
              queryClient.invalidateQueries({ queryKey: ['/agencies/all'] });
              afterCreate?.(createdAgency);

              setAgenciesCreation(undefined);
              onClose();
            })
            .catch(() => {
              add({
                name: 'create-agencies-error',
                theme: 'danger',
                content: 'Ошибка при создании карточки Ведомства',
              });
            });
        },
        onInvalid: errors => {
          console.log('@errors', errors);
        },
      }}
      renderForm={formComponent =>
        withDialog ? (
          <CreateHandbookDialog
            onClose={onClose}
            open={open}
            title="Создание карточки Ведомства"
          >
            {formComponent}
          </CreateHandbookDialog>
        ) : (
          <RightSidebar
            open={open}
            onClose={onClose}
          >
            <RightSidebar.Header
              title="Создание карточки Ведомства"
              onClose={onClose}
            />
            {formComponent}
          </RightSidebar>
        )
      }
    />
  );
}
