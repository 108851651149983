import { TabsItemProps } from '@gravity-ui/uikit';

import { MediaItemProps } from '@entities';
import {
  ChatSettingsModalProps,
  CURRENT_USER_ID_MOCK,
  Dialog,
  PersonMessageGroup,
  ProjectMessageGroup,
} from '@widgets';
import avatarImageMockUrl from '@shared/assets/images/avatar-mock-image.png?url';
import botAvatarImageMockUrl from '@shared/assets/images/bot-avatar-mock-image.png?url';
import mediaImageMockUrl from '@shared/assets/images/media-mock-image.png?url';

export const PERSON_MESSAGE_GROUPS_MOCK: PersonMessageGroup[] = [
  {
    dateTimestamp: new Date('07/27/2024').getTime(),
    messages: [
      {
        id: '1',
        status: 'readed',
        sendedTimestamp: new Date('07/27/2024').getTime(),
        type: 'user',
        user: {
          id: CURRENT_USER_ID_MOCK,
          name: 'Пользователь',
          avatar: {
            name: 'Пользователь',
            src: '',
          },
        },
        contentType: 'text',
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
      },
      {
        id: '2',
        status: 'readed',
        sendedTimestamp: new Date('07/27/2024 11:53').getTime(),
        type: 'user',
        user: {
          id: CURRENT_USER_ID_MOCK,
          name: 'Пользователь',
          avatar: {
            name: 'Пользователь',
            src: '',
          },
        },
        contentType: 'text',
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
      },
      {
        id: '3',
        status: 'readed',
        sendedTimestamp: new Date('07/27/2024 11:55').getTime(),
        type: 'user',
        user: {
          id: CURRENT_USER_ID_MOCK,
          name: 'Пользователь',
          avatar: {
            name: 'Пользователь',
            src: '',
          },
        },
        contentType: 'text',
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
      },
      {
        id: '4',
        status: 'readed',
        sendedTimestamp: new Date('07/27/2024 11:59').getTime(),
        type: 'user',
        user: {
          id: '1',
          name: 'Пользователь 2',
          avatar: {
            name: 'Пользователь 2',
            src: '',
          },
        },
        contentType: 'text',
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
      },
      {
        id: '5',
        status: 'readed',
        sendedTimestamp: new Date('07/27/2024 12:03').getTime(),
        type: 'user',
        user: {
          id: '1',
          name: 'Пользователь 2',
          avatar: {
            name: 'Пользователь 2',
            src: '',
          },
        },
        contentType: 'text',
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
      },
    ],
  },
  {
    dateTimestamp: Date.now(),
    messages: [
      {
        id: '6',
        status: 'readed',
        sendedTimestamp: Date.now(),
        type: 'user',
        user: {
          id: CURRENT_USER_ID_MOCK,
          name: 'Пользователь',
          avatar: {
            name: 'Пользователь',
            src: '',
          },
        },
        contentType: 'text',
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
      },
      {
        id: '7',
        status: 'readed',
        sendedTimestamp: Date.now(),
        type: 'user',
        user: {
          id: CURRENT_USER_ID_MOCK,
          name: 'Пользователь',
          avatar: {
            name: 'Пользователь',
            src: '',
          },
        },
        contentType: 'text',
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
      },
      {
        id: '8',
        status: 'readed',
        sendedTimestamp: Date.now(),
        type: 'user',
        user: {
          id: CURRENT_USER_ID_MOCK,
          name: 'Пользователь',
          avatar: {
            name: 'Пользователь',
            src: '',
          },
        },
        contentType: 'text',
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
      },
      {
        id: '9',
        status: 'readed',
        sendedTimestamp: Date.now(),
        type: 'user',
        user: {
          id: '1',
          name: 'Пользователь 2',
          avatar: {
            name: 'Пользователь 2',
            src: '',
          },
        },
        contentType: 'text',
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
      },
      {
        id: '10',
        status: 'readed',
        sendedTimestamp: Date.now(),
        type: 'user',
        user: {
          id: '1',
          name: 'Пользователь 2',
          avatar: {
            name: 'Пользователь 2',
            src: '',
          },
        },
        contentType: 'text',
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
      },
    ],
  },
];

export const PROJECT_MESSAGE_GROUPS_MOCK: ProjectMessageGroup[] = [
  {
    dateTimestamp: new Date('07/27/2024').getTime(),
    stage: 'OFFERS',
    messages: [
      {
        type: 'user',
        contentType: 'text',
        id: '1',
        user: {
          id: '2',
          name: 'Артем Аментес',
          avatar: {
            name: 'Артем Аментес',
            src: avatarImageMockUrl,
          },
        },
        sendedTimestamp: new Date('07/27/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        status: 'readed',
      },
      {
        type: 'user',
        contentType: 'text',
        id: '2',
        user: {
          id: '2',
          name: 'Артем Аментес',
          avatar: {
            name: 'Артем Аментес',
            src: avatarImageMockUrl,
          },
        },
        sendedTimestamp: new Date('07/27/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        status: 'readed',
      },
      {
        type: 'user',
        contentType: 'text',
        id: '3',
        user: {
          id: '2',
          name: 'Артем Аментес',
          avatar: {
            name: 'Артем Аментес',
            src: avatarImageMockUrl,
          },
        },
        sendedTimestamp: new Date('07/27/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        status: 'readed',
      },
      {
        type: 'user',
        contentType: 'text',
        id: '4',
        user: {
          id: '2',
          name: 'Артем Аментес',
          avatar: {
            name: 'Артем Аментес',
            src: avatarImageMockUrl,
          },
        },
        sendedTimestamp: new Date('07/27/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        status: 'readed',
      },
      {
        type: 'user',
        contentType: 'file',
        id: '5',
        user: {
          id: '2',
          name: 'Артем Аментес',
          avatar: {
            name: 'Артем Аментес',
            src: avatarImageMockUrl,
          },
        },
        sendedTimestamp: new Date('07/27/2024 22:36').getTime(),
        status: 'readed',
        file: {
          name: 'КП для Димитрова И.С.',
          size: 24000000,
          extension: 'pdf',
        },
      },
      {
        type: 'user',
        contentType: 'image',
        id: '6',
        user: {
          id: '2',
          name: 'Артем Аментес',
          avatar: {
            name: 'Артем Аментес',
            src: avatarImageMockUrl,
          },
        },
        sendedTimestamp: new Date('07/27/2024 22:36').getTime(),
        status: 'readed',
        imageSrc: mediaImageMockUrl,
      },
      {
        type: 'bot',
        contentType: 'text',
        id: '7',
        bot: {
          id: '1',
          name: 'Bot',
          avatar: {
            name: 'Bot',
            src: botAvatarImageMockUrl,
          },
        },
        sendedTimestamp: new Date('07/27/2024 22:36').getTime(),
        status: 'readed',
        text: 'Текст сообщения',
        variant: 'good',
      },
      {
        type: 'bot',
        contentType: 'text',
        id: '8',
        bot: {
          id: '1',
          name: 'Bot',
          avatar: {
            name: 'Bot',
            src: botAvatarImageMockUrl,
          },
        },
        sendedTimestamp: new Date('07/27/2024 22:36').getTime(),
        status: 'readed',
        text: 'Текст сообщения',
        variant: 'danger',
      },
      {
        type: 'bot',
        contentType: 'text',
        id: '9',
        bot: {
          id: '1',
          name: 'Bot',
          avatar: {
            name: 'Bot',
            src: botAvatarImageMockUrl,
          },
        },
        sendedTimestamp: new Date('07/27/2024 22:36').getTime(),
        status: 'readed',
        text: 'Текст сообщения',
        variant: 'normal',
      },
      {
        type: 'bot',
        contentType: 'text',
        id: '10',
        bot: {
          id: '1',
          name: 'Bot',
          avatar: {
            name: 'Bot',
            src: botAvatarImageMockUrl,
          },
        },
        sendedTimestamp: new Date('07/27/2024 22:36').getTime(),
        status: 'readed',
        text: 'Текст сообщения',
        variant: 'alert',
      },
      {
        id: '25',
        type: 'server',
        contentType: 'action',
        action: 'КП -> Торги',
        sendedTimestamp: new Date('07/27/2024 22:36').getTime(),
        status: 'readed',
      },
    ],
  },
  {
    dateTimestamp: new Date('07/28/2024').getTime(),
    stage: 'TENDERS',
    messages: [
      {
        type: 'user',
        contentType: 'text',
        id: '11',
        user: {
          id: '1',
          name: 'Артем Аментес',
          avatar: {
            name: 'Артем Аментес',
            src: avatarImageMockUrl,
          },
        },
        sendedTimestamp: new Date('07/28/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        status: 'readed',
      },
      {
        type: 'user',
        contentType: 'text',
        id: '12',
        user: {
          id: '1',
          name: 'Артем Аментес',
          avatar: {
            name: 'Артем Аментес',
            src: avatarImageMockUrl,
          },
        },
        sendedTimestamp: new Date('07/28/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        status: 'readed',
      },
      {
        type: 'user',
        contentType: 'text',
        id: '13',
        user: {
          id: '2',
          name: 'Артем Аментес',
          avatar: {
            name: 'Артем Аментес',
            src: avatarImageMockUrl,
          },
        },
        sendedTimestamp: new Date('07/28/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        status: 'readed',
      },
      {
        type: 'user',
        contentType: 'text',
        id: '14',
        user: {
          id: '2',
          name: 'Артем Аментес',
          avatar: {
            name: 'Артем Аментес',
            src: avatarImageMockUrl,
          },
        },
        sendedTimestamp: new Date('07/28/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        status: 'readed',
      },
      {
        id: '24',
        type: 'server',
        contentType: 'action',
        action: 'Торги -> Контракты',
        sendedTimestamp: new Date('07/27/2024 22:36').getTime(),
        status: 'readed',
      },
    ],
  },
  {
    dateTimestamp: new Date('07/28/2024').getTime(),
    stage: 'CONTRACT',
    messages: [
      {
        type: 'user',
        contentType: 'text',
        id: '15',
        user: {
          id: '1',
          name: 'Артем Аментес',
          avatar: {
            name: 'Артем Аментес',
            src: avatarImageMockUrl,
          },
        },
        sendedTimestamp: new Date('07/28/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        status: 'readed',
      },
      {
        type: 'user',
        contentType: 'text',
        id: '16',
        user: {
          id: '1',
          name: 'Артем Аментес',
          avatar: {
            name: 'Артем Аментес',
            src: avatarImageMockUrl,
          },
        },
        sendedTimestamp: new Date('07/28/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        status: 'readed',
      },
      {
        type: 'user',
        contentType: 'text',
        id: '17',
        user: {
          id: '2',
          name: 'Артем Аментес',
          avatar: {
            name: 'Артем Аментес',
            src: avatarImageMockUrl,
          },
        },
        sendedTimestamp: new Date('07/28/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        status: 'readed',
      },
      {
        id: '23',
        type: 'server',
        contentType: 'action',
        action: 'Сергей Петров изменил адрес поставки «Монино» —> «Санкт-Петербург»',
        sendedTimestamp: new Date('07/27/2024 22:36').getTime(),
        status: 'readed',
      },
      {
        type: 'user',
        contentType: 'text',
        id: '18',
        user: {
          id: '2',
          name: 'Артем Аментес',
          avatar: {
            name: 'Артем Аментес',
            src: avatarImageMockUrl,
          },
        },
        sendedTimestamp: new Date('07/28/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        status: 'readed',
      },
    ],
  },
  {
    dateTimestamp: Date.now(),
    stage: 'CONTRACT',
    messages: [
      {
        type: 'user',
        contentType: 'text',
        id: '19',
        user: {
          id: '1',
          name: 'Артем Аментес',
          avatar: {
            name: 'Артем Аментес',
            src: avatarImageMockUrl,
          },
        },
        sendedTimestamp: Date.now(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        status: 'readed',
      },
      {
        type: 'user',
        contentType: 'text',
        id: '20',
        user: {
          id: '1',
          name: 'Артем Аментес',
          avatar: {
            name: 'Артем Аментес',
            src: avatarImageMockUrl,
          },
        },
        sendedTimestamp: Date.now(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        status: 'readed',
      },
      {
        type: 'user',
        contentType: 'text',
        id: '21',
        user: {
          id: '2',
          name: 'Артем Аментес',
          avatar: {
            name: 'Артем Аментес',
            src: avatarImageMockUrl,
          },
        },
        sendedTimestamp: Date.now(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        status: 'readed',
      },
      {
        type: 'user',
        contentType: 'text',
        id: '22',
        user: {
          id: '2',
          name: 'Артем Аментес',
          avatar: {
            name: 'Артем Аментес',
            src: avatarImageMockUrl,
          },
        },
        sendedTimestamp: Date.now(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        status: 'readed',
      },
    ],
  },
];

export const TABS_MOCK: TabsItemProps[] = [
  {
    id: 'all',
    title: 'Все диалоги',
    counter: 999,
  },
  {
    id: 'peoples',
    title: 'Люди',
    counter: 999,
  },
  {
    id: 'projects',
    title: 'Проекты',
    counter: 999,
  },
  {
    id: 'commercial-offers',
    title: 'КП',
    counter: 999,
  },
  {
    id: 'bargaining',
    title: 'Торги',
    counter: 999,
  },
  {
    id: 'contracts',
    title: 'Контракты',
    counter: 999,
  },
];

export const DIALOGS_MOCK: Dialog[] = [
  {
    id: '1',
    projectName: 'Орион — ООО Димитров Иван Сергеевич',
    lastMessageText: 'Последнее сообщение',
    lastMessageTimestamp: '2024-08-31T14:12:13.000Z',
    lastMessageSender: 'Иванов Иван Петрович',
    stage: 'OFFERS',
    type: 'project',
    membersCount: 999,
  },
  {
    type: 'person',
    online: true,
    id: '2',
    lastMessageText: 'Последнее сообщение',
    lastMessageTimestamp: '2024-08-31T13:12:13.000Z',
    name: 'Артем Аментес',
    avatar: {
      name: 'Артем Аментес',
      src: avatarImageMockUrl,
    },
  },
  {
    type: 'person',
    online: false,
    id: '3',
    lastMessageText: 'Последнее сообщение',
    lastMessageTimestamp: '2024-08-31T12:12:13.000Z',
    name: 'Артем Аментес',
    avatar: {
      name: 'Артем Аментес',
      src: 'invalid-link',
    },
  },
];

export const SETTINGS_MEDIA_MOCK: MediaItemProps[] = Array.from({ length: 30 }, () => ({
  createdAt: Date.now(),
  createdBy: 'Аментес А.В.',
  extension: 'jpeg',
  imageName: 'Изображение от Аментес А.В.',
  imageUrl: mediaImageMockUrl,
  name: 'Название медиа',
  size: 234000,
}));

export const SETTINGS_USERS_MOCK = [
  { name: 'Сергей Петров' },
  { name: 'Артем Аментес' },
  { name: 'Константин Фабин' },
  { name: 'Елизавета Кудрявцева' },
  { name: 'Сергей Петров' },
  { name: 'Артем Аментес' },
  { name: 'Константин Фабин' },
  { name: 'Елизавета Кудрявцева' },
  { name: 'Сергей Петров' },
  { name: 'Артем Аментес' },
  { name: 'Константин Фабин' },
  { name: 'Елизавета Кудрявцева' },
];

export const SETTINGS_HISTORY_MOCK: ChatSettingsModalProps['history'] = [
  {
    variant: 'default',
    title: 'Статус',
    description: 'КП —> Торги',
    author: 'Сергей Петров',
    timestamp: new Date('03/18/24').getTime(),
  },
  {
    variant: 'default',
    title: 'Срок поставки',
    description: '24.02.24 —> 01.03.24',
    author: 'Артем Аментес',
    timestamp: new Date('01/24/24').getTime(),
  },
  {
    variant: 'danger',
    title: 'Просрочен «срок поставки»',
    description: '24.02.24',
    timestamp: new Date('01/24/24').getTime(),
  },
  {
    variant: 'default',
    title: 'Добавлен участник',
    description: 'Артем Аментес',
    author: 'Ильдар Матвеев',
    timestamp: new Date('11/12/23').getTime(),
  },
  {
    variant: 'default',
    title: 'Добавлен участник',
    description: 'Добавлен участник',
    author: 'Ильдар Матвеев',
    timestamp: new Date('11/12/23').getTime(),
  },
  {
    variant: 'default',
    title: 'Статус',
    description: 'Ирина Сергеева',
    author: 'Ильдар Матвеев',
    timestamp: new Date('11/12/23').getTime(),
  },
];
