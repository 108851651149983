import { ColumnsFilters } from '@features';

import { ColumnsContractsTable } from '../model';

export const TABLE_COLUMNS_WITH_FILTER: Record<ColumnsContractsTable[number], ColumnsFilters> = {
  'Дата, номер': 'date',
  Наименование: 'search',
  Основание: 'select',
  ФЗ: 'select',
  'В рамках ГОЗ по 275-ФЗ': 'select',
  Сопровождение: 'select',
  'Уникальный номер': 'search',
  Этап: 'select',
  'Причина расторжения': 'search',
  Ответственный: 'select',
  Заказчик: 'select',
  'Приоритет заказчика': 'priority',
  Поставщик: 'select',
  'Объект торгов': 'select',
  Комментарий: 'search',
  Дни: 'select',
  'Фактический срок оплаты с момента оплаты': 'search',
  'Сумма контракта': 'currency',
  'Номер контракта': 'search',
  'Дата подписания': 'date',
  'Срок действия контракта': 'date',
  'Реестровый номер контракта': 'search',
  Площадка: 'select',
  'КП/Тендер': 'select',
  'Регион поставки': 'select',
  'Адрес поставки': 'search',
  'Срок поставки': 'search',
  'Актуальный файл расчета': 'boolean',
  'Файл основания (контракт, обеспечение, доп.соглашение/договор/счет)': 'boolean',
  'Есть преимущество/ограничение для СМП?': 'boolean',
  'Обеспечение исполнения контракта есть': 'boolean',
  'Сумма обеспечения исполнения': 'currency',
  'Вид обеспечения исполнения': 'select',
  'Срок обеспечения исполнения': 'date',
  'Обеспечение гарантийных обязательств есть': 'boolean',
  'Сумма обеспечения гарантийных обязательств': 'currency',
  'Вид обеспечения гарантийных обязательств': 'select',
  'Срок обеспечения гарантийных обязательств': 'date',
  'Прогресс исполнения': 'number',
  'Лимит контракта': 'currency',
  'Сумма отгрузок, с НДС': 'currency',
  'Сумма отгрузок, без НДС': 'currency',
  'Сумма НДС': 'currency',
  'Сумма закупок': 'currency',
  'Остаток лимита': 'currency',
  'Сумма оплаты': 'currency',
  'Сумма ДЗ': 'currency',
  'В т.ч. Сумма ПДЗ': 'currency',
};
