import { bodyResponseType } from '@shared/api';

import { CreateETradingPlatforms } from './CreateETradingPlatforms';
import { EditETradingPlatforms } from './EditETradingPlatforms';

type ETradingPlatformsDrawerProps = {
  onClose: () => void;
  withDialog?: boolean;
} & (
  | {
      type: 'create';
      open: boolean;
      id?: undefined;
      afterCreate?: (
        createdETradingPlatform: bodyResponseType<'post', '/handbook/e-trading-platforms'>
      ) => void;
    }
  | {
      type: 'edit';
      open?: undefined;
      id?: string;
      afterCreate?: undefined;
    }
);

export function ETradingPlatformsDrawer({
  type,
  open,
  onClose,
  id,
  afterCreate,
  withDialog,
}: ETradingPlatformsDrawerProps) {
  return (
    <>
      {type === 'create' ? (
        <CreateETradingPlatforms
          open={open}
          onClose={onClose}
          afterCreate={afterCreate}
          withDialog={withDialog}
        />
      ) : (
        <EditETradingPlatforms
          onClose={onClose}
          id={id}
        />
      )}
    </>
  );
}
