import { useSetAtom } from 'jotai';
import { ReactNode, useState } from 'react';

import {
  createdCustomerForViewAtom,
  createdEmployeeForViewAtom,
  createdETradingPlatformForViewAtom,
  createdPurchaseObjectForViewAtom,
  createdRegionForViewAtom,
  createdSupplierForViewAtom,
} from '@entities';
import { ContractForm, ContractFormProps } from '@features';
import { CustomersDrawer } from '@widgets/custom-handbook-drawers/customers-drawer/@x';
import { ETradingPlatformsDrawer } from '@widgets/custom-handbook-drawers/e-trading-platforms-drawer/@x';
import { EmployeesDrawer } from '@widgets/custom-handbook-drawers/employees-drawer/@x';
import { PurchaseObjectsDrawer } from '@widgets/custom-handbook-drawers/purchase-objects-drawer/@x';
import { RegionsDrawer } from '@widgets/custom-handbook-drawers/regions-drawer/@x';
import { SuppliersDrawer } from '@widgets/custom-handbook-drawers/suppliers-drawer/@x';

interface ContractLayoutProps {
  renderForm: (formComponent: ReactNode) => ReactNode;
  formProps: ContractFormProps;
}

export function ContractLayout({ renderForm, formProps }: ContractLayoutProps) {
  const setCreatedEmployeeForView = useSetAtom(createdEmployeeForViewAtom);
  const setCreatedCustomerForView = useSetAtom(createdCustomerForViewAtom);
  const setCreatedSupplierForView = useSetAtom(createdSupplierForViewAtom);
  const setCreatedPurchaseObjectForView = useSetAtom(createdPurchaseObjectForViewAtom);
  const setCreatedETradingPlatformForView = useSetAtom(createdETradingPlatformForViewAtom);
  const setCreatedRegionForView = useSetAtom(createdRegionForViewAtom);

  const [createEmployeesOpen, setCreateEmployeesOpen] = useState(false);
  const [createCustomersOpen, setCreateCustomersOpen] = useState(false);
  const [createSuppliersOpen, setCreateSuppliersOpen] = useState(false);
  const [createPurchaseObjectsOpen, setCreatePurchaseObjectsOpen] = useState(false);
  const [createdETradingPlatformOpen, setCreatedETradingPlatformOpen] = useState(false);
  const [createRegionsOpen, setCreateRegionsOpen] = useState(false);

  return (
    <>
      {renderForm(
        <ContractForm
          {...formProps}
          onClickCreateEmployeesHandbook={() => setCreateEmployeesOpen(true)}
          onClickCreateCustomersHandbook={() => setCreateCustomersOpen(true)}
          onClickCreateSuppliersHandbook={() => setCreateSuppliersOpen(true)}
          onClickCreatePurchaseObjectsHandbook={() => setCreatePurchaseObjectsOpen(true)}
          onClickCreateETradingPlatformsHandbook={() => setCreatedETradingPlatformOpen(true)}
          onClickCreateRegionsHandbook={() => setCreateRegionsOpen(true)}
        />
      )}
      <EmployeesDrawer
        withDialog
        type="create"
        open={createEmployeesOpen}
        onClose={() => setCreateEmployeesOpen(false)}
        afterCreate={values => {
          setCreatedEmployeeForView({
            employee_value: values.name_initials,
            employee_id: values.id,
          });
        }}
      />
      <CustomersDrawer
        withDialog
        type="create"
        open={createCustomersOpen}
        onClose={() => setCreateCustomersOpen(false)}
        afterCreate={values => {
          setCreatedCustomerForView({
            customer_value: values.name,
            customer_id: values.id,
          });
        }}
      />
      <RegionsDrawer
        withDialog
        type="create"
        open={createRegionsOpen}
        onClose={() => setCreateRegionsOpen(false)}
        afterCreate={values => {
          setCreatedRegionForView({
            region_value: values.name,
            region_id: values.id,
          });
        }}
      />
      <PurchaseObjectsDrawer
        withDialog
        type="create"
        open={createPurchaseObjectsOpen}
        onClose={() => setCreatePurchaseObjectsOpen(false)}
        afterCreate={values => {
          setCreatedPurchaseObjectForView({
            purchaseObject_value: values.object,
            purchaseObject_id: values.id,
          });
        }}
      />
      <ETradingPlatformsDrawer
        withDialog
        type="create"
        open={createdETradingPlatformOpen}
        onClose={() => setCreatedETradingPlatformOpen(false)}
        afterCreate={values => {
          setCreatedETradingPlatformForView({
            eTradingPlatform_value: values.name,
            eTradingPlatform_id: values.id,
          });
        }}
      />
      <SuppliersDrawer
        withDialog
        type="create"
        open={createSuppliersOpen}
        onClose={() => setCreateSuppliersOpen(false)}
        afterCreate={values => {
          setCreatedSupplierForView({
            supplier_value: values.name,
            supplier_id: values.id,
          });
        }}
      />
    </>
  );
}
