import { OrganizationType } from '@entities';
import { components } from '@shared/api';
import { yupRu } from '@shared/config';
import { schemaFields } from '@shared/lib';

type ContactStatus = NonNullable<components['schemas']['handbook.Contact']['status']>;
type ContactTypeContact = NonNullable<components['schemas']['handbook.Contact']['type']>;

export const contactsSchema = yupRu.object({
  /** @description Комментарий @gotags: validate:"min_len:3|max_len:255" */
  comment: schemaFields.textArea(),
  /** @description Эл. адрес @gotags: validate:"email" */
  email: schemaFields.email(),
  /** @description ФИО @gotags: validate:"required|min_len:3|max_len:255" */
  full_name: schemaFields.text().required(),
  /** @description Идентификатор организации */
  organization_id: yupRu.string().transform(value => value || undefined),
  /** @description Тип организации  */
  organization_type: schemaFields.radio<OrganizationType>(['customer', 'legal_entity', 'supplier']),
  organization_with_type: yupRu
    .string<`${string}/${OrganizationType}`>()
    .transform(value => value || undefined),
  /** @description Телефон @gotags: validate:"required|min_len:3|max_len:255" */
  phone: schemaFields.phone().required(),
  /** @description Должность @gotags: validate:"min_len:3|max_len:255" */
  position: schemaFields.text(),
  /**
   * @description Статус контактного лица @gotags: enums:"Ассистент менеджера,Менеджер,Бухгалтерия,Доставка,Иное,Руководитель менеджера,Склад"
   * @enum {string}
   */
  status: schemaFields.radio<ContactStatus>([
    'Ассистент менеджера',
    'Менеджер',
    'Бухгалтерия',
    'Доставка',
    'Иное',
    'Руководитель менеджера',
    'Склад',
  ]),
  /**
   * @description Тип контактного лица @gotags: enums:"ГОССНАБ,Заказчик,Поставщик"
   * @enum {string}
   */
  type: schemaFields.radio<ContactTypeContact>(['ГОССНАБ', 'Заказчик', 'Поставщик']),
});

export type ContactsSchema = yupRu.InferType<typeof contactsSchema>;
