import { dateTime } from '@gravity-ui/date-utils';
import { Text } from '@gravity-ui/uikit';

import { ProcessItem } from '@entities';
import { bodyResponseType } from '@shared/api';
import { DATE_FORMATS, URLS } from '@shared/consts';

const parseDate = (date: string, withTime?: boolean) =>
  dateTime({ input: date }).format(
    !withTime ? DATE_FORMATS.fullDate : DATE_FORMATS.fullDateWithTimeRounded
  );

export function CommercialOfferRelations({
  relations,
  offer,
}: {
  relations: bodyResponseType<'get', '/offer/related/{id}'>;
  offer: bodyResponseType<'get', '/offer/{id}'>;
}) {
  if (relations)
    return (
      <div className="flex flex-col px-8 overflow-y-scroll hide-scrollbar">
        {relations?.coordinations &&
          relations.coordinations.some(obj => Object.keys(obj).length) && (
            <div className="pb-8 flex flex-col">
              <Text
                variant="subheader-2"
                color="primary"
                className="mb-2"
              >
                Согласования
              </Text>
              {relations.coordinations.map(({ id, created_at }, index) => (
                <ProcessItem
                  key={index}
                  dateAndNumber={`${parseDate(created_at, true)}, №${id}`}
                  link={URLS.processes.agreements + '/' + id}
                  name={offer.title}
                />
              ))}
            </div>
          )}
        {relations?.contracts && relations.contracts.some(obj => Object.keys(obj).length) && (
          <div className="pb-8 flex flex-col">
            <Text
              variant="subheader-2"
              color="primary"
              className="mb-2"
            >
              Контракты
            </Text>
            {relations.contracts.map(({ id, created_at, name }, index) => (
              <ProcessItem
                key={index}
                dateAndNumber={created_at ? `${parseDate(created_at, true)}, №${id}` : name}
                link={URLS.processes.contracts + '/' + id}
                name={name}
              />
            ))}
          </div>
        )}
        {relations?.tender && Object.keys(relations.tender).length && (
          <div className="pb-8 flex flex-col">
            <Text
              variant="subheader-2"
              color="primary"
              className="mb-2"
            >
              Торги
            </Text>
            {relations.tender && (
              <ProcessItem
                dateAndNumber={
                  relations.tender.created_at
                    ? `${parseDate(relations.tender.created_at, true)}, №${relations.tender.id}`
                    : relations.tender.title
                }
                link={URLS.processes.tenders + '/' + relations.tender.id}
                name={relations.tender.title}
              />
            )}
          </div>
        )}
      </div>
    );
}
