import { Button, useToaster } from '@gravity-ui/uikit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { $departmentsHooks, $employeesHooks } from '@entities';
import { bodyResponseType } from '@shared/api';
import { DATE_FORMATS, MAX_FILE_SIZE, NOT_ALLOWED_FILE_EXTENSIONS } from '@shared/consts';
import { cn, useFieldsWithPreview } from '@shared/lib';
import { FieldWithPreview, RightSidebar } from '@shared/ui';

import { EMPLOYEES_OPTIONS } from '../consts';
import { employeesSchema, EmployeesSchema } from '../model';

export interface EmployeesFormProps {
  onValid: SubmitHandler<EmployeesSchema>;
  onValuesChange?: (values: EmployeesSchema) => void;
  response?: bodyResponseType<'get', '/handbook/employees/{id}'>;
  onInvalid?: SubmitErrorHandler<EmployeesSchema>;
  onClickCancelButton?: () => void;
  initialEditable?: boolean;
  disabled?: boolean;
  hideBottomPanel?: boolean;
  rightSidebarButtons?: boolean;
  defaultValues?: EmployeesSchema;
  cancelButtonText?: string;
  submitButtonText?: string;
  compactBottomPanel?: boolean;
}

export function EmployeesForm({
  onValid,
  onValuesChange,
  response,
  onInvalid,
  initialEditable = false,
  onClickCancelButton,
  disabled = false,
  hideBottomPanel = false,
  defaultValues,
  cancelButtonText = 'Отмена',
  submitButtonText = 'Применить',
  compactBottomPanel = false,
}: EmployeesFormProps) {
  const { add } = useToaster();

  const form = useForm<EmployeesSchema>({
    resolver: yupResolver(employeesSchema),
    mode: 'all',
    defaultValues,
    resetOptions: {
      keepDirtyValues: true,
      keepDefaultValues: false,
    },
  });

  const { createFieldRef, isFieldEditable, selectField, selectedFieldPath, resetSelectedField } =
    useFieldsWithPreview({
      form: form,
      initialEditable,
      disabled,
    });

  const values = form.watch();

  // Queries
  const [filterDepartments, setFilterDepartments] = useState('');
  const getHandbookDepartmentsInfiniteQuery = $departmentsHooks.useGetAll(
    {
      query: filterDepartments,
      limit: 30,
    },
    selectedFieldPath === 'department_id'
  );
  const flattedDepartments = useMemo(
    () => getHandbookDepartmentsInfiniteQuery.data?.pages.flat(),
    [getHandbookDepartmentsInfiniteQuery.data]
  );

  const [filterEmployees, setFilterEmployees] = useState('');
  const getHandbookEmployeesInfiniteQuery = $employeesHooks.useGetAll(
    {
      query: filterEmployees,
      limit: 30,
    },
    selectedFieldPath === 'direct_subordinate_ids' ||
      selectedFieldPath === 'indirect_subordinate_ids'
  );
  const flattedEmployees = useMemo(
    () => getHandbookEmployeesInfiniteQuery.data?.pages.flat(),
    [getHandbookEmployeesInfiniteQuery.data]
  );
  //

  const [fileFieldsLoading, setFileFieldsLoading] = useState<
    Record<Extract<typeof selectedFieldPath, 'photo'>, boolean>
  >({ photo: false });

  const isFilesLoading = Object.values(fileFieldsLoading).some(
    fileFieldLoading => fileFieldLoading
  );

  useDeepCompareEffect(() => {
    onValuesChange?.(values);
  }, [values]);

  useDeepCompareEffect(() => {
    if (response) form.reset(response);
  }, [response || {}]);

  useEffect(() => {
    if (Object.values(form.formState.errors).length > 0 && form.formState.isSubmitted)
      add({
        title: 'Не все обязательные поля заполнены',
        name: 'validation-fields-error',
        theme: 'warning',
      });
  }, [form.formState.submitCount]);

  return (
    <form
      className="flex flex-col overflow-hidden grow"
      onSubmit={form.handleSubmit(onValid, onInvalid)}
    >
      <FieldWithPreview>
        <FieldWithPreview.Text
          ref={createFieldRef('name_initials')}
          control={form.control}
          path="name_initials"
          name="Фамилия И.О."
          edit={isFieldEditable('name_initials')}
          onFieldSelect={() => selectField('name_initials')}
          textInputProps={{ disabled }}
          required
        />
        <FieldWithPreview.Text
          ref={createFieldRef('full_name')}
          control={form.control}
          path="full_name"
          name="ФИО полностью"
          edit={isFieldEditable('full_name')}
          onFieldSelect={() => selectField('full_name')}
          textInputProps={{ disabled }}
          required
        />
        <FieldWithPreview.Radio
          ref={createFieldRef('type')}
          control={form.control}
          edit={isFieldEditable('type')}
          name="Тип"
          options={EMPLOYEES_OPTIONS['type']}
          defaultOption={
            response?.type ? { value: response.type, content: response.type } : undefined
          }
          path="type"
          resetSelectedField={resetSelectedField}
          onFieldSelect={() => selectField('type')}
          radioButtonProps={{ disabled }}
        />
        <FieldWithPreview.File
          control={form.control}
          path="photo"
          name="Фото"
          edit={isFieldEditable('photo')}
          ref={createFieldRef('photo')}
          onFieldSelect={() => selectField('photo')}
          maxSize={MAX_FILE_SIZE}
          notAllowedExtension={NOT_ALLOWED_FILE_EXTENSIONS}
          acceptExtensions="image/*"
          disabled={disabled}
          onLoadingChange={loading => setFileFieldsLoading(prev => ({ ...prev, photo: loading }))}
        />
        <FieldWithPreview.Text
          ref={createFieldRef('position')}
          control={form.control}
          path="position"
          name="Должность"
          edit={isFieldEditable('position')}
          onFieldSelect={() => selectField('position')}
          textInputProps={{ disabled }}
        />
        <FieldWithPreview.Select
          control={form.control}
          path="department_id"
          name="Подразделение"
          edit={isFieldEditable('department_id')}
          ref={createFieldRef('department_id')}
          onFieldSelect={() => selectField('department_id')}
          options={
            flattedDepartments?.map(department => ({
              content: department.name,
              value: department.id,
            })) || []
          }
          defaultOptions={
            response?.department_id
              ? [
                  {
                    content: response.department_value,
                    value: response.department_id,
                  },
                ]
              : []
          }
          filter={filterDepartments}
          onFilterChange={filter => setFilterDepartments(filter)}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            disabled,
            placeholder: 'Выбрать',
            loading:
              getHandbookDepartmentsInfiniteQuery.isFetching ||
              getHandbookDepartmentsInfiniteQuery.isFetchingNextPage ||
              getHandbookDepartmentsInfiniteQuery.isLoading ||
              getHandbookDepartmentsInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookDepartmentsInfiniteQuery.fetchNextPage();
            },
          }}
        />
        <FieldWithPreview.Text
          ref={createFieldRef('rank')}
          control={form.control}
          path="rank"
          name="Разряд"
          edit={isFieldEditable('rank')}
          onFieldSelect={() => selectField('rank')}
          textInputProps={{ disabled }}
        />
        {/* Заместо доступа */}
        <FieldWithPreview.Date
          control={form.control}
          path="blocked_at"
          name="Блокировка/Ограничен доступ"
          edit={false}
          ref={createFieldRef('blocked_at')}
          onFieldSelect={() => selectField('blocked_at')}
          format={DATE_FORMATS.fullDateWithTime}
          formatPreview={DATE_FORMATS.fullDateWithTime}
          datePickerProps={{
            disabled: true,
            placeholder: 'Дата и время',
          }}
        />
        <FieldWithPreview.Phone
          ref={createFieldRef('mobile_phone')}
          control={form.control}
          path="mobile_phone"
          name="Мобильный телефон"
          edit={isFieldEditable('mobile_phone')}
          onFieldSelect={() => selectField('mobile_phone')}
          patternFormatProps={{ disabled }}
        />
        <FieldWithPreview.Phone
          ref={createFieldRef('work_phone')}
          control={form.control}
          path="work_phone"
          name="Рабочий телефон"
          edit={isFieldEditable('work_phone')}
          onFieldSelect={() => selectField('work_phone')}
          patternFormatProps={{ disabled }}
        />
        <FieldWithPreview.Text
          ref={createFieldRef('email')}
          control={form.control}
          path="email"
          name="Электронная почта"
          edit={isFieldEditable('email')}
          onFieldSelect={() => selectField('email')}
          textInputProps={{ disabled }}
          required
        />
        <FieldWithPreview.TextArea
          ref={createFieldRef('note')}
          control={form.control}
          path="note"
          name="Заметка"
          edit={isFieldEditable('note')}
          onFieldSelect={() => selectField('note')}
          wrapperProps={{ textAlign: 'start' }}
          textAreaProps={{ disabled }}
        />
        <FieldWithPreview.MultiSelect
          ref={createFieldRef('direct_subordinate_ids')}
          control={form.control}
          path="direct_subordinate_ids"
          edit={isFieldEditable('direct_subordinate_ids')}
          onFieldSelect={() => selectField('direct_subordinate_ids')}
          name="Прямые подчиненные"
          options={
            flattedEmployees?.map(employee => ({
              content: employee?.full_name,
              value: employee.id,
            })) || []
          }
          defaultOptions={response?.direct_subordinate_ids?.map((employee, i) => ({
            content: response?.direct_subordinate_values?.[i],
            value: employee,
          }))}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          filter={filterEmployees}
          onFilterChange={filter => setFilterEmployees(filter)}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
            loading:
              getHandbookEmployeesInfiniteQuery.isFetching ||
              getHandbookEmployeesInfiniteQuery.isFetchingNextPage ||
              getHandbookEmployeesInfiniteQuery.isLoading ||
              getHandbookEmployeesInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookEmployeesInfiniteQuery.fetchNextPage();
            },
          }}
        />
        <FieldWithPreview.MultiSelect
          ref={createFieldRef('indirect_subordinate_ids')}
          control={form.control}
          path="indirect_subordinate_ids"
          edit={isFieldEditable('indirect_subordinate_ids')}
          onFieldSelect={() => selectField('indirect_subordinate_ids')}
          name="Косвенные подчиненные"
          options={
            flattedEmployees?.map(employee => ({
              content: employee?.full_name,
              value: employee.id,
            })) || []
          }
          defaultOptions={response?.indirect_subordinate_ids?.map((employee, i) => ({
            content: response?.indirect_subordinate_values?.[i],
            value: employee,
          }))}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          filter={filterEmployees}
          onFilterChange={filter => setFilterEmployees(filter)}
          selectProps={{
            // placeholder: initialEditable ? 'Заполнится автоматически после создания' : 'Выбрать',
            // disabled: initialEditable,
            disabled,
            loading:
              getHandbookEmployeesInfiniteQuery.isFetching ||
              getHandbookEmployeesInfiniteQuery.isFetchingNextPage ||
              getHandbookEmployeesInfiniteQuery.isLoading ||
              getHandbookEmployeesInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookEmployeesInfiniteQuery.fetchNextPage();
            },
          }}
        />
      </FieldWithPreview>
      {!hideBottomPanel && (
        <RightSidebar.BottomPanel>
          <Button
            className={cn({ 'ml-auto': compactBottomPanel })}
            size="xl"
            view={compactBottomPanel ? 'flat' : 'normal'}
            width={'max'}
            onClick={onClickCancelButton}
            disabled={disabled || isFilesLoading}
          >
            {cancelButtonText}
          </Button>
          <Button
            size="xl"
            view="action"
            type="submit"
            width={'max'}
            onClick={form.handleSubmit(onValid, onInvalid)}
            disabled={disabled || isFilesLoading}
          >
            {submitButtonText}
          </Button>
        </RightSidebar.BottomPanel>
      )}
    </form>
  );
}
