import {
  convertAgreementResultStatusFromBackend,
  convertAgreementTypeFromBackend,
} from '@entities';

export const OPTIONS = {
  reject_reason_offer: [
    { value: 'Иная причина', content: 'Иная причина' },
    { value: 'Не успели рассчитать', content: 'Не успели рассчитать' },
    { value: 'Не успели подготовить заявку', content: 'Не успели подготовить заявку' },
    { value: 'Низкая маржа начальная', content: 'Низкая маржа начальная' },
  ],
  reject_reason_tender: [
    { value: 'Иная причина', content: 'Иная причина' },
    { value: 'Не успели', content: 'Не успели' },
    { value: 'Не рентабельно', content: 'Не рентабельно' },
  ],
  status: [
    {
      value: 'PENDING',
      content: convertAgreementResultStatusFromBackend('PENDING'),
      preview: 'normal',
    } as const,
    {
      value: 'ACCEPTED',
      content: convertAgreementResultStatusFromBackend('ACCEPTED'),
      preview: 'success',
    } as const,
    {
      value: 'REJECTED ',
      content: convertAgreementResultStatusFromBackend('REJECTED'),
      preview: 'danger',
    } as const,
  ],
  coordination_type: [
    { value: 'MIN_PRICE', content: convertAgreementTypeFromBackend('MIN_PRICE') } as const,
    { value: 'OFFER_PRICE', content: convertAgreementTypeFromBackend('OFFER_PRICE') } as const,
    { value: 'PARTICIPATION', content: convertAgreementTypeFromBackend('PARTICIPATION') } as const,
    { value: 'REJECTION', content: convertAgreementTypeFromBackend('REJECTION') } as const,
  ],
  is_proposal_agree: [
    { value: 'Да', content: 'Да', preview: 'success' } as const,
    { value: 'Нет', content: 'Нет', preview: 'normal' } as const,
  ],
};
