import { yupRu } from '@shared/config';
import { CustomBooleanSchema } from '@shared/model';

import { ImplementationMethod, PurchaseStatus, TenderStatus } from '../types';

export const tenderSchema = yupRu.object({
  responsible_id: yupRu.string().required(),
  title: yupRu.string().required(),
  status: yupRu
    .string()
    .oneOf<TenderStatus>([
      'Подача',
      'Ожидание',
      'Выигран',
      'Проигран',
      'Отклонение',
      'Не подано',
      'Отказ',
      'Ожидание',
    ])
    .required(),
  // Отклонение
  decline: yupRu
    .string()
    .oneOf<'По объекту закупки' | 'По информации и документам'>([
      'По информации и документам',
      'По объекту закупки',
    ])
    .transform(v => v || undefined),
  // .when('status', {
  //   is: (tenderStatus: TenderStatus) => tenderStatus === 'Отклонение',
  //   then: schema => schema.required(),
  //   otherwise: schema => schema.transform(value => value || undefined),
  // }),
  // Основание и причина отклонения
  decline_reason: yupRu.string().transform(v => v || undefined),
  // .when('status', {
  //   is: (tenderStatus: TenderStatus) => tenderStatus === 'Отклонение',
  //   then: schema => schema.required(),
  //   otherwise: schema => schema.transform(value => value || undefined),
  // }),
  // Причина отказа
  reject_reason: yupRu
    .string()
    .oneOf<'Не успели' | 'Не рентабельно' | 'Иная причина' | undefined>([
      'Не успели',
      'Не рентабельно',
      'Иная причина',
      undefined,
    ])
    .transform(value => value || undefined),
  // .when('status', {
  //   is: (tenderStatus: TenderStatus) => tenderStatus === 'Отказ',
  //   then: schema => schema.oneOf<'Не успели' | 'Не рентабельно' | 'Иная причина'>(['Иная причина', 'Не рентабельно', 'Не успели']).required(),
  //   otherwise: schema => schema.transform(value => value || undefined),
  // }),
  reject_description: yupRu.string().transform(value => value || undefined),
  purchase_status: yupRu
    .string()
    .oneOf<PurchaseStatus>(['В процессе', 'Состоялась', 'Не состоялась', 'Отменена', 'Неизвестно'])
    .required(),
  no_take_place_reason: yupRu
    .string()
    .oneOf<'Не подано заявок' | 'Всех отклонили'>(['Всех отклонили', 'Не подано заявок'])
    .when('purchase_status', {
      is: (purchaseStatus: PurchaseStatus) => purchaseStatus === 'Не состоялась',
      then: schema => schema.required(),
      otherwise: schema => schema.transform(value => value || undefined),
    }),
  notice_number: yupRu
    .string()
    .test({
      test: value => {
        if (value === undefined) return false;
        try {
          return !!BigInt(value);
        } catch {
          return false;
        }
      },
      message: 'Должен состоять из цифр',
    })
    .required(),
  procedure_link: yupRu
    .string()
    .url()
    .transform(value => value || undefined),
  trading_platform_id: yupRu.string().required(),
  // В рамках ГОЗ по 275-ФЗ
  goz: yupRu.string().oneOf<'ГОЗ' | 'НЕГОЗ'>(['НЕГОЗ', 'ГОЗ']),
  fz: yupRu.string().oneOf<'44-ФЗ' | '223-ФЗ' | 'КОМ.'>(['44-ФЗ', '223-ФЗ', 'КОМ.']).required(),
  // is_centralized: yupRu
  //   .string()
  //   .oneOf<IsCentralized | ''>(['Нет', 'Да', ''])
  //   .transform((v: boolean | undefined | IsCentralized) => {
  //     if (typeof v === 'string') {
  //       return v;
  //     }
  //     switch (v) {
  //       case true:
  //         return 'Да';
  //       case false:
  //         return 'Нет';
  //       default:
  //         return '';
  //     }
  //   }),
  is_centralized: yupRu.mixed<CustomBooleanSchema>(),
  customer_ids: yupRu.array(yupRu.string().required()).requiredArray(),
  customer_priorities: yupRu.string().transform((v: number[] | string | undefined) => {
    if (!v) return undefined;
    return v.toString();
  }),
  object_ids: yupRu.array(yupRu.string().required()).requiredArray(),
  cost_price: yupRu.number().required(),
  participate_as_id: yupRu.string().required(),
  comment: yupRu
    .string()
    .min(3)
    .max(255)
    .transform(v => v || undefined),
  implementation_method: yupRu
    .string()
    .oneOf<ImplementationMethod>([
      'Электронный аукцион',
      'Запрос котировок',
      'Закупка ЕП',
      'Конкурс',
      'Запрос предложений',
      'Запрос котировок в электронной форме',
    ])
    .required(),
  organizer_id: yupRu.string().when('implementation_method', {
    is: (implementationMethod: ImplementationMethod) =>
      implementationMethod === 'Электронный аукцион',
    then: schema => schema.required(),
    otherwise: schema => schema.transform(value => value || undefined),
  }),
  additional_information_ids: yupRu.array(yupRu.string().required()).requiredArray(),
  tender_support: yupRu.number().required(),
  submission_deadline: yupRu.string().required(),
  procedure_at: yupRu.string().transform(value => value || undefined),
  results_at: yupRu.string().required(),
  documentation_files: yupRu
    .array(yupRu.string().required())
    .transform((value: string[]) => (value.length > 0 ? value : undefined)),
  comments_files: yupRu
    .array(yupRu.string().required())
    .transform((value: string[]) => (value.length > 0 ? value : undefined)),
  protocol_files: yupRu
    .array(yupRu.string().required())
    .transform((value: string[]) => (value.length > 0 ? value : undefined)),
  delivery_region_id: yupRu.string().required(),
  delivery_address: yupRu.string().required(),
  delivery_at: yupRu.string().required(),

  //   Рабочие параметры
  base_cost_price: yupRu.number(),
  // .when('editTender', {
  //   is: (val: boolean | undefined) => val,
  //   then: schema => schema.required(),
  // }),
  margin: yupRu.number(),
  is_under_approval: yupRu.bool(),
  calculation_files: yupRu.array(yupRu.string().required()),
  request_file: yupRu.string(),
  specialist_min_cost_price: yupRu.number(),
  min_cost_price_reason: yupRu
    .string()
    .min(3)
    .max(255)
    .transform(v => v || undefined),
  approve_comment: yupRu.string(),
  min_cost_price: yupRu.number(),
  min_margin: yupRu.number(),

  //   Итоговые параметры
  final_cost_price: yupRu.number().when(['status', 'purchaseStatus'], {
    is: (tenderStatus: TenderStatus, purchaseStatus: PurchaseStatus) =>
      purchaseStatus === 'Состоялась' || tenderStatus === 'Выигран' || tenderStatus === 'Проигран',
    then: schema => schema.required(),
    otherwise: schema => schema.transform(() => undefined),
  }),
  decrease_percent: yupRu.number(),
  final_margin: yupRu.number(),
  winner_id: yupRu.string().when(['status', 'purchaseStatus'], {
    is: (tenderStatus: TenderStatus, purchaseStatus: PurchaseStatus) =>
      purchaseStatus === 'Состоялась' || tenderStatus === 'Выигран' || tenderStatus === 'Проигран',
    then: schema => schema.required(),
    otherwise: schema => schema.transform(() => undefined),
  }),
  winner_type: yupRu
    .string()
    .oneOf<'legal_entity' | 'competitor'>(['legal_entity', 'competitor'])
    .when(['status', 'purchaseStatus'], {
      is: (tenderStatus: TenderStatus, purchaseStatus: PurchaseStatus) =>
        purchaseStatus === 'Состоялась' ||
        tenderStatus === 'Выигран' ||
        tenderStatus === 'Проигран',
      then: schema => schema.required(),
      otherwise: schema => schema.transform(() => undefined),
    }),
  winner_with_type: yupRu
    .string<`${string}/${'legal_entity' | 'competitor'}`>()
    .transform(v => v || undefined),
  participant_ids: yupRu.array(yupRu.string().required()),
  responsible_supervisor_id: yupRu.string().transform(v => v || undefined),
});

export type TenderSchema = yupRu.InferType<typeof tenderSchema>;
