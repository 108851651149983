import { dateTime } from '@gravity-ui/date-utils';
import { Button, ButtonProps, Divider, Spin, Text, useToaster } from '@gravity-ui/uikit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAtom, useAtomValue } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm, UseFormProps } from 'react-hook-form';
import useDeepCompareEffect from 'use-deep-compare-effect';

import {
  $competitorsHooks,
  $customersHooks,
  $employeesHooks,
  $eTradingPlatformsHooks,
  $legalEntitiesHooks,
  $purchaseObjectsHooks,
  $regionsHooks,
  $restrictionAdvantageRequirementsHooks,
  createdCompetitorForViewAtom,
  createdCustomerForViewAtom,
  createdEmployeeForViewAtom,
  createdETradingPlatformForViewAtom,
  createdPurchaseObjectForViewAtom,
  createdRegionForViewAtom,
  createdRestrictionAdvantageRequirementForViewAtom,
  useGetHandbookWinnersInfiniteQuery,
  userAtom,
} from '@entities';
import { getIsSubmissionDeadlineExpired } from '@features/commercial-offer-form/@x/';
import { bodyResponseType } from '@shared/api';
import { DATE_FORMATS, MAX_FILE_SIZE, NOT_ALLOWED_FILE_EXTENSIONS } from '@shared/consts';
import {
  calculateMargin,
  calculatePrice,
  fromBooleanToYesNo,
  useFieldsWithPreview,
} from '@shared/lib';
import { YesNo } from '@shared/model';
import { FieldWithPreview, RightSidebar } from '@shared/ui';

import { useGetGosplanQuery } from '../api';
import { DEFAULT_VALUES } from '../consts';
import { generateTitle, getAgreementSubmit } from '../lib';
import {
  createdCompetitorParticipantsForViewAtom,
  createdCustomerOrganizerForViewAtom,
  createdLegalEntityParticipateAsForViewAtom,
  createdLegalEntityWinnerForViewAtom,
  OPTIONS_MOCK,
  PurchaseStatus,
  tenderSchema,
  TenderSchema,
  TenderStatus,
} from '../model';

export interface TenderFormProps {
  onValuesChange?: (values: TenderSchema) => void;
  response?: Omit<
    bodyResponseType<'get', '/tender/{id}'>,
    'is_centralized' | 'customer_priorities'
  > & {
    is_centralized: YesNo | undefined;
    customer_priorities: string | undefined;
  };
  editTender: boolean;
  hideTitleField?: boolean;
  defaultValues?: UseFormProps<TenderSchema>['defaultValues'];
  values?: UseFormProps<TenderSchema>['values'];
  onValid?: SubmitHandler<TenderSchema>;
  onInvalid?: SubmitErrorHandler<TenderSchema>;
  initialEditable?: boolean;
  hide?: boolean;
  disabled?: boolean;
  hideBottomPanel?: boolean;
  submitButtonText?: string;
  cancelButtonText?: string;
  onClickCancelButton?: () => void;
  approvalButtonProps?: Pick<ButtonProps, 'onClick' | 'disabled'>;
  allowedStatuses: (values: TenderSchema) => TenderStatus[];
  allowedPurchaseStatuses: (values: TenderSchema) => PurchaseStatus[];

  onClickCreateEmployeesHandbook?: () => void;
  onClickCreateETradingPlatformsHandbook?: () => void;
  onClickCreateCustomersHandbook?: () => void;
  onClickCreateCustomersOrganizerHandbook?: () => void;
  onClickCreatePurchaseObjectsHandbook?: () => void;
  onClickCreateLegalEntitiesParticipateAsHandbook?: () => void;
  onClickCreateRestrictionAdvantageRequirementsHandbook?: () => void;
  onClickCreateRegionsHandbook?: () => void;
  onClickCreateLegalEntitiesWinnerHandbook?: () => void;
  onClickCreateCompetitorsHandbook?: () => void;
  onClickCreateCompetitorsParticipantsHandbook?: () => void;
}

export function TenderForm({
  onValuesChange,
  response,
  editTender,
  hideTitleField = true,
  defaultValues,
  initialEditable = false,
  disabled = false,
  hide = false,
  hideBottomPanel = false,
  cancelButtonText = 'Отмена',
  submitButtonText,
  // onValuesChange,
  onClickCancelButton,
  onInvalid,
  onValid = () => {},
  // approvalButtonProps,
  allowedStatuses: getAllowedStatuses,
  allowedPurchaseStatuses: getAllowedPurchaseStatuses,
  onClickCreateEmployeesHandbook,
  onClickCreateETradingPlatformsHandbook,
  onClickCreateCustomersHandbook,
  onClickCreateCustomersOrganizerHandbook,
  onClickCreatePurchaseObjectsHandbook,
  onClickCreateLegalEntitiesParticipateAsHandbook,
  onClickCreateRestrictionAdvantageRequirementsHandbook,
  onClickCreateRegionsHandbook,
  onClickCreateLegalEntitiesWinnerHandbook,
  onClickCreateCompetitorsHandbook,
  onClickCreateCompetitorsParticipantsHandbook,
}: TenderFormProps) {
  const { add } = useToaster();

  const [createdEmployeeForView, setCreatedEmployeeForView] = useAtom(createdEmployeeForViewAtom);
  const [createdETradingPlatformForView, setCreatedETradingPlatformForView] = useAtom(
    createdETradingPlatformForViewAtom
  );
  const [createdCustomerForView, setCreatedCustomerForView] = useAtom(createdCustomerForViewAtom);
  const [createdCustomerOrganizerForView, setCreatedCustomerOrganizerForView] = useAtom(
    createdCustomerOrganizerForViewAtom
  );
  const [createdPurchaseObjectForView, setCreatedPurchaseObjectForView] = useAtom(
    createdPurchaseObjectForViewAtom
  );
  const [createdLegalEntityParticipateAsForView, setCreatedLegalEntityParticipateAsForView] =
    useAtom(createdLegalEntityParticipateAsForViewAtom);
  const [
    createdRestrictionAdvantageRequirementForView,
    setCreatedRestrictionAdvantageRequirementForView,
  ] = useAtom(createdRestrictionAdvantageRequirementForViewAtom);
  const [createdRegionForView, setCreatedRegionForView] = useAtom(createdRegionForViewAtom);
  const [createdLegalEntityWinnerForView, setCreatedLegalEntityWinnerForView] = useAtom(
    createdLegalEntityWinnerForViewAtom
  );
  const [createdCompetitorForView, setCreatedCompetitorForView] = useAtom(
    createdCompetitorForViewAtom
  );
  const [createdCompetitorParticipantsForView, setCreatedCompetitorParticipantsForView] = useAtom(
    createdCompetitorParticipantsForViewAtom
  );

  const user = useAtomValue(userAtom);

  const tenderForm = useForm<TenderSchema>({
    resolver: yupResolver(tenderSchema),
    mode: 'all',
    defaultValues: { ...DEFAULT_VALUES, ...defaultValues },
    resetOptions: {
      keepDirtyValues: true,
      keepDefaultValues: false,
    },
  });

  const { createFieldRef, isFieldEditable, resetSelectedField, selectField, selectedFieldPath } =
    useFieldsWithPreview({
      form: tenderForm,
      initialEditable,
      disabled,
    });

  useDeepCompareEffect(() => {
    if (response) {
      setPreviousNoticeNumber(response.notice_number);
      tenderForm.reset({
        ...response,
        winner_with_type:
          response.winner_id && response.winner_type
            ? `${response.winner_id}/${response.winner_type}`
            : undefined,
      });
    }
  }, [response || {}]);

  const values = tenderForm.watch();

  useDeepCompareEffect(() => {
    onValuesChange?.(values);
  }, [values]);

  // atoms
  useEffect(() => {
    if (createdEmployeeForView)
      tenderForm.setValue('responsible_id', createdEmployeeForView.employee_id);
  }, [createdEmployeeForView]);
  useEffect(() => {
    if (createdETradingPlatformForView)
      tenderForm.setValue(
        'trading_platform_id',
        createdETradingPlatformForView.eTradingPlatform_id
      );
  }, [createdETradingPlatformForView]);
  useEffect(() => {
    if (createdCustomerForView)
      tenderForm.setValue('customer_ids', [createdCustomerForView.customer_id]);
  }, [createdCustomerForView]);
  useEffect(() => {
    if (createdCustomerOrganizerForView)
      tenderForm.setValue('organizer_id', createdCustomerOrganizerForView.customer_id);
  }, [createdCustomerOrganizerForView]);
  useEffect(() => {
    if (createdPurchaseObjectForView)
      tenderForm.setValue('object_ids', [createdPurchaseObjectForView.purchaseObject_id]);
  }, [createdPurchaseObjectForView]);
  useEffect(() => {
    if (createdLegalEntityParticipateAsForView)
      tenderForm.setValue(
        'participate_as_id',
        createdLegalEntityParticipateAsForView.legalEntity_id
      );
  }, [createdLegalEntityParticipateAsForView]);
  useEffect(() => {
    if (createdRestrictionAdvantageRequirementForView)
      tenderForm.setValue('additional_information_ids', [
        createdRestrictionAdvantageRequirementForView.restrictionAdvantageRequirement_id,
      ]);
  }, [createdRestrictionAdvantageRequirementForView]);
  useEffect(() => {
    if (createdRegionForView)
      tenderForm.setValue('delivery_region_id', createdRegionForView.region_id);
  }, [createdRegionForView]);
  useEffect(() => {
    if (createdLegalEntityWinnerForView) {
      tenderForm.setValue('winner_id', createdLegalEntityWinnerForView.legalEntity_id);
      tenderForm.setValue('winner_type', 'legal_entity');
      tenderForm.setValue(
        'winner_with_type',
        `${createdLegalEntityWinnerForView.legalEntity_id}/legal_entity`
      );
    }
  }, [createdLegalEntityWinnerForView]);
  useEffect(() => {
    if (createdCompetitorForView) {
      tenderForm.setValue('winner_id', createdCompetitorForView.competitor_id);
      tenderForm.setValue('winner_type', 'competitor');
      tenderForm.setValue(
        'winner_with_type',
        `${createdCompetitorForView.competitor_id}/competitor`
      );
    }
  }, [createdCompetitorForView]);
  useEffect(() => {
    if (createdCompetitorParticipantsForView)
      tenderForm.setValue('participant_ids', [createdCompetitorParticipantsForView.competitor_id]);
  }, [createdCompetitorParticipantsForView]);
  //

  // System filters
  const [filterStatus, setFilterStatus] = useState('');
  const [filterDecline, setFilterDecline] = useState('');
  const [filterRejectReason, setFilterRejectReason] = useState('');
  const [filterPurchaseStatus, setFilterPurchaseStatus] = useState('');
  const [filterNoTakePlaceReason, setFilterNoTakePlaceReason] = useState('');
  // const [filterGOZ, setFilterGOZ] = useState('');
  // const [filterFZ, setFilterFZ] = useState('');
  const [filterImplementationMethod, setFilterImplementationMethod] = useState('');
  //

  // Queries
  const [filterEmployees, setFilterEmployees] = useState('');
  const getHandbookEmployeesInfiniteQuery = $employeesHooks.useGetAll(
    {
      query: filterEmployees,
      limit: 30,
    },
    selectedFieldPath === 'responsible_id'
  );
  const flattedEmployees = useMemo(
    () => getHandbookEmployeesInfiniteQuery.data?.pages.flat(),
    [getHandbookEmployeesInfiniteQuery.data]
  );

  const [filterETradingPlatforms, setFilterETradingPlatforms] = useState('');
  const getHandbookETradingPlatformsInfiniteQuery = $eTradingPlatformsHooks.useGetAll(
    {
      query: filterETradingPlatforms,
      limit: 30,
    },
    selectedFieldPath === 'trading_platform_id'
  );
  const flattedETradingPlatforms = useMemo(
    () => getHandbookETradingPlatformsInfiniteQuery.data?.pages.flat(),
    [getHandbookETradingPlatformsInfiniteQuery.data]
  );

  const [filterCustomers, setFilterCustomers] = useState('');
  const getHandbookCustomersInfiniteQuery = $customersHooks.useGetAll(
    {
      query: filterCustomers,
      limit: 30,
    },
    selectedFieldPath === 'customer_ids'
  );
  const flattedCustomers = useMemo(
    () => getHandbookCustomersInfiniteQuery.data?.pages.flat().filter(data => !!data),
    [getHandbookCustomersInfiniteQuery.data]
  );

  const [filterPurchaseObjects, setFilterPurchaseObjects] = useState('');
  const getHandbookPurchaseObjectsInfiniteQuery = $purchaseObjectsHooks.useGetAll(
    {
      query: filterPurchaseObjects,
      limit: 30,
    },
    selectedFieldPath === 'object_ids'
  );
  const flattedPurchaseObjects = useMemo(
    () => getHandbookPurchaseObjectsInfiniteQuery.data?.pages.flat().filter(data => !!data),
    [getHandbookPurchaseObjectsInfiniteQuery.data]
  );

  const [filterLegalEntities, setFilterLegalEntities] = useState('');
  const getHandbookLegalEntitiesInfiniteQuery = $legalEntitiesHooks.useGetAll(
    {
      query: filterLegalEntities,
      limit: 30,
    },
    selectedFieldPath === 'participate_as_id'
  );
  const flattedLegalEntities = useMemo(
    () => getHandbookLegalEntitiesInfiniteQuery.data?.pages.flat().filter(data => !!data),
    [getHandbookLegalEntitiesInfiniteQuery.data]
  );

  const [filterRestrictionAdvantageRequirements, setFilterRestrictionAdvantageRequirements] =
    useState('');
  const getHandbookRestrictionAdvantageRequirementsInfiniteQuery =
    $restrictionAdvantageRequirementsHooks.useGetAll(
      {
        query: filterRestrictionAdvantageRequirements,
        limit: 30,
      },
      selectedFieldPath === 'additional_information_ids'
    );
  const flattedRestrictionAdvantageRequirements = useMemo(
    () =>
      getHandbookRestrictionAdvantageRequirementsInfiniteQuery.data?.pages
        .flat()
        .filter(data => !!data),
    [getHandbookRestrictionAdvantageRequirementsInfiniteQuery.data]
  );

  const [filterRegions, setFilterRegions] = useState('');
  const getHandbookRegionsInfiniteQuery = $regionsHooks.useGetAll(
    {
      query: filterRegions,
      limit: 30,
    },
    selectedFieldPath === 'delivery_region_id'
  );
  const flattedRegions = useMemo(
    () => getHandbookRegionsInfiniteQuery.data?.pages.flat(),
    [getHandbookRegionsInfiniteQuery.data]
  );

  const [filterWinners, setFilterWinners] = useState('');
  const getHandbookWinnersInfiniteQuery = useGetHandbookWinnersInfiniteQuery(
    {
      query: filterWinners,
      limit: 30,
    },
    selectedFieldPath === 'winner_with_type' &&
      (values.status === 'Выигран' || values.status === 'Проигран')
  );
  const flattedWinners = useMemo(
    () =>
      getHandbookWinnersInfiniteQuery.data?.pages
        .flat()
        .filter(winner =>
          values.status === 'Проигран'
            ? winner.type === 'competitor'
            : winner.type === 'legal_entity'
        ),
    [
      getHandbookWinnersInfiniteQuery.data,
      values.status === 'Выигран',
      values.status === 'Проигран',
    ]
  );

  const [filterCompetitors, setFilterCompetitors] = useState('');
  const getHandbookCompetitorsInfiniteQuery = $competitorsHooks.useGetAll(
    {
      query: filterCompetitors,
      limit: 30,
    },
    selectedFieldPath === 'participant_ids'
  );
  const flattedCompetitors = useMemo(
    () => getHandbookCompetitorsInfiniteQuery.data?.pages.flat(),
    [getHandbookCompetitorsInfiniteQuery.data]
  );
  //

  // Госплан
  const [previousNoticeNumber, setPreviousNoticeNumber] = useState(
    response?.notice_number || values.notice_number || ''
  );

  const getGosplanQuery = useGetGosplanQuery(
    values['notice_number'],
    !!values['notice_number'] &&
      previousNoticeNumber !== values['notice_number'] &&
      !tenderForm.formState.errors.notice_number &&
      tenderForm.control.getFieldState('notice_number').isDirty
  );

  useEffect(() => {
    if (getGosplanQuery.isError && getGosplanQuery.isFetchedAfterMount) {
      add({
        title: 'Ошибка получения данных из Госплана или Тендер не был найден',
        name: 'gosplan-error',
        theme: 'danger',
      });
    }
    if (getGosplanQuery.isSuccess && getGosplanQuery.isFetchedAfterMount) {
      add({ title: 'Добавлены данные из Госплана', name: 'gosplan-success', theme: 'success' });
    }
  }, [getGosplanQuery.isFetching]);

  useEffect(() => {
    if (getGosplanQuery.data) {
      setPreviousNoticeNumber(values['notice_number']);
      const data = getGosplanQuery.data;
      const newValues: Partial<TenderSchema> = {};

      if (data.comments_files && data.comments_files.length)
        newValues.comments_files = data.comments_files;

      if (data.documentation_files && data.documentation_files.length)
        newValues.documentation_files = data.documentation_files;

      if (typeof data.cost_price === 'number' && data.cost_price > 0)
        newValues.cost_price = data.cost_price;

      if (data.customer_inns && data.customer_inns.length) {
        newValues.customer_ids = data.customer_inns
          .map(customer => (customer.name ? customer.id : undefined))
          .filter(v => !!v) as string[];
      }
      if (data.delivery_address) newValues.delivery_address = data.delivery_address;
      if (data.delivery_region && data.delivery_region.name)
        newValues.delivery_region_id = data.delivery_region.id;
      if (data.fz && (data.fz === '223-ФЗ' || data.fz === '44-ФЗ')) newValues.fz = data.fz;
      if (
        data.implementation_method &&
        (data.implementation_method === 'Закупка ЕП' ||
          data.implementation_method === 'Запрос котировок' ||
          data.implementation_method === 'Запрос предложений' ||
          data.implementation_method === 'Конкурс' ||
          data.implementation_method === 'Электронный аукцион' ||
          data.implementation_method === 'Запрос котировок в электронной форме')
      )
        newValues.implementation_method = data.implementation_method;
      if (data.is_centralized !== undefined)
        newValues.is_centralized = fromBooleanToYesNo(data.is_centralized);
      if (data.procedure_at && data.procedure_at !== '0001-01-01T00:00:00Z')
        newValues.procedure_at = data.procedure_at;
      if (data.results_at && data.results_at !== '0001-01-01T00:00:00Z')
        newValues.results_at = data.results_at;
      if (data.submission_deadline && data.submission_deadline !== '0001-01-01T00:00:00Z')
        newValues.submission_deadline = data.submission_deadline;
      if (typeof data.tender_support === 'number' && data.tender_support > 0)
        newValues.tender_support = data.tender_support;
      if (data.trading_platform && data.trading_platform.name) {
        newValues.trading_platform_id = data.trading_platform.id;
      }

      if (Object.values(newValues).length) tenderForm.reset({ ...values, ...newValues });
    }
  }, [getGosplanQuery.data]);
  //

  const allowedStatuses = getAllowedStatuses(values);
  const allowedPurchaseStatuses = getAllowedPurchaseStatuses(values);

  const statusOptions = OPTIONS_MOCK['Статус заявки'].filter(option =>
    allowedStatuses?.includes(option.value)
  );

  const purchaseStatusOptions = OPTIONS_MOCK['Статус закупки'].filter(option =>
    allowedPurchaseStatuses?.includes(option.value)
  );

  useEffect(() => {
    if (values['customer_ids']?.[0] && values['object_ids']?.[0] && values['notice_number']) {
      const customerValue = values['customer_ids'][0];
      const objectValue = values['object_ids'][0];
      const noticeNumberValue = values['notice_number'];
      const customer = getGosplanQuery.data
        ? getGosplanQuery.data.customer_inns?.find(customer => customer.id === customerValue)?.name
        : flattedCustomers?.find(customer => customer.id === customerValue)?.name;
      const supplyObject = flattedPurchaseObjects?.find(
        supplyObject => supplyObject.id === objectValue
      )?.object;
      if (customer && supplyObject) {
        const title = generateTitle({
          customer: customer,
          supplyObject: supplyObject,
          uniqueNumber: noticeNumberValue,
        });

        tenderForm.setValue('title', title ?? undefined);
      }
      // else {
      //   tenderForm.reset({...values, customer_ids: undefined, object_ids: undefined, notice_number: undefined })
      // }
    }
  }, [values['customer_ids'], values['notice_number'], values['object_ids'], defaultValues]);

  useEffect(() => {
    if (!allowedStatuses?.includes(values['status'])) {
      tenderForm.resetField('status');
      // tenderForm.resetField('status', { defaultValue: '' as ApplicationStatus });
    }

    if (!allowedPurchaseStatuses?.includes(values['purchase_status'])) {
      tenderForm.resetField('purchase_status');
      // tenderForm.resetField('Статус закупки', { defaultValue: '' as ProducementStatus });
    }
    if (
      (values.status === 'Выигран' || values.status === 'Проигран') &&
      values.purchase_status !== 'Состоялась'
    ) {
      tenderForm.setValue('purchase_status', 'Состоялась');
    }
  }, [values['status'], values['purchase_status']]);

  useEffect(() => {
    if (values['status'] === 'Отказ') {
      const isSubmissionDeadlineExpired = getIsSubmissionDeadlineExpired(
        values['submission_deadline']
      );

      if (isSubmissionDeadlineExpired) {
        tenderForm.resetField('status');
        return add({
          name: 'tender-cannot-reject',
          theme: 'danger',
          title:
            'Невозможно отказаться после 16:00 (МСК) предшествующего дня окончания подачи заявки',
        });
      }
    }
  }, [values['status'], values['submission_deadline']]);

  useEffect(() => {
    const submissionDate = values['submission_deadline']
      ? dateTime({ input: values['submission_deadline'] })
      : null;

    const procedureExecutionDate = values['procedure_at']
      ? dateTime({ input: values['procedure_at'] })
      : null;

    const summarizingDate = values['results_at'] ? dateTime({ input: values['results_at'] }) : null;

    if (submissionDate && procedureExecutionDate?.isBefore(submissionDate)) {
      tenderForm.resetField('procedure_at', { defaultValue: null as unknown as undefined });
    }

    if (submissionDate && summarizingDate?.isBefore(submissionDate)) {
      tenderForm.resetField('results_at', { defaultValue: null as unknown as undefined });
    }

    if (procedureExecutionDate && summarizingDate?.isBefore(procedureExecutionDate)) {
      tenderForm.resetField('results_at', { defaultValue: null as unknown as undefined });
    }
  }, [values['submission_deadline'], values['procedure_at'], values['results_at']]);

  // Обработка загрузки файлов
  const [fileFieldsLoading, setFileFieldsLoading] = useState<Record<string, boolean>>({});

  const isFilesLoading = Object.values(fileFieldsLoading).some(
    fileFieldLoading => fileFieldLoading
  );
  //

  useEffect(() => {
    if (Object.values(tenderForm.formState.errors).length > 0 && tenderForm.formState.isSubmitted)
      add({
        title: 'Не все обязательные поля заполнены',
        name: 'validation-fields-error',
        theme: 'warning',
      });
  }, [tenderForm.formState.submitCount]);

  const submitButtonTextForAgreement = useMemo(
    () => getAgreementSubmit(values, !!response?.is_blocked_work_params),
    [values]
  );

  if (hide) return null;

  return (
    <form
      className="flex flex-col overflow-hidden"
      onSubmit={tenderForm.handleSubmit(onValid, onInvalid)}
    >
      <FieldWithPreview>
        <Text
          variant="body-2"
          className="font-semibold uppercase"
        >
          Начальные параметры
        </Text>
        {/* Начальные параметры */}
        <FieldWithPreview.Select
          ref={createFieldRef('responsible_id')}
          control={tenderForm.control}
          path="responsible_id"
          edit={isFieldEditable('responsible_id')}
          onFieldSelect={() => selectField('responsible_id')}
          name="Ответственный"
          options={
            flattedEmployees?.map(employee => ({
              content: employee?.name_initials,
              value: employee.id,
            })) || []
          }
          delayFilter={300}
          defaultOptions={
            createdEmployeeForView
              ? [
                  {
                    content: createdEmployeeForView.employee_value,
                    value: createdEmployeeForView.employee_id,
                  },
                ]
              : response
                ? [
                    {
                      content: response.responsible_value,
                      value: response.responsible_id,
                    },
                  ]
                : user
                  ? [{ content: user.name_initials, value: user.id }]
                  : undefined
          }
          onPlusClick={onClickCreateEmployeesHandbook}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          required
          filter={filterEmployees}
          onFilterChange={filter => setFilterEmployees(filter)}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
            loading:
              getHandbookEmployeesInfiniteQuery.isFetching ||
              getHandbookEmployeesInfiniteQuery.isFetchingNextPage ||
              getHandbookEmployeesInfiniteQuery.isLoading ||
              getHandbookEmployeesInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookEmployeesInfiniteQuery.fetchNextPage();
            },
          }}
        />
        {/* TODO: Уточнить, скрывается ли поле при просмотре карточки */}
        <FieldWithPreview.Text
          ref={createFieldRef('title')}
          control={tenderForm.control}
          path="title"
          edit={false}
          show={!hideTitleField}
          name="Наименование"
          textInputProps={{
            disabled,
          }}
        />
        <FieldWithPreview.Select
          ref={createFieldRef('status')}
          control={tenderForm.control}
          path="status"
          edit={isFieldEditable('status')}
          onFieldSelect={() => selectField('status')}
          name="Статус заявки"
          options={statusOptions.filter(v => v.content.includes(filterStatus))}
          defaultOptions={
            response
              ? [
                  {
                    content: response.status,
                    value: response.status,
                  },
                ]
              : undefined
          }
          filter={filterStatus}
          onFilterChange={filter => setFilterStatus(filter)}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
            onUpdate: ([status]) => {
              if (
                (values.purchase_status === 'Состоялась' ||
                  status === 'Выигран' ||
                  status === 'Проигран') &&
                typeof values['final_cost_price'] === 'number' &&
                typeof values.cost_price === 'number' &&
                values.decrease_percent !==
                  calculateMargin(values.cost_price, values.final_cost_price)
              ) {
                tenderForm.setValue(
                  'decrease_percent',
                  calculateMargin(values.cost_price, values.final_cost_price)
                );
              } else if (values.decrease_percent !== undefined) {
                tenderForm.setValue('decrease_percent', undefined);
              }
            },
          }}
          required
        />
        <FieldWithPreview.Select
          show={values['status'] === 'Отклонение'}
          ref={createFieldRef('decline')}
          control={tenderForm.control}
          path="decline"
          edit={isFieldEditable('decline')}
          onFieldSelect={() => selectField('decline')}
          name="Отклонение"
          options={OPTIONS_MOCK['Отклонение'].filter(v => v.content.includes(filterDecline))}
          defaultOptions={
            response && response.decline
              ? [
                  {
                    content: response.decline,
                    value: response.decline,
                  },
                ]
              : undefined
          }
          filter={filterDecline}
          onFilterChange={filter => setFilterDecline(filter)}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
          }}
        />
        <FieldWithPreview.TextArea
          show={values['status'] === 'Отклонение'}
          ref={createFieldRef('decline_reason')}
          control={tenderForm.control}
          path="decline_reason"
          edit={isFieldEditable('decline_reason')}
          onFieldSelect={() => selectField('decline_reason')}
          name="Основание и причина отклонения"
          wrapperProps={{
            textAlign: 'start',
          }}
          textAreaProps={{
            disabled,
          }}
        />
        <FieldWithPreview.Select
          show={values['status'] === 'Отказ'}
          ref={createFieldRef('reject_reason')}
          control={tenderForm.control}
          path="reject_reason"
          edit={isFieldEditable('reject_reason')}
          onFieldSelect={() => selectField('reject_reason')}
          name="Причина отказа"
          options={OPTIONS_MOCK['Причина отказа'].filter(v =>
            v.content.includes(filterRejectReason)
          )}
          defaultOptions={
            response && response.reject_reason
              ? [
                  {
                    content: response.reject_reason,
                    value: response.reject_reason,
                  },
                ]
              : undefined
          }
          filter={filterRejectReason}
          onFilterChange={filter => setFilterRejectReason(filter)}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
          }}
        />
        <FieldWithPreview.TextArea
          show={values['status'] === 'Отказ'}
          ref={createFieldRef('reject_description')}
          control={tenderForm.control}
          path="reject_description"
          edit={isFieldEditable('reject_description')}
          onFieldSelect={() => selectField('reject_description')}
          name="Пояснение к причине отказа"
          wrapperProps={{
            textAlign: 'start',
          }}
          textAreaProps={{
            disabled,
          }}
        />
        <FieldWithPreview.Select
          ref={createFieldRef('purchase_status')}
          control={tenderForm.control}
          path="purchase_status"
          edit={isFieldEditable('purchase_status')}
          onFieldSelect={() => selectField('purchase_status')}
          name="Статус закупки"
          options={purchaseStatusOptions.filter(v => v.content.includes(filterPurchaseStatus))}
          defaultOptions={
            response
              ? [
                  {
                    content: response.purchase_status,
                    value: response.purchase_status,
                  },
                ]
              : undefined
          }
          filter={filterPurchaseStatus}
          onFilterChange={filter => setFilterPurchaseStatus(filter)}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
            onUpdate: ([purchaseStatus]) => {
              if (
                (purchaseStatus === 'Состоялась' ||
                  values['status'] === 'Выигран' ||
                  values['status'] === 'Проигран') &&
                typeof values['final_cost_price'] === 'number' &&
                typeof values.cost_price === 'number' &&
                values.decrease_percent !==
                  calculateMargin(values.cost_price, values.final_cost_price)
              ) {
                tenderForm.setValue(
                  'decrease_percent',
                  calculateMargin(values.cost_price, values.final_cost_price)
                );
              } else if (values.decrease_percent !== undefined) {
                tenderForm.setValue('decrease_percent', undefined);
              }
            },
          }}
          required
        />
        <FieldWithPreview.Select
          show={values['purchase_status'] === 'Не состоялась'}
          ref={createFieldRef('no_take_place_reason')}
          control={tenderForm.control}
          path="no_take_place_reason"
          edit={isFieldEditable('no_take_place_reason')}
          onFieldSelect={() => selectField('no_take_place_reason')}
          name="Причина почему не состоялась?"
          options={OPTIONS_MOCK['Причина почему не состоялась?'].filter(v =>
            v.content.includes(filterNoTakePlaceReason)
          )}
          defaultOptions={
            response && response.no_take_place_reason
              ? [
                  {
                    content: response.no_take_place_reason,
                    value: response.no_take_place_reason,
                  },
                ]
              : undefined
          }
          filter={filterNoTakePlaceReason}
          onFilterChange={filter => setFilterNoTakePlaceReason(filter)}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
          }}
        />
        <FieldWithPreview.Text
          ref={createFieldRef('notice_number')}
          control={tenderForm.control}
          path="notice_number"
          edit={isFieldEditable('notice_number')}
          onFieldSelect={() => selectField('notice_number')}
          name="Номер извещения"
          textInputProps={{
            disabled,
            endContent: getGosplanQuery.isFetching ? <Spin /> : undefined,
          }}
          required
          delayTextInput={500}
        />
        <FieldWithPreview.Text
          ref={createFieldRef('procedure_link')}
          control={tenderForm.control}
          path="procedure_link"
          edit={isFieldEditable('procedure_link')}
          onFieldSelect={() => selectField('procedure_link')}
          name="Ссылка на процедуру"
          textInputProps={{
            disabled,
          }}
        />
        <FieldWithPreview.Select
          ref={createFieldRef('trading_platform_id')}
          control={tenderForm.control}
          path="trading_platform_id"
          edit={isFieldEditable('trading_platform_id')}
          onFieldSelect={() => selectField('trading_platform_id')}
          name="Электронная торговая площадка"
          options={
            flattedETradingPlatforms?.map(platform => ({
              content: platform.name,
              value: platform.id,
            })) || []
          }
          delayFilter={300}
          defaultOptions={
            createdETradingPlatformForView
              ? [
                  {
                    content: createdETradingPlatformForView.eTradingPlatform_value,
                    value: createdETradingPlatformForView.eTradingPlatform_id,
                  },
                ]
              : getGosplanQuery.data?.trading_platform &&
                  getGosplanQuery.data?.trading_platform.name
                ? [
                    {
                      content: getGosplanQuery.data.trading_platform.name,
                      value: getGosplanQuery.data.trading_platform.id,
                    },
                  ]
                : response
                  ? [
                      {
                        content: response.trading_platform_value,
                        value: response.trading_platform_id,
                      },
                    ]
                  : undefined
          }
          onPlusClick={onClickCreateETradingPlatformsHandbook}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          required
          filter={filterETradingPlatforms}
          onFilterChange={filter => setFilterETradingPlatforms(filter)}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
            loading:
              getHandbookETradingPlatformsInfiniteQuery.isFetching ||
              getHandbookETradingPlatformsInfiniteQuery.isFetchingNextPage ||
              getHandbookETradingPlatformsInfiniteQuery.isLoading ||
              getHandbookETradingPlatformsInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookETradingPlatformsInfiniteQuery.fetchNextPage();
            },
          }}
        />
        <FieldWithPreview.Radio
          show={
            flattedETradingPlatforms?.find(
              platform => platform.id === values['trading_platform_id']
            )?.name === 'АСТ ГОЗ'
          }
          ref={createFieldRef('goz')}
          control={tenderForm.control}
          path="goz"
          edit={isFieldEditable('goz')}
          onFieldSelect={() => selectField('goz')}
          name="В рамках ГОЗ по 275-ФЗ"
          defaultOption={
            flattedETradingPlatforms?.find(
              platform => platform.id === values['trading_platform_id']
            )?.name === 'АСТ ГОЗ'
              ? { content: 'НЕГОЗ', value: 'НЕГОЗ' }
              : undefined
          }
          options={OPTIONS_MOCK['В рамках ГОЗ по 275-ФЗ']}
          resetSelectedField={resetSelectedField}
          required
        />
        <FieldWithPreview.Radio
          ref={createFieldRef('fz')}
          control={tenderForm.control}
          path="fz"
          edit={isFieldEditable('fz')}
          onFieldSelect={() => selectField('fz')}
          name="ФЗ"
          options={OPTIONS_MOCK['ФЗ']}
          defaultOption={response ? { value: response.fz, content: response.fz } : undefined}
          resetSelectedField={resetSelectedField}
          required
        />
        <FieldWithPreview.Radio
          ref={createFieldRef('is_centralized')}
          control={tenderForm.control}
          path="is_centralized"
          edit={isFieldEditable('is_centralized')}
          onFieldSelect={() => selectField('is_centralized')}
          name="Закупка централизованная или совместная?"
          options={OPTIONS_MOCK['Закупка централизованная или совместная?']}
          resetSelectedField={resetSelectedField}
          defaultOption={
            response?.is_centralized !== undefined
              ? {
                  value: response.is_centralized,
                  content: response.is_centralized,
                }
              : undefined
          }
          radioButtonProps={{
            disabled,
          }}
        />
        <FieldWithPreview.MultiSelect
          ref={createFieldRef('customer_ids')}
          control={tenderForm.control}
          path="customer_ids"
          edit={isFieldEditable('customer_ids')}
          onFieldSelect={() => selectField('customer_ids')}
          name="Заказчики"
          hasCheckMarkOnFirstOption
          options={
            flattedCustomers?.map(customer => ({
              content: customer.name,
              value: customer.id,
            })) || []
          }
          defaultOptions={
            createdCustomerForView
              ? [
                  {
                    content: createdCustomerForView.customer_value,
                    value: createdCustomerForView.customer_id,
                  },
                ]
              : getGosplanQuery.data?.customer_inns &&
                  getGosplanQuery.data?.customer_inns.filter(v => !!v.name).length
                ? getGosplanQuery.data.customer_inns
                    .filter(customer => !!customer.name)
                    .map(customer => ({
                      content: customer.name,
                      value: customer.id,
                    }))
                : response
                  ? response.customer_ids.map((customer, i) => ({
                      value: customer,
                      content: response.customer_values?.[i],
                    }))
                  : undefined
          }
          onPlusClick={onClickCreateCustomersHandbook}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          filter={filterCustomers}
          onFilterChange={filter => setFilterCustomers(filter)}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
            loading:
              getHandbookCustomersInfiniteQuery.isFetching ||
              getHandbookCustomersInfiniteQuery.isFetchingNextPage ||
              getHandbookCustomersInfiniteQuery.isLoading ||
              getHandbookCustomersInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookCustomersInfiniteQuery.fetchNextPage();
            },
          }}
          required
        />
        <FieldWithPreview.Radio
          ref={createFieldRef('customer_priorities')}
          control={tenderForm.control}
          path="customer_priorities"
          edit={isFieldEditable('customer_priorities')}
          onFieldSelect={() => selectField('customer_priorities')}
          name="Приоритет заказчика"
          options={OPTIONS_MOCK['Приоритет заказчика']}
          defaultOption={
            response?.customer_priorities?.[0]
              ? {
                  content: response.customer_priorities[0].toString(),
                  value: response.customer_priorities[0].toString(),
                }
              : undefined
          }
          resetSelectedField={resetSelectedField}
          radioButtonProps={{
            disabled,
          }}
        />
        <FieldWithPreview.Select
          show={values['implementation_method'] === 'Электронный аукцион'}
          control={tenderForm.control}
          path="organizer_id"
          edit={isFieldEditable('organizer_id')}
          onFieldSelect={() => selectField('organizer_id')}
          name="Организатор"
          options={
            flattedCustomers?.map(customer => ({
              content: customer.name,
              value: customer.id,
            })) || []
          }
          delayFilter={300}
          defaultOptions={
            createdCustomerOrganizerForView
              ? [
                  {
                    content: createdCustomerOrganizerForView.customer_value,
                    value: createdCustomerOrganizerForView.customer_id,
                  },
                ]
              : response && response.organizer_id
                ? [
                    {
                      content: response.organizer_value,
                      value: response.organizer_id,
                    },
                  ]
                : undefined
          }
          onPlusClick={onClickCreateCustomersOrganizerHandbook}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          filter={filterCustomers}
          onFilterChange={filter => setFilterCustomers(filter)}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
            loading:
              getHandbookCustomersInfiniteQuery.isFetching ||
              getHandbookCustomersInfiniteQuery.isFetchingNextPage ||
              getHandbookCustomersInfiniteQuery.isLoading ||
              getHandbookCustomersInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookCustomersInfiniteQuery.fetchNextPage();
            },
          }}
          required
        />
        <FieldWithPreview.MultiSelect
          ref={createFieldRef('object_ids')}
          control={tenderForm.control}
          path="object_ids"
          edit={isFieldEditable('object_ids')}
          onFieldSelect={() => selectField('object_ids')}
          name="Объект торгов"
          options={
            flattedPurchaseObjects?.map(object => ({
              content: object.object,
              value: object.id,
            })) || []
          }
          delayFilter={300}
          defaultOptions={
            createdPurchaseObjectForView
              ? [
                  {
                    content: createdPurchaseObjectForView.purchaseObject_value,
                    value: createdPurchaseObjectForView.purchaseObject_id,
                  },
                ]
              : response
                ? response.object_ids.map((object, i) => ({
                    value: object,
                    content: response.object_values[i],
                  }))
                : undefined
          }
          onPlusClick={onClickCreatePurchaseObjectsHandbook}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          filter={filterPurchaseObjects}
          onFilterChange={filter => setFilterPurchaseObjects(filter)}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
            loading:
              getHandbookPurchaseObjectsInfiniteQuery.isFetching ||
              getHandbookPurchaseObjectsInfiniteQuery.isFetchingNextPage ||
              getHandbookPurchaseObjectsInfiniteQuery.isLoading ||
              getHandbookPurchaseObjectsInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookPurchaseObjectsInfiniteQuery.fetchNextPage();
            },
          }}
          required
        />
        <FieldWithPreview.Currency
          ref={createFieldRef('cost_price')}
          control={tenderForm.control}
          path="cost_price"
          edit={isFieldEditable('cost_price')}
          onFieldSelect={() => selectField('cost_price')}
          name="Начальная цена"
          numberFormatProps={{
            disabled,
            min: 0,
            onValueChange: ({ floatValue }) => {
              if (
                (typeof floatValue === 'number' && floatValue <= 0) ||
                selectedFieldPath !== 'cost_price'
              )
                return;
              const baseCostPrice = values['base_cost_price'];
              const finalCostPrice = values['final_cost_price'];

              if (typeof baseCostPrice === 'number' && typeof floatValue === 'number') {
                tenderForm.setValue('margin', calculateMargin(floatValue, baseCostPrice));
              }
              if (typeof finalCostPrice === 'number' && typeof floatValue === 'number') {
                tenderForm.setValue(
                  'decrease_percent',
                  calculateMargin(floatValue, finalCostPrice)
                );
              }
            },
          }}
          required
        />
        <FieldWithPreview.Select
          ref={createFieldRef('participate_as_id')}
          control={tenderForm.control}
          path="participate_as_id"
          edit={isFieldEditable('participate_as_id')}
          onFieldSelect={() => selectField('participate_as_id')}
          name="Участвуем от"
          options={
            flattedLegalEntities?.map(entity => ({
              content: entity.name,
              value: entity.id,
            })) || []
          }
          delayFilter={300}
          defaultOptions={
            createdLegalEntityParticipateAsForView
              ? [
                  {
                    content: createdLegalEntityParticipateAsForView.legalEntity_value,
                    value: createdLegalEntityParticipateAsForView.legalEntity_id,
                  },
                ]
              : response
                ? [
                    {
                      content: response.participate_as_value,
                      value: response.participate_as_id,
                    },
                  ]
                : undefined
          }
          onPlusClick={onClickCreateLegalEntitiesParticipateAsHandbook}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          filter={filterLegalEntities}
          onFilterChange={filter => setFilterLegalEntities(filter)}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
            loading:
              getHandbookLegalEntitiesInfiniteQuery.isFetching ||
              getHandbookLegalEntitiesInfiniteQuery.isFetchingNextPage ||
              getHandbookLegalEntitiesInfiniteQuery.isLoading ||
              getHandbookLegalEntitiesInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookLegalEntitiesInfiniteQuery.fetchNextPage();
            },
          }}
          required
        />
        <FieldWithPreview.TextArea
          ref={createFieldRef('comment')}
          control={tenderForm.control}
          path="comment"
          edit={isFieldEditable('comment')}
          onFieldSelect={() => selectField('comment')}
          name="Комментарий"
          wrapperProps={{
            textAlign: 'start',
          }}
          textAreaProps={{
            disabled,
          }}
        />
        <FieldWithPreview.Select
          ref={createFieldRef('implementation_method')}
          control={tenderForm.control}
          path="implementation_method"
          edit={isFieldEditable('implementation_method')}
          onFieldSelect={() => selectField('implementation_method')}
          name="Способ проведения"
          options={OPTIONS_MOCK['Способ проведения'].filter(v =>
            v.content.includes(filterImplementationMethod)
          )}
          defaultOptions={
            getGosplanQuery.data?.implementation_method &&
            (getGosplanQuery.data.implementation_method === 'Закупка ЕП' ||
              getGosplanQuery.data.implementation_method === 'Запрос котировок' ||
              getGosplanQuery.data.implementation_method === 'Запрос предложений' ||
              getGosplanQuery.data.implementation_method === 'Конкурс' ||
              getGosplanQuery.data.implementation_method === 'Электронный аукцион' ||
              getGosplanQuery.data.implementation_method === 'Запрос котировок в электронной форме')
              ? [
                  OPTIONS_MOCK['Способ проведения'].find(
                    v => v.value === getGosplanQuery.data.implementation_method
                  )!,
                ]
              : response
                ? [
                    {
                      content: response.implementation_method,
                      value: response.implementation_method,
                    },
                  ]
                : undefined
          }
          filter={filterImplementationMethod}
          onFilterChange={filter => setFilterImplementationMethod(filter)}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
          }}
          required
        />
        <FieldWithPreview.MultiSelect
          ref={createFieldRef('additional_information_ids')}
          control={tenderForm.control}
          path="additional_information_ids"
          edit={isFieldEditable('additional_information_ids')}
          onFieldSelect={() => selectField('additional_information_ids')}
          name="Ограничения, преимущества и доп. Требования"
          options={
            flattedRestrictionAdvantageRequirements?.map(requirement => ({
              content: requirement.name,
              value: requirement.id,
            })) || []
          }
          delayFilter={300}
          defaultOptions={
            createdRestrictionAdvantageRequirementForView
              ? [
                  {
                    content:
                      createdRestrictionAdvantageRequirementForView.restrictionAdvantageRequirement_value,
                    value:
                      createdRestrictionAdvantageRequirementForView.restrictionAdvantageRequirement_id,
                  },
                ]
              : response
                ? response.additional_information_ids.map((additional_information, i) => ({
                    value: additional_information,
                    content: response.additional_information_values[i],
                  }))
                : undefined
          }
          onPlusClick={onClickCreateRestrictionAdvantageRequirementsHandbook}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          filter={filterRestrictionAdvantageRequirements}
          onFilterChange={filter => setFilterRestrictionAdvantageRequirements(filter)}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
            loading:
              getHandbookRestrictionAdvantageRequirementsInfiniteQuery.isFetching ||
              getHandbookRestrictionAdvantageRequirementsInfiniteQuery.isFetchingNextPage ||
              getHandbookRestrictionAdvantageRequirementsInfiniteQuery.isLoading ||
              getHandbookRestrictionAdvantageRequirementsInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookRestrictionAdvantageRequirementsInfiniteQuery.fetchNextPage();
            },
          }}
          required
        />
        <FieldWithPreview.Currency
          ref={createFieldRef('tender_support')}
          control={tenderForm.control}
          path="tender_support"
          edit={isFieldEditable('tender_support')}
          onFieldSelect={() => selectField('tender_support')}
          name="Обеспечение заявки"
          numberFormatProps={{
            disabled,
            min: 0,
          }}
          required
        />
        <FieldWithPreview.Date
          ref={createFieldRef('submission_deadline')}
          control={tenderForm.control}
          path="submission_deadline"
          edit={isFieldEditable('submission_deadline')}
          onFieldSelect={() => selectField('submission_deadline')}
          name="Окончание подачи"
          format={DATE_FORMATS.fullDateWithTimeRounded}
          formatPreview={DATE_FORMATS.fullDateWithTimeRounded}
          datePickerProps={{
            placeholder: 'Дата и время',
            disabled,
          }}
          required
        />
        <FieldWithPreview.Date
          ref={createFieldRef('procedure_at')}
          control={tenderForm.control}
          path="procedure_at"
          edit={isFieldEditable('procedure_at')}
          onFieldSelect={() => selectField('procedure_at')}
          name="Проведение процедуры"
          format={DATE_FORMATS.fullDateWithTimeRounded}
          formatPreview={DATE_FORMATS.fullDateWithTimeRounded}
          datePickerProps={{
            placeholder: 'Дата и время',
            disabled,
            minValue: values['submission_deadline']
              ? dateTime({ input: values['submission_deadline'] })
              : undefined,
          }}
        />
        <FieldWithPreview.Date
          ref={createFieldRef('results_at')}
          control={tenderForm.control}
          path="results_at"
          edit={isFieldEditable('results_at')}
          onFieldSelect={() => selectField('results_at')}
          name="Подведение итогов"
          format={DATE_FORMATS.fullDate}
          formatPreview={DATE_FORMATS.fullDate}
          datePickerProps={{
            placeholder: 'Дата',
            disabled,
            minValue: values['procedure_at']
              ? dateTime({ input: values['procedure_at'] })
              : undefined,
          }}
          required
        />
        <FieldWithPreview.MultiFile
          control={tenderForm.control}
          path="documentation_files"
          name="Документация"
          edit={isFieldEditable('documentation_files', {
            whenDirty: false,
          })}
          onFieldSelect={() => selectField('documentation_files')}
          ref={createFieldRef('documentation_files')}
          maxSize={MAX_FILE_SIZE}
          notAllowedExtension={NOT_ALLOWED_FILE_EXTENSIONS}
          disabled={disabled}
          onLoadingChange={loading =>
            setFileFieldsLoading(prev => ({ ...prev, documentation_files: loading }))
          }
        />
        <FieldWithPreview.MultiFile
          control={tenderForm.control}
          path="comments_files"
          name="Разъяснения"
          edit={isFieldEditable('comments_files')}
          onFieldSelect={() => selectField('comments_files')}
          ref={createFieldRef('comments_files')}
          maxSize={MAX_FILE_SIZE}
          notAllowedExtension={NOT_ALLOWED_FILE_EXTENSIONS}
          disabled={disabled}
          onLoadingChange={loading =>
            setFileFieldsLoading(prev => ({ ...prev, comments_files: loading }))
          }
        />
        <FieldWithPreview.MultiFile
          control={tenderForm.control}
          path="protocol_files"
          name="Протоколы"
          edit={isFieldEditable('protocol_files')}
          onFieldSelect={() => selectField('protocol_files')}
          ref={createFieldRef('protocol_files')}
          maxSize={MAX_FILE_SIZE}
          notAllowedExtension={NOT_ALLOWED_FILE_EXTENSIONS}
          disabled={disabled}
          onLoadingChange={loading =>
            setFileFieldsLoading(prev => ({ ...prev, protocol_files: loading }))
          }
        />
        <FieldWithPreview.Select
          ref={createFieldRef('delivery_region_id')}
          control={tenderForm.control}
          path="delivery_region_id"
          edit={isFieldEditable('delivery_region_id')}
          onFieldSelect={() => selectField('delivery_region_id')}
          name="Регион поставки"
          options={
            flattedRegions?.map(region => ({
              content: region.name,
              value: region.id,
            })) || []
          }
          delayFilter={300}
          defaultOptions={
            createdRegionForView
              ? [
                  {
                    content: createdRegionForView.region_value,
                    value: createdRegionForView.region_id,
                  },
                ]
              : getGosplanQuery.data?.delivery_region && getGosplanQuery.data?.delivery_region.name
                ? [
                    {
                      content: getGosplanQuery.data.delivery_region.name,
                      value: getGosplanQuery.data.delivery_region.id,
                    },
                  ]
                : response
                  ? [
                      {
                        content: response.delivery_region_value,
                        value: response.delivery_region_id,
                      },
                    ]
                  : undefined
          }
          onPlusClick={onClickCreateRegionsHandbook}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          filter={filterRegions}
          onFilterChange={filter => setFilterRegions(filter)}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
            loading:
              getHandbookRegionsInfiniteQuery.isFetching ||
              getHandbookRegionsInfiniteQuery.isFetchingNextPage ||
              getHandbookRegionsInfiniteQuery.isLoading ||
              getHandbookRegionsInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookRegionsInfiniteQuery.fetchNextPage();
            },
          }}
          required
        />
        <FieldWithPreview.TextArea
          ref={createFieldRef('delivery_address')}
          control={tenderForm.control}
          path="delivery_address"
          edit={isFieldEditable('delivery_address')}
          onFieldSelect={() => selectField('delivery_address')}
          name="Адрес поставки"
          textAreaProps={{
            disabled,
          }}
          required
        />
        <FieldWithPreview.Text
          ref={createFieldRef('delivery_at')}
          control={tenderForm.control}
          path="delivery_at"
          edit={isFieldEditable('delivery_at')}
          onFieldSelect={() => selectField('delivery_at')}
          name="Срок поставки"
          textInputProps={{
            disabled,
          }}
        />
        <Divider />
        <Text
          variant="body-2"
          className="font-semibold uppercase"
        >
          Рабочие параметры
        </Text>
        {/* Рабочие параметры */}
        <FieldWithPreview.Currency
          ref={createFieldRef('base_cost_price')}
          control={tenderForm.control}
          path="base_cost_price"
          edit={
            !response || !!response?.is_blocked_work_params || !editTender
              ? false
              : isFieldEditable('base_cost_price')
          }
          onFieldSelect={() => selectField('base_cost_price')}
          name="Себестоимость"
          numberFormatProps={{
            disabled: disabled || !response || !!response?.is_blocked_work_params || !editTender,
            min: 0,
            onValueChange: ({ floatValue }) => {
              if (
                (typeof floatValue === 'number' && floatValue <= 0) ||
                selectedFieldPath !== 'base_cost_price'
              )
                return;
              const costPrice = values['cost_price'];
              const minCostPrice = values['min_cost_price'];
              const finalCostPrice = values['final_cost_price'];

              if (typeof costPrice === 'number' && typeof floatValue === 'number') {
                tenderForm.setValue('margin', calculateMargin(costPrice, floatValue));
              }
              if (typeof minCostPrice === 'number' && typeof floatValue === 'number') {
                tenderForm.setValue('min_margin', calculateMargin(minCostPrice, floatValue));
              }
              if (typeof finalCostPrice === 'number' && typeof floatValue === 'number') {
                tenderForm.setValue('final_margin', calculateMargin(finalCostPrice, floatValue));
              }
            },
          }}
        />
        <FieldWithPreview.Percentage
          ref={createFieldRef('margin')}
          control={tenderForm.control}
          path="margin"
          edit={false}
          onFieldSelect={() => selectField('margin')}
          name="Маржа начальная"
          numberFormatProps={{
            disabled: disabled || !response || !!response?.is_blocked_work_params || !editTender,
            max: 100,
            onValueChange: ({ floatValue }) => {
              if (
                (typeof floatValue === 'number' && floatValue >= 100) ||
                selectedFieldPath !== 'margin'
              )
                return;
              const baseCostPrice = values['base_cost_price'];
              if (typeof baseCostPrice === 'number' && typeof floatValue === 'number') {
                tenderForm.setValue('cost_price', calculatePrice(baseCostPrice, floatValue));
              }
            },
          }}
        />
        <FieldWithPreview.MultiFile
          control={tenderForm.control}
          path="calculation_files"
          name="Файл расчета процедуры"
          edit={
            !response || !!response?.is_blocked_work_params || !editTender
              ? false
              : isFieldEditable('calculation_files')
          }
          ref={createFieldRef('calculation_files')}
          onFieldSelect={() => selectField('calculation_files')}
          maxSize={MAX_FILE_SIZE}
          notAllowedExtension={NOT_ALLOWED_FILE_EXTENSIONS}
          disabled={disabled || !response || !!response?.is_blocked_work_params || !editTender}
          onLoadingChange={loading =>
            setFileFieldsLoading(prev => ({ ...prev, calculation_files: loading }))
          }
        />
        <FieldWithPreview.File
          control={tenderForm.control}
          path="request_file"
          name="Файл заявки"
          edit={
            !response || !!response?.is_blocked_work_params || !editTender
              ? false
              : isFieldEditable('request_file')
          }
          ref={createFieldRef('request_file')}
          onFieldSelect={() => selectField('request_file')}
          maxSize={MAX_FILE_SIZE}
          notAllowedExtension={NOT_ALLOWED_FILE_EXTENSIONS}
          disabled={disabled || !response || !!response?.is_blocked_work_params || !editTender}
          onLoadingChange={loading =>
            setFileFieldsLoading(prev => ({ ...prev, request_file: loading }))
          }
        />
        <FieldWithPreview.Currency
          ref={createFieldRef('specialist_min_cost_price')}
          control={tenderForm.control}
          path="specialist_min_cost_price"
          edit={
            !response || !!response?.is_blocked_work_params || !editTender
              ? false
              : isFieldEditable('specialist_min_cost_price')
          }
          onFieldSelect={() => selectField('specialist_min_cost_price')}
          name="Предлагаемая специалистом минимальная цена"
          numberFormatProps={{
            disabled: disabled || !response || !!response?.is_blocked_work_params || !editTender,
            min: 0,
          }}
        />
        <FieldWithPreview.TextArea
          ref={createFieldRef('min_cost_price_reason')}
          control={tenderForm.control}
          path="min_cost_price_reason"
          edit={
            !response || !!response?.is_blocked_work_params || !editTender
              ? false
              : isFieldEditable('min_cost_price_reason')
          }
          onFieldSelect={() => selectField('min_cost_price_reason')}
          name="Обоснование предложения минимальной цены"
          wrapperProps={{
            textAlign: 'start',
          }}
          textAreaProps={{
            disabled: disabled || !response || !!response?.is_blocked_work_params || !editTender,
          }}
        />
        <FieldWithPreview.TextArea
          ref={createFieldRef('approve_comment')}
          control={tenderForm.control}
          path="approve_comment"
          edit={false}
          onFieldSelect={() => selectField('approve_comment')}
          name="Комментарий согласования"
          wrapperProps={{
            textAlign: 'start',
          }}
          textAreaProps={{
            disabled: true,
          }}
        />
        <FieldWithPreview.Currency
          ref={createFieldRef('min_cost_price')}
          control={tenderForm.control}
          path="min_cost_price"
          edit={
            // !response || !!response?.is_blocked_work_params
            //   ? false
            //   : isFieldEditable('min_cost_price')
            false
          }
          onFieldSelect={() => selectField('min_cost_price')}
          name="Минимальная цена"
          numberFormatProps={{
            // disabled: disabled || !response || !!response?.is_blocked_work_params,
            disabled: true,
            min: 0,
            // onValueChange: ({ floatValue }) => {
            //   if (typeof floatValue === 'number' && floatValue <= 0) return;
            //   const baseCostPrice = values['base_cost_price'];

            //   if (typeof baseCostPrice === 'number' && typeof floatValue === 'number') {
            //     tenderForm.setValue('min_margin', calculateMargin(floatValue, baseCostPrice));
            //   }
            // },
          }}
        />
        <FieldWithPreview.Percentage
          ref={createFieldRef('min_margin')}
          control={tenderForm.control}
          path="min_margin"
          edit={false}
          onFieldSelect={() => selectField('min_margin')}
          name="Маржа минимальная"
          numberFormatProps={{
            // disabled: disabled || !response || !!response?.is_blocked_work_params,
            disabled: true,
            max: 100,
            // onValueChange: ({ floatValue }) => {
            //   if (typeof floatValue === 'number' && floatValue >= 100) return;
            //   const baseCostPrice = values['base_cost_price'];
            //   if (typeof baseCostPrice === 'number' && typeof floatValue === 'number') {
            //     tenderForm.setValue('min_cost_price', calculatePrice(baseCostPrice, floatValue));
            //   }
            // },
          }}
        />
        {(values.purchase_status === 'Состоялась' ||
          values['status'] === 'Выигран' ||
          values['status'] === 'Проигран') && (
          <>
            <Divider />
            <Text
              variant="body-2"
              className="font-semibold uppercase"
            >
              Итоговые параметры
            </Text>
          </>
        )}
        {/* Итоговые параметры */}
        <FieldWithPreview.Currency
          show={
            values['purchase_status'] === 'Состоялась' ||
            values['status'] === 'Выигран' ||
            values['status'] === 'Проигран'
          }
          ref={createFieldRef('final_cost_price')}
          control={tenderForm.control}
          path="final_cost_price"
          edit={isFieldEditable('final_cost_price')}
          onFieldSelect={() => selectField('final_cost_price')}
          name="Итоговая цена"
          numberFormatProps={{
            disabled,
            min: 0,
            onValueChange: ({ floatValue }) => {
              if (
                (typeof floatValue === 'number' && floatValue <= 0) ||
                selectedFieldPath !== 'final_cost_price'
              )
                return;
              const baseCostPrice = values['base_cost_price'];
              const costPrice = values.cost_price;

              if (typeof baseCostPrice === 'number' && typeof floatValue === 'number') {
                tenderForm.setValue('final_margin', calculateMargin(floatValue, baseCostPrice));
              }
              if (typeof costPrice === 'number' && typeof floatValue === 'number') {
                tenderForm.setValue('decrease_percent', calculateMargin(costPrice, floatValue));
              }
            },
          }}
          required
        />
        <FieldWithPreview.Percentage
          show={
            values['purchase_status'] === 'Состоялась' ||
            values['status'] === 'Выигран' ||
            values['status'] === 'Проигран'
          }
          ref={createFieldRef('decrease_percent')}
          control={tenderForm.control}
          path="decrease_percent"
          edit={false}
          onFieldSelect={() => selectField('decrease_percent')}
          name="Процент снижения"
          numberFormatProps={{
            disabled,
            // onValueChange: ({ floatValue }) => {
            //   if (typeof floatValue === 'number' && floatValue >= 100) return;
            //   const finalCostPrice = values['final_cost_price'];
            //   if (typeof finalCostPrice === 'number' && typeof floatValue === 'number') {
            //     tenderForm.setValue('min_cost_price', calculatePrice(finalCostPrice, floatValue));
            //   }
            // },
          }}
        />
        <FieldWithPreview.Percentage
          show={
            values['purchase_status'] === 'Состоялась' ||
            values['status'] === 'Выигран' ||
            values['status'] === 'Проигран'
          }
          ref={createFieldRef('final_margin')}
          control={tenderForm.control}
          path="final_margin"
          edit={false}
          onFieldSelect={() => selectField('final_margin')}
          name="Маржа итоговая"
          numberFormatProps={{
            disabled,
            max: 100,
            onValueChange: ({ floatValue }) => {
              if (
                (typeof floatValue === 'number' && floatValue >= 100) ||
                selectedFieldPath !== 'final_margin'
              )
                return;
              const baseCostPrice = values['base_cost_price'];
              if (typeof baseCostPrice === 'number' && typeof floatValue === 'number') {
                tenderForm.setValue('final_cost_price', calculatePrice(baseCostPrice, floatValue));
              }
            },
          }}
        />
        <FieldWithPreview.Select
          show={
            values['purchase_status'] === 'Состоялась' ||
            values['status'] === 'Выигран' ||
            values['status'] === 'Проигран'
          }
          ref={createFieldRef('winner_with_type')}
          control={tenderForm.control}
          path="winner_with_type"
          edit={isFieldEditable('winner_with_type')}
          delayFilter={300}
          onFieldSelect={() => selectField('winner_with_type')}
          name="Победитель"
          options={[
            { value: 'add-legal-entities', content: 'Добавить в «Наши организации»' },
            { value: 'add-competitors', content: 'Добавить в «Конкуренты»' },
            ...(flattedWinners?.map(winner => ({
              content: winner.name,
              value: `${winner.id}/${winner.type}`,
            })) || []),
          ]}
          defaultOptions={
            createdLegalEntityWinnerForView
              ? [
                  {
                    content: createdLegalEntityWinnerForView.legalEntity_value,
                    value: `${createdLegalEntityWinnerForView.legalEntity_id}/${
                      'legal_entity' as NonNullable<
                        bodyResponseType<'get', '/offer/{id}'>['winner_type']
                      >
                    }`,
                  },
                ]
              : createdCompetitorForView
                ? [
                    {
                      content: createdCompetitorForView.competitor_value,
                      value: `${createdCompetitorForView.competitor_id}/${
                        'competitor' as NonNullable<
                          bodyResponseType<'get', '/offer/{id}'>['winner_type']
                        >
                      }`,
                    },
                  ]
                : response && response.winner_id && response.winner_type
                  ? [
                      {
                        content: response.winner_value,
                        value: `${response.winner_id}/${response.winner_type}`,
                      },
                    ]
                  : undefined
          }
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          filter={filterWinners}
          onFilterChange={filter => setFilterWinners(filter)}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
            loading:
              getHandbookWinnersInfiniteQuery.isFetching ||
              getHandbookWinnersInfiniteQuery.isFetchingNextPage ||
              getHandbookWinnersInfiniteQuery.isLoading ||
              getHandbookWinnersInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookWinnersInfiniteQuery.fetchNextPage();
            },
            onUpdate: ([winnerWithType]) => {
              if (selectedFieldPath !== 'winner_with_type' || !winnerWithType) return;
              if (winnerWithType === 'add-legal-entities') {
                tenderForm.resetField('winner_id');
                tenderForm.resetField('winner_type');
                tenderForm.resetField('winner_with_type');
                return onClickCreateLegalEntitiesWinnerHandbook?.();
              }

              if (winnerWithType === 'add-competitors') {
                tenderForm.resetField('winner_id');
                tenderForm.resetField('winner_type');
                tenderForm.resetField('winner_with_type');
                return onClickCreateCompetitorsHandbook?.();
              }

              if (winnerWithType && response) {
                const winnerId = winnerWithType.split('/')[0]!;
                const winnerType = winnerWithType.split('/')[1]! as NonNullable<
                  typeof response.winner_type
                >;
                tenderForm.setValue('winner_id', winnerId);
                tenderForm.setValue('winner_type', winnerType);
              } else {
                tenderForm.resetField('winner_type');
                tenderForm.resetField('winner_id');
              }
            },
          }}
          required
        />
        <FieldWithPreview.MultiSelect
          show={
            values['purchase_status'] === 'Состоялась' ||
            values['status'] === 'Выигран' ||
            values['status'] === 'Проигран'
          }
          ref={createFieldRef('participant_ids')}
          control={tenderForm.control}
          path="participant_ids"
          edit={isFieldEditable('participant_ids')}
          onFieldSelect={() => selectField('participant_ids')}
          name="Участники (если имеются)"
          options={
            flattedCompetitors?.map(competitor => ({
              content: competitor.name,
              value: competitor.id,
            })) || []
          }
          delayFilter={300}
          defaultOptions={
            createdCompetitorParticipantsForView
              ? [
                  {
                    content: createdCompetitorParticipantsForView.competitor_value,
                    value: createdCompetitorParticipantsForView.competitor_id,
                  },
                ]
              : response
                ? response.participant_ids?.map((participant, i) => ({
                    value: participant,
                    content: response.participant_values?.[i],
                  }))
                : undefined
          }
          onPlusClick={onClickCreateCompetitorsParticipantsHandbook}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          filter={filterCompetitors}
          onFilterChange={filter => setFilterCompetitors(filter)}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
            loading:
              getHandbookCompetitorsInfiniteQuery.isFetching ||
              getHandbookCompetitorsInfiniteQuery.isFetchingNextPage ||
              getHandbookCompetitorsInfiniteQuery.isLoading ||
              getHandbookCompetitorsInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookCompetitorsInfiniteQuery.fetchNextPage();
            },
          }}
        />
        <FieldWithPreview.Select
          show={!!values['responsible_supervisor_id']}
          ref={createFieldRef('responsible_supervisor_id')}
          control={tenderForm.control}
          path="responsible_supervisor_id"
          edit={false}
          name="Руководитель ответственного"
          options={[]}
          defaultOptions={
            response?.responsible_supervisor_id
              ? [
                  {
                    content: response.responsible_supervisor_value,
                    value: response.responsible_supervisor_id,
                  },
                ]
              : undefined
          }
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            disabled: true,
          }}
        />
      </FieldWithPreview>
      {!hideBottomPanel && (
        <RightSidebar.BottomPanel>
          <Button
            size="xl"
            view="normal"
            width="max"
            onClick={onClickCancelButton}
            disabled={disabled || isFilesLoading}
          >
            {cancelButtonText}
          </Button>
          <Button
            size="xl"
            view="action"
            type="submit"
            width="max"
            onClick={tenderForm.handleSubmit(valid => {
              onValid(valid);
              setCreatedEmployeeForView(undefined);
              setCreatedETradingPlatformForView(undefined);
              setCreatedCustomerForView(undefined);
              setCreatedCustomerOrganizerForView(undefined);
              setCreatedPurchaseObjectForView(undefined);
              setCreatedLegalEntityParticipateAsForView(undefined);
              setCreatedRestrictionAdvantageRequirementForView(undefined);
              setCreatedRegionForView(undefined);
              setCreatedLegalEntityWinnerForView(undefined);
              setCreatedCompetitorForView(undefined);
              setCreatedCompetitorParticipantsForView(undefined);
            }, onInvalid)}
            disabled={disabled || isFilesLoading}
          >
            {submitButtonTextForAgreement ? 'Согласовать' : submitButtonText || 'Применить'}
          </Button>
        </RightSidebar.BottomPanel>
      )}
    </form>
  );
}
